import {
  CallsheetStatuses,
  CallsheetStatusType,
} from 'src/store/Callsheet/types'

const style = {
  wrapper: (isClickable: boolean) => ({
    boxShadow: '0px 5px 8px 0px #8585851A',
    border: '1px solid #E2E2E2',
    borderRadius: '8px',
    ...(isClickable && { '> div:first-of-type': { cursor: 'pointer' } }),
  }),
  titleBox: {
    '> * + * ': { mt: '4px', ml: '12px' },
    mb: '12px',
    width: '240px',
  },
  title: {
    fontWeight: 900,
    fontSize: '18px',
    lineHeight: '28px',
    color: 'secondary.dark',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  imageBox: {
    p: '3px',
    backgroundColor: '#ECF6FF',
    height: '28px',
    width: '50px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  statisticsBox: {
    display: 'flex',
    alignItems: 'center',
    px: '20px',
    gap: 1,
  },
  statusNumber: (status: CallsheetStatusType) => ({
    fontSize: '20px',
    fontWeight: 900,
    lineHeight: '28px',
    ...(status === CallsheetStatuses.ACCEPTED && {
      color: '#38C171',
    }),
    ...(status === CallsheetStatuses.DECLINED && {
      color: '#FF3B30',
    }),
    ...(status === CallsheetStatuses.TENTATIVE && {
      color: '#FB8333',
    }),
  }),
}

export default style
