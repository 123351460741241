const style = {
  dataBox: (color: string, link?: boolean) => ({
    width: '300px',
    backgroundColor: color,
    borderRadius: '12px',
    padding: 1,
    gap: '12px',
    alignItems: 'flex-start',
    h6: {
      display: 'flex',
      gap: 0,
    },
    ...(link && {
      svg: {
        path: {
          stroke: '#265A88',
        },
      },
    }),
  }),
  chip: (color: string) => ({
    backgroundColor: color,
    color: 'white',
    padding: '0px',
    borderRadius: '26px',
    fontWeight: 900,
    fontSize: '10px',
    lineHeight: '20px',
    minWidth: '98px',
    height: '20px',
    span: {
      padding: '0px 16px',
    },
  }),
}
export default style
