const style = {
  title: {
    fontSize: '28px',
    fontWeight: 500,
    lineHeight: '36px',
    color: 'text.primary',
  },
  info: {
    mt: '2px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: 'text.primary',
  },
  wrapper: {
    margin: 'auto',
    maxWidth: '600px',
  },
  announcementCard: (important: boolean, selected: boolean) => ({
    p: 2,

    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '16px',
    border: '1px solid #E2E2E2',
    boxShadow: ' 0px 5px 8px 0px #8585851A',
    color: 'text.secondary',
    svg: {
      path: {
        stroke: important ? '#FF3B30' : '#1F1F1F',
      },
    },

    ...(important && { border: '1px solid #FF3B30', color: 'error.main' }),
    ...(selected && {
      border: '1px solid #38C171',
      color: '#38C171',
      svg: {
        path: {
          fill: '#38C171',
        },
      },
    }),
  }),
}

export default style
