import {
  CallsheetStatuses,
  CallsheetStatusType,
} from 'src/store/Callsheet/types'

const style = {
  status: (status: CallsheetStatusType) => ({
    color: 'text.disabled',
    ...(status === CallsheetStatuses.PUBLISHED && {
      color: '#265A88',
      borderColor: '#265A88',
    }),
    ...(status === CallsheetStatuses.LIVE && {
      color: '#FF3B30',
      borderColor: '#FF3B30',
    }),
    ...(status === CallsheetStatuses.COMPLETED && {
      color: '#38C171',
      borderColor: '#38C171',
    }),
    ...(status === CallsheetStatuses.PENDING && {
      color: '#7C9CB7',
      borderColor: '#7C9CB7',
    }),
    ...(status === CallsheetStatuses.TENTATIVE && {
      color: '#FA6400',
      borderColor: '#FA6400',
    }),
    ...(status === CallsheetStatuses.DELETED && {
      color: '#CC2E26',
      borderColor: '#CC2E26',
    }),
    fontSize: '12px',
    fontWeight: 900,
    lineHeight: '20px',
    px: '8px',
    '& .MuiChip-label': {
      textTransform: 'capitalize',
    },
  }),
}

export default style
