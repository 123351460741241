import { noop } from 'lodash'
import React, { FC } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material'

import style from './style'

const dialog = {
  standard: {
    maxWidth: '300px',
    btnCancelColor: '#1F1F1F',
    btnConfirmColor: 'error.main',
  },
  apply: {
    maxWidth: '464px',
    btnCancelColor: '#FF3B30',
    btnConfirmColor: '#265A88',
  },
}

type ConfirmDialogProps = {
  variant?: 'standard' | 'apply'
  isOpen: boolean
  title: string
  content: string
  confirmTitle?: string
  cancelTitle?: string
  handleCancel: typeof noop
  handleConfirm: typeof noop
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  isOpen,
  title,
  content,
  confirmTitle = 'Ok',
  cancelTitle = 'Cancel',
  handleCancel,
  handleConfirm,
  variant = 'standard',
}) => {
  const { btnCancelColor, btnConfirmColor, maxWidth } = dialog[variant]
  return (
    <Dialog open={isOpen}>
      <Stack sx={style.wrapper}>
        <DialogTitle sx={style.title}>{title}</DialogTitle>
        <DialogContent sx={style.content(maxWidth)}>{content}</DialogContent>
        <DialogActions sx={style.btn_wrapper(btnCancelColor, btnConfirmColor)}>
          <Button onClick={handleCancel} fullWidth variant='outlined'>
            {cancelTitle}
          </Button>
          <Button onClick={handleConfirm} fullWidth variant='contained'>
            {confirmTitle}
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  )
}

export default ConfirmDialog
