import React, { FC } from 'react'
import { noop } from 'lodash'
import { Box, InputLabel } from '@mui/material'
import CustomCheckbox from '../../../../../components/CustomCheckbox/CustomCheckbox'

type SaveToCatalogFieldTypes = {
  companyType: string
  checked: boolean
  onChange: typeof noop
}

const SaveToCatalogField: FC<SaveToCatalogFieldTypes> = ({
  companyType,
  checked,
  onChange,
}) => {
  return (
    <Box>
      <InputLabel
        sx={{
          fontSize: '14px',
          color: 'text.secondary',
          textAlign: 'right',
          mb: 1,
        }}
      >
        <span style={{ textTransform: 'initial' }}>
          Save {companyType} company data to catalog
        </span>{' '}
        <CustomCheckbox
          onChange={onChange}
          checked={checked}
          sx={{ p: '0px' }}
        />
      </InputLabel>
    </Box>
  )
}

export default SaveToCatalogField
