const style = {
  card: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    padding: '12px',
    height: '52px',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  editBtn: {
    p: '0px',
  },
  label: {
    color: '#0F2436',
  },
  name: {
    fontSize: '14px',
    fontWeight: 900,
    color: 'primary.light',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  address: {
    fontWeight: 400,
    color: 'text.secondary',
  },
}

export default style
