const style = {
  avatarBox: (width: string) => ({
    width,
    maxHeight: width,
    borderRadius: '4px',
  }),
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '32px',
    borderRadius: '4px',
    backgroundColor: '#D3DEE7',
    svg: {
      width: '18px',
    },
  },
  avatar: (src?: string) => ({
    width: '32px',
    height: '32px',
    borderRadius: '4px',
    background: `url(${src}), #F6F6F6`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }),
}

export default style
