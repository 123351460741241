import { AxiosResponse } from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'
import { SampleError } from '../../api/types'
import {
  createUserApi,
  getUserApi,
  getUserByIdApi,
  updateUserApi,
} from '../../api/user'
import {
  saveUser,
  userRequest,
  userRequestError,
  userRequestSuccess,
} from './actions'

import { GetUserResultType } from '../../api/user/types'
import {
  GET_USER,
  GET_USER_BY_ID,
  UPDATE_USER_REQUEST,
  CREATE_USER_REQUEST,
} from './actionTypes'
import { setNotificationErrorWorker } from '../Notification/sagas'
import { CreateUserAction, GetUserByIdAction, UpdateUserAction } from './types'

export function* GetUserWorker() {
  yield put(userRequest())
  try {
    const {
      data: { data },
    }: AxiosResponse<GetUserResultType> = yield call(getUserApi)
    yield put(saveUser(data))
    yield put(userRequestSuccess())
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(userRequestError())
  }
}

export function* GetUserByIdWorker({ payload: { id } }: GetUserByIdAction) {
  yield put(userRequest())
  try {
    const {
      data: { data },
    }: AxiosResponse<GetUserResultType> = yield call(getUserByIdApi, id)
    yield put(saveUser(data))
    yield put(userRequestSuccess())
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(userRequestError())
  }
}

export function* UpdateUserWorker({
  payload: { userId, user },
}: UpdateUserAction) {
  yield put(userRequest())
  try {
    yield call(updateUserApi, userId, user)
    yield put(userRequestSuccess())
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(userRequestError())
  }
}

export function* CreateUserWorker({ payload: { user } }: CreateUserAction) {
  yield put(userRequest())
  try {
    yield call(createUserApi, user)
    yield put(userRequestSuccess())
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(userRequestError())
  }
}

export function* requestUserWatcher(): Generator {
  yield takeLatest(GET_USER, GetUserWorker)
  yield takeLatest(GET_USER_BY_ID, GetUserByIdWorker)
  yield takeLatest(UPDATE_USER_REQUEST, UpdateUserWorker)
  yield takeLatest(CREATE_USER_REQUEST, CreateUserWorker)
}
