import { toLower } from 'lodash'
import React, { ChangeEventHandler, FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Stack, Typography } from '@mui/material'

import { selectIsGroupMembersPanel } from '../../../../store/Builder/selectors'
import { CallTime, GroupMember } from '../../../../store/GroupMembers/types'
import { selectIsStackDisplay } from '../../../../store/Groups/selectors'
import {
  deleteGroupMember,
  setGroupMemberCallTime,
  setGroupMemberVisibility,
  setGroupMemberWrapTime,
} from '../../../../store/GroupMembers/actions'
import { formatPhoneNumber } from '../../../../utils/contactHelpers'

import TimePickerDropdown from '../../../../components/TimePickerDropdown'
import CustomTooltip from '../../../../components/CustomTooltip'
import AvatarBox from '../../../../components/AvatarBox'

import {
  TrashcanIcon,
  EyeIcon,
  EyeHiddenIcon,
  EyeDisabledIcon,
} from '../../../../assets/icons'

import { gridsLong, gridsShort } from './constants'
import { CALLTIME_PLACEHOLDER } from '../../../../constants'
import style from './style'

const Member: FC<GroupMember & { isPreview?: boolean }> = ({
  id,
  firstName,
  lastName,
  title,
  email,
  phone,
  logo,
  hidePersonalInfo,
  callTime,
  wrapTime,
  isPrimary,
  notes,
  isPreview,
}) => {
  const isStackDisplay = useSelector(selectIsStackDisplay)
  const isGroupMembersPanel = useSelector(selectIsGroupMembersPanel)
  const grids = !isStackDisplay || isGroupMembersPanel ? gridsShort : gridsLong

  const dispatch = useDispatch()

  const handleDelete = () => {
    dispatch(deleteGroupMember(id))
  }

  const handleVisibility = () => {
    dispatch(setGroupMemberVisibility(id))
  }

  const handleCallTime =
    (time: CallTime): ChangeEventHandler<HTMLTextAreaElement> =>
    ({ target: { value } }) => {
      if (time === CallTime.START_TIME) {
        dispatch(setGroupMemberCallTime(id, value))
        return
      }
      dispatch(setGroupMemberWrapTime(id, value))
    }
  return (
    <Grid container sx={style.wrapper}>
      <Grid item xs={isPreview ? 12 : 11}>
        <Grid container sx={style.content}>
          <Grid item xs={grids.name}>
            <Box sx={style.avatar_wrapper}>
              <AvatarBox img={logo} />
              <Stack>
                <Typography variant='body2'>{`${firstName} ${lastName}`}</Typography>
                <Typography variant='body1' color='text.disabled'>
                  {title}
                </Typography>
              </Stack>
            </Box>
          </Grid>
          {isStackDisplay && !isGroupMembersPanel && (
            <Grid item xs={grids.phone}>
              {!hidePersonalInfo ? (
                <Typography sx={style.email_phone}>
                  {formatPhoneNumber(phone)}
                </Typography>
              ) : (
                <CustomTooltip
                  title={phone}
                  variant='light'
                  placement='top'
                  arrow
                >
                  <Typography variant='body1' color='text.secondary'>
                    Hidden
                  </Typography>
                </CustomTooltip>
              )}
            </Grid>
          )}
          <Grid item xs={grids.email}>
            {!hidePersonalInfo ? (
              <Typography sx={style.email_phone}>{email}</Typography>
            ) : (
              <CustomTooltip
                title={email}
                variant='light'
                placement='top'
                arrow
              >
                <Typography variant='body1' color='text.secondary'>
                  Hidden
                </Typography>
              </CustomTooltip>
            )}
          </Grid>
          <Grid item xs={grids.callTime}>
            <TimePickerDropdown
              placeholder={CALLTIME_PLACEHOLDER}
              value={toLower(callTime)}
              endLimit={!wrapTime ? '11:45pm' : toLower(wrapTime)}
              onChange={handleCallTime(CallTime.START_TIME)}
              disabled={isPreview}
            />
          </Grid>
          <Grid item xs={grids.wrapTime}>
            <TimePickerDropdown
              placeholder={CALLTIME_PLACEHOLDER}
              value={toLower(wrapTime)}
              beginLimit={!callTime ? '12:00am' : toLower(callTime)}
              onChange={handleCallTime(CallTime.WRAP_TIME)}
              disabled={isPreview}
            />
          </Grid>
          {isStackDisplay && !isGroupMembersPanel && (
            <Grid item xs={grids.notes}>
              <Typography variant='body1' color='text.secondary'>
                {notes || '-'}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      {isPreview ? null : (
        <Grid item xs={1}>
          <Box sx={style.icon_wrapper}>
            {isPrimary ? (
              <EyeDisabledIcon />
            ) : (
              <>
                {!hidePersonalInfo ? (
                  <CustomTooltip
                    variant='colored'
                    title='You can hide contact personal details'
                    placement='top'
                  >
                    <EyeIcon onClick={handleVisibility} className='clickable' />
                  </CustomTooltip>
                ) : (
                  <EyeHiddenIcon
                    onClick={handleVisibility}
                    className='clickable'
                  />
                )}
              </>
            )}

            <TrashcanIcon onClick={handleDelete} className='clickable' />
          </Box>
        </Grid>
      )}
    </Grid>
  )
}

export default Member
