import { SampleError } from 'src/api/types'
import { History } from 'history'
import {
  ACCEPT_ANNOUNCEMENT,
  ACCEPT_CALLSHEET_REQUEST,
  ACCEPT_CONFIRMATION_REQUEST,
  CALLSHEET_CHANGE_STATUS_REQUEST_ERROR,
  CALLSHEET_CHANGE_STATUS_REQUEST_SUCCESS,
  DECLINE_CALLSHEET_REQUEST,
  DECLINE_CONFIRMATION_REQUEST,
  DUPLICATE_CALLSHEET_REQUEST,
  PENDING_CALLSHEET_REQUEST,
  PUBLISH_CALLSHEET_REQUEST,
  PUBLISH_CALLSHEET_REQUEST_ERROR,
  PUBLISH_CALLSHEET_REQUEST_SUCCESS,
  RESTORE_CALLSHEET_REQUEST,
  SET_ANNOUNVEMENTS_TO_ACCEPT,
  SET_CONFIRMATION_STEP,
  TENTATIVE_CALLSHEET_REQUEST,
  TENTATIVE_CONFIRMATION_REQUEST,
} from './actionTypes'
import {
  AcceptAnnouncement,
  AcceptCallsheetRequestType,
  AcceptConfirmationRequestType,
  CallsheetChangeStatusRequestErrorType,
  CallsheetChangeStatusRequestSuccessType,
  DeclineCallsheetRequestType,
  DeclineConfirmationRequestType,
  DuplicateCallsheetRequestType,
  PendingCallsheetRequestType,
  PublishCallsheetRequestErrorType,
  PublishCallsheetRequestSuccessType,
  PublishCallsheetRequestType,
  RestoreCallsheetRequestType,
  SetAnnouncementsToAccept,
  SetConfirmationStep,
  TentativeCallsheetRequestType,
  TentativeConfirmationRequestType,
} from './types'

export const setConfirmationStep = (step: number): SetConfirmationStep => ({
  type: SET_CONFIRMATION_STEP,
  step,
})

export const acceptAnnouncement = (id: string): AcceptAnnouncement => ({
  type: ACCEPT_ANNOUNCEMENT,
  id,
})

export const setAnnouncementsToAccept = (
  announcementsToAccept: (string | undefined)[]
): SetAnnouncementsToAccept => ({
  type: SET_ANNOUNVEMENTS_TO_ACCEPT,
  announcementsToAccept,
})

export const declineCallsheetRequest = (): DeclineCallsheetRequestType => ({
  type: DECLINE_CALLSHEET_REQUEST,
})

export const pendingCallsheetRequest = (): PendingCallsheetRequestType => ({
  type: PENDING_CALLSHEET_REQUEST,
})

export const declineConfirmationRequest =
  (): DeclineConfirmationRequestType => ({
    type: DECLINE_CONFIRMATION_REQUEST,
  })

export const tentativeCallsheetRequest = (): TentativeCallsheetRequestType => ({
  type: TENTATIVE_CALLSHEET_REQUEST,
})
export const tentativeConfimationRequest =
  (): TentativeConfirmationRequestType => ({
    type: TENTATIVE_CONFIRMATION_REQUEST,
  })

export const acceptCallsheetRequest = (): AcceptCallsheetRequestType => ({
  type: ACCEPT_CALLSHEET_REQUEST,
})

export const acceptConfirmationRequest = (): AcceptConfirmationRequestType => ({
  type: ACCEPT_CONFIRMATION_REQUEST,
})

export const duplicateCallsheetRequest = (
  id: string
): DuplicateCallsheetRequestType => ({
  type: DUPLICATE_CALLSHEET_REQUEST,
  payload: { id },
})

export const restoreCallsheetRequest = (
  id: string
): RestoreCallsheetRequestType => ({
  type: RESTORE_CALLSHEET_REQUEST,
  payload: { id },
})

export const callsheetChangeStatusRequestSuccess = (
  id?: string
): CallsheetChangeStatusRequestSuccessType => ({
  type: CALLSHEET_CHANGE_STATUS_REQUEST_SUCCESS,
  id,
})

export const callsheetChangeStatusRequestError = (
  error: SampleError
): CallsheetChangeStatusRequestErrorType => ({
  type: CALLSHEET_CHANGE_STATUS_REQUEST_ERROR,
  error,
})

export const publishCallsheetRequest = (
  history: History
): PublishCallsheetRequestType => ({
  type: PUBLISH_CALLSHEET_REQUEST,
  history,
})

export const publishCallsheetRequestSuccess =
  (): PublishCallsheetRequestSuccessType => ({
    type: PUBLISH_CALLSHEET_REQUEST_SUCCESS,
  })

export const publishCallsheetRequestError = (
  error: SampleError
): PublishCallsheetRequestErrorType => ({
  type: PUBLISH_CALLSHEET_REQUEST_ERROR,
  error,
})
