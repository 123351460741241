import { Stack, Typography } from '@mui/material'
import React, { FC, PropsWithChildren } from 'react'

const LabeledContainer: FC<{ label: string } & PropsWithChildren> = ({
  label,
  children,
}) => (
  <Stack gap='4px'>
    <Typography variant='body1' color='text.disabled'>
      {label}
    </Typography>
    {children}
  </Stack>
)

export default LabeledContainer
