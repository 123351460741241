import React, { SyntheticEvent, useState, FC } from 'react'
import { Tabs, Tab } from '@mui/material'

import style from './style'

type CustomTabsProps = {
  tabLabels: string[]
  onTabClick?: (name: string) => () => void
}

const CustomTabs: FC<CustomTabsProps> = ({ tabLabels, onTabClick }) => {
  const [tabIndex, setTabIndex] = useState(0)

  const handleTabsChange = (event: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  return (
    <Tabs value={tabIndex} onChange={handleTabsChange} sx={style.tabs}>
      {tabLabels.map(label => (
        <Tab
          key={label}
          label={label}
          sx={style.tab}
          onClick={onTabClick && onTabClick(label)}
        />
      ))}
    </Tabs>
  )
}

export default CustomTabs
