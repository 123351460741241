import { pickBy } from 'lodash'

interface ObjectWithFields {
  [key: string]: any
}

interface OptionsTypes {
  caseSensitive?: boolean
  exactMatch?: boolean
}

export const removeEmptyFields = (obj: ObjectWithFields): ObjectWithFields => {
  return pickBy(obj, value => value !== '')
}

const flatten = (array: any[]): [] => {
  return array.reduce(
    (flat, toFlatten) =>
      flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten),
    []
  )
}

export const getValuesForKey = (key: string, item: any) => {
  const keys = key.split('.')
  let results = [item]
  keys.forEach((curKey: string) => {
    const tmp: string[] = []
    results.forEach(result => {
      if (result) {
        if (result instanceof Array) {
          const index = parseInt(curKey, 10)
          if (!Number.isNaN(index)) {
            return tmp.push(result[index])
          }
          result.forEach(res => {
            tmp.push(res[curKey])
          })
        } else if (result && typeof result.get === 'function') {
          tmp.push(result.get(curKey))
        } else {
          tmp.push(result[curKey])
        }
      }
    })

    results = tmp
  })

  // Support arrays and Immutable lists.
  results = results.map(r => (r && r.push && r.toArray ? r.toArray() : r))
  results = flatten(results)

  return results.filter(r => typeof r === 'string' || typeof r === 'number')
}

export const searchStrings = (
  strings: string[],
  term: string | RegExp,
  options: OptionsTypes = {}
) => {
  const { caseSensitive, exactMatch } = options
  strings = strings.map(e => e.toString())

  try {
    return strings.some(value => {
      try {
        if (!caseSensitive) {
          value = value.toLowerCase()
        }
        if (exactMatch) {
          term = new RegExp(`^${term}$`, 'i')
        }
        return !!(value && value.search(term) !== -1)
      } catch (e) {
        return false
      }
    })
  } catch (e) {
    return false
  }
}

export function createFilter(
  term: string,
  keys: string[],
  options: { caseSensitive?: boolean } = {}
) {
  return (item: any) => {
    if (term === '') {
      return true
    }

    if (!options.caseSensitive) {
      term = term.toLowerCase()
    }

    const terms = term.split(' ')

    if (!keys) {
      return terms.every((_term: string) =>
        searchStrings([item], _term, options)
      )
    }

    if (typeof keys === 'string') {
      keys = [keys]
    }

    return terms.every(curTerm => {
      // allow search in specific fields with the syntax `field:search`
      let currentKeys
      if (curTerm.indexOf(':') !== -1) {
        const searchedField = curTerm.split(':')[0]
        // eslint-disable-next-line prefer-destructuring
        curTerm = curTerm.split(':')[1]
        currentKeys = keys.filter(
          key => key.toLowerCase().indexOf(searchedField) > -1
        )
      } else {
        currentKeys = keys
      }

      return currentKeys.some(key => {
        const values = getValuesForKey(key, item)
        return searchStrings(values, curTerm, options)
      })
    })
  }
}
