import React, { ChangeEvent, useState } from 'react'
import {
  Box,
  Button,
  Chip,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'

import style from './style'

interface ITemplateVariables {
  [key: string]: string
}

interface IEditorProps {
  onSubmit?: (message: string) => void
  onChange?: (message: string) => void
  variables: ITemplateVariables
  placeholder?: string
  variant: 'select' | 'tag'
  submitBtn?: boolean
  value?: string
}

export const Editor = ({
  onSubmit,
  onChange,
  variables,
  placeholder,
  variant,
  value,
  submitBtn = false,
}: IEditorProps) => {
  const [message, setMessage] = useState(value ?? '')

  const handleVariableSelect = (event: SelectChangeEvent) => {
    const variable = event.target.value as string
    setMessage(prevMessage => prevMessage + variables[variable])
    event.target.value = ''
  }

  const handleTagSelect = (tag: string) => () => {
    setMessage(prevMessage => {
      const nextMessage = prevMessage + variables[tag]
      onChange && onChange(nextMessage)
      return nextMessage
    })
  }

  const handleSubmit = () => {
    onSubmit && onSubmit(message)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value)
    onChange && onChange(e.target.value)
  }

  return (
    <Box>
      <Stack>
        <TextField
          value={message}
          onChange={handleChange}
          placeholder={placeholder || 'Enter your message here'}
          multiline
          fullWidth
          size='medium'
          style={{ marginBottom: '10px' }}
        />
        {variant === 'tag' ? (
          <Box display='flex' gap={0}>
            <Typography variant='body2'>Variables:</Typography>
            <Box sx={style.tagsWrapper}>
              {Object.keys(variables).map(variable => (
                <Chip
                  key={variable}
                  label={variable}
                  sx={style.tag}
                  onClick={handleTagSelect(variable)}
                />
              ))}
            </Box>
          </Box>
        ) : (
          <FormControl sx={{ minWidth: 220 }}>
            <Select
              labelId='variable-select-label'
              id='variable-select'
              value='Select a variable'
              size='medium'
              color='secondary'
              onChange={handleVariableSelect}
            >
              <MenuItem value='Select a variable' disabled>
                Select a variable
              </MenuItem>
              {Object.keys(variables).map(variable => (
                <MenuItem key={variable} value={variable}>
                  {variable}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Stack>
      {submitBtn && (
        <div style={{ marginTop: '10px' }}>
          <Button variant='contained' onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      )}
    </Box>
  )
}

const variables = {
  firstname: '[FIRST_NAME]',
  lastname: '[LAST_NAME]',
}

const SmsEditor = () => {
  const handleSubmit = (message: string) => {
    console.log(message)
  }

  return (
    <Editor
      variant='tag'
      variables={variables}
      onSubmit={handleSubmit}
      placeholder='Please, enter SMS text'
    />
  )
}

export default SmsEditor
