import { SampleError } from 'src/api/types'
import { Callsheet, CallsheetStatusType } from '../Callsheet/types'
import {
  DELETE_CALLSHEET_REQUEST,
  DELETE_CALLSHEET_REQUEST_ERROR,
  DELETE_CALLSHEET_REQUEST_SUCCESS,
  SET_CALLSHEETS_FILTER,
  SET_CALLSHEET_TO_DELETE,
  SET_DELETE_CALLSHEET_MODAL,
  SET_IS_PERMANENT_DELETE,
  UPDATE_CALLSHEET_EVENT_DATE_REQUEST,
} from './actionTypes'
import {
  DeleteCallsheetRequestErrorType,
  DeleteCallsheetRequestSuccessType,
  DeleteCallsheetRequestType,
  SetCallsheetToDelete,
  SetDeleteCallsheetModal,
  SetIsPermanentDelete,
  UpdateCallsheetEventDateRequestType,
} from './types'

export const setCallsheetToDelete = (
  callsheetToDeleteId: string,
  title: string
): SetCallsheetToDelete => ({
  type: SET_CALLSHEET_TO_DELETE,
  callsheetToDeleteId,
  title,
})

export const setDeleteCallsheetModal = (
  deleteCallsheetModal: boolean
): SetDeleteCallsheetModal => ({
  type: SET_DELETE_CALLSHEET_MODAL,
  deleteCallsheetModal,
})

export const setIsPermanentDelete = (
  isPermanentDelete: boolean
): SetIsPermanentDelete => ({
  type: SET_IS_PERMANENT_DELETE,
  isPermanentDelete,
})

export const deleteCallsheetRequest = (
  callsheetId: string
): DeleteCallsheetRequestType => ({
  type: DELETE_CALLSHEET_REQUEST,
  payload: {
    callsheetId,
  },
})

export const deleteCallsheetRequestSuccess =
  (): DeleteCallsheetRequestSuccessType => ({
    type: DELETE_CALLSHEET_REQUEST_SUCCESS,
  })

export const deleteCallsheetRequestError = (
  error: SampleError
): DeleteCallsheetRequestErrorType => ({
  type: DELETE_CALLSHEET_REQUEST_ERROR,
  error,
})

export const updateCallsheetEventDateRequest = (
  callsheet: Partial<Callsheet>
): UpdateCallsheetEventDateRequestType => ({
  type: UPDATE_CALLSHEET_EVENT_DATE_REQUEST,
  payload: callsheet,
})
