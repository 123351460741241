import { CallsheetViewsType } from '../Callsheet/types'
import { AppState } from '../types'

export const selectCallsheetsView = (state: AppState): CallsheetViewsType =>
  state.galleryState.view

export const selectCallsheetsFilterTags = (state: AppState) =>
  state.galleryState.filterTags
export const selectCallsheetsSortTag = (state: AppState) =>
  state.galleryState.sortTag
export const selectCallsheetStatisticId = (state: AppState): string =>
  state.galleryState.callsheetStatisticId
export const selectIsCallsheetStatisticModal = (state: AppState): boolean =>
  state.galleryState.callsheetStatisticModal
