const style = {
  logo: (src?: string) => ({
    background: src ? `url(${src})` : '#F6F6F6',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '40px',
    height: '40px',
    borderRadius: '8px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),

  avatarWrapper: {
    display: 'flex',
    gap: 0,
    alignItems: 'center',
  },

  name: {
    whiteSpace: 'nowrap',
    maxWidth: '25ch',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}

export default style
