import axios, { AxiosPromise } from 'axios'
import { apiClient } from '../clients'
import { METHODS, URLS } from '../constants'

export const getAnnouncementApi = (id: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.GET,
    url: `${URLS.CALLSHEET}/${id}/announcement`,
  })

export const deleteAnnouncementApi = (id: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.DELETE,
    url: `${URLS.CALLSHEET}/announcement/${id}`,
  })

export const addAnnouncementApi = (
  callsheetId: string,
  important: boolean,
  content: string
): AxiosPromise =>
  apiClient.request({
    method: METHODS.POST,
    url: `${URLS.CALLSHEET}/announcement`,
    data: {
      callsheetId,
      important,
      content,
    },
  })

export const updateAnnouncementApi = (
  id: string,
  important: boolean,
  content: string
): AxiosPromise =>
  apiClient.request({
    method: METHODS.PATCH,
    url: `${URLS.CALLSHEET}/announcement/${id}`,
    data: {
      important,
      content,
    },
  })
