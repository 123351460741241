const style = {
  wrapper: {
    cursor: 'pointer',
    boxShadow: '0px 5px 8px rgba(133, 133, 133, 0.1)',
  },
  content: {
    p: '16px',
    display: 'flex',
    textAlign: 'left',
  },
  iconContent: {
    pr: 1,
  },
  textContent: {
    pr: 1,
  },
  label: {
    cursor: 'pointer',
  },
}

export default style
