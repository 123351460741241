import React, { FC } from 'react'
import { Box, Step, StepLabel, Stepper } from '@mui/material'
import Connector from './Connector'

import style from './style'
import CustomStepIcon, {
  BuilderStepIcon,
} from './CustomStepIcon/CustomStepIcon'

const CustomStepper: FC<{
  steps: string[]
  activeStep?: number
  builderStepper: boolean
}> = ({ steps, activeStep, builderStepper = true }) => (
  <Box sx={style.progressBar}>
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      connector={<Connector />}
      sx={style.stepper(builderStepper)}
    >
      {steps.map(label => (
        <Step key={label} sx={style.step}>
          <StepLabel
            StepIconComponent={
              builderStepper ? BuilderStepIcon : CustomStepIcon
            }
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  </Box>
)

export default CustomStepper
