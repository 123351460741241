const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
export const TAGS = ['Condensed', 'Expanded']
export const DEFAULT_TAG = 'Condensed'
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
  muiinputbase: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
}
