import { CallsheetStatusType } from '../../../store/Callsheet/types'

export const DEFAULT_TAG = 'Show All'
export const DEFAULT_GROUP_STATE = { myCallsheets: false, invitations: false }

type FilterGroupType = {
  [key: string]: { groupTag: string; filters: string[] }
}

export const filterGroups: FilterGroupType = {
  myCallsheets: {
    groupTag: 'My Callsheets',
    filters: ['Draft', 'Published', 'Live', 'Completed', 'Deleted'],
  },
  invitations: {
    groupTag: 'Invitations',
    filters: ['Pending', 'Accepted', 'Tentative', 'Declined'],
  },
}

export const mapFilterStatuses = (values: string[]): CallsheetStatusType[] =>
  values.map(value => value.toUpperCase()) as CallsheetStatusType[]

export const tagColor: { [key: string]: string } = {
  Draft: '#545454',
  Published: '#265A88',
  Live: '#FF3B30',
  Completed: '#217343',
  Deleted: '#CC2E26',
  Accepted: '#38C171',
  Tentative: '#FB8333',
  Pending: '#7C9CB7',
  Declined: '#FF3B30',
}

export const MenuProps = {
  PaperProps: {
    style: {
      width: 240,
    },
  },
  muiinputbase: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
}
