import React, { FC } from 'react'
import { noop } from 'lodash'
import { Box, InputLabel } from '@mui/material'
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox'
import style from './style'
import { LabelWithCheckboxTypes } from './types'

const LabelWithCheckbox: FC<LabelWithCheckboxTypes> = ({
  required,
  id,
  LabelIcon,
  label,
  checkbox,
  checked,
  onChange = noop,
  labelStyles = {},
}) => {
  return (
    <Box display='flex' justifyContent='space-between'>
      <InputLabel
        required={required}
        htmlFor={id}
        sx={{ ...style.labelIcon, ...labelStyles }}
      >
        {LabelIcon && (
          <Box sx={style.labelIconBox}>
            <LabelIcon />
          </Box>
        )}
        {label}
      </InputLabel>
      {checkbox && (
        <InputLabel sx={style.labelCheckbox}>
          <Box display='flex' justifyContent='center' alignItems='end'>
            <div>{checkbox}</div>
            <CustomCheckbox
              sx={style.checkbox}
              onChange={onChange}
              checked={checked}
            />
          </Box>
        </InputLabel>
      )}
    </Box>
  )
}

export default LabelWithCheckbox
