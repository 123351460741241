const style = {
  helperTxt: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: 'text.secondary',
  },
  remindTxt: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: 'text.primary',
  },
  remindWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  settingsWrapper: {
    p: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    boxShadow: '0px 5px 8px 0px #8585851A',
  },
}

export default style
