import { Box, Divider, Stack, Typography } from '@mui/material'
import { noop } from 'lodash'
import React, { FC, PropsWithChildren } from 'react'
import { RadioBtnCheckedIcon, RadioBtnIcon } from '../../../assets/icons'

import style from './style'

type DeleteActionProps = {
  title: string
  text: string
  checked: boolean
  onSelect: typeof noop
}

const DeleteAction: FC<DeleteActionProps & PropsWithChildren> = ({
  title,
  text,
  checked,
  onSelect,
  children,
}) => {
  return (
    <Stack sx={style.wrapper} gap={1}>
      <Box sx={style.actionBox}>
        {checked ? (
          <RadioBtnCheckedIcon cursor='pointer' onClick={onSelect} />
        ) : (
          <RadioBtnIcon cursor='pointer' onClick={onSelect} />
        )}

        <Stack gap='4px'>
          <Typography variant='subtitle2' color='text.primary'>
            {title}
          </Typography>
          <Typography sx={{ fontSize: '14px', lineHeight: '20px' }}>
            {text}
          </Typography>
        </Stack>
      </Box>
      {checked && (
        <>
          <Divider variant='middle' />
          {children}
        </>
      )}
    </Stack>
  )
}

export default DeleteAction
