import { AxiosPromise, AxiosResponse } from 'axios'
import { METHODS, URLS } from '../constants'
import { networkApiClient } from '../clients'
import { GetUserByIdResponse, GetUserResultType, User } from './types'

export const getUserApi = (): AxiosPromise<GetUserByIdResponse> =>
  networkApiClient.request({
    method: METHODS.GET,
    url: `${URLS.USER}/current`,
  })

export const getUserByIdApi = (
  userId: string
): AxiosPromise<GetUserByIdResponse> =>
  networkApiClient.request({
    method: METHODS.GET,
    url: `${URLS.USER}/${userId}`,
  })

export const updateUserApi = (
  userId: string,
  user: User
): Promise<AxiosResponse<GetUserResultType>> =>
  networkApiClient.request<GetUserResultType>({
    method: METHODS.PATCH,
    url: `${URLS.USER}/${userId}`,
    data: { user },
  })

export const createUserApi = (user: User): AxiosPromise<GetUserByIdResponse> =>
  networkApiClient.request({
    method: METHODS.POST,
    url: URLS.USER,
    data: user,
  })
