import { call, put, select, takeLatest } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { selectCallsheetId } from '../Callsheet/selectors'
import {
  addWalkieChannelsApi,
  deleteWalkieChannelsApi,
  getWalkieChannelsApi,
  updateWalkieChannelsApi,
} from '../../api/walkieChannels'
import { GetWalkieChannelsResultType } from '../../api/walkieChannels/types'
import {
  ADD_CHANNEL_REQUEST,
  DELETE_CHANNEL_REQUEST,
  GET_CHANNEL_REQUEST,
  SAVE_CHANNEL_REQUEST,
} from './actionTypes'
import { SampleError } from '../../api/types'
import { setNotificationErrorWorker } from '../Notification/sagas'
import {
  getWalkieChannelsError,
  getWalkieChannelsSuccessRequest,
  saveWalkieChannelsRequestError,
  saveWalkieChannelsRequestSuccess,
} from './actions'
import {
  AddWalkieChannelRequestType,
  DeleteWalkieChannelRequestType,
  WalkieChannel,
} from './types'
import { selectInitialWalkieChannels, selectWalkieChannels } from './selector'
import {
  getAddedWalkieChannels,
  getDeletedWalkieChannels,
  getUpdatedWalkieChannels,
} from '../../utils/walkieChannelsHelpers'

export function* GetWalkieChannelsWorker() {
  try {
    const callsheetId: string = yield select(selectCallsheetId)
    const {
      data: { data },
    }: AxiosResponse<GetWalkieChannelsResultType> = yield call(
      getWalkieChannelsApi,
      callsheetId
    )
    yield put(getWalkieChannelsSuccessRequest(data))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(getWalkieChannelsError(data as SampleError))
  }
}

export function* AddWalkieChannelsWorker({
  payload: { name, channel, callsheetId },
}: AddWalkieChannelRequestType) {
  try {
    yield call(addWalkieChannelsApi, callsheetId, name, channel)
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
  }
}

export function* DeleteWalkieChannelsWorker({
  payload: { id },
}: DeleteWalkieChannelRequestType) {
  try {
    yield call(deleteWalkieChannelsApi, id)
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
  }
}

export function* SaveWalkieChannelsWorker() {
  const callsheetId: string = yield select(selectCallsheetId)
  const initialWalkieChannels: WalkieChannel[] = yield select(
    selectInitialWalkieChannels
  )
  const walkieChannels: WalkieChannel[] = yield select(selectWalkieChannels)
  try {
    const addedWalkieChannels = getAddedWalkieChannels(walkieChannels)
    const deletedWalkieChannels = getDeletedWalkieChannels(
      initialWalkieChannels,
      walkieChannels
    )
    const updatedWalkieChannels = getUpdatedWalkieChannels(
      initialWalkieChannels,
      walkieChannels
    )

    for (let i = 0; i < deletedWalkieChannels.length; i += 1) {
      const { id } = deletedWalkieChannels[i]
      if (id) {
        yield call(deleteWalkieChannelsApi, id)
      }
    }

    for (let i = 0; i < updatedWalkieChannels.length; i += 1) {
      const { id, channel, name } = updatedWalkieChannels[i]
      if (id) {
        yield call(updateWalkieChannelsApi, id, name, channel)
      }
    }

    for (let i = 0; i < addedWalkieChannels.length; i += 1) {
      const { name, channel } = addedWalkieChannels[i]
      yield call(addWalkieChannelsApi, callsheetId, name, channel)
    }

    yield put(saveWalkieChannelsRequestSuccess())
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(saveWalkieChannelsRequestError(data as SampleError))
  }
}

export function* requestWalkieChannelsWatcher(): Generator {
  yield takeLatest(GET_CHANNEL_REQUEST, GetWalkieChannelsWorker)
  yield takeLatest(ADD_CHANNEL_REQUEST, AddWalkieChannelsWorker)
  yield takeLatest(DELETE_CHANNEL_REQUEST, DeleteWalkieChannelsWorker)
  yield takeLatest(SAVE_CHANNEL_REQUEST, SaveWalkieChannelsWorker)
}
