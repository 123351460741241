import { WalkieChannel } from '../store/WalkieChannels/types'

export const getAddedWalkieChannels = (current: WalkieChannel[]) => {
  return current.filter(channel => !channel.id)
}

export const getDeletedWalkieChannels = (
  initial: WalkieChannel[],
  current: WalkieChannel[]
) => {
  return initial.filter(
    channel => !current.some(item => item.id === channel.id)
  )
}

export const getUpdatedWalkieChannels = (
  initial: WalkieChannel[],
  current: WalkieChannel[]
) => {
  return initial.filter(channel =>
    current.some(
      item =>
        item.id === channel.id &&
        (item.name !== channel.name || item.channel !== channel.channel)
    )
  )
}

export const validateChannels = (
  valueType: keyof WalkieChannel,
  value: string,
  walkieChannels: WalkieChannel[]
) => {
  return walkieChannels.find(
    walkieChannel => walkieChannel[valueType] === value
  )
}

export const isEmptyValues = (walkieChannels: WalkieChannel[]): boolean =>
  walkieChannels.some(({ name, channel }) => !name || !channel)
