import { fill } from 'lodash'
import React, { FC } from 'react'
import { Box, Stack, Typography } from '@mui/material'

import CallTimeBox from '../CallTimeBox'

import style from './style'

const TextPlaceholder = () => (
  <Stack gap={0} mt={3} width='100%'>
    {fill(Array(5), '').map((_, idx: number) => (
      <Box key={idx} sx={style.textPlaceholder} />
    ))}
  </Stack>
)

const EmailResult: FC<{ content: string; subject: string }> = ({
  content,
  subject,
}) => {
  return (
    <Stack sx={style.wrapper} gap={3}>
      {subject ? (
        <Typography sx={style.subject}>{subject}</Typography>
      ) : (
        <Box sx={style.titlePlaceholder} />
      )}
      {content ? (
        <Typography sx={style.description}>{content}</Typography>
      ) : (
        <TextPlaceholder />
      )}
      <CallTimeBox />
      {!content && <TextPlaceholder />}
    </Stack>
  )
}

export default EmailResult
