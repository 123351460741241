const style = {
  header: {
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    mb: '14px',
  },
  dateTitle: {
    color: '#517B9F',
    fontWeight: 900,
    fontSize: '12px',
  },
  date: {
    fontSize: '18px',
    fontWeight: 900,
    color: '#1E486C',
  },
}

export default style
