import { useSelector, useDispatch } from 'react-redux'
import React, { ChangeEventHandler, FC, useState } from 'react'
import { Paper, Popper } from '@mui/material'

import SearchField from '../../SearchField'
import ContactOption from './ContactOption'

import {
  selectSearchContacts,
  selectSearchContactsRequestStatus,
} from '../../../store/Contacts/selectors'
import { getContactsByNameEmailRequest } from '../../../store/Contacts/actions'
import { Contact } from '../../../store/Contacts/types'

import { DEFAULT_WIDTH, EXTENDED_WIDTH } from './constants'

import style from './style'

const ContactSearchField: FC = () => {
  const dispatch = useDispatch()
  const [minWidth, setWidth] = useState<string>(DEFAULT_WIDTH)
  const [searchValue, setValue] = useState<string>()
  const contacts = useSelector(selectSearchContacts)
  const searchContactRequestStatus = useSelector(
    selectSearchContactsRequestStatus
  )

  const handleContactSearch: ChangeEventHandler<
    HTMLTextAreaElement
  > = async e => {
    if (e) {
      const {
        target: { value },
      } = e

      setValue(value)
      if (value.length > 2) {
        dispatch(getContactsByNameEmailRequest(value))
      }
    }
  }

  return (
    <SearchField
      id='contact-search'
      width={minWidth}
      inputStyles={style.input}
      placeholder='Search contact by name or email'
      onInputChange={handleContactSearch}
      filterOptions
      value={searchValue}
      onFocus={() => setWidth(EXTENDED_WIDTH)}
      options={contacts}
      requestStatus={searchContactRequestStatus}
      PopperComponent={props => <Popper {...props} sx={style.popper} />}
      PaperComponent={props => <Paper {...props} />}
      renderOption={(
        props: React.HTMLAttributes<HTMLLIElement>,
        option: Contact
      ) => <ContactOption props={props} option={option} key={option.id} />}
      label=''
    />
  )
}

export default ContactSearchField
