const style = {
  pdfBox: {
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: ' 0px 5px 8px 0px #8585851A',
    padding: 1,
    borderRadius: '16px',
  },
  title: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    color: 'text.primary',
  },
}

export default style
