import { Box, Typography } from '@mui/material'
import React, { FC } from 'react'

import style from './style'

type FieldsProps = {
  label: string
  value?: string
  labelColor?: string
}

const ContactDetailsField: FC<FieldsProps> = ({
  label,
  value = '',
  labelColor = 'text.secondary',
}) => {
  return (
    <Box sx={style.wrapper}>
      <Typography variant='subtitle2' color={labelColor}>
        {label}
      </Typography>
      <Box sx={style.value}>
        <Typography variant='body2'>{value}</Typography>
      </Box>
    </Box>
  )
}

export default ContactDetailsField
