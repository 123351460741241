import React from 'react'
import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

import style from './style'

export const CustomTooltip = styled(
  ({
    className,
    variant = 'colored',
    ...props
  }: TooltipProps & { variant: 'light' | 'colored' }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ variant }) => ({
  [`& .${tooltipClasses.tooltip}`]: style[variant],
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
  },
}))

export default CustomTooltip
