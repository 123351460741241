import moment from 'moment'
import React, { FC } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import style from './style'

type TitleHeaderTypes = {
  title: string
  date: DateConstructor | null
}

const TitleHeader: FC<TitleHeaderTypes> = ({ title, date }) => (
  <Grid container sx={style.header}>
    <Grid item>
      <Typography variant='h3' component='h3' color='text.main'>
        {title || 'Add Title for Preview'}
      </Typography>
    </Grid>
    <Grid item>
      <Box sx={{ textAlign: 'right' }}>
        <Typography sx={style.dateTitle}>Event date:</Typography>
        <Typography sx={style.date}>
          {date
            ? moment(date.toString()).format('DD/MM/YY')
            : 'Add for Preview'}
        </Typography>
      </Box>
    </Grid>
  </Grid>
)

export default TitleHeader
