import React, { FC } from 'react'
import { noop } from 'lodash'
import { Box, ButtonBase, Paper, Typography } from '@mui/material'
import { CrossIcon } from '../../../../../assets/icons'
import style from './style'

type AddressCardType = {
  title: string
  address: string
  onClose: typeof noop
}

const AddressView: FC<AddressCardType> = ({ title, address, onClose }) => {
  return (
    <Paper variant='outlined' sx={{ p: '14px' }}>
      <Box display='flex' justifyContent='space-between'>
        <Box>
          <div>
            <Typography
              variant='subtitle2'
              color='primary.light'
              sx={{ mb: '4px' }}
            >
              {title}
            </Typography>
          </div>
          <div>
            <Typography sx={style.address}>{address}</Typography>
          </div>
        </Box>
        <Box>
          <ButtonBase onClick={onClose}>
            <CrossIcon />
          </ButtonBase>
        </Box>
      </Box>
    </Paper>
  )
}

export default AddressView
