import { useSelector } from 'react-redux'
import { selectCallsheetStatisticById } from 'src/store/Callsheet/selectors'
import { CallsheetStatuses } from 'src/store/Callsheet/types'

const useCallsheetStatistic = (id?: string) => {
  if (!id) {
    return undefined
  }

  const callsheetStatistics = useSelector(selectCallsheetStatisticById(id))

  const statistics: { [key: string]: number } = {}

  statistics[CallsheetStatuses.TENTATIVE] =
    callsheetStatistics?.[CallsheetStatuses.TENTATIVE] ?? 0
  statistics[CallsheetStatuses.ACCEPTED] =
    callsheetStatistics?.[CallsheetStatuses.ACCEPTED] ?? 0
  statistics[CallsheetStatuses.DECLINED] =
    callsheetStatistics?.[CallsheetStatuses.DECLINED] ?? 0

  if (callsheetStatistics) {
    const sum = Object.values(statistics).reduce((acc, value) => acc + value, 0)
    return {
      statistics,
      percent: (sum / callsheetStatistics.ALL_MEMBERS_AMOUNT) * 100,
      total: callsheetStatistics.ALL_MEMBERS_AMOUNT,
      viewed: callsheetStatistics.VIEWED,
    }
  }

  return undefined
}

export default useCallsheetStatistic
