import { noop } from 'lodash'
import React, { FC, HTMLAttributes } from 'react'
import { Box, Paper, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { Company } from '../../../../../store/Companies/types'
import { selectContacts } from '../../../../../store/Contacts/selectors'
import { Contact } from '../../../../../store/Contacts/types'
import { getContactsById } from '../../../../../utils/contactHelpers'
import { addBase64Data } from '../../../../../utils/image'
import { prefillCompany } from '../localStore/actions'
import style from './style'

type CompanyOptionsType = {
  props: HTMLAttributes<HTMLLIElement>
  option: Company
  dispatch: typeof noop
}

const CompanyOption: FC<CompanyOptionsType> = ({ props, option, dispatch }) => {
  const contacts = useSelector(selectContacts)
  const handleItemClick =
    (company: Company) => (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      const { primaryMembers, ...companyData } = company
      // @ts-ignore
      const members: Contact[] = getContactsById(primaryMembers, contacts)

      dispatch(
        prefillCompany({
          ...companyData,
          primaryMembers: members,
        })
      )
      props.onClick && props.onClick(e)
    }

  return (
    <Box
      {...props}
      component='li'
      sx={style.wrapper}
      onClick={handleItemClick(option)}
    >
      <Paper variant='outlined' sx={style.option}>
        <Box sx={style.content}>
          <Box sx={style.logo}>
            <img
              src={option.logo ? addBase64Data(option.logo) : ''}
              alt='logo'
            />
          </Box>
          <Box>
            <Typography variant='subtitle1'>{option.name}</Typography>
            <Typography variant='subtitle2' color='text.disabled'>
              Company
            </Typography>
          </Box>
        </Box>
        <Box sx={style.circle} />
      </Paper>
    </Box>
  )
}

export default CompanyOption
