import React from 'react'
import { Box, Paper, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import {
  selectCallsheetProductionNotes,
  selectCallsheetProductionNotesTitle,
} from '../../../store/Callsheet/selectors'
import style from '../style'

const NotesView = () => {
  const productionNotesTitle = useSelector(selectCallsheetProductionNotesTitle)
  const productionNotes = useSelector(selectCallsheetProductionNotes)

  return (
    <>
      {productionNotesTitle || productionNotes ? (
        <Box sx={{ mb: 5 }}>
          <Typography sx={style.title}>Production Notes</Typography>
          <Paper variant='outlined' sx={style.notesItem}>
            {productionNotesTitle && (
              <Typography variant='h5' sx={{ mb: 1 }}>
                {productionNotesTitle.toUpperCase()}
              </Typography>
            )}
            <Typography variant='subtitle1'>{productionNotes}</Typography>
          </Paper>
        </Box>
      ) : null}
    </>
  )
}

export default NotesView
