import { History } from 'history'
import { SampleError } from '../../api/types'
import * as actionTypes from './actionTypes'
import {
  ChangeSignInEmailActionType,
  ChangeSignInNameActionType,
  ChangeSignInLastNameActionType,
  ChangeSignInPasswordActionType,
  SignInRequestActionType,
  SignInRequestSuccessActionType,
  SignInRequestErrorActionType,
  MakeSignInRequestActionType,
  ReturnToInitialSignInRequestState,
  MakeAfterAuthRequests,
} from './types'

export const changeSignInEmail = (
  email: string
): ChangeSignInEmailActionType => ({
  type: actionTypes.CHANGE_SIGN_IN_EMAIL,
  value: email,
})

export const changeSignInName = (name: string): ChangeSignInNameActionType => ({
  type: actionTypes.CHANGE_SIGN_IN_NAME,
  value: name,
})

export const changeSignInLastName = (
  lastName: string
): ChangeSignInLastNameActionType => ({
  type: actionTypes.CHANGE_SIGN_IN_LAST_NAME,
  value: lastName,
})

export const changeSignInPassword = (
  password: string
): ChangeSignInPasswordActionType => ({
  type: actionTypes.CHANGE_SIGN_IN_PASSWORD,
  value: password,
})

export const makeSignInRequest = (
  history: History
): MakeSignInRequestActionType => ({
  type: actionTypes.MAKE_SIGN_IN_REQUEST,
  history,
})

export const signInRequest = (): SignInRequestActionType => ({
  type: actionTypes.SIGN_IN_REQUEST,
})

export const signInRequestSuccess = (): SignInRequestSuccessActionType => ({
  type: actionTypes.SIGN_IN_REQUEST_SUCCESS,
})

export const signInRequestError = (
  error: SampleError | null
): SignInRequestErrorActionType => ({
  type: actionTypes.SIGN_IN_REQUEST_ERROR,
  error,
})

export const returnToInitialSignInRequestState =
  (): ReturnToInitialSignInRequestState => ({
    type: actionTypes.RETURN_TO_INITIAL_SIGN_IN_REQUEST_STATE,
  })

export const makeAfterAuthRequests = (): MakeAfterAuthRequests => ({
  type: actionTypes.MAKE_AFTER_AUTH_REQUESTS,
})
