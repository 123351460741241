import {
  SET_STEP,
  SET_COMPANY_TYPE,
  SET_IS_COMPANY_PANEL,
  SET_IS_MEMBERS_PANEL,
  SET_IS_LOCATION_PANEL,
  SET_IS_USER_GROUP_PANEL,
  SET_IS_GROUP_MEMBERS_PANEL,
  SET_IS_PERSISTENT_PANEL,
  SET_IS_ADD_CONTACT_PANEL,
  SET_IS_MEMBER_PROFILE_PANEL,
  SET_IS_UPDATE_OVERVIEW,
  SET_IS_CALLSHEET_CONFIRM_MODAL,
  SET_IS_CALLSHEET_DECLINE_MODAL,
  SET_IS_CONFIRM_DELETE_ACTION_MODAL,
  SET_CALLSHEET_ACTION_MODAL,
  SET_IS_CONFIRM_DELETE_CALLSHEET_DIALOG,
  SET_IS_CONTACT_DETAILS_PANEL,
  SET_IS_CONFIRM_DELETE_CONTACT_MODAL,
  SET_IS_COMPANY_DETAILS_PANEL,
  SET_IS_CONFIRM_DELETE_COMPANY_MODAL,
} from './actionTypes'

export enum CallsheetGalleryActions {
  DELETE = 'Delete',
  RESTORE = 'Restore',
  DUPLICATE = 'Duplicate',
  PERMANETLY_DELETE = 'Permanently Delete',
  UPDATE_DATE = 'Update date',
}

export enum CallsheetViewActions {
  DECLINE = 'Decline',
  TENTATIVE = 'Tentative',
  ACCEPT = 'Accept',
}

export type ActionDialogType = {
  open: boolean
  action: CallsheetGalleryActions | CallsheetViewActions
}

export interface BuilderState {
  step: number
  isContactDetailsPanel: boolean
  isCompanyDetailsPanel: boolean
  isPersistentPanel: boolean
  isCompanyPanel: boolean
  isMembersPanel: boolean
  isMemberProfilePanel: boolean
  isAddContactPanel: boolean
  isLocationPanel: boolean
  isUserGroupPanel: boolean
  isGroupMembersPanel: boolean
  isUpdateOverview: boolean
  isCallsheetConfirmModal: boolean
  isCallsheetDeclineModal: boolean
  isConfirmDeleteActionModal: boolean
  isConfirmDeleteContactModal: boolean
  isConfirmDeleteCompanyModal: boolean
  callsheetActionConfirmModal: ActionDialogType
  isConfirmDeleteCallsheetDialog: boolean
  companyType: string
}

export interface SetStep {
  type: typeof SET_STEP
  step: number
}

export interface SetIsContactDetailsPanel {
  type: typeof SET_IS_CONTACT_DETAILS_PANEL
  isContactDetailsPanel: boolean
}

export interface SetIsCompanyDetailsPanel {
  type: typeof SET_IS_COMPANY_DETAILS_PANEL
  isCompanyDetailsPanel: boolean
}

export interface SetIsPersistentPanel {
  type: typeof SET_IS_PERSISTENT_PANEL
  isPersistentPanel: boolean
}

export interface SetIsCompanyPanel {
  type: typeof SET_IS_COMPANY_PANEL
  isCompanyPanel: boolean
}

export interface SetIsMembersPanel {
  type: typeof SET_IS_MEMBERS_PANEL
  isMembersPanel: boolean
}

export interface SetIsMemberProfilePanel {
  type: typeof SET_IS_MEMBER_PROFILE_PANEL
  isMemberProfilePanel: boolean
}

export interface SetIsAddContactPanel {
  type: typeof SET_IS_ADD_CONTACT_PANEL
  isAddContactPanel: boolean
}

export interface SetIsLocationPanel {
  type: typeof SET_IS_LOCATION_PANEL
  isLocationPanel: boolean
}

export interface SetIsUserGroupPanel {
  type: typeof SET_IS_USER_GROUP_PANEL
  isUserGroupPanel: boolean
}

export interface SetIsGroupMembersPanel {
  type: typeof SET_IS_GROUP_MEMBERS_PANEL
  isGroupMembersPanel: boolean
}

export interface SetIsCallsheetConfirmModal {
  type: typeof SET_IS_CALLSHEET_CONFIRM_MODAL
  isCallsheetConfirmModal: boolean
}

export interface SetIsCallsheetDeclineModal {
  type: typeof SET_IS_CALLSHEET_DECLINE_MODAL
  isCallsheetDeclineModal: boolean
}

export interface SetIsConfirmDeleteActionModal {
  type: typeof SET_IS_CONFIRM_DELETE_ACTION_MODAL
  isConfirmDeleteActionModal: boolean
}

export interface SetIsConfirmDeleteCallsheetDialog {
  type: typeof SET_IS_CONFIRM_DELETE_CALLSHEET_DIALOG
  isConfirmDeleteCallsheetDialog: boolean
}

export interface SetIsUpdateOverview {
  type: typeof SET_IS_UPDATE_OVERVIEW
  isUpdateOverview: boolean
}

export interface SetCompanyType {
  type: typeof SET_COMPANY_TYPE
  companyType: string
}

export interface SetCallsheetActionConfirmDialog {
  type: typeof SET_CALLSHEET_ACTION_MODAL
  open: boolean
  action?: CallsheetGalleryActions | CallsheetViewActions
}

export interface SetIsConfirmDeleteContactModal {
  type: typeof SET_IS_CONFIRM_DELETE_CONTACT_MODAL
  isConfirmDeleteContactModal: boolean
}

export interface SetIsConfirmDeleteCompanyModal {
  type: typeof SET_IS_CONFIRM_DELETE_COMPANY_MODAL
  isConfirmDeleteCompanyModal: boolean
}

export type BuilderActions =
  | SetStep
  | SetCompanyType
  | SetIsCompanyPanel
  | SetIsMembersPanel
  | SetIsMemberProfilePanel
  | SetIsAddContactPanel
  | SetIsLocationPanel
  | SetIsUserGroupPanel
  | SetIsGroupMembersPanel
  | SetIsPersistentPanel
  | SetIsUpdateOverview
  | SetIsCallsheetConfirmModal
  | SetIsCallsheetDeclineModal
  | SetIsConfirmDeleteActionModal
  | SetCallsheetActionConfirmDialog
  | SetIsConfirmDeleteCallsheetDialog
  | SetIsContactDetailsPanel
  | SetIsConfirmDeleteContactModal
  | SetIsCompanyDetailsPanel
  | SetIsConfirmDeleteCompanyModal
