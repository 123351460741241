import React from 'react'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { selectGroups } from '../../../store/Groups/selectors'
import Team from '../../BuilderPage/ManageUserGroups/Team'

const GroupsPreview = () => {
  const groups = useSelector(selectGroups)

  return (
    <>
      {groups.map(({ title, callTime }) => (
        <Box key={title} sx={{ mb: 1 }}>
          <Team title={title} callTime={callTime} isPreview />
        </Box>
      ))}
    </>
  )
}

export default GroupsPreview
