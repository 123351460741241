const style = {
  button: (type?: string) => ({
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: 'text.primary',
    ml: 1,
    ...(type && { fontWeight: 900, color: 'primary.main' }),
  }),
  item: {
    ml: 4,
    height: '34px',
    p: 0,
  },

  text: (color?: string) => ({
    fontWeight: 900,
    ...(color && { color }),
  }),

  icon: {
    right: '15px !important',
    top: '6px !important',
    '> svg': {
      width: '10px',
      path: {
        stroke: '#1F1F1F',
      },
    },
  },
}

export default style
