import React from 'react'
import { Box, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PlusCircle } from '../../assets/icons'
import { clearStore } from '../../store/rootActions'
import { getUserById } from '../../store/User/actions'
import { getTokensFromStorage } from '../../utils/tokens'
import style from './style'

const CreateCallsheetCard = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleCardClick = () => {
    dispatch(clearStore())
    const { token } = getTokensFromStorage()
    if (token) {
      dispatch(getUserById(token))
    }
    history.push('/builder')
  }

  return (
    <Box sx={style.card} onClick={handleCardClick}>
      <Typography sx={style.title}>Create New CallSheet</Typography>
      <PlusCircle />
    </Box>
  )
}

export default CreateCallsheetCard
