import React, { FC } from 'react'
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material'

import { PlusWhiteIcon } from '../../../assets/icons'

import style from './style'
import { Entities } from '../constants'

const menuItems = [
  { name: 'Add Contact', entity: Entities.CONTACT },
  { name: 'Add Company', entity: Entities.COMPANY },
]

type AddNewDropdownProps = {
  onAddNewEntity: (value: Entities) => void
}

const AddNewDropdown: FC<AddNewDropdownProps> = ({ onAddNewEntity }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOptionChoose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleAddNew = (value: Entities) => () => {
    onAddNewEntity(value)
    handleClose()
  }

  return (
    <>
      <Button
        color='info'
        variant='contained'
        sx={style.builderBtn}
        onClick={handleOptionChoose}
      >
        <Box sx={style.builderBtnLarge}>
          <PlusWhiteIcon />
        </Box>
        <Typography variant='subtitle2' sx={style.builderBtnText}>
          Add New...
        </Typography>
      </Button>
      <Menu
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuItems.map(({ name, entity }) => (
          <MenuItem
            onClick={handleAddNew(entity)}
            sx={style.menuItem}
            key={name}
          >
            {name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default AddNewDropdown
