const style = {
  wrapper: {
    height: '100%',
  },
  content: {
    height: 'calc(100% - 40px)',
  },
  footer: {},
}

export default style
