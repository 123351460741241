import { GroupType } from 'src/store/Groups/types'
import { CallTime, GroupMember } from '../store/GroupMembers/types'

export const SELECT_GROUP_PLACEHOLDER = 'Select Group'
export const DEFAULT_CALLTIME = '12:00PM'

export const deleteGroupMemberById = (
  members: GroupMember[],
  memberId: string
) => members.filter(member => member.id !== memberId)

export const filterGroupMembersByGroupTitle = (
  members: GroupMember[],
  groupTitle: string
) => members.filter(member => member.groupTitle !== groupTitle)

export const setGroupMemberVisibilityById = (
  members: GroupMember[],
  memberId: string
) =>
  members.map(member =>
    member.id === memberId
      ? { ...member, hidePersonalInfo: !member.hidePersonalInfo }
      : member
  )

export const setGroupMemberCallTimeById =
  (time: CallTime) =>
  (members: GroupMember[], memberId: string, callTime: string) =>
    members.map(member =>
      member.id === memberId
        ? {
            ...member,
            [time === CallTime.START_TIME ? 'callTime' : 'wrapTime']: callTime,
          }
        : member
    )

export const addNewGroupMember = (
  members: GroupMember[],
  member: GroupMember
) => [...members.filter(storeMember => storeMember.id !== member.id), member]

export const getUpdatedMembersGroupTitle = (
  oldTitle: string,
  newTitle: string,
  members: GroupMember[]
) => {
  return members.map(member =>
    member.groupTitle === oldTitle
      ? { ...member, groupTitle: newTitle }
      : member
  )
}

export const updateMembers = (
  members: GroupMember[],
  preSelectedMembers: GroupMember[]
) => {
  const updated = members
    .filter(
      member =>
        member.groupTitle !== SELECT_GROUP_PLACEHOLDER &&
        !preSelectedMembers.some(item => item.id === member.id)
    )
    .concat(preSelectedMembers)

  return { members: updated, preSelectedMembers: updated }
}

export const isMissingDetails = (
  members: GroupMember[],
  group: GroupType
): boolean => {
  // temporary usage DEFAULT_CALLTIME due to backend compatibility
  if (group.callTime === DEFAULT_CALLTIME || !group.callTime) return true

  for (let i = 0; i < members.length; i += 1) {
    if (members[i].userGroupId === group.id) {
      const { email, title, firstName, lastName, phone, callTime, wrapTime } =
        members[i]

      if (
        !(
          !!email &&
          !!title &&
          !!firstName &&
          !!phone &&
          !!lastName &&
          !!callTime &&
          !!wrapTime
        )
      ) {
        return true
      }
    }
  }
  return false
}
