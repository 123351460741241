import React, {
  FC,
  FunctionComponent,
  PropsWithChildren,
  SVGProps,
} from 'react'
import { noop } from 'lodash'
import { Box, Paper, Typography } from '@mui/material'
import { CallsheetStatuses } from '../../store/Callsheet/types'

import style from './style'
import CustomTextButton from '../CustomTextButton'
import AvatarBox from '../AvatarBox'
import CustomSelect from '../CustomSelect'

type ContactItemTypes = {
  logo?: string
  name: string
  onTitleClick?: typeof noop
  btnProps?: {
    title: string
    color: string
    disabled?: boolean
    onBtnClick: typeof noop
    Icon: FunctionComponent<SVGProps<SVGSVGElement>>
  }
  selectProps?: {
    label: string
    values: string[]
    onSelect: typeof noop
    defaultValue?: string
  }
  isStatistic?: boolean
  statisticStatus?: string
}

const ContactPanel: FC<ContactItemTypes & PropsWithChildren> = ({
  children,
  logo,
  name,
  btnProps,
  selectProps,
  onTitleClick,
  isStatistic,
  statisticStatus,
}) => {
  const { title, Icon, color, disabled, onBtnClick } = btnProps || {}

  const { label, values, onSelect, defaultValue } = selectProps || {}

  return (
    <Paper sx={style.wrapper} variant='outlined'>
      <Box display='flex' alignItems='center' gap={1}>
        <AvatarBox img={logo} />
        <Box>
          <Typography
            variant='body2'
            onClick={onTitleClick && onTitleClick}
            sx={{ cursor: onTitleClick ? 'pointer' : 'auto' }}
          >
            {name}
          </Typography>
          {children}
        </Box>
      </Box>
      {btnProps && (
        <Box sx={{ pr: '12px' }}>
          <CustomTextButton
            title={title}
            Icon={Icon}
            color={color}
            onClick={onBtnClick}
            disabled={disabled}
          />
        </Box>
      )}
      {isStatistic ? (
        <Typography
          sx={style.statistic(statisticStatus || CallsheetStatuses.PENDING)}
        >
          {statisticStatus} <span />
        </Typography>
      ) : null}
      {label && values && onSelect && defaultValue && !isStatistic && (
        <Box sx={{ pr: '12px' }}>
          <CustomSelect
            placeHolder={label}
            values={values}
            onSelect={onSelect}
            defaultValue={defaultValue}
          />
        </Box>
      )}
    </Paper>
  )
}

export default ContactPanel
