import React, { FC } from 'react'
import { LoadingButton } from '@mui/lab'
import { Box, Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import useCallsheetConfirmation from './useCallsheetConfirmation'
import { selectIsCallsheetDeclineModal } from '../../store/Builder/selectors'
import { selectCrewCallTime } from '../../store/Callsheet/selectors'
import { selectLocations } from '../../store/Logistics/selectors'
import {
  selectAnnouncementsToAccept,
  selectCallsheetStatusRequest,
  selectConfirmationStep,
} from '../../store/CallsheetStatus/selectors'
import {
  pendingCallsheetRequest,
  setConfirmationStep,
} from '../../store/CallsheetStatus/actions'
import {
  setIsCallsheetConfirmModal,
  setIsCallsheetDeclineModal,
} from '../../store/Builder/actions'
import { AnnouncementToAccept } from '../../store/CallsheetStatus/types'
import { CallsheetStatuses } from '../../store/Callsheet/types'

import ConfirmModalWrapper from './ConfirmModalWrapper/ConfirmModalWrapper'
import InvitationStatusBox from '../InvitationStatusBox'
import ConfirmDeclineModal from './ConfirmDeclineModal'
import AnnouncementsStep from './AnnouncementsStep'
import TimeAndLocation from './TimeAndLocation'
import ControlButtons from '../ControlButtons'
import CustomStepper from '../CustomStepper'
import SuccessStep from './SuccessStep'

import { EMPTY_LOCATION_ITEM } from '../../pages/BuilderPage/ProjectOverview/AddLocationForm/constants'
import { steps, STEPS } from './constants'

const CallsheetConfirmationModal: FC<{
  title: string
  open: boolean
  onClose: () => void
}> = ({ open, onClose, title }) => {
  const dispatch = useDispatch()
  const locations = useSelector(selectLocations)
  const step = useSelector(selectConfirmationStep)
  const crewCallTime = useSelector(selectCrewCallTime)
  const isCallsheetDeclineModal = useSelector(selectIsCallsheetDeclineModal)
  const announcementsToAccept = useSelector(selectAnnouncementsToAccept)
  const requestStatus = useSelector(selectCallsheetStatusRequest)
  const { onAccept, onDecline, onSubmit, onTentative } =
    useCallsheetConfirmation()

  const locationsItems = locations.length
    ? locations
    : [EMPTY_LOCATION_ITEM, EMPTY_LOCATION_ITEM]

  const handleBack = () => {
    dispatch(setIsCallsheetDeclineModal(false))
    onClose()
  }

  const handleBackStep = () => {
    dispatch(setConfirmationStep(step - 1))
  }

  const handleUndoDecline = () => {
    dispatch(pendingCallsheetRequest())
  }

  const isDisabled =
    announcementsToAccept.filter((item: AnnouncementToAccept) => !item.accepted)
      .length > 0

  return (
    <>
      <ConfirmModalWrapper
        title='CallSheet Confirmation'
        open={open}
        onClose={onClose}
        DialogContentChildren={
          <>
            <CustomStepper
              activeStep={step}
              steps={steps}
              builderStepper={false}
            />
            {step === STEPS.timeAndLocation ? (
              <TimeAndLocation time={crewCallTime} locations={locationsItems} />
            ) : null}
            {step === STEPS.announcements ? <AnnouncementsStep /> : null}
            {step === STEPS.done ? (
              <SuccessStep
                successText='Easy peazy lemon squeezy'
                info='Information has been confirmed.'
                infoSubtitle='The project admin will be notified of all CallSheet confirmations. Be sure to familiarise yourself with the CallSheet time/date, location, schedule and visual direction before starts.'
              />
            ) : null}
          </>
        }
        DialogActionsChildren={
          <>
            {step === STEPS.done ? (
              <LoadingButton
                variant='contained'
                onClick={() => dispatch(setIsCallsheetConfirmModal(false))}
              >
                Back to CallSheet
              </LoadingButton>
            ) : null}
            {step === STEPS.timeAndLocation ? (
              <Box display='flex' justifyContent='space-between' width='100%'>
                <Button
                  variant='outlined'
                  onClick={onClose}
                  sx={{
                    color: '#265A88',
                    borderColor: '#265A88',
                    minWidth: '148px',
                  }}
                >
                  Cancel
                </Button>
                <Box display='flex' gap={1}>
                  <InvitationStatusBox
                    status={CallsheetStatuses.DECLINED}
                    withIcon
                    onClick={onDecline}
                  />
                  <InvitationStatusBox
                    status={CallsheetStatuses.TENTATIVE}
                    withIcon
                    onClick={onTentative}
                  />
                  <InvitationStatusBox
                    status={CallsheetStatuses.ACCEPTED}
                    withIcon
                    onClick={onAccept}
                  />
                </Box>
              </Box>
            ) : null}
            {step === STEPS.announcements ? (
              <ControlButtons
                disabled={isDisabled}
                confirmTitle='Accept and Finish'
                cancelTitle='Back'
                requestStatus={requestStatus}
                handleCancelBtn={handleBackStep}
                handleConfirmBtn={onSubmit}
              />
            ) : null}
          </>
        }
      />
      <ConfirmDeclineModal
        open={isCallsheetDeclineModal}
        onClose={() => dispatch(setIsCallsheetDeclineModal(false))}
        onUndoDecline={handleUndoDecline}
        onBack={handleBack}
        title={title}
      />
    </>
  )
}

export default CallsheetConfirmationModal
