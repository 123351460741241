import { template } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { Announcement, AnnouncementsState } from '../store/Announcements/types'

const announcementCompiled = template(
  '<% _.forEach(sentences, function(sentence) { %><%= sentence %>.<% }); %>'
)

export const combinedAnnouncements = (announcements: string[]): string =>
  announcementCompiled({ sentences: announcements.filter(item => item !== '') })

export const extractAnnouncementValues = (announcements: Announcement[]) =>
  announcements.map((item: Announcement) => item.content)

export const switchCombinedAnnouncements = (state: AnnouncementsState) => {
  const {
    isCombined,
    announcements,
    combinedAnnouncement,
    announcementsLength,
  } = state
  const newCombinedAnnouncement =
    combinedAnnouncement === ''
      ? combinedAnnouncements([...extractAnnouncementValues(announcements)])
      : combinedAnnouncement

  const isExpanded =
    newCombinedAnnouncement !==
    combinedAnnouncements([...extractAnnouncementValues(announcements)])

  const newAnnouncements = isExpanded
    ? [
        {
          content: newCombinedAnnouncement,
          important: false,
          tmpId: uuidv4(),
        },
      ]
    : announcements

  state.announcements = newAnnouncements
  state.combinedAnnouncement =
    combinedAnnouncement === ''
      ? combinedAnnouncements([...extractAnnouncementValues(announcements)])
      : combinedAnnouncement
  state.isCombined = !isCombined
  state.announcementsLength = newAnnouncements.length
}

export const getAddedAnnouncements = (current: Announcement[]) => {
  return current.filter(item => !item.id)
}

export const getDeletedAnnouncements = (
  initial: Announcement[],
  current: Announcement[]
) => {
  return initial.filter(
    announcement => !current.some(item => item.id === announcement.id)
  )
}

export const getUpdatedAnnouncements = (
  initial: Announcement[],
  current: Announcement[]
) => {
  return current.filter(announcement =>
    initial.some(
      item =>
        item.id === announcement.id &&
        (item.content !== announcement.content ||
          item.important !== announcement.important)
    )
  )
}
