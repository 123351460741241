import { AxiosPromise } from 'axios'
import { Contact } from '../../store/Contacts/types'
import { apiClient, networkApiClient } from '../clients'
import { METHODS, URLS } from '../constants'

export const getContactsApi = (): AxiosPromise =>
  apiClient.request({
    method: METHODS.GET,
    url: URLS.NETWORK,
  })

export const addContactApi = (
  userId: string,
  contact: Omit<Contact, 'id'>
): AxiosPromise =>
  networkApiClient.request({
    method: METHODS.POST,
    url: URLS.CONTACT,
    data: {
      userId,
      ...contact,
    },
  })

export const updateContactApi = (
  userId: string,
  contact: Contact
): AxiosPromise =>
  networkApiClient.request({
    method: METHODS.PATCH,
    url: `${URLS.CONTACT}/${userId}/${contact.id}`,
    data: contact,
  })

export const addContactImageApi = (
  userId: string,
  contactId: string,
  image: Blob,
  format: string
): AxiosPromise => {
  return networkApiClient.request({
    method: METHODS.PATCH,
    url: `${URLS.CONTACT}/image/${userId}/${contactId}`,
    data: image,
    headers: {
      'Content-type': format,
    },
  })
}

export const getContactImageApi = (
  userId: string,
  contactId: string
): AxiosPromise => {
  return networkApiClient.request({
    method: METHODS.GET,
    url: `${URLS.CONTACT}/image/${userId}/${contactId}`,
  })
}

export const deleteContactApi = (
  contactId: string,
  userId: string
): AxiosPromise => {
  return networkApiClient.request({
    method: METHODS.DELETE,
    url: `${URLS.CONTACT}/${userId}`,
    data: {
      id: contactId,
    },
  })
}

export const getContactsByEmailAndNameApi = (value: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.GET,
    url: URLS.NETWORK,
    params: {
      firstName: value,
      lastName: value,
      email: value,
    },
  })
