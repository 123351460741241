import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar'
import React, { FC } from 'react'
import { Box, Button } from '@mui/material'
import style from './style'

const CustomActionBar: FC<PickersActionBarProps> = ({ onSetToday }) => {
  return (
    <Box display='flex' justifyContent='space-between' sx={style.container}>
      <Box>
        <Button
          size='small'
          onClick={onSetToday}
          sx={{ ...style.button, backgroundColor: '#F6F6F6' }}
        >
          Today
        </Button>
      </Box>
    </Box>
  )
}

export default CustomActionBar
