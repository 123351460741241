import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectIsUpdateOverview,
  selectStep,
} from '../../store/Builder/selectors'
import { getCallsheetRequest } from '../../store/Callsheet/actions'
import { selectCallsheetId } from '../../store/Callsheet/selectors'
import { getGroupsRequest } from '../../store/Groups/actions'
import ManageUserGroups from './ManageUserGroups'

import ProjectOverview from './ProjectOverview'
import BuilderLayout from '../../components/BuilderLayout'

import { STEPS } from './constants'
import FinalTouches from './FinalTouches'
import PublishAndSend from './PublishAndSend'

const BuilderPage: FC = () => {
  const callsheetId = useSelector(selectCallsheetId)
  const isUpdateOverview = useSelector(selectIsUpdateOverview)
  const step = useSelector(selectStep)
  const dispatch = useDispatch()

  useEffect(() => {
    if (callsheetId && !isUpdateOverview) {
      dispatch(getCallsheetRequest(callsheetId))
    }
    if (callsheetId && step === STEPS.userGroups) {
      dispatch(getGroupsRequest())
    }
  }, [callsheetId])

  return (
    <BuilderLayout>
      {step === STEPS.overview || step === STEPS.logistic ? (
        <ProjectOverview />
      ) : null}
      {step === STEPS.userGroups ? <ManageUserGroups /> : null}
      {step === STEPS.finalTouches ? <FinalTouches /> : null}
      {step === STEPS.publishAndSend ? <PublishAndSend /> : null}
    </BuilderLayout>
  )
}

export default BuilderPage
