import { RequestStatuses } from '../../api/constants'
import { AppState } from '../types'
import { WalkieChannel } from './types'

export const selectWalkieChannels = (state: AppState): WalkieChannel[] =>
  state.walkieChannelsState.walkieChannels

export const selectWalkieChannelsRequest = (state: AppState): RequestStatuses =>
  state.walkieChannelsState.requestStatus

export const selectWalkieChannelsSaveRequest = (
  state: AppState
): RequestStatuses => state.walkieChannelsState.saveRequestStatus

export const selectInitialWalkieChannels = (state: AppState): RequestStatuses =>
  state.walkieChannelsState.initialWalkieChannels
