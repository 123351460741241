import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getLastRequestError } from '../../store/Notification/selectors'
import { isNotificationShown } from '../../utils/notification'
import Toast from '../Toast'

const GlobalErrorToast: FC = () => {
  const lastRequestError = useSelector(getLastRequestError)
  const errorMessage = lastRequestError?.message || ''
  const [open, setOpen] = useState(isNotificationShown(lastRequestError))
  useEffect(() => {
    setOpen(isNotificationShown(lastRequestError))
  }, [lastRequestError])

  return (open && <Toast open setOpen={setOpen} text={errorMessage} />) || null
}

export default GlobalErrorToast
