import { noop } from 'lodash'
import React, { FC, useState } from 'react'
import {
  Box,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { useToggle } from '../../../../hooks/useToggle'
import CustomSwitch from '../../../../components/CustomSwitch'

import { getReminderOption } from '../../../../utils/getReminderOption'

import { remindBeforeOptions, REMIND_BEFORE_PLACEHOLDER } from './constants'
import { ClockIcon } from '../../../../assets/icons'

import style from './style'

type ReminderProps = {
  defaultValue?: number
  checked?: boolean
  onEmailReminderToggle?: typeof noop
  onRemindBeforeChange?: (value: number) => void
  variant?: 'sms' | 'email'
}

const Reminder: FC<ReminderProps> = ({
  defaultValue,
  checked = false,
  onEmailReminderToggle,
  onRemindBeforeChange,
  variant = 'email',
}) => {
  const [value, setValue] = useState<string>(
    defaultValue?.toString() ?? REMIND_BEFORE_PLACEHOLDER
  )
  const [isEmailReminder, setEmailReminder] = useToggle(checked)

  const handleReminderToggle = () => {
    setEmailReminder()
    onEmailReminderToggle && onEmailReminderToggle()
  }

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value)
    onRemindBeforeChange && onRemindBeforeChange(Number(event.target.value))
  }
  return (
    <Paper variant='outlined' sx={style.settingsWrapper}>
      <Box display='flex' gap={3}>
        <Stack gap='4px'>
          <Typography
            variant='subtitle2'
            sx={{ textTransform: 'capitalize' }}
          >{`${variant} reminder`}</Typography>
          <Typography sx={style.helperTxt}>
            {`You can setup a date and time when the ${variant} should remind all
            recipients about the event`}
          </Typography>
        </Stack>
        <CustomSwitch
          checked={isEmailReminder}
          onChange={handleReminderToggle}
        />
      </Box>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Box sx={style.remindWrapper}>
          <ClockIcon />
          <Typography sx={style.remindTxt}>Remind before the event:</Typography>
        </Box>
        <Select id='select-hour' value={value} onChange={handleChange}>
          {remindBeforeOptions.map((option, idx) => (
            <MenuItem key={idx} value={option}>
              {getReminderOption(option)}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Paper>
  )
}

export default Reminder
