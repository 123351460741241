import { Contact } from '../../../store/Contacts/types'

export const grids: { [key: string]: string } = {
  'Name/Primary Role': '20%',
  'Phone Number': '12%',
  Email: '13%',
  Website: '5%',
  Links: '50%',
}

export const sortByName = (a: Contact, b: Contact) =>
  a.firstName.localeCompare(b.firstName)
export const sortByTitle = (a: Contact, b: Contact) =>
  a?.title && b?.title ? a.title.localeCompare(b.title) : -1

export const NAME_FIELD = 'Name/Primary Role'
