const style = {
  tag: {
    borderRadius: '4px',
    padding: '0px',
    span: {
      padding: '2px 6px',
      fontSize: '12px',
      fontWeight: 900,
      lineHeight: '20px',
    },
    border: '1px solid #E2E2E2',
  },
  tagsWrapper: {
    flexWrap: 'wrap',
    display: 'flex',
    gap: 0,
    maxWidth: '320px',
  },
}

export default style
