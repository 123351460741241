import produce from 'immer'
import { RequestStatuses } from '../../api/constants'
import {
  GET_ATTACHMENTS_REQUEST,
  GET_ATTACHMENTS_REQUEST_ERROR,
  GET_ATTACHMENTS_REQUEST_SUCCESS,
  SAVE_ATTACHMENTS_REQUEST,
  SAVE_ATTACHMENTS_REQUEST_SUCCESS,
  SET_ATTACHMENTS,
} from './actionTypes'
import { AttachmentsActions, AttachmentsState } from './types'

const attachmentsInitialState: AttachmentsState = {
  initialAttachments: [],
  attachments: [],
  requestStatus: RequestStatuses.UNCALLED,
}

const attachmentsReducer = produce(
  (draft = attachmentsInitialState, action: AttachmentsActions) => {
    switch (action.type) {
      case SET_ATTACHMENTS:
        draft.attachments = action.attachments
        break
      case GET_ATTACHMENTS_REQUEST:
      case SAVE_ATTACHMENTS_REQUEST:
        draft.requestStatus = RequestStatuses.PENDING
        break
      case GET_ATTACHMENTS_REQUEST_SUCCESS:
        draft.initialAttachments = action.attachments
        draft.attachments = action.attachments
        draft.requestStatus = RequestStatuses.SUCCEEDED
        break

      case SAVE_ATTACHMENTS_REQUEST_SUCCESS:
        draft.requestStatus = RequestStatuses.SUCCEEDED
        break
      case GET_ATTACHMENTS_REQUEST_ERROR:
        draft.requestStatus = RequestStatuses.FAILED
        break
      default:
        return draft
    }
  }
)

export default attachmentsReducer
