import { Box, Grid, Typography } from '@mui/material'
import React, { FC } from 'react'

import AddressCard from '../../../pages/BuilderPage/ProjectOverview/OverviewResult/AddressCard'
import { LocationItem } from '../../../store/Logistics/types'
import openGoogleMaps from '../../../utils/openGoogleMaps'
import { getLocationName } from '../../../utils/location'
import { CalendarWithNumberIcon } from '../../../assets/icons'

import style from './style'

const TimeAndLocation: FC<{ time: string; locations: LocationItem[] }> = ({
  time,
  locations,
}) => {
  const {
    title,
    name,
    city,
    state,
    zip,
    locationNotes,
    image,
    latitude,
    longitude,
  } = locations[0]

  const handleMapOpen = () => {
    if (longitude && latitude) {
      openGoogleMaps(latitude, longitude)
    }
  }
  return (
    <Grid container sx={style.container} justifyContent='space-between'>
      <Grid item sx={style.timeContainer} xs={5.85}>
        <Box>
          <Typography
            color='text.secondary'
            variant='subtitle1'
            lineHeight='24px'
          >
            Your call time:
          </Typography>
          <Typography sx={style.callTime}>{time}</Typography>
        </Box>
        <Box mt={0}>
          <Typography
            color='text.disabled'
            variant='subtitle1'
            lineHeight='24px'
          >
            Event date:
          </Typography>
          <Box display='flex' gap={0}>
            <CalendarWithNumberIcon />
            <Typography sx={style.date}>Monday, August 15th, 2022</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={5.85}>
        <AddressCard
          title={title}
          name={name}
          address={getLocationName(city, state, zip)}
          description={locationNotes}
          image={image}
          onAddressClick={handleMapOpen}
          openSitemap
        />
      </Grid>
    </Grid>
  )
}

export default TimeAndLocation
