import { GroupMember } from 'src/store/GroupMembers/types'
import { GroupType } from '../store/Groups/types'

export const createNewGroup = (title: string) => ({
  title,
  callTime: '12:00PM',
  isSelected: false,
  isPrimary: false,
})

export const toggleSelectByTitle = (title: string, groups: GroupType[]) =>
  groups.map(group =>
    group.title === title ? { ...group, isSelected: !group.isSelected } : group
  )

export const deleteGroupByTitle = (title: string, groups: GroupType[]) =>
  groups.filter(group => group.title !== title)

export const groupValidator = (groups: GroupType[]) => (title: string) =>
  groups.some(group => group.title === title)

export const updateGroupTitle = (
  oldTitle: string,
  newTitle: string,
  groups: GroupType[]
) => {
  return groups.map(group =>
    group.title === oldTitle ? { ...group, title: newTitle } : group
  )
}

export const setGroupCallTimeByTitle = (
  groups: GroupType[],
  title: string,
  callTime: string
) =>
  groups.map(group => (group.title === title ? { ...group, callTime } : group))

export const setGroupFavoriteByTitle = (groups: GroupType[], title: string) =>
  groups.map(group => ({
    ...group,
    isPrimary: group.title === title ? !group.isPrimary : false,
  }))

export const getGroupTitles = (groups: GroupType[]) =>
  groups.map(group => group.title)

export const getGroupIdByTitle = (groups: GroupType[], title: string) =>
  groups.find(group => group.title === title)?.id

export const getGroupTitleById = (groups: GroupType[], id: string) =>
  groups.find(group => group.id === id)?.title

export const addUserGroupTitleToMembers = (
  members: GroupMember[],
  userGroups: GroupType[]
) =>
  members.map(member => ({
    ...member,
    groupTitle: getGroupTitleById(userGroups, member.userGroupId || ''),
  }))

export const validateUserGroups = (
  groups: GroupType[],
  groupMembers: GroupMember[]
) => {
  const createdGroups = groups.filter(group => group.isSelected)
  if (!createdGroups.length) {
    return false
  }

  if (!groupMembers.length) {
    return false
  }

  for (let i = 0; i < createdGroups.length; i += 1) {
    if (
      !groupMembers.some(member => member.groupTitle === createdGroups[i].title)
    ) {
      return false
    }
  }

  return true
}
