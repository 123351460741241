const style = {
  wrapper: {
    width: '100%',
    border: '1px solid #E2E2E2',
    borderRadius: '8px',
  },
  header: {
    padding: 0,
    px: 1,
  },
  label: {
    fontWeight: 900,
    fontSize: '12px',
    lineHeight: '20px',
  },
}

export default style
