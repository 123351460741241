import React from 'react'
import {
  CallsheetStatuses,
  CallsheetStatusType,
} from 'src/store/Callsheet/types'

interface Props {
  percent: number
  total: number
  data: {
    [key: string]: number
  }
  radius?: number
}

const colorMap: { [key: string]: string } = {
  [CallsheetStatuses.ACCEPTED]: '#5FCD8D',
  [CallsheetStatuses.DECLINED]: '#FF3B30',
  [CallsheetStatuses.TENTATIVE]: '#FB8333',
}

const CircleProgressBar: React.FC<Props> = ({
  percent,
  data,
  total,
  radius = 30,
}) => {
  const strokeWidth = 20
  const centerX = radius
  const centerY = radius

  let startAngle = -Math.PI / 2
  let endAngle = 0

  const angles = Object.keys(data).map(value => {
    const angle = (data[value] / total) * 2 * Math.PI
    startAngle = endAngle
    endAngle += angle
    if (endAngle === 2 * Math.PI) {
      endAngle = 2 * Math.PI - 0.001
    }

    return {
      startAngle,
      endAngle,
      color: colorMap[value as CallsheetStatusType],
    }
  })

  const paths = angles.map((angle, i) => {
    const startX = centerX + radius * Math.cos(angle.startAngle)
    const startY = centerY + radius * Math.sin(angle.startAngle)
    const endX = centerX + radius * Math.cos(angle.endAngle)
    const endY = centerY + radius * Math.sin(angle.endAngle)
    const largeArcFlag = angle.endAngle - angle.startAngle <= Math.PI ? 0 : 1
    const d = `M ${startX} ${startY} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY} L ${centerX} ${centerY} Z`
    return <path key={i} d={d} fill={angle.color} />
  })

  return (
    <svg width={radius * 2} height={radius * 2}>
      {paths}
      {total === 0 ||
        (percent === 0 && (
          <>
            <circle
              cx='50%'
              cy='50%'
              r={radius}
              fill='#7C9CB7'
              strokeWidth={strokeWidth}
            />
          </>
        ))}

      <circle
        cx='50%'
        cy='50%'
        r={radius / 1.3}
        fill='white'
        strokeWidth={strokeWidth}
      />
      <text
        x='50%'
        y='50%'
        dominantBaseline='middle'
        textAnchor='middle'
        fill='#545454'
        fontSize='14'
        fontWeight={900}
      >
        {percent.toFixed(0)}%
      </text>
    </svg>
  )
}

export default CircleProgressBar
