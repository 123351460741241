import { AxiosPromise } from 'axios'
import { METHODS, URLS } from '../constants'
import { apiClient } from '../clients'
import { EmailParameters } from '../../store/EmailParameters/types'
import { SmsParameters } from '../../store/SmsParameters/types'

export const updatePublishDetailsApi = (
  id: string,
  emailParameters: Omit<EmailParameters, 'isEmailReminder'>,
  smsParameters: Partial<Omit<SmsParameters, 'isSmsReminder'>>
): AxiosPromise =>
  apiClient.request({
    method: METHODS.PATCH,
    url: `${URLS.CALLSHEET}/${id}/publish-details`,
    data: {
      ...emailParameters,
      ...smsParameters,
    },
  })

export const getPublishDetailsApi = (id: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.GET,
    url: `${URLS.CALLSHEET}/${id}/publish-details`,
  })
