import {
  DELETE_GROUP_MEMBER,
  SET_GROUP_MEMBER_VISIBILITY,
  SET_GROUP_MEMBER_CALLTIME,
  SET_GROUP_MEMBER_WRAPTIME,
  ADD_PRESELECTED_GROUP_MEMBER,
  UPDATE_MEMBERS_GROUP_TITLE,
  UPDATE_GROUP_MEMBERS,
  DELETE_GROUP_MEMBER_BY_GROUP_TITLE,
  MEMBER_REQUEST,
  MEMBER_REQUEST_SUCCESS,
  MEMBER_REQUEST_ERROR,
  GET_MEMBERS_REQUEST,
  GET_MEMBERS_REQUEST_SUCCESS,
  ADD_MEMBER_REQUEST,
  UPDATE_MEMBER_REQUEST,
} from './actionTypes'
import {
  GroupMember,
  DeleteGroupMember,
  SetGroupMemberVisibility,
  SetGroupMemberCallTime,
  SetGroupMemberWrapTime,
  AddPreselectedGroupMember,
  UpdateMembersGroupTitle,
  UpdateGroupMembers,
  DeleteGroupMembersByGroupTitle,
  MemberRequestType,
  MemberRequestSuccessType,
  MemberRequestErrorType,
  GetMembersRequestType,
  GetMembersRequestSuccessType,
  AddMemberRequestType,
  UpdateMemberRequestType,
} from './types'

export const addMemberRequest = (
  userGroupId: string,
  contactId: string,
  hidePersonalInfo: boolean,
  callTime?: string,
  wrapTime?: string
): AddMemberRequestType => ({
  type: ADD_MEMBER_REQUEST,
  payload: {
    userGroupId,
    contactId,
    hidePersonalInfo,
    callTime,
    wrapTime,
  },
})

export const updateMemberRequest = (
  member: GroupMember
): UpdateMemberRequestType => ({
  type: UPDATE_MEMBER_REQUEST,
  payload: {
    member,
  },
})

export const updateMembersGroupTitle = (
  members: GroupMember[]
): UpdateMembersGroupTitle => ({
  type: UPDATE_MEMBERS_GROUP_TITLE,
  members,
})

export const addPreselectedGroupMember = (
  member: GroupMember
): AddPreselectedGroupMember => ({
  type: ADD_PRESELECTED_GROUP_MEMBER,
  member,
})

export const setGroupMemberVisibility = (
  memberId: string
): SetGroupMemberVisibility => ({
  type: SET_GROUP_MEMBER_VISIBILITY,
  memberId,
})

export const deleteGroupMember = (memberId: string): DeleteGroupMember => ({
  type: DELETE_GROUP_MEMBER,
  memberId,
})

export const deleteGroupMembersByGroupTitle = (
  groupTitle: string
): DeleteGroupMembersByGroupTitle => ({
  type: DELETE_GROUP_MEMBER_BY_GROUP_TITLE,
  groupTitle,
})

export const setGroupMemberCallTime = (
  memberId: string,
  callTime: string
): SetGroupMemberCallTime => ({
  type: SET_GROUP_MEMBER_CALLTIME,
  memberId,
  callTime,
})

export const setGroupMemberWrapTime = (
  memberId: string,
  wrapTime: string
): SetGroupMemberWrapTime => ({
  type: SET_GROUP_MEMBER_WRAPTIME,
  memberId,
  wrapTime,
})

export const updateGroupMembers = (isReset = false): UpdateGroupMembers => ({
  type: UPDATE_GROUP_MEMBERS,
  isReset,
})

export const memberRequest = (): MemberRequestType => ({
  type: MEMBER_REQUEST,
})

export const memberRequestSuccess = (): MemberRequestSuccessType => ({
  type: MEMBER_REQUEST_SUCCESS,
})

export const memberRequestError = (): MemberRequestErrorType => ({
  type: MEMBER_REQUEST_ERROR,
})

export const getMembersRequest = (
  callsheetId: string
): GetMembersRequestType => ({
  type: GET_MEMBERS_REQUEST,
  payload: {
    callsheetId,
  },
})

export const getMembersRequestSuccess = (
  members: GroupMember[]
): GetMembersRequestSuccessType => ({
  type: GET_MEMBERS_REQUEST_SUCCESS,
  members,
})
