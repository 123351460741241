import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TableBody, TableHead, TableRow } from '@mui/material'
import { useToggle } from '../../../hooks/useToggle'

import { selectContacts } from '../../../store/Contacts/selectors'
import { Contact } from '../../../store/Contacts/types'
import ContactNameCell from '../../../components/ContactNameCell'
import SocialLinks from '../../../components/SocialLinks'
import SocialLink from '../../../components/SocialLink'
import Loader from '../../../components/Loader'

import {
  CaretBottomIcon,
  CaretRightIcon,
  CaretUpIcon,
  WebsiteIcon,
} from '../../../assets/icons'
import { setIsContactDetailsPanel } from '../../../store/Builder/actions'
import { setSelectedContact } from '../../../store/Contacts/actions'
import { selectUserId } from '../../../store/User/selectors'

import { grids, NAME_FIELD, sortByName, sortByTitle } from './constants'
import { logoSrc } from '../../../constants'
import { StyledTableCell } from './style'
import ListWrapper from '../ListWrapper'
import { extractInitials } from '../../../utils/extractInitials'

const ContactsList: FC = () => {
  const dispatch = useDispatch()
  const contacts = useSelector(selectContacts)
  const userId = useSelector(selectUserId)

  const [isSorted, setIsSorted] = useToggle()

  const handleDetailsOpen = (id: string) => () => {
    dispatch(setSelectedContact(id))
    dispatch(setIsContactDetailsPanel(true))
  }

  const sortedContacts = useMemo(() => {
    const sorted: Contact[] = [...contacts]
    if (isSorted) {
      return sorted.sort(sortByTitle)
    }

    return sorted.sort(sortByName)
  }, [isSorted, contacts])

  if (!contacts.length) {
    return <Loader />
  }

  const columnName = (key: string) => {
    if (key === NAME_FIELD) {
      return isSorted ? 'Primary Role/Name' : key
    }

    return key
  }

  return (
    <>
      <ListWrapper listSize={contacts.length} variant='contact'>
        <TableHead>
          <TableRow>
            {Object.keys(grids).map(key => (
              <StyledTableCell align='left' width={grids[key]} key={key}>
                {columnName(key)}
                {key === NAME_FIELD &&
                  (isSorted ? (
                    <CaretUpIcon onClick={setIsSorted} />
                  ) : (
                    <CaretBottomIcon onClick={setIsSorted} />
                  ))}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedContacts.map(
            ({
              firstName,
              lastName,
              email,
              phone,
              website,
              instagramLink,
              unsplashLink,
              id,
              title,
              logo,
            }: Contact) => (
              <TableRow key={id}>
                <StyledTableCell
                  component='th'
                  scope='row'
                  onClick={handleDetailsOpen(id)}
                  sx={{ cursor: 'pointer' }}
                >
                  <ContactNameCell
                    firstName={firstName}
                    lastName={lastName}
                    title={title ?? ''}
                    img={logoSrc(userId, id)}
                    placeHolder={extractInitials(firstName, 2)}
                  />
                </StyledTableCell>
                <StyledTableCell align='left'>{phone}</StyledTableCell>
                <StyledTableCell align='left'>{email}</StyledTableCell>
                <StyledTableCell align='left'>
                  {website ? (
                    <SocialLink link={website} type='website'>
                      <WebsiteIcon />
                    </SocialLink>
                  ) : (
                    '-'
                  )}
                </StyledTableCell>
                <StyledTableCell align='left'>
                  {instagramLink || unsplashLink ? (
                    <SocialLinks
                      instagramLink={instagramLink}
                      unsplashLink={unsplashLink}
                    />
                  ) : (
                    '-'
                  )}
                </StyledTableCell>
                <StyledTableCell align='right' onClick={handleDetailsOpen(id)}>
                  <CaretRightIcon />
                </StyledTableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </ListWrapper>
    </>
  )
}

export default ContactsList
