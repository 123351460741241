const style = {
  header: {
    p: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '64px',
    backgroundColor: '#FAFAFA',
    borderBottom: '1px solid rgba(31, 31, 31, 0.08)',
  },
  titleBox: {
    display: 'flex',
  },
  title: (outlined: boolean) => ({
    ml: 0,
    mr: 2,
    fontSize: '20px',
    fontWeight: 500,
    ...(outlined && { borderBottom: '2px solid #0971E5' }),
  }),
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
}

export default style
