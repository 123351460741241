import React from 'react'
import { Box, Typography } from '@mui/material'

import style from './style'

const CallsheetLiveFooter = () => {
  return (
    <Box sx={style.content}>
      <Typography sx={style.title}>This Callsheet is</Typography>
      <Typography sx={style.subtitle}>Happening Now</Typography>
    </Box>
  )
}

export default CallsheetLiveFooter
