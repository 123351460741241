import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'
import { ImageListType } from 'react-images-uploading'

import FileUploadField from '../FileUploadField'
import Loader from '../../../../components/Loader'

import {
  selectAttachments,
  selectAttachmentsRequestStatus,
} from '../../../../store/Attachments/selectors'
import {
  getAttachmentsRequest,
  setAttachments,
} from '../../../../store/Attachments/actions'

import { updateFiles } from '../../../../utils/attachmentHelpers'
import { RequestStatuses } from '../../../../api/constants'

import style from './style'

const Attachments: FC = () => {
  const attachments = useSelector(selectAttachments)
  const requestStatus = useSelector(selectAttachmentsRequestStatus)
  const dispatch = useDispatch()

  const handleChange = async (myObjects: ImageListType) => {
    const updated = await updateFiles(myObjects)
    dispatch(setAttachments(updated))
  }

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant='h5' sx={style.label}>
        Attachments
      </Typography>
      {requestStatus === RequestStatuses.PENDING ? (
        <Loader />
      ) : (
        <FileUploadField onChange={handleChange} defaultValue={attachments} />
      )}
    </Box>
  )
}

export default Attachments
