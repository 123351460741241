import React, { FC, useState } from 'react'
import { Box, Typography } from '@mui/material'
import {
  CheckCircleGreenContainedIcon,
  CheckCircleEmptyIcon,
  CheckCircleIcon,
} from 'src/assets/icons'

import style from './style'

type AnnouncementCardProps = {
  id?: string
  content: string
  important: boolean
  combined: boolean
  onSelect: (id: string) => void
}

const AnnouncementCard: FC<AnnouncementCardProps> = ({
  id,
  content,
  important,
  combined,
  onSelect,
}) => {
  const [isSelected, setSelected] = useState<boolean>(false)

  const handleSelect = (selected: boolean) => () => {
    setSelected(selected)
    onSelect(id ?? '')
  }

  const SelectIcon = combined ? CheckCircleIcon : CheckCircleEmptyIcon
  return (
    <Box sx={style.announcementCard(important || combined, isSelected)}>
      <Typography variant='subtitle2'>{content}</Typography>
      {isSelected ? (
        <CheckCircleGreenContainedIcon onClick={handleSelect(false)} />
      ) : (
        <SelectIcon cursor='pointer' onClick={handleSelect(true)} />
      )}
    </Box>
  )
}

export default AnnouncementCard
