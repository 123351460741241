import {
  DELETE_GROUP_MEMBER,
  SET_GROUP_MEMBER_VISIBILITY,
  SET_GROUP_MEMBER_CALLTIME,
  SET_GROUP_MEMBER_WRAPTIME,
  ADD_PRESELECTED_GROUP_MEMBER,
  UPDATE_GROUP_MEMBERS,
  DELETE_GROUP_MEMBER_BY_GROUP_TITLE,
  MEMBER_REQUEST,
  MEMBER_REQUEST_SUCCESS,
  MEMBER_REQUEST_ERROR,
  ADD_MEMBER_REQUEST,
  DELETE_MEMBER_REQUEST,
  GET_MEMBERS_REQUEST,
  UPDATE_MEMBER_REQUEST,
  GET_MEMBERS_REQUEST_SUCCESS,
  UPDATE_MEMBERS_GROUP_TITLE,
} from './actionTypes'

export type GroupMember = {
  id: string
  lastName: string
  firstName: string
  email: string
  phone: string
  title?: string
  logo?: string
  callTime?: string
  wrapTime?: string
  status?: string
  hidePersonalInfo: boolean
  isPrimary: boolean
  notes?: string
  contactId?: string
  userGroupId?: string
  groupTitle?: string
  isUpdated?: boolean
  newUserGroupId?: string
}

export enum CallTime {
  START_TIME = 'START_TIME',
  WRAP_TIME = 'WRAP_TIME',
}

export interface GroupMembersState {
  members: GroupMember[]
  preSelectedMembers: GroupMember[]
  requestStatus: string
}

export interface UpdateMembersGroupTitle {
  type: typeof UPDATE_MEMBERS_GROUP_TITLE
  members: GroupMember[]
}

export interface AddPreselectedGroupMember {
  type: typeof ADD_PRESELECTED_GROUP_MEMBER
  member: GroupMember
}

export interface DeleteGroupMember {
  type: typeof DELETE_GROUP_MEMBER
  memberId: string
}

export interface DeleteGroupMembersByGroupTitle {
  type: typeof DELETE_GROUP_MEMBER_BY_GROUP_TITLE
  groupTitle: string
}

export interface SetGroupMemberVisibility {
  type: typeof SET_GROUP_MEMBER_VISIBILITY
  memberId: string
}

export interface SetGroupMemberCallTime {
  type: typeof SET_GROUP_MEMBER_CALLTIME
  memberId: string
  callTime: string
}

export interface SetGroupMemberWrapTime {
  type: typeof SET_GROUP_MEMBER_WRAPTIME
  memberId: string
  wrapTime: string
}

export interface UpdateGroupMembers {
  type: typeof UPDATE_GROUP_MEMBERS
  isReset?: boolean
}

export interface MemberRequestType {
  type: typeof MEMBER_REQUEST
}

export interface MemberRequestSuccessType {
  type: typeof MEMBER_REQUEST_SUCCESS
}

export interface MemberRequestErrorType {
  type: typeof MEMBER_REQUEST_ERROR
}

export interface AddMemberRequestType {
  type: typeof ADD_MEMBER_REQUEST
  payload: {
    userGroupId: string
    contactId: string
    hidePersonalInfo: boolean
    callTime?: string
    wrapTime?: string
  }
}

export interface DeleteMemberRequestType {
  type: typeof DELETE_MEMBER_REQUEST
  payload: {
    memberId: string
  }
}

export interface GetMembersRequestType {
  type: typeof GET_MEMBERS_REQUEST
  payload: {
    callsheetId: string
  }
}

export interface GetMembersRequestSuccessType {
  type: typeof GET_MEMBERS_REQUEST_SUCCESS
  members: GroupMember[]
}

export interface UpdateMemberRequestType {
  type: typeof UPDATE_MEMBER_REQUEST
  payload: {
    member: GroupMember
  }
}

export type BuilderActions =
  | DeleteGroupMember
  | SetGroupMemberVisibility
  | SetGroupMemberCallTime
  | SetGroupMemberWrapTime
  | UpdateMembersGroupTitle
  | AddPreselectedGroupMember
  | UpdateGroupMembers
  | DeleteGroupMembersByGroupTitle
  | MemberRequestType
  | MemberRequestSuccessType
  | MemberRequestErrorType
  | AddMemberRequestType
  | DeleteMemberRequestType
  | GetMembersRequestType
  | GetMembersRequestSuccessType
  | UpdateMemberRequestType
