const style = {
  title: {
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 900,
    textAlign: 'center',
    color: 'text.primary',
    paddingTop: '0px',
  },
  content: (maxWidth: string) => ({
    maxWidth,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
    textAlign: 'center',
    color: 'text.primary',
  }),
  wrapper: {
    fontFamily: 'Avenir',
    py: 3,
    px: 4,
  },
  btn_wrapper: (btnCancelColor: string, btnConfirmColor: string) => ({
    padding: '0px',
    display: 'flex',
    justifyContent: 'center',
    gap: 0,
    fontWeight: 900,

    'button:first-of-type': {
      border: `1px solid ${btnCancelColor}`,
      color: btnCancelColor,
    },
    'button:last-of-type': {
      backgroundColor: btnConfirmColor,
      color: 'white',
    },
  }),
}

export default style
