export const PREFIX = 'GROUPS/'
export const GET_GROUPS_REQUEST = `${PREFIX}GET_GROUPS_REQUEST` as const
export const GET_GROUPS_REQUEST_SUCCESS =
  `${PREFIX} GET_GROUPS_REQUEST_SUCCESS` as const
export const GET_GROUPS_REQUEST_ERROR =
  `${PREFIX}GET_GROUPS_REQUEST_ERROR` as const

export const UPDATE_GROUPS_REQUEST = `${PREFIX}UPDATE_GROUPS_REQUEST` as const
export const UPDATE_GROUPS_REQUEST_SUCCESS =
  `${PREFIX}UPDATE_GROUPS_REQUEST_SUCCESS` as const
export const UPDATE_GROUPS_REQUEST_ERROR =
  `${PREFIX}UPDATE_GROUPS_REQUEST_ERROR` as const

export const SAVE_GROUPS_REQUEST = `${PREFIX}SAVE_GROUPS_REQUEST` as const
export const ADD_GROUP_REQUEST_SUCCESS =
  `${PREFIX}ADD_GROUP_REQUEST_SUCCESS` as const

export const SET_GROUPS = `SET_GROUPS` as const
export const SET_FAVORITE = `SET_FAVORITE` as const
export const SET_GROUPS_DISPLAY = `SET_GROUPS_DISPLAY` as const
export const SET_GROUP_CALL_TIME = `SET_GROUP_CALL_TIME` as const
