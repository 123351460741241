const style = {
  select: (inputProps: { [key: string]: string }) => ({
    borderRadius: '4px',
    height: '32px',
    fontSize: '14px',
    color: 'primary.light',
    minWidth: '90px',
    '> div': {
      pr: '14px !important',
      textOverflow: 'unset !important',
    },
    ...inputProps,
  }),
  menuInput: {
    fontSize: '14px',
    minWidth: '140px',
  },
}

export default style
