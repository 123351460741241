import { Box, Stack, Typography } from '@mui/material'
import { noop } from 'lodash'
import React, { FC } from 'react'

import { EditIcon } from '../../../../../assets/icons'
import background from './assets/background.svg'
import style from './style'

type ScheduleItemProps = {
  name: string
  altStyle: boolean
  onEdit: typeof noop
  duration: string
  isPreview?: boolean
}

const ScheduleItem: FC<ScheduleItemProps> = ({
  name,
  altStyle,
  onEdit,
  duration,
  isPreview,
}) => {
  return (
    <Box sx={style.wrapper(background, altStyle, isPreview)}>
      <Box display='flex' gap={2}>
        <Box sx={style.rect(altStyle)} />
        <Stack sx={style.titleWrapper}>
          <Typography variant='h4'>{name}</Typography>
          <Typography>{duration}</Typography>
        </Stack>
      </Box>
      {isPreview ? null : (
        <Box sx={style.editBtn}>
          <EditIcon onClick={onEdit} />
        </Box>
      )}
    </Box>
  )
}

export default ScheduleItem
