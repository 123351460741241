import { makeStyles } from '@mui/styles'

interface Props {
  height: number
}

const useStyles = makeStyles({
  container: {
    height: (props: Props) => `${props.height}px`,
    width: 'calc(100% + 25px)',
    overflow: 'auto',
    paddingRight: '20px',
    marginRight: '-20px',
  },
})

export default useStyles
