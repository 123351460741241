import { noop } from 'lodash'
import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, TableBody, TableHead, TableRow, Typography } from '@mui/material'

import { useToggle } from '../../../hooks/useToggle'
import { Company } from '../../../store/Companies/types'
import { setIsCompanyDetailsPanel } from '../../../store/Builder/actions'

import {
  selectCompanies,
  selectGetCompaniesRequestStatus,
} from '../../../store/Companies/selectors'
import { selectIsCompanyDetailsPanel } from '../../../store/Builder/selectors'

import SocialLink from '../../../components/SocialLink'
import { StyledTableCell } from '../ListWrapper/style'
import SidePanel from '../../../components/SidePanel'
import Loader from '../../../components/Loader'
import CompanyDetails from '../CompanyDetails'
import ListWrapper from '../ListWrapper'

import { grids, NAME_FIELD, sortByNameAsc, sortByNameDesc } from './constants'
import { RequestStatuses } from '../../../api/constants'
import { addBase64Data } from '../../../utils/image'
import {
  CaretBottomIcon,
  CaretRightIcon,
  CaretUpIcon,
  WebsiteIcon,
} from '../../../assets/icons'
import { extractInitials } from '../../../utils/extractInitials'
import style from './style'

type CompaniesListType = {
  setSelectedCompany: typeof noop
  handleEditCompanyPanel: typeof noop
  selectedCompany?: Company
}

const CompaniesList: FC<CompaniesListType> = ({
  setSelectedCompany,
  handleEditCompanyPanel,
  selectedCompany,
}) => {
  const dispatch = useDispatch()
  const companies = useSelector(selectCompanies)
  const isCompanyDetailsPanel = useSelector(selectIsCompanyDetailsPanel)
  const companiesRequestStatus = useSelector(selectGetCompaniesRequestStatus)
  const [isSorted, setIsSorted] = useToggle()

  const handleDetailsClose = () => {
    dispatch(setIsCompanyDetailsPanel(false))
  }

  const handleDetailsOpen = (id?: string) => () => {
    setSelectedCompany(companies.find((company: Company) => company.id === id))
    dispatch(setIsCompanyDetailsPanel(true))
  }

  const sortedCompanies = useMemo(() => {
    const sorted: Company[] = [...companies]
    if (isSorted) {
      return sorted.sort(sortByNameDesc)
    }
    return sorted.sort(sortByNameAsc)
  }, [isSorted, companies])

  if (companiesRequestStatus === RequestStatuses.PENDING) {
    return <Loader />
  }

  return (
    <>
      <ListWrapper listSize={companies.length} variant='company'>
        <TableHead>
          <TableRow>
            {Object.keys(grids).map(key => (
              <StyledTableCell align='left' width={grids[key]} key={key}>
                {key}
                {key === NAME_FIELD &&
                  (isSorted ? (
                    <CaretUpIcon onClick={setIsSorted} />
                  ) : (
                    <CaretBottomIcon onClick={setIsSorted} />
                  ))}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedCompanies.map(
            ({ name, address, website, id, logo }: Company) => (
              <TableRow key={id}>
                <StyledTableCell
                  component='th'
                  scope='row'
                  sx={{ cursor: 'pointer' }}
                  onClick={handleDetailsOpen(id)}
                >
                  <Box sx={style.avatarWrapper}>
                    <Box sx={style.logo(addBase64Data(logo))}>
                      {!logo && extractInitials(name, 1)}
                    </Box>
                    <Typography variant='subtitle1' sx={style.name}>
                      {name}
                    </Typography>
                  </Box>
                </StyledTableCell>
                <StyledTableCell align='left'>
                  {website ? (
                    <SocialLink link={website} type='website'>
                      <WebsiteIcon />
                    </SocialLink>
                  ) : (
                    '-'
                  )}
                </StyledTableCell>
                <StyledTableCell align='left'>
                  {address ? (
                    <Typography variant='subtitle1' sx={style.name}>
                      {address}
                    </Typography>
                  ) : (
                    '-'
                  )}
                </StyledTableCell>
                <StyledTableCell
                  align='right'
                  sx={{ cursor: 'pointer' }}
                  onClick={handleDetailsOpen(id)}
                >
                  <CaretRightIcon />
                </StyledTableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </ListWrapper>
      <SidePanel isOpen={isCompanyDetailsPanel} onClose={handleDetailsClose}>
        {selectedCompany ? (
          <CompanyDetails
            company={selectedCompany}
            onEdit={handleEditCompanyPanel}
            onClose={handleDetailsClose}
          />
        ) : null}
      </SidePanel>
    </>
  )
}

export default CompaniesList
