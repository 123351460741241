import { Box, Typography } from '@mui/material'
import React, { FC } from 'react'

import successIcon from '../../../assets/images/sorryToSeeYouGo.png'

import style from './style'

type SuccessStepProps = {
  successText: string
  info: string
  infoSubtitle: string
}

const SuccessStep: FC<SuccessStepProps> = ({
  successText,
  info,
  infoSubtitle,
}) => (
  <Box sx={style.contentWrapper}>
    <img src={successIcon} alt='success-icon' />
    <Box>
      <Typography
        sx={style.modalText}
        mb='12px'
        color='#1E486C'
        textTransform='uppercase'
      >
        {successText}
      </Typography>
      <Typography sx={style.modalText} color='text.primary'>
        {info}
      </Typography>
      <Typography sx={style.modalSubtitle}>{infoSubtitle}</Typography>
    </Box>
  </Box>
)

export default SuccessStep
