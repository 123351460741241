const PREFIX = 'CALLSHEET_STATUS/'

export const PUBLISH_CALLSHEET_REQUEST =
  `${PREFIX}PUBLISH_CALLSHEET_REQUEST` as const
export const PUBLISH_CALLSHEET_REQUEST_SUCCESS =
  `${PREFIX}PUBLISH_CALLSHEET_REQUEST_SUCCESS` as const
export const PUBLISH_CALLSHEET_REQUEST_ERROR =
  `${PREFIX}PUBLISH_CALLSHEET_REQUEST_ERROR` as const

export const DECLINE_CALLSHEET_REQUEST =
  `${PREFIX}DECLINE_CALLSHEET_REQUEST` as const
export const TENTATIVE_CALLSHEET_REQUEST =
  `${PREFIX}TENTATIVE_CALLSHEET_REQUEST` as const
export const ACCEPT_CALLSHEET_REQUEST =
  `${PREFIX}ACCEPT_CALLSHEET_REQUEST` as const
export const DUPLICATE_CALLSHEET_REQUEST =
  `${PREFIX}DUPLICATE_CALLSHEET_REQUEST` as const
export const RESTORE_CALLSHEET_REQUEST =
  `${PREFIX}RESTORE_CALLSHEET_REQUEST` as const
export const PENDING_CALLSHEET_REQUEST =
  `${PREFIX}PENDING_CALLSHEET_REQUEST` as const

export const TENTATIVE_CONFIRMATION_REQUEST =
  `${PREFIX}TENTATIVE_CONFIRMATION_REQUEST` as const
export const DECLINE_CONFIRMATION_REQUEST =
  `${PREFIX}DECLINE_CONFIRMATION_REQUEST` as const
export const ACCEPT_CONFIRMATION_REQUEST =
  `${PREFIX}ACCEPT_CONFIRMATION_REQUEST` as const

export const CALLSHEET_CHANGE_STATUS_REQUEST_SUCCESS =
  `${PREFIX}CALLSHEET_CHANGE_STATUS_REQUEST_SUCCESS` as const
export const CALLSHEET_CHANGE_STATUS_REQUEST_ERROR =
  `${PREFIX}CALLSHEET_CHANGE_STATUS_REQUEST_ERROR` as const

export const SET_CONFIRMATION_STEP = `${PREFIX}SET_CONFIRMATION_STEP` as const

export const ACCEPT_ANNOUNCEMENT = `${PREFIX}ACCEPT_ANNOUNCEMENT` as const
export const SET_ANNOUNVEMENTS_TO_ACCEPT =
  `${PREFIX}SET_ANNOUNVEMENTS_TO_ACCEPT` as const
