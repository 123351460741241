import { noop } from 'lodash'
import React, {
  ChangeEventHandler,
  FC,
  FunctionComponent,
  SVGProps,
  useState,
} from 'react'
import { Box, TextField, Typography } from '@mui/material'
import { useToggle } from 'src/hooks/useToggle'
import {
  CheckCircleDisabledIcon,
  CheckCircleGreenContainedIcon,
  CheckCircleIcon,
  EditIcon,
  TrashcanIcon,
} from 'src/assets/icons'

import style from './style'

type EditableTextFieldTypes = {
  defaultValue?: string | null
  id?: string
  validator?: (...args: any[]) => boolean
  placeholder: string
  editMode?: boolean
  isChecked?: boolean
  onSelect?: () => void
  onChange?: typeof noop
  onEdit?: typeof noop
  onDelete?: typeof noop
  helperText?: string
  isReversed?: boolean
  toggleEditMode?: boolean
  selectionColor?: string
  iconsProps?: {
    Icon: FunctionComponent<SVGProps<SVGSVGElement>>
    IconDisabled: FunctionComponent<SVGProps<SVGSVGElement>>
    IconChecked: FunctionComponent<SVGProps<SVGSVGElement>>
  }
}

const defaultIconProps = {
  Icon: CheckCircleIcon,
  IconDisabled: CheckCircleDisabledIcon,
  IconChecked: CheckCircleGreenContainedIcon,
}

const EditableTextField: FC<EditableTextFieldTypes> = ({
  defaultValue,
  id,
  placeholder,
  helperText = '',
  editMode = false,
  isChecked = false,
  isReversed = false,
  toggleEditMode = true,
  onSelect = () => {},
  onChange = noop,
  onEdit = noop,
  onDelete = noop,
  selectionColor = '#38C171',
  validator = () => false,
  iconsProps = defaultIconProps,
}) => {
  const [isSelected, toggleSelected] = useToggle(isChecked)
  const [isEdit, toggleEdit] = useToggle(editMode)
  const [value, setValue] = useState<string | null | undefined>(defaultValue)
  const [error, setError] = useState<boolean>(false)

  const { Icon, IconChecked, IconDisabled } = iconsProps

  const isDisabled = !value

  const handleEdit = () => {
    if (isDisabled || error) return
    onEdit(value)
    if (toggleEditMode) {
      toggleEdit()
    } else {
      toggleSelected()
      onSelect()
    }
  }
  const handleKeyDown = (e: { key: string }) => {
    if (e.key === 'Enter') {
      handleEdit()
    }
  }

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    if (validator(e.target.value.trim())) {
      setError(true)
    } else {
      setError(false)
    }

    setValue(e.target.value.trim())
    onChange(e.target.value.trim())
  }

  const handleSelect = () => {
    toggleSelected()
    onSelect()
  }

  const InputContent = () => {
    return (
      <>
        <Typography variant='subtitle2'>{value}</Typography>
        <Box sx={style.icons}>
          <EditIcon onClick={toggleEdit} />
          {isSelected ? (
            <IconChecked onClick={handleSelect} />
          ) : (
            <Icon onClick={handleSelect} />
          )}
        </Box>
      </>
    )
  }

  return (
    <Box sx={style.wrapper(isReversed)}>
      <Box
        sx={style.inputBox}
        border={
          isSelected ? `1px solid ${selectionColor}` : '1px solid #E2E2E2'
        }
      >
        {isEdit ? (
          <>
            <TextField
              id={id}
              fullWidth
              error={error}
              defaultValue={value}
              placeholder={placeholder}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              helperText={error && !isDisabled ? helperText : ''}
            />

            {isDisabled || error ? (
              <IconDisabled className='disabled_icon' />
            ) : (
              <>
                {isSelected ? (
                  <IconChecked onClick={handleEdit} />
                ) : (
                  <Icon onClick={handleEdit} />
                )}
              </>
            )}
          </>
        ) : (
          <InputContent />
        )}
      </Box>
      {isEdit && <TrashcanIcon onClick={onDelete} />}
    </Box>
  )
}

export default EditableTextField
