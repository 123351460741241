import axios from 'axios'
import axiosRetry from 'axios-retry'
import { RETRY_ATTEMPTS, RETRY_DELAY } from '../constants'
import { getTokensFromStorage } from '../utils/tokens'

export const apiClient = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_URL
      : '/api/',
  timeout: 5000,
})

export const networkApiClient = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_NETWORK_API_URL
      : '/networkApi/',
  timeout: 5000,
})

apiClient.interceptors.request.use(
  config => {
    const { token } = getTokensFromStorage()

    if (token) {
      Object.assign(config.headers, { 'Access-User-Id': token })
    }
    return Promise.resolve(config)
  },
  e => Promise.reject(e)
)

networkApiClient.interceptors.request.use(
  config => {
    const { token } = getTokensFromStorage()

    if (token) {
      Object.assign(config.headers, { 'Access-User-Id': token })
    }
    return Promise.resolve(config)
  },
  e => Promise.reject(e)
)

axiosRetry(apiClient, {
  retries: RETRY_ATTEMPTS,
  retryDelay: retryCount => retryCount + RETRY_DELAY,
  retryCondition: error => error.code === 'ECONNABORTED',
})
