import { call, put, takeLatest, select } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'

import {
  selectCallsheetId,
  selectCallsheetData,
  selectIsCombinedNotes,
} from '../Callsheet/selectors'
import { setNotificationErrorWorker } from '../Notification/sagas'
import { SampleError } from '../../api/types'
import {
  ADD_ANNOUNCEMENT_REQUEST,
  DELETE_ANNOUNCEMENT_REQUEST,
  GET_ANNOUNCEMENT_REQUEST,
  SAVE_ANNOUNCEMENT_REQUEST,
  UPDATE_ANNOUNCEMENT_REQUEST,
} from './actionTypes'
import { GetAnnouncementResultType } from '../../api/announcements/types'
import {
  addAnnouncementApi,
  deleteAnnouncementApi,
  getAnnouncementApi,
  updateAnnouncementApi,
} from '../../api/announcements'
import {
  addAnnouncementRequest,
  deleteAnnouncementRequest,
  getAnnouncementRequestError,
  getAnnouncementRequestSuccess,
  saveAnnouncementRequestError,
  saveAnnouncementRequestSuccess,
  updateAnnouncementRequest,
} from './actions'
import {
  AddAnnouncementRequestType,
  Announcement,
  DeleteAnnouncementRequestType,
  UpdateAnnouncementRequestType,
} from './types'
import {
  selectAnnouncements,
  selectCombinedAnnouncement,
  selectInitialAnnouncements,
  selectIsAnnouncementCombined,
} from './selectors'
import {
  getAddedAnnouncements,
  getDeletedAnnouncements,
  getUpdatedAnnouncements,
} from '../../utils/announcementHelpers'
import {
  setIsCombinedNotes,
  updateCallsheetRequestSuccess,
} from '../Callsheet/actions'
import { updateCallsheetApi } from '../../api/callsheet'
import { SaveCallsheetResultType } from '../../api/callsheet/types'
import { Callsheet } from '../Callsheet/types'

export function* GetAnnouncementRequestWorker() {
  try {
    const callsheetId: string = yield select(selectCallsheetId)
    const isCombined: boolean = yield select(selectIsCombinedNotes)
    const {
      data: { data },
    }: AxiosResponse<GetAnnouncementResultType> = yield call(
      getAnnouncementApi,
      callsheetId
    )
    yield put(getAnnouncementRequestSuccess(data, isCombined))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(getAnnouncementRequestError(data as SampleError))
  }
}

export function* DeleteAnnouncementRequestWorker({
  payload: { id },
}: DeleteAnnouncementRequestType) {
  try {
    yield call(deleteAnnouncementApi, id)
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
  }
}

export function* AddAnnouncementRequestWorker({
  payload: { callsheetId, content, important },
}: AddAnnouncementRequestType) {
  try {
    yield call(addAnnouncementApi, callsheetId, important, content)
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
  }
}

export function* UpdateAnnouncementRequestWorker({
  payload: { id, content, important },
}: UpdateAnnouncementRequestType) {
  try {
    yield call(updateAnnouncementApi, id, important, content)
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
  }
}

function* saveAnnouncementsCallbackSaga(
  addeded: Announcement[],
  deleted: Announcement[],
  updated: Announcement[],
  callsheetId: string
) {
  for (let i = 0; i < deleted.length; i += 1) {
    const { id } = deleted[i]
    if (id) {
      yield put(deleteAnnouncementRequest(id))
    }
  }

  for (let i = 0; i < updated.length; i += 1) {
    const { id, content, important } = updated[i]
    if (id) {
      yield put(updateAnnouncementRequest(id, content, important))
    }
  }

  for (let i = 0; i < addeded.length; i += 1) {
    const { content, important } = addeded[i]
    yield put(addAnnouncementRequest(callsheetId, content, important))
  }
}

function* saveCombinedCallbackSaga(
  initial: Announcement[],
  callsheetId: string
) {
  const combined: string = yield select(selectCombinedAnnouncement)

  for (let i = 0; i < initial.length; i += 1) {
    const { id } = initial[i]
    if (id) {
      yield put(deleteAnnouncementRequest(id))
    }
  }

  yield put(addAnnouncementRequest(callsheetId, combined, false))
}

export function* SaveAnnouncementRequestWorker() {
  const callsheetId: string = yield select(selectCallsheetId)
  const callsheet: Callsheet = yield select(selectCallsheetData)
  const isCombined: boolean = yield select(selectIsAnnouncementCombined)
  const initial: Announcement[] = yield select(selectInitialAnnouncements)
  const current: Announcement[] = yield select(selectAnnouncements)
  try {
    const addedAnnouncements = getAddedAnnouncements(current)
    const deletedAnnouncements = getDeletedAnnouncements(initial, current)
    const updatedAnnouncements = getUpdatedAnnouncements(initial, current)

    if (isCombined) {
      yield saveCombinedCallbackSaga(initial, callsheetId)
    } else {
      yield saveAnnouncementsCallbackSaga(
        addedAnnouncements,
        deletedAnnouncements,
        updatedAnnouncements,
        callsheetId
      )
    }
    yield put(setIsCombinedNotes(isCombined))
    yield call(updateCallsheetApi, callsheetId, {
      combinedNotes: isCombined,
    })
    yield put(updateCallsheetRequestSuccess())
    yield put(saveAnnouncementRequestSuccess())
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(saveAnnouncementRequestError(data as SampleError))
  }
}

export function* requestAnnouncementsWatcher(): Generator {
  yield takeLatest(GET_ANNOUNCEMENT_REQUEST, GetAnnouncementRequestWorker)
  yield takeLatest(DELETE_ANNOUNCEMENT_REQUEST, DeleteAnnouncementRequestWorker)
  yield takeLatest(ADD_ANNOUNCEMENT_REQUEST, AddAnnouncementRequestWorker)
  yield takeLatest(UPDATE_ANNOUNCEMENT_REQUEST, UpdateAnnouncementRequestWorker)
  yield takeLatest(SAVE_ANNOUNCEMENT_REQUEST, SaveAnnouncementRequestWorker)
}
