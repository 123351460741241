import produce from 'immer'
import { RequestStatuses } from '../../api/constants'

import {
  ADD_EVENT,
  DELETE_EVENT,
  GET_EVENTS_REQUEST,
  GET_EVENTS_REQUEST_ERROR,
  GET_EVENTS_REQUEST_SUCCESS,
  SAVE_EVENTS_REQUEST,
  SAVE_EVENTS_REQUEST_ERROR,
  SAVE_EVENTS_REQUEST_SUCCESS,
  UPDATE_EVENT,
} from './actionTypes'
import { EventsActions, EventsState, ScheduleEvent } from './types'

const eventsInitialState: EventsState = {
  initialEvents: [],
  events: [],
  requestStatus: RequestStatuses.UNCALLED,
  saveRequestStatus: RequestStatuses.UNCALLED,
}

const eventsReducer = produce(
  (draft = eventsInitialState, action: EventsActions) => {
    switch (action.type) {
      case GET_EVENTS_REQUEST:
        draft.requestStatus = RequestStatuses.PENDING
        break
      case GET_EVENTS_REQUEST_SUCCESS:
        draft.requestStatus = RequestStatuses.SUCCEEDED
        draft.initialEvents = action.events
        draft.events = action.events
        break
      case GET_EVENTS_REQUEST_ERROR:
        draft.requestStatus = RequestStatuses.FAILED
        break
      case SAVE_EVENTS_REQUEST:
        draft.saveRequestStatus = RequestStatuses.PENDING
        break
      case SAVE_EVENTS_REQUEST_SUCCESS:
        draft.saveRequestStatus = RequestStatuses.SUCCEEDED
        break
      case SAVE_EVENTS_REQUEST_ERROR:
        draft.requestStatus = RequestStatuses.FAILED
        break
      case ADD_EVENT:
        draft.events.push({ ...action.event, tmpId: action.id })
        break
      case UPDATE_EVENT:
        draft.events = draft.events.map((item: ScheduleEvent) =>
          item.id === action.id || item.tmpId === action.id
            ? { ...action.event }
            : item
        )
        break
      case DELETE_EVENT:
        draft.events = draft.events.filter(
          (item: ScheduleEvent) =>
            item.tmpId !== action.id && item.id !== action.id
        )
        break
      default:
        return draft
    }
  }
)

export default eventsReducer
