import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import GlobalErrorToast from 'src/components/GlobalErrorToast'
import { RequestStatuses } from '../api/constants'
import { PATHS } from '../constants'
import { selectSignInRequestStatus } from '../store/SignIn/selectors'
import { getUserById } from '../store/User/actions'
import { selectUserId } from '../store/User/selectors'
import { getTokensFromStorage } from '../utils/tokens'
import PrivateRouter from './PrivateRouter'
import PublicRouter from './PublicRouter'

const MainRouter: FC = () => {
  // TODO simple login. Change when API ready
  const dispatch = useDispatch()
  const history = useHistory()
  const userId = useSelector(selectUserId)
  const { token } = getTokensFromStorage()
  const requestStatus = useSelector(selectSignInRequestStatus)
  const [currentToken, setCurrentToken] = useState<string | null>(token)

  useEffect(() => {
    if (requestStatus === RequestStatuses.SUCCEEDED) {
      setCurrentToken(token)
    }
  }, [requestStatus])

  useEffect(() => {
    if (currentToken) {
      dispatch(getUserById(currentToken))
    } else {
      history.push(PATHS.PUBLIC.WELCOME)
    }
  }, [currentToken])

  useEffect(() => {
    const storage = getTokensFromStorage()

    if (!storage.token) {
      setCurrentToken(null)
    }
  }, [userId])

  return (
    <>
      <GlobalErrorToast />
      {currentToken ? <PrivateRouter /> : <PublicRouter />}
    </>
  )
}

export default MainRouter
