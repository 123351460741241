import { History } from 'history'
import { SampleError } from '../../api/types'
import {
  SAVE_PUBLISH_DETAILS_REQUEST,
  SAVE_PUBLISH_DETAILS_REQUEST_SUCCESS,
  SAVE_PUBLISH_DETAILS_REQUEST_ERROR,
  GET_PUBLISH_DETAILS_REQUEST,
  GET_PUBLISH_DETAILS_REQUEST_SUCCESS,
  GET_PUBLISH_DETAILS_REQUEST_ERROR,
} from './actionTypes'
import {
  GetPublishDetailsRequestErrorType,
  GetPublishDetailsRequestSuccessType,
  GetPublishDetailsRequestType,
  SavePublishDetailsRequestErrorType,
  SavePublishDetailsRequestSuccessType,
  SavePublishDetailsRequestType,
} from './types'

export const savePublishDetailsRequest = (
  history: History,
  asDraft?: boolean
): SavePublishDetailsRequestType => ({
  type: SAVE_PUBLISH_DETAILS_REQUEST,
  history,
  asDraft,
})

export const getPublishDetailsRequest = (): GetPublishDetailsRequestType => ({
  type: GET_PUBLISH_DETAILS_REQUEST,
})

export const getPublishDetailsRequestSuccess =
  (): GetPublishDetailsRequestSuccessType => ({
    type: GET_PUBLISH_DETAILS_REQUEST_SUCCESS,
  })

export const getPublishDetailsRequestError = (
  error: SampleError
): GetPublishDetailsRequestErrorType => ({
  type: GET_PUBLISH_DETAILS_REQUEST_ERROR,
  error,
})

export const savePublishDetailsRequestSuccess =
  (): SavePublishDetailsRequestSuccessType => ({
    type: SAVE_PUBLISH_DETAILS_REQUEST_SUCCESS,
  })
export const savePublishDetailsRequestError = (
  error: SampleError
): SavePublishDetailsRequestErrorType => ({
  type: SAVE_PUBLISH_DETAILS_REQUEST_ERROR,
  error,
})
