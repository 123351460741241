const style = {
  input: {
    fontWeight: 900,
    fontSize: '12px',
    lineHeight: '20px',
    color: 'text.secondary',
    py: '0px',
  },
}

export default style
