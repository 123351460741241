const style = {
  label: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: 'text.primary',
  },
}

export default style
