import React, { FC, SyntheticEvent } from 'react'
import { Alert, IconButton, Snackbar, SnackbarCloseReason } from '@mui/material'
import { CrossWhiteIcon } from '../../assets/icons'

interface ToastProps {
  setOpen: (open: boolean) => void
  open: boolean
  text?: string
}

const Toast: FC<ToastProps> = ({
  setOpen,
  open,
  text = 'There was an error processing your request. Please try again later.',
}) => {
  const handleClickOnCloseButton = () => {
    setOpen(false)
  }

  const handleClose = (
    event: SyntheticEvent | Event,
    reason: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      onClose={handleClose}
    >
      <Alert
        elevation={6}
        variant='filled'
        severity='error'
        sx={{ '& .MuiAlert-action': { pt: '0px' } }}
        action={
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={handleClickOnCloseButton}
          >
            <CrossWhiteIcon />
          </IconButton>
        }
      >
        {text}
      </Alert>
    </Snackbar>
  )
}

export default Toast
