import React, { FC, useEffect, useReducer } from 'react'
import { noop } from 'lodash'
import { Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setIsMembersPanel } from '../../../../store/Builder/actions'
import { updateCompanyMembersRequest } from '../../../../store/Companies/actions'
import { selectMembersRequestStatus } from '../../../../store/Companies/selectors'

import { selectContacts } from '../../../../store/Contacts/selectors'
import { Contact } from '../../../../store/Contacts/types'
import ControlButtons from '../../../../components/ControlButtons'
import TextHead from '../../../../components/HeadText/HeadText'
import { setMembers } from '../CompanyForm/localStore/actions'
import { addContactsToList } from './localStore/actions'
import { membersFormReducer } from './localStore/reducer'
import MembersBox from './MembersBox'
import BackBtn from '../../../../components/BackBtn'
import ContactsBox from './ContactsBox'

import style from './style'

type MembersFormTypes = {
  companyDispatch: typeof noop
  companyId?: string
  members?: Contact[]
}

const MembersForm: FC<MembersFormTypes> = ({
  companyDispatch,
  companyId,
  members = [],
}) => {
  const contacts = useSelector(selectContacts)
  const requestStatus = useSelector(selectMembersRequestStatus)

  const dispatch = useDispatch()
  const [{ membersList, contactsList }, membersFormDispatch] = useReducer(
    membersFormReducer,
    {
      membersList: members,
      contactsList: [],
    }
  )

  const handleBtnClick = () => {
    dispatch(setIsMembersPanel(false))
  }

  const handleCancelBtn = () => {
    dispatch(setIsMembersPanel(false))
  }

  const getDeletedMembers = (
    companyMembers: Contact[],
    updatedMembers: Contact[]
  ) =>
    companyMembers.filter(member => {
      return !updatedMembers.includes(member)
    })

  function getAddedMembers(
    companyMembers: Contact[],
    updatedMembers: Contact[]
  ) {
    const diff: Contact[] = []
    updatedMembers.forEach(updatedMember => {
      const addedMember = companyMembers.find(
        companyMember => companyMember.id === updatedMember.id
      )
      if (!addedMember) {
        diff.push(updatedMember)
      }
    })

    return diff
  }

  const handleConfirmBtn = () => {
    if (companyId) {
      const deletedMembers = getDeletedMembers(members, membersList)
      const addedMembers = getAddedMembers(members, membersList)
      dispatch(
        updateCompanyMembersRequest(companyId, deletedMembers, addedMembers)
      )
    } else {
      dispatch(setIsMembersPanel(false))
    }

    companyDispatch(setMembers(membersList))
  }

  useEffect(() => {
    if (contacts.length) {
      membersFormDispatch(
        addContactsToList(
          contacts.filter(
            (contact: Contact) =>
              !members.some(member => member.id === contact.id)
          )
        )
      )
    }
  }, [contacts])

  return (
    <Box sx={style.wrapper}>
      <Box sx={style.content}>
        <BackBtn
          text='Back to production company details'
          onClick={handleBtnClick}
        />
        <TextHead
          title='Add production company contacts'
          subtitle='Please, choose and add contacts to the production company. Max allowed number of contacts - 2.'
        />
        <MembersBox
          members={membersList}
          membersFormDispatch={membersFormDispatch}
        />
        <ContactsBox
          membersCount={membersList.length}
          contacts={contactsList}
          formDispatch={membersFormDispatch}
        />
      </Box>
      <Box sx={style.footer}>
        <ControlButtons
          confirmTitle='Save and Close'
          handleCancelBtn={handleCancelBtn}
          handleConfirmBtn={handleConfirmBtn}
          requestStatus={requestStatus}
        />
      </Box>
    </Box>
  )
}

export default MembersForm
