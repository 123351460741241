import { RequestStatus } from 'src/api/types'
import { AppState } from '../types'
import { Announcement } from './types'

export const selectAnnouncements = (state: AppState): Announcement[] =>
  state.announcementsState.announcements

export const selectIsAnnouncementCombined = (state: AppState): boolean =>
  state.announcementsState.isCombined

export const selectCombinedAnnouncement = (state: AppState): string =>
  state.announcementsState.combinedAnnouncement

export const selectInitialAnnouncements = (state: AppState): Announcement[] =>
  state.announcementsState.initialAnnouncements

export const selectAnnouncementRequestStatus = (
  state: AppState
): RequestStatus => state.announcementsState.requestStatus

export const selectAnnouncementSaveRequestStatus = (
  state: AppState
): RequestStatus => state.announcementsState.saveRequestStatus
