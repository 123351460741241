import React, { FC } from 'react'
import moment, { MomentInput } from 'moment'
import { Box, Typography } from '@mui/material'

import style from './style'

const CallsheetDeletedFooter: FC<{ deletedAt?: DateConstructor | null }> = ({
  deletedAt,
}) => {
  return (
    <Box sx={style.content}>
      <Typography sx={style.title}>Deleted</Typography>
      <Typography sx={style.subtitle}>{`${moment(
        deletedAt as MomentInput
      ).fromNow()}`}</Typography>
    </Box>
  )
}

export default CallsheetDeletedFooter
