import React from 'react'
import { Box, Typography } from '@mui/material'

import style from './style'

const CallTimeCard = ({ callTime }: { callTime: string }) => {
  return (
    <Box sx={style.card}>
      <Typography sx={style.title}>{callTime}</Typography>
      <Typography sx={style.subtitle} variant='h5'>
        Your Call Time
      </Typography>
    </Box>
  )
}

export default CallTimeCard
