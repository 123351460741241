import { DRAWER_WIDTH } from '../../themes/constants'

const style = {
  wrapper: {
    height: '100%',
    position: 'relative',
    overflowY: 'hidden',
  },
  content: {
    height: 'calc(100vh - 40px)',
  },
  btnContainer: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: DRAWER_WIDTH,
    padding: '20px 40px',
    backgroundColor: 'white',
    margin: 'auto',
  },
  membersContainer: {
    mt: 2,
    overflow: 'scroll',
    maxHeight: '580px',
  },
}

export default style
