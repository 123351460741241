import { useEffect, useState } from 'react'

const useImageLoad = (url: URL) => {
  const [isImageLoaded, setIsImg] = useState<boolean>(true)
  const loadImage = async () => {
    try {
      const response = await fetch(url)
      if (!response.ok) {
        throw new Error('Failed to load image')
      }
    } catch (error) {
      setIsImg(false)
    }
  }
  useEffect(() => {
    loadImage()
  }, [])

  return { isImageLoaded, src: url }
}

export default useImageLoad
