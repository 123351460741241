import { Contact } from '../../../../../store/Contacts/types'
import {
  DELETE_CONTACTS_LIST_ITEM,
  ADD_CONTACTS_LIST_ITEM,
  ADD_MEMBERS_LIST_ITEM,
  DELETE_MEMBERS_LIST_ITEM,
  ADD_CONTACTS_TO_LIST,
} from './actionTypes'
import { MembersFormState, ActionTypes } from './types'

export const membersFormReducer = (
  state: MembersFormState,
  action: ActionTypes
): MembersFormState => {
  switch (action.type) {
    case ADD_CONTACTS_TO_LIST:
      return {
        ...state,
        contactsList: action.contacts,
      }
    case ADD_CONTACTS_LIST_ITEM:
      return {
        ...state,
        contactsList: [...state.contactsList, action.contactsListItem],
      }
    case DELETE_CONTACTS_LIST_ITEM:
      return {
        ...state,
        contactsList: state.contactsList.filter(
          (item: Contact) => item.email !== action.email
        ),
      }
    case ADD_MEMBERS_LIST_ITEM:
      return {
        ...state,
        membersList: [...state.membersList, action.membersListItem],
      }
    case DELETE_MEMBERS_LIST_ITEM:
      return {
        ...state,
        membersList: state.membersList.filter(
          (item: Contact) => item.email !== action.email
        ),
      }
    default:
      return state
  }
}
