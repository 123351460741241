import { ScheduleEvent } from '../store/Events/types'

export const getAddedEvents = (current: ScheduleEvent[]) => {
  return current.filter(item => !item.id)
}

export const getDeletedEvents = (
  initial: ScheduleEvent[],
  current: ScheduleEvent[]
) => {
  return initial.filter(event => !current.some(item => item.id === event.id))
}

export const getUpdatedEvents = (
  initial: ScheduleEvent[],
  current: ScheduleEvent[]
) => {
  return current.filter(event =>
    initial.some(
      item =>
        item.id === event.id &&
        (item.name !== event.name ||
          item.startDate !== event.startDate ||
          item.endDate !== event.endDate)
    )
  )
}

export const validate = (event: ScheduleEvent, events: ScheduleEvent[]) =>
  events.some(
    item =>
      item.name === event.name &&
      (item.id !== event.id || item.tmpId !== event.tmpId)
  )
