const style = {
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'space-between',
    height: '100%',
  },
  deleteBtn: {
    p: 0,
    backgroundColor: '#F6F6F6',
    borderRadius: '10px',
    width: '40px',
    height: '40px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  timeWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: (editFooter: boolean) => ({
    width: '100%',
    ...(editFooter && { pb: '110px' }),
  }),
  footer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'end',
    width: '100%',
    '> div': {
      width: '100%',
    },
  },
  item: {
    flexBasis: '50%',
    minHeight: 258,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}

export default style
