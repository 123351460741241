const PREFIX = 'CALLSHEET_DELETE_STATE/'

export const SET_CALLSHEETS_VIEW = `${PREFIX}SET_CALLSHEETS_VIEW` as const
export const SET_CALLSHEET_TO_DELETE =
  `${PREFIX}SET_CALLSHEET_TO_DELETE` as const

export const SET_CALLSHEETS_FILTER = `${PREFIX}SET_CALLSHEETS_FILTER` as const
export const SET_CALLSHEETS_SORT_TAG =
  `${PREFIX}SET_CALLSHEETS_SORT_TAG` as const

export const DELETE_CALLSHEET_REQUEST =
  `${PREFIX}DELETE_CALLSHEET_REQUEST` as const
export const DELETE_CALLSHEET_REQUEST_SUCCESS =
  `${PREFIX}DELETE_CALLSHEET_REQUEST_SUCCESS` as const
export const DELETE_CALLSHEET_REQUEST_ERROR =
  `${PREFIX}DELETE_CALLSHEET_REQUEST_ERROR` as const
export const SET_IS_PERMANENT_DELETE =
  `${PREFIX}SET_IS_PERMANENT_DELET` as const

export const UPDATE_CALLSHEET_EVENT_DATE_REQUEST =
  `${PREFIX}UPDATE_CALLSHEET_EVENT_DATE_REQUEST` as const

export const SET_CALLSHEET_ACTION_MODAL =
  `${PREFIX}SET_CALLSHEET_ACTION_MODAL` as const
export const SET_DELETE_CALLSHEET_MODAL =
  `${PREFIX}SET_DELETE_CALLSHEET_MODAL` as const

export const SET_CALLSHEET_TO_DUPLICATE =
  `${PREFIX}SET_CALLSHEET_TO_DUPLICATE` as const
