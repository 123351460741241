export const CHANGE_SIGN_IN_EMAIL = 'CHANGE_SIGN_IN_EMAIL'
export const CHANGE_SIGN_IN_NAME = 'CHANGE_SIGN_IN_NAME'
export const CHANGE_SIGN_IN_LAST_NAME = 'CHANGE_SIGN_IN_LAST_NAME'
export const CHANGE_SIGN_IN_PASSWORD = 'CHANGE_SIGN_IN_PASSWORD'
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST'
export const SIGN_IN_REQUEST_SUCCESS = 'SIGN_IN_REQUEST_SUCCESS'
export const SIGN_IN_REQUEST_ERROR = 'SIGN_IN_REQUEST_ERROR'
export const MAKE_SIGN_IN_REQUEST = 'MAKE_SIGN_IN_REQUEST'
export const RETURN_TO_INITIAL_SIGN_IN_REQUEST_STATE =
  'RETURN_TO_INITIAL_SIGN_IN_REQUEST_STATE'
export const MAKE_AFTER_AUTH_REQUESTS = 'MAKE_AFTER_AUTH_REQUESTS'
