import { Box, Stack, Typography } from '@mui/material'
import React, { FC } from 'react'
import useCallsheetStatistic from '../../../hooks/callsheet/useCallsheetStatistic'
import CircleProgressBar from '../../CircleProgressBar'
import style from './style'

type StatisticSectionTypes = {
  id?: string
}

const StatisticSection: FC<StatisticSectionTypes> = ({ id }) => {
  const { statistics, percent, total, viewed } = useCallsheetStatistic(id) ?? {}

  return (
    <Box sx={style.container}>
      {statistics ? (
        <Box display='flex'>
          <Box sx={style.progressBarContainer}>
            <CircleProgressBar
              percent={percent ?? 0}
              data={statistics}
              total={total}
              radius={60}
            />
            <Stack sx={style.legend}>
              <Typography sx={style.titleItem}>Statistics:</Typography>
              <Typography sx={style.listItem('Accepted')}>
                <span /> Accepted
              </Typography>
              <Typography sx={style.listItem('Declined')}>
                <span /> Decline
              </Typography>
              <Typography sx={style.listItem('Tentative')}>
                <span /> Tentative
              </Typography>
            </Stack>
          </Box>
          <Box sx={style.viewsContainer}>
            <Typography sx={style.viewsTitle}>Amount of view:</Typography>
            <Typography sx={style.viewsCount}>{viewed} Views</Typography>
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

export default StatisticSection
