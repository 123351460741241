import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import SocialLinks from '../../SocialLinks'
import useImageLoad from '../../../hooks/useImageLoad'

import { CameraIcon } from '../../../assets/icons'
import { TEXTS } from '../../../constants'

import style from './style'

type AvatarHeaderProps = {
  logo?: string
  firstName: string
  lastName?: string
  middleName?: string
  title?: string
  instagramLink?: string
  unsplashLink?: string
  bio?: string
  email?: string
  website?: string
  initialsProps?: { [key: string]: string }
  titleProps?: { [key: string]: string }
}

const AvatarHeader: FC<AvatarHeaderProps> = ({
  logo,
  firstName,
  lastName,
  bio,
  instagramLink,
  unsplashLink,
  title,
  email,
  website,
  initialsProps,
  titleProps,
  middleName,
}) => {
  const { isImageLoaded } = useImageLoad(logo as unknown as URL)
  return (
    <>
      <Box display='flex'>
        <Box sx={style.selectedAvatar}>
          {logo && isImageLoaded ? (
            <Box sx={style.avatarImg(logo)} />
          ) : (
            <Box sx={style.avatarPlaceholder}>
              <CameraIcon />
            </Box>
          )}
        </Box>
        <Box>
          <Typography sx={style.initials(initialsProps)} variant='h5'>
            {firstName} {lastName} {middleName}
          </Typography>
          <Typography sx={style.selectedTitle(titleProps)}>{title}</Typography>
          {email && (
            <Box sx={style.socialProfiles}>
              <SocialLinks
                instagramLink={instagramLink}
                unsplashLink={unsplashLink}
                personalLink={website}
                email={email}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={style.bio}>
        <Typography sx={style.bioHeader}>Notes</Typography>
        <Typography sx={style.bioContent}>
          {bio || (
            <Typography sx={style.noBioText}>
              {TEXTS.PROFILE_TEXTS.NO_BIO}
            </Typography>
          )}
        </Typography>
      </Box>
    </>
  )
}

export default AvatarHeader
