const PREFIX = 'ATTACHMENTS/'

export const SET_ATTACHMENTS = `${PREFIX}SET_ATTACHMENTS` as const

export const GET_ATTACHMENTS_REQUEST =
  `${PREFIX}GET_ATTACHMENTS_REQUEST` as const
export const GET_ATTACHMENTS_REQUEST_SUCCESS =
  `${PREFIX}GET_ATTACHMENTS_REQUEST_SUCCESS` as const
export const GET_ATTACHMENTS_REQUEST_ERROR =
  `${PREFIX}GET_ATTACHMENTS_REQUEST_ERROR` as const

export const SAVE_ATTACHMENTS_REQUEST =
  `${PREFIX}SAVE_ATTACHMENTS_REQUEST` as const
export const SAVE_ATTACHMENTS_REQUEST_SUCCESS =
  `${PREFIX}SAVE_ATTACHMENTS_REQUEST_SUCCES` as const

export const ADD_ATTACHMENTS_REQUEST =
  `${PREFIX}ADD_ATTACHMENTS_REQUEST` as const

export const DELETE_ATTACHMENTS_REQUEST =
  `${PREFIX}DELETE_ATTACHMENTS_REQUEST` as const

export const DOWNLOAD_ATTACHMENTS_REQUEST =
  `${PREFIX}DOWNLOAD_ATTACHMENTS_REQUEST` as const
