import produce from 'immer'
import { RequestStatuses } from 'src/api/constants'
import { switchCombinedAnnouncements } from '../../utils/announcementHelpers'

import {
  ADD_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  GET_ANNOUNCEMENT_REQUEST,
  GET_ANNOUNCEMENT_REQUEST_ERROR,
  GET_ANNOUNCEMENT_REQUEST_SUCCESS,
  SAVE_ANNOUNCEMENT_REQUEST,
  SAVE_ANNOUNCEMENT_REQUEST_ERROR,
  SAVE_ANNOUNCEMENT_REQUEST_SUCCESS,
  SELECT_ANNOUNCEMENT,
  SET_ANNOUNCEMENT,
  SET_COMBINED_ANNOUNCEMENT,
  SWITCH_COMBINED_ANNOUNCEMENT,
} from './actionTypes'
import { Announcement, AnnouncementsActions, AnnouncementsState } from './types'

const announcementsInitialState: AnnouncementsState = {
  initialAnnouncements: [],
  announcements: [],
  combinedAnnouncement: '',
  isCombined: false,
  announcementsLength: 0,
  requestStatus: RequestStatuses.UNCALLED,
  saveRequestStatus: RequestStatuses.UNCALLED,
}

const announcementsReducer = produce(
  (draft = announcementsInitialState, action: AnnouncementsActions) => {
    switch (action.type) {
      case GET_ANNOUNCEMENT_REQUEST:
        draft.requestStatus = RequestStatuses.PENDING
        break
      case GET_ANNOUNCEMENT_REQUEST_SUCCESS:
        draft.requestStatus = RequestStatuses.SUCCEEDED
        draft.initialAnnouncements = action.announcements
        draft.announcements = action.announcements
        draft.isCombined = action.isCombined ?? false
        draft.combinedAnnouncement = action.isCombined
          ? action.announcements[0].content
          : ''
        break
      case GET_ANNOUNCEMENT_REQUEST_ERROR:
        draft.requestStatus = RequestStatuses.FAILED
        break
      case SAVE_ANNOUNCEMENT_REQUEST:
        draft.saveRequestStatus = RequestStatuses.PENDING
        break
      case SAVE_ANNOUNCEMENT_REQUEST_SUCCESS:
        draft.saveRequestStatus = RequestStatuses.SUCCEEDED
        break
      case SAVE_ANNOUNCEMENT_REQUEST_ERROR:
        draft.saveRequestStatus = RequestStatuses.FAILED
        break
      case ADD_ANNOUNCEMENT:
        draft.announcements.push(action.announcement)
        draft.announcementsLength += 1
        break
      case SET_ANNOUNCEMENT:
        draft.announcements = draft.announcements.map((item: Announcement) =>
          item.tmpId === action.id || item.id === action.id
            ? { ...item, content: action.content }
            : item
        )
        draft.combinedAnnouncement = ''
        break
      case SELECT_ANNOUNCEMENT:
        draft.announcements = draft.announcements.map((item: Announcement) =>
          item.tmpId === action.id || item.id === action.id
            ? { ...item, important: !item.important }
            : item
        )
        break
      case SWITCH_COMBINED_ANNOUNCEMENT:
        switchCombinedAnnouncements(draft)
        break
      case SET_COMBINED_ANNOUNCEMENT:
        draft.combinedAnnouncement = action.content
        break
      case DELETE_ANNOUNCEMENT:
        if (draft.announcements.length > 1) {
          draft.announcements = draft.announcements.filter(
            (item: Announcement) =>
              item.tmpId !== action.id && item.id !== action.id
          )
          draft.announcementsLength -= 1
        }
        draft.combinedAnnouncement = ''
        break
      default:
        return draft
    }
  }
)

export default announcementsReducer
