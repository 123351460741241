export const gridsShort = {
  name: 3,
  email: 2.25,
  phone: 0,
  callTime: 2,
  wrapTime: 2.25,
  notes: 0,
}

export const gridsLong = {
  name: 1.8,
  email: 1.5,
  phone: 1.5,
  callTime: 1.5,
  wrapTime: 1.5,
  notes: 1.95,
}
