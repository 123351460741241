import { EXTENDED_WIDTH } from './constants'

const style = {
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    minWidth: EXTENDED_WIDTH,
    overflow: 'hidden',
  },
  input: {
    py: '6px',
    fontSize: '12px',
    lineHeight: '20px',
  },

  popper: {
    '& .MuiPaper-root': {
      '& .MuiAutocomplete-listbox': {
        overflowX: 'hidden',
      },
    },
  },
}

export default style
