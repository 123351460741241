import { Popover } from '@mui/material'
import { noop } from 'lodash'
import React, { PropsWithChildren, FC, useState } from 'react'

type InfoPopoverProps = {
  onClose: typeof noop
  open: boolean
  anchorEl: HTMLElement | null
}

const InfoPopover: FC<InfoPopoverProps & PropsWithChildren> = ({
  children,
  onClose,
  open,
  anchorEl,
}) => {
  return (
    <Popover
      id='mouse-over-popover'
      sx={{
        pointerEvents: 'none',
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onClose}
      disableRestoreFocus
    >
      {children}
    </Popover>
  )
}

export default InfoPopover
