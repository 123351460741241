import produce from 'immer'
import { RequestStatuses } from '../../api/constants'

import {
  GET_CALLSHEETS_REQUEST,
  GET_CALLSHEETS_REQUEST_ERROR,
  GET_CALLSHEETS_REQUEST_SUCCESS,
  GET_CALLSHEET_BY_ID_REQUEST,
  GET_CALLSHEET_BY_ID_REQUEST_ERROR,
  GET_CALLSHEET_BY_ID_REQUEST_SUCCESS,
  GET_MEMBER_TITLES_REQUEST,
  GET_MEMBER_TITLES_REQUEST_ERROR,
  GET_MEMBER_TITLES_REQUEST_SUCCESS,
  SAVE_CALLSHEET_REQUEST,
  SAVE_CALLSHEET_REQUEST_ERROR,
  SAVE_CALLSHEET_REQUEST_SUCCESS,
  UPDATE_CALLSHEET_REQUEST,
  UPDATE_CALLSHEET_REQUEST_ERROR,
  UPDATE_CALLSHEET_REQUEST_SUCCESS,
  SET_CALLSHEET,
  SET_CALLSHEET_ID,
  SET_CALLSHEET_TITLE,
  SET_CLIENT_COMPANY_ID,
  SET_CREW_CALL_TIME,
  SET_EVENT_DATE,
  SET_IS_COMBINED_NOTES,
  SET_PRODUCT_COMPANY_ID,
  SET_PRODUCTION_NOTE_DETAILS,
  SET_PRODUCTION_NOTE_TITLE,
  SAVE_CALLSHEET_FINAL_TOUCHES_REQUEST,
  SAVE_CALLSHEET_FINAL_TOUCHES_REQUEST_ERROR,
  SAVE_CALLSHEET_FINAL_TOUCHES_REQUEST_SUCCESS,
  SET_CALLSHEET_STATUS,
  GET_CALLSHEETS_STATISTIC_REQUEST_SUCCESS,
} from './actionTypes'
import { Callsheet, CallsheetActions, CallsheetState } from './types'

export const INITIAL_CALLSHEET = {
  title: '',
  eventDate: null,
  deletedAt: null,
  crewCallTime: '',
  productCompanyId: '',
  clientCompanyId: '',
  productionNoteDetails: '',
  productionNoteTitle: '',
}

const CallsheetInitialState: CallsheetState = {
  callsheet: INITIAL_CALLSHEET,
  callsheets: [],
  memberTitles: [],
  callsheetsStatistic: {},
  requestStatus: RequestStatuses.UNCALLED,
  finalTouchesRequestStatus: RequestStatuses.UNCALLED,
  callsheetsRequestStatus: RequestStatuses.UNCALLED,
  memberTitlesRequestStatus: RequestStatuses.UNCALLED,
}

const callsheetReducer = produce(
  (draft = CallsheetInitialState, action: CallsheetActions) => {
    switch (action.type) {
      case GET_CALLSHEETS_REQUEST:
        draft.callsheetsRequestStatus = RequestStatuses.PENDING
        break

      case GET_CALLSHEETS_REQUEST_SUCCESS:
        draft.callsheets = action.callsheets
        draft.callsheetsRequestStatus = RequestStatuses.SUCCEEDED
        break
      case GET_CALLSHEETS_REQUEST_ERROR:
        draft.callsheetsRequestStatus = RequestStatuses.FAILED
        break
      case GET_CALLSHEET_BY_ID_REQUEST:
      case SAVE_CALLSHEET_REQUEST:
      case UPDATE_CALLSHEET_REQUEST:
        draft.requestStatus = RequestStatuses.PENDING
        break
      case SAVE_CALLSHEET_FINAL_TOUCHES_REQUEST:
        draft.finalTouchesRequestStatus = RequestStatuses.PENDING
        break
      case GET_CALLSHEET_BY_ID_REQUEST_ERROR:
      case SAVE_CALLSHEET_REQUEST_ERROR:
      case UPDATE_CALLSHEET_REQUEST_ERROR:
        draft.requestStatus = RequestStatuses.FAILED
        break
      case SAVE_CALLSHEET_FINAL_TOUCHES_REQUEST_ERROR:
        draft.finalTouchesRequestStatus = RequestStatuses.FAILED
        break
      case GET_CALLSHEET_BY_ID_REQUEST_SUCCESS:
        draft.callsheet = { ...draft.callsheet, ...action.callsheet }
        draft.requestStatus = RequestStatuses.SUCCEEDED
        break
      case SAVE_CALLSHEET_REQUEST_SUCCESS:
        draft.callsheet.id = action.id
        draft.requestStatus = RequestStatuses.SUCCEEDED
        break
      case UPDATE_CALLSHEET_REQUEST_SUCCESS:
        draft.requestStatus = RequestStatuses.SUCCEEDED
        break
      case SAVE_CALLSHEET_FINAL_TOUCHES_REQUEST_SUCCESS:
        draft.finalTouchesRequestStatus = RequestStatuses.SUCCEEDED
        break
      case GET_MEMBER_TITLES_REQUEST:
        draft.memberTitlesRequestStatus = RequestStatuses.SUCCEEDED
        break
      case GET_MEMBER_TITLES_REQUEST_SUCCESS:
        draft.memberTitles = action.titles
        draft.memberTitlesRequestStatus = RequestStatuses.SUCCEEDED
        break
      case GET_MEMBER_TITLES_REQUEST_ERROR:
        draft.memberTitlesRequestStatus = RequestStatuses.SUCCEEDED
        break
      case SET_CALLSHEET:
        draft.callsheet = action.callsheet
        break
      case SET_CALLSHEET_ID:
        draft.callsheet.id = action.id
        break
      case SET_CALLSHEET_TITLE:
        draft.callsheet.title = action.title
        break
      case SET_CALLSHEET_STATUS:
        draft.callsheets = draft.callsheets.map((item: Callsheet) => ({
          ...item,
          status: item.id === action.callsheetId ? action.status : item.status,
        }))
        break
      case SET_EVENT_DATE:
        draft.callsheet.eventDate = action.eventDate
        break
      case SET_CREW_CALL_TIME:
        draft.callsheet.crewCallTime = action.crewCallTime
        break
      case SET_PRODUCT_COMPANY_ID:
        draft.callsheet.productCompanyId = action.productCompanyId
        break
      case SET_CLIENT_COMPANY_ID:
        draft.callsheet.clientCompanyId = action.clientCompanyId
        break
      case SET_IS_COMBINED_NOTES:
        draft.callsheet.combinedNotes = action.combinedNotes
        break
      case SET_PRODUCTION_NOTE_DETAILS:
        draft.callsheet.productionNoteDetails = action.productionNoteDetails
        break
      case SET_PRODUCTION_NOTE_TITLE:
        draft.callsheet.productionNoteTitle = action.productionNoteTitle
        break
      case GET_CALLSHEETS_STATISTIC_REQUEST_SUCCESS:
        draft.callsheetsStatistic = action.callsheetsStatistic
        break
      default:
        return draft
    }
  }
)

export default callsheetReducer
