import React, { FC } from 'react'
import { Box, Chip } from '@mui/material'
import { ContactTag } from '../../store/Contacts/types'

import style from './style'

type ContactChipsProps = {
  tags?: ContactTag[]
  isFriend: boolean
}

const ContactChips: FC<ContactChipsProps> = ({ tags = [], isFriend }) => {
  const type = isFriend ? 'friend' : 'contact'
  return (
    <Box sx={style.chipsBox}>
      {tags.length
        ? tags.map(tag => (
            <Chip key={tag.id} label={tag.name} sx={style.chip} />
          ))
        : null}
      <Chip label={type} sx={{ ...style.chip, ...style.chipBg[type] }} />
    </Box>
  )
}

export default ContactChips
