import { LoadingButton } from '@mui/lab'
import { Box, Button } from '@mui/material'
import React, { FC } from 'react'

import ConfirmModalWrapper from '../ConfirmModalWrapper/ConfirmModalWrapper'
import SuccessStep from '../SuccessStep'
import style from './style'

const ConfirmDeclineModal: FC<{
  open: boolean
  onBack: () => void
  onClose: () => void
  onUndoDecline: () => void
  title: string
}> = ({ open, onClose, title, onBack, onUndoDecline }) => {
  return (
    <ConfirmModalWrapper
      open={open}
      onClose={onClose}
      title='CallSheet Confirmation'
      maxWidth='732px'
      dialogContentStyle={style.declineContentWrapper}
      DialogContentChildren={
        <SuccessStep
          successText='sorry to see you go'
          info={`Callsheet "${title}" has been declined.`}
          infoSubtitle=' Lorem ipsum dolor sit amet consectetur. Aliquam ac quis leo nisl
              aliquet arcu ac sit. Euismod nam convallis bibendum varius cras
              lorem. Ut sed faucibus urna pharetra lobortis vestibulum.'
        />
      }
      DialogActionsChildren={
        <Box display='flex' justifyContent='space-between' gap='12px'>
          <Button variant='outlined' onClick={onUndoDecline} sx={style.undoBtn}>
            Undo Decline
          </Button>
          <LoadingButton variant='contained' fullWidth onClick={onBack}>
            Back to CallSheet
          </LoadingButton>
        </Box>
      }
    />
  )
}

export default ConfirmDeclineModal
