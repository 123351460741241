import { SampleError } from 'src/api/types'
import {
  ADD_ANNOUNCEMENT,
  ADD_ANNOUNCEMENT_REQUEST,
  DELETE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT_REQUEST,
  GET_ANNOUNCEMENT_REQUEST,
  GET_ANNOUNCEMENT_REQUEST_ERROR,
  GET_ANNOUNCEMENT_REQUEST_SUCCESS,
  SAVE_ANNOUNCEMENT_REQUEST,
  SAVE_ANNOUNCEMENT_REQUEST_ERROR,
  SAVE_ANNOUNCEMENT_REQUEST_SUCCESS,
  SELECT_ANNOUNCEMENT,
  SET_ANNOUNCEMENT,
  SET_COMBINED_ANNOUNCEMENT,
  SWITCH_COMBINED_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT_REQUEST,
} from './actionTypes'
import {
  AddAnnouncement,
  AddAnnouncementRequestType,
  Announcement,
  DeleteAnnouncement,
  DeleteAnnouncementRequestType,
  GetAnnouncementRequestErrorType,
  GetAnnouncementRequestSuccessType,
  GetAnnouncementRequestType,
  SaveAnnouncementRequestErrorType,
  SaveAnnouncementRequestSuccessType,
  SaveAnnouncementRequestType,
  SelectAnnouncement,
  SetAnnouncement,
  SetCombinedAnnouncement,
  SwitchCombinedAnnouncement,
  UpdateAnnouncementRequestType,
} from './types'

export const addAnnouncement = (
  announcement: Announcement
): AddAnnouncement => ({
  type: ADD_ANNOUNCEMENT,
  announcement,
})

export const setAnnouncement = (
  id: string,
  content: string
): SetAnnouncement => ({
  type: SET_ANNOUNCEMENT,
  id,
  content,
})

export const selectAnnouncement = (id: string): SelectAnnouncement => ({
  type: SELECT_ANNOUNCEMENT,
  id,
})

export const deleteAnnouncement = (id: string): DeleteAnnouncement => ({
  type: DELETE_ANNOUNCEMENT,
  id,
})

export const switchCombinedAnnouncement = (): SwitchCombinedAnnouncement => ({
  type: SWITCH_COMBINED_ANNOUNCEMENT,
})

export const setCombinedAnnouncement = (
  content: string
): SetCombinedAnnouncement => ({
  type: SET_COMBINED_ANNOUNCEMENT,
  content,
})

export const getAnnouncementRequest = (): GetAnnouncementRequestType => ({
  type: GET_ANNOUNCEMENT_REQUEST,
})

export const getAnnouncementRequestSuccess = (
  announcements: Announcement[],
  isCombined: boolean
): GetAnnouncementRequestSuccessType => ({
  type: GET_ANNOUNCEMENT_REQUEST_SUCCESS,
  announcements,
  isCombined,
})

export const getAnnouncementRequestError = (
  error: SampleError | null
): GetAnnouncementRequestErrorType => ({
  type: GET_ANNOUNCEMENT_REQUEST_ERROR,
  error,
})

export const deleteAnnouncementRequest = (
  id: string
): DeleteAnnouncementRequestType => ({
  type: DELETE_ANNOUNCEMENT_REQUEST,
  payload: {
    id,
  },
})

export const addAnnouncementRequest = (
  callsheetId: string,
  content: string,
  important: boolean
): AddAnnouncementRequestType => ({
  type: ADD_ANNOUNCEMENT_REQUEST,
  payload: {
    callsheetId,
    content,
    important,
  },
})

export const updateAnnouncementRequest = (
  id: string,
  content: string,
  important: boolean
): UpdateAnnouncementRequestType => ({
  type: UPDATE_ANNOUNCEMENT_REQUEST,
  payload: {
    id,
    content,
    important,
  },
})

export const saveAnnouncementRequest = (): SaveAnnouncementRequestType => ({
  type: SAVE_ANNOUNCEMENT_REQUEST,
})

export const saveAnnouncementRequestSuccess =
  (): SaveAnnouncementRequestSuccessType => ({
    type: SAVE_ANNOUNCEMENT_REQUEST_SUCCESS,
  })

export const saveAnnouncementRequestError = (
  error: SampleError
): SaveAnnouncementRequestErrorType => ({
  type: SAVE_ANNOUNCEMENT_REQUEST_ERROR,
  error,
})
