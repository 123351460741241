import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  deleteCallsheetApi,
  publishCallsheetApi,
  updateCallsheetApi,
} from '../../api/callsheet'

import { SampleError } from '../../api/types'

import { setNotificationErrorWorker } from '../Notification/sagas'
import {
  deleteCallsheetRequestError,
  deleteCallsheetRequestSuccess,
  setDeleteCallsheetModal,
} from './actions'
import {
  DELETE_CALLSHEET_REQUEST,
  UPDATE_CALLSHEET_EVENT_DATE_REQUEST,
} from './actionTypes'

import {
  DeleteCallsheetRequestType,
  UpdateCallsheetEventDateRequestType,
} from './types'
import { GetCallsheetsRequestWorker } from '../Callsheet/sagas'
import { CallsheetStatuses } from '../Callsheet/types'
import {
  setCallsheetActionModal,
  setIsConfirmDeleteActionModal,
} from '../Builder/actions'
import { CallsheetGalleryActions } from '../Builder/types'
import { selectIsPermanentDelete } from './selectors'

export function* DeleteCallsheetRequestWorker({
  payload: { callsheetId },
}: DeleteCallsheetRequestType) {
  const isPermanentDelete: boolean = yield select(selectIsPermanentDelete)
  try {
    yield call(deleteCallsheetApi, callsheetId)
    yield put(deleteCallsheetRequestSuccess())
    yield put(setDeleteCallsheetModal(false))

    if (isPermanentDelete) {
      yield put(
        setCallsheetActionModal(true, CallsheetGalleryActions.PERMANETLY_DELETE)
      )
    } else {
      yield put(setCallsheetActionModal(true, CallsheetGalleryActions.DELETE))
    }

    yield call(GetCallsheetsRequestWorker)
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(deleteCallsheetRequestError(data as SampleError))
  }
}

export function* UpdateCallsheetEventDateRequestWorker({
  payload,
}: UpdateCallsheetEventDateRequestType) {
  const { id, status, title, ...updated } = payload

  try {
    if (id) {
      yield call(updateCallsheetApi, id, updated)
      if (status === CallsheetStatuses.PUBLISHED) {
        yield call(publishCallsheetApi, id)
      }
      yield put(deleteCallsheetRequestSuccess())
    }
    yield put(setDeleteCallsheetModal(false))
    yield put(setIsConfirmDeleteActionModal(true))
    yield call(GetCallsheetsRequestWorker)
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(deleteCallsheetRequestError(data as SampleError))
  }
}

export function* callsheetDeleteStateWatcher(): Generator {
  yield takeLatest(DELETE_CALLSHEET_REQUEST, DeleteCallsheetRequestWorker)
  yield takeLatest(
    UPDATE_CALLSHEET_EVENT_DATE_REQUEST,
    UpdateCallsheetEventDateRequestWorker
  )
}
