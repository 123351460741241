import { RequestStatus } from 'src/api/types'
import { AppState } from '../types'
import { ScheduleEvent } from './types'

export const selectEvents = (state: AppState): ScheduleEvent[] =>
  state.eventsState.events

export const selectInitialEvents = (state: AppState): ScheduleEvent[] =>
  state.eventsState.initialEvents

export const selectEventsRequestStatus = (state: AppState): RequestStatus =>
  state.eventsState.requestStatus

export const selectEventsSaveRequestStatus = (state: AppState): RequestStatus =>
  state.eventsState.saveRequestStatus
