const style = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  popOver: {
    textAlign: 'center',
    maxWidth: '389px',
    p: 3,
    '> h5': {
      p: '0px',
      m: '0px',
      fontWeight: 900,
      fontSize: '18px',
      lineHeight: '28px',
      marginBottom: 1,
    },
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 0,
    '> h5': {
      color: 'text.primary',
      textAlign: 'left',
      lineHeight: '24px',
    },
  },

  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,

    '> p': {
      color: 'text.primary',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
}

export default style
