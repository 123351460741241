const style = {
  buttonsBox: {
    position: 'absolute',
    margin: '32px',
  },
  button: {
    marginRight: '12px',
    padding: '6px 24px',
    height: '32px',
  },
  iconBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconBox: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '5px',
    svg: {
      height: '14px',
    },
  },
  exitBtn: {
    width: '75px',
    color: '#99221C',
    borderColor: '#99221C',
  },
  prevBtn: {
    width: '90px',
    color: 'text.primary',
    borderColor: 'text.primary',
  },
}

export default style
