import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { getResponseErrorMessage, getResponseErrorCode } from 'src/utils/api'
import { SignInApiResultType } from 'src/api/signIn/types'
import { postSignInData } from '../../api/signIn'
import { PATHS } from '../../constants'
import { setTokensInStorage } from '../../utils/tokens'
import { setNotificationErrorWorker } from '../Notification/sagas'
import * as actionTypes from './actionTypes'
import {
  selectSignInEmail,
  selectSignInLastName,
  selectSignInName,
  selectSignInPassword,
} from './selectors'
import { SampleError } from '../../api/types'
import {
  signInRequestSuccess,
  signInRequestError,
  signInRequest,
  makeAfterAuthRequests,
} from './actions'
import { getUser, saveUser } from '../User/actions'
import { MakeSignInRequestActionType } from './types'

export function* fetchSignIn({ history }: MakeSignInRequestActionType) {
  yield put(signInRequest())
  const email: string = yield select(selectSignInEmail)
  const firstName: string = yield select(selectSignInName)
  const lastName: string = yield select(selectSignInLastName)
  // const password = yield select(selectSignInPassword)

  try {
    const {
      data: { data },
    }: AxiosResponse<SignInApiResultType> = yield call(postSignInData, {
      email,
      firstName,
      lastName,
    })

    yield call(setTokensInStorage, data.id)
    yield put(saveUser(data))
    yield put(signInRequestSuccess())
    yield call(history.push, PATHS.PRIVATE.CALLSHEETS)
  } catch ({ response: data }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(signInRequestError(data as SampleError))
  }
}

export function* makeAfterAuthRequestsWorker(): Generator {
  yield all([put(getUser())])
}

export function* watchSignIn(): Generator {
  yield takeLatest(actionTypes.MAKE_SIGN_IN_REQUEST, fetchSignIn)
  yield takeLeading(
    actionTypes.MAKE_AFTER_AUTH_REQUESTS,
    makeAfterAuthRequestsWorker
  )
}
