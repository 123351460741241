import { AppState } from '../types'

export const selectGroupMembersByTitle =
  (groupTitle: string) => (state: AppState) =>
    state.groupMembersState.members.filter(
      member => member.groupTitle === groupTitle
    )
export const selectGroupMembersRequestStatus = (state: AppState) =>
  state.groupMembersState.requestStatus
export const selectGroupMembers = (state: AppState) =>
  state.groupMembersState.members

export const selectPreselectedGroupMembers = (state: AppState) =>
  state.groupMembersState.preSelectedMembers
