const style = {
  wrapper: {
    borderRadius: '32px',
    minHeight: '448px',
  },
  avatarBox: {
    backgroundColor: '#F1F1F2',
    borderRadius: '32px 32px 0px 0px',
    padding: '12px',
  },
  avatar: {
    borderRadius: '100px',
    width: '48px',
    height: '48px',
    backgroundColor: 'text.disabled',
  },
  phone: {
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '15px',
    mt: '2px',
    textTransform: 'capitalize',
  },
  chatWrapper: {
    mt: 2,
    ml: '12px',
  },
  message: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: 'text.secondary',
  },

  msgWrapper: {
    maxWidth: '483px',
    backgroundColor: '#F2F9FF',
    borderRadius: '20px 20px 20px 1px',
    padding: '12px',
  },
}

export default style
