import { Contact } from '../../store/Contacts/types'

export const initialContact: Omit<Contact, 'id'> = {
  logo: '',
  firstName: '',
  lastName: '',
  middleName: '',
  email: '',
  phone: '',
  title: '',
  website: '',
  instagramLink: '',
  unsplashLink: '',
  notes: '',
  isFriend: false,
}
