import React, { FC } from 'react'
import { Stack, Box } from '@mui/material'

import { user1, user2, user3, userInfo } from './assets'
import style from './style'

const users = [user1, user2, user3]

const UserGroupCard: FC = React.memo(() => {
  return (
    <Stack spacing={1} sx={style.wrapper}>
      {users.map((user: string, idx: number) => (
        <Box display='flex' key={idx} alignItems='center'>
          <img src={user} alt={`user${idx}`} />
          <Stack spacing='4px' ml={2}>
            {Array(3)
              .fill(1)
              .map((_, id: number) => (
                <img src={userInfo} alt={`userInfo${id}`} key={id} />
              ))}
          </Stack>
        </Box>
      ))}
    </Stack>
  )
})
export default UserGroupCard
