import { Box, Stack, Typography } from '@mui/material'
import React, { FC } from 'react'
import AvatarBox from '../AvatarBox'

import style from './style'

type ContactNameCellProps = {
  firstName: string
  lastName: string
  title: string
  img?: string
  placeHolder?: string
}

const ContactNameCell: FC<ContactNameCellProps> = ({
  firstName,
  lastName,
  placeHolder,
  img,
  title,
}) => (
  <Box sx={style.avatar_wrapper}>
    <AvatarBox placeHolder={placeHolder} img={img} />
    <Stack>
      <Typography variant='body2'>{`${firstName} ${lastName}`}</Typography>
      <Typography variant='body1' color='text.disabled'>
        {title}
      </Typography>
    </Stack>
  </Box>
)

export default ContactNameCell
