import {
  Divider,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material'
import React, { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  setSmsReminder,
  switchSmsReminder,
} from '../../../../store/SmsParameters/actions'
import { selectSmsParameters } from '../../../../store/SmsParameters/selectors'

import PreviewFormControl from '../PreviewFormControl'
import { Editor } from '../../../../components/Editor/Editor'
import SmsResult from './SmsResult'
import Reminder from '../Reminder'

import { SELECT_PHONE_PLACEHOLDER, variables } from '../../../../constants'

type SmsPreviewFormProps = {
  smsText: string
  phone: string
  onSmsContentChange: (value: string) => void
  onPhoneChange: (value: string) => void
}

const SmsPreviewForm: FC<SmsPreviewFormProps> = ({
  smsText,
  phone,
  onSmsContentChange,
  onPhoneChange,
}) => {
  const dispatch = useDispatch()
  const { smsReminder, isSmsReminder } = useSelector(selectSmsParameters)
  const handleChangeSmsFrom = (event: SelectChangeEvent) => {
    onPhoneChange(event.target.value as string)
  }

  return (
    <Grid container gap='48px'>
      <Grid item>
        <Stack gap={1} py={3} width='458px'>
          <PreviewFormControl label='SMS text' required>
            <Editor
              value={smsText}
              variant='tag'
              placeholder='Please, enter SMS text'
              onChange={onSmsContentChange}
              variables={variables}
            />
          </PreviewFormControl>
          <Divider />
          <PreviewFormControl label='SMS settings'>
            <Reminder
              variant='sms'
              defaultValue={smsReminder}
              checked={isSmsReminder}
              onRemindBeforeChange={(value: number) =>
                dispatch(setSmsReminder(value))
              }
              onEmailReminderToggle={() => dispatch(switchSmsReminder())}
            />
          </PreviewFormControl>
        </Stack>
      </Grid>
      <Grid item>
        <Stack gap={1} py={3} width='546px'>
          <PreviewFormControl label='SMS from' required>
            <Select
              labelId='phone-select-label'
              id='phone-select'
              value={phone || SELECT_PHONE_PLACEHOLDER}
              onChange={handleChangeSmsFrom}
            >
              <MenuItem value={SELECT_PHONE_PLACEHOLDER} disabled>
                {SELECT_PHONE_PLACEHOLDER}
              </MenuItem>
              <MenuItem value='77555'>77555</MenuItem>
            </Select>
          </PreviewFormControl>
          <SmsResult text={smsText} />
        </Stack>
      </Grid>
    </Grid>
  )
}

export default SmsPreviewForm
