import React from 'react'
import { Box, InputAdornment, InputBase, Typography } from '@mui/material'
import style from './style'

const SelectFilterInput = (
  props: JSX.IntrinsicAttributes & {
    label: string
    outlined?: boolean
    inputStyleProps?: { [key: string]: string }
    color?: string
  }
) => {
  const {
    label,
    outlined,
    inputStyleProps,
    color = 'text.primary',
    ...rest
  } = props
  return (
    <Box display='flex' alignItems='center'>
      <InputBase
        {...rest}
        sx={style.input(outlined, inputStyleProps)}
        startAdornment={
          <InputAdornment position='start'>
            <Typography variant='body2' color={color} sx={style.label}>
              {`${label}:`}
            </Typography>
          </InputAdornment>
        }
      />
    </Box>
  )
}

export default SelectFilterInput
