const style = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    p: '0px 16px 16px 16px',
  },
  button: {
    color: 'text.primary',
    fontWeight: 800,
  },
}

export default style
