const style = {
  declineContentWrapper: {
    mb: '62px',
    mt: '86px',
  },

  undoBtn: {
    color: '#265A88',
    borderColor: '#265A88',
    minWidth: '148px',
  },
}

export default style
