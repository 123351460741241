import {
  Divider,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material'
import { omit } from 'lodash'
import React, { FC } from 'react'

import { Editor } from '../../../../components/Editor/Editor'

import PreviewFormControl from '../PreviewFormControl'
import EmailResult from './EmailResult'
import EmailSettings from './EmailSettings'

import {
  SELECT_EMAIL_PLACEHOLDER,
  SEND_EMAIL,
  variables,
} from '../../../../constants'

type EmailPreviewFormProps = {
  subject: string
  description: string
  emailFrom: string
  onSubjectChange: (value: string) => void
  onDescriptionChange: (value: string) => void
  onEmailFromChange: (value: string) => void
}

const EmailPreviewForm: FC<EmailPreviewFormProps> = ({
  subject,
  description,
  emailFrom,
  onSubjectChange,
  onDescriptionChange,
  onEmailFromChange,
}) => {
  const handleEmailSelect = (e: SelectChangeEvent) => {
    onEmailFromChange(e.target.value)
  }
  return (
    <Grid container spacing='48px'>
      <Grid item xs={5.5}>
        <Stack gap={1} py={3}>
          <PreviewFormControl label='Email Subject' required>
            <Editor
              value={subject}
              variant='tag'
              placeholder='Please, enter email subject'
              onChange={onSubjectChange}
              variables={omit(variables, ['memberCallTime'])}
            />
          </PreviewFormControl>
          <PreviewFormControl label='Email description' required>
            <Editor
              value={description}
              variant='tag'
              placeholder='Please, enter email description'
              onChange={onDescriptionChange}
              variables={variables}
            />
          </PreviewFormControl>
          <Divider />
          <PreviewFormControl label='Email settings'>
            <EmailSettings />
          </PreviewFormControl>
        </Stack>
      </Grid>
      <Grid item xs={6.5}>
        <Stack gap={1} py={3}>
          <PreviewFormControl label='Email from' required>
            <Select
              id='sms-from-select'
              placeholder='Select email'
              value={emailFrom || SELECT_EMAIL_PLACEHOLDER}
              onChange={handleEmailSelect}
            >
              <MenuItem value={SELECT_EMAIL_PLACEHOLDER} disabled>
                {SELECT_EMAIL_PLACEHOLDER}
              </MenuItem>
              <MenuItem value={SEND_EMAIL}>{SEND_EMAIL}</MenuItem>
            </Select>
          </PreviewFormControl>
          <EmailResult subject={subject} content={description} />
        </Stack>
      </Grid>
    </Grid>
  )
}

export default EmailPreviewForm
