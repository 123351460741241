import { PANELS } from 'src/constants'
import { SampleError } from '../../api/types'
import { Attachment } from '../Attachments/types'
import {
  ADD_CONTACT_REQUEST,
  ADD_CONTACT_REQUEST_ERROR,
  ADD_CONTACT_REQUEST_SUCCESS,
  GET_CONTACTS_REQUEST,
  GET_CONTACTS_REQUEST_ERROR,
  GET_CONTACTS_REQUEST_SUCCESS,
  UPDATE_CONTACT_REQUEST,
  UPDATE_CONTACT_REQUEST_SUCCESS,
  UPDATE_CONTACT_REQUEST_ERROR,
  GET_CONTACTS_TAGS_REQUEST,
  GET_CONTACTS_TAGS_REQUEST_ERROR,
  GET_CONTACTS_TAGS_REQUEST_SUCCESS,
  SET_CONTACTS,
  UPDATE_CONTACT_IMAGE_REQUEST,
  GET_CONTACT_IMAGE_REQUEST,
  SET_CONTACT_IMAGE,
  DELETE_CONTACT_REQUEST,
  DELETE_CONTACT_REQUEST_SUCCESS,
  DELETE_CONTACT_REQUEST_ERROR,
  SET_EDIT_CONTACT,
  GET_CONTACTS_BY_NAME_EMAIL_REQUEST,
  GET_CONTACTS_BY_NAME_EMAIL_REQUEST_SUCCESS,
  SET_SELECTED_CONTACT,
  GET_CONTACTS_BY_NAME_EMAIL_REQUEST_ERROR,
} from './actionTypes'
import {
  Contact,
  ContactTag,
  SetContacts,
  GetContactsRequestType,
  GetContactsRequestSuccessType,
  GetContactsRequestErrorType,
  AddContactRequestType,
  AddContactRequestErrorType,
  AddContactRequestSuccessType,
  UpdateContactRequestType,
  UpdateContactRequestSuccessType,
  UpdateContactRequestErrorType,
  GetContactsTagsRequestType,
  GetContactsTagsRequestSuccessType,
  GetContactsTagsRequestErrorType,
  UpdateContactImageRequestType,
  GetContactImageRequestType,
  DeleteContactRequestType,
  DeleteContactRequestSuccessType,
  DeleteContactRequestErrorType,
  SetEditContact,
  GetContactsByNameEmailRequestType,
  GetContactsByNameEmailRequestSuccessType,
  SetSelectedContact,
  GetContactsByNameEmailRequestErrorType,
} from './types'

export const getContactsRequest = (): GetContactsRequestType => ({
  type: GET_CONTACTS_REQUEST,
})

export const deleteContactRequest = (
  contactId: string
): DeleteContactRequestType => ({
  type: DELETE_CONTACT_REQUEST,
  payload: {
    contactId,
  },
})

export const deleteContactRequestSuccess =
  (): DeleteContactRequestSuccessType => ({
    type: DELETE_CONTACT_REQUEST_SUCCESS,
  })

export const deleteContactRequestError = (
  error: SampleError | null
): DeleteContactRequestErrorType => ({
  type: DELETE_CONTACT_REQUEST_ERROR,
  error,
})

export const updateContactImageRequest = (
  image: Attachment,
  contactId: string
): UpdateContactImageRequestType => ({
  type: UPDATE_CONTACT_IMAGE_REQUEST,
  payload: {
    image,
    contactId,
  },
})

export const getContactImageRequest = (
  contactId: string
): GetContactImageRequestType => ({
  type: GET_CONTACT_IMAGE_REQUEST,
  payload: {
    contactId,
  },
})

export const getContactsRequestSuccess = (
  contacts: Contact[]
): GetContactsRequestSuccessType => ({
  type: GET_CONTACTS_REQUEST_SUCCESS,
  contacts,
})

export const getContactsRequestError = (
  error: SampleError
): GetContactsRequestErrorType => ({
  type: GET_CONTACTS_REQUEST_ERROR,
  error,
})

export const addContactRequest = (
  contact: Omit<Contact, 'id'>
): AddContactRequestType => ({
  type: ADD_CONTACT_REQUEST,
  payload: contact,
})

export const addContactRequestSuccess = (
  id?: string
): AddContactRequestSuccessType => ({
  type: ADD_CONTACT_REQUEST_SUCCESS,
  id,
})

export const addContactRequestError = (): AddContactRequestErrorType => ({
  type: ADD_CONTACT_REQUEST_ERROR,
})

export const updateContactRequest = (
  contact: Contact,
  panel: PANELS
): UpdateContactRequestType => ({
  type: UPDATE_CONTACT_REQUEST,
  payload: { contact, panel },
})

export const updateContactRequestSuccess = (
  id: string
): UpdateContactRequestSuccessType => ({
  type: UPDATE_CONTACT_REQUEST_SUCCESS,
  id,
})

export const updateContactRequestError = (): UpdateContactRequestErrorType => ({
  type: UPDATE_CONTACT_REQUEST_ERROR,
})

export const getContactsTagsRequest = (): GetContactsTagsRequestType => ({
  type: GET_CONTACTS_TAGS_REQUEST,
})

export const getContactsTagsRequestSuccess = (
  tags: ContactTag[]
): GetContactsTagsRequestSuccessType => ({
  type: GET_CONTACTS_TAGS_REQUEST_SUCCESS,
  tags,
})

export const getContactsTagsRequestError = (
  error: SampleError
): GetContactsTagsRequestErrorType => ({
  type: GET_CONTACTS_TAGS_REQUEST_ERROR,
  error,
})

export const setContacts = (contacts: Contact[]): SetContacts => ({
  type: SET_CONTACTS,
  contacts,
})

export const setEditContact = (editContact?: Contact): SetEditContact => ({
  type: SET_EDIT_CONTACT,
  editContact,
})

export const setSelectedContact = (
  selectedContactId: string
): SetSelectedContact => ({
  type: SET_SELECTED_CONTACT,
  selectedContactId,
})

export const getContactsByNameEmailRequest = (
  value: string
): GetContactsByNameEmailRequestType => ({
  type: GET_CONTACTS_BY_NAME_EMAIL_REQUEST,
  payload: {
    value,
  },
})

export const getContactsByNameEmailRequestSuccess = (
  contacts: Contact[]
): GetContactsByNameEmailRequestSuccessType => ({
  type: GET_CONTACTS_BY_NAME_EMAIL_REQUEST_SUCCESS,
  contacts,
})

export const getContactsByNameEmailRequestError = (
  error: SampleError | null
): GetContactsByNameEmailRequestErrorType => ({
  type: GET_CONTACTS_BY_NAME_EMAIL_REQUEST_ERROR,
  error,
})
