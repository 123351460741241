import React, { ReactElement } from 'react'
import {
  CheckCircleGreenIcon,
  CrossIcon,
  HelpCircleOutlineIcon,
} from 'src/assets/icons'
import { CallsheetStatuses } from '../../../store/Callsheet/types'

export type ActionBtn = { txt: string; icon?: ReactElement }

export const btnText: { [key: string]: ActionBtn } = {
  [CallsheetStatuses.ACCEPTED]: { txt: 'Accepted' },
  [CallsheetStatuses.DECLINED]: { txt: 'Declined', icon: <CrossIcon /> },
  [CallsheetStatuses.TENTATIVE]: {
    txt: 'Tentative',
    icon: <HelpCircleOutlineIcon />,
  },
  [CallsheetStatuses.PENDING]: { txt: 'Accept Callsheet' },
}

export type ButtonStatuses =
  | CallsheetStatuses.ACCEPTED
  | CallsheetStatuses.DECLINED
  | CallsheetStatuses.TENTATIVE

export const menuItems: {
  status: ButtonStatuses
  txt: string
  icon: ReactElement
}[] = [
  {
    status: CallsheetStatuses.ACCEPTED,
    txt: 'Accept',
    icon: <CheckCircleGreenIcon />,
  },
  { status: CallsheetStatuses.DECLINED, txt: 'Decline', icon: <CrossIcon /> },
  {
    status: CallsheetStatuses.TENTATIVE,
    txt: 'Tentative',
    icon: <HelpCircleOutlineIcon />,
  },
]
