import { User } from '../../api/user/types'
import {
  GET_USER,
  GET_USER_BY_ID,
  SAVE_USER,
  USER_REQUEST,
  USER_REQUEST_SUCCESS,
  USER_REQUEST_ERROR,
} from './actionTypes'
import {
  GetUserAction,
  GetUserByIdAction,
  SaveUserAction,
  UserRequestAction,
  UserRequestErrorAction,
  UserRequestSuccessAction,
} from './types'

export const getUser = (): GetUserAction => ({
  type: GET_USER,
})

export const getUserById = (id: string): GetUserByIdAction => ({
  type: GET_USER_BY_ID,
  payload: {
    id,
  },
})

export const saveUser = (user: User): SaveUserAction => ({
  type: SAVE_USER,
  payload: user,
})

export const userRequest = (): UserRequestAction => ({
  type: USER_REQUEST,
})

export const userRequestError = (): UserRequestErrorAction => ({
  type: USER_REQUEST_ERROR,
})

export const userRequestSuccess = (): UserRequestSuccessAction => ({
  type: USER_REQUEST_SUCCESS,
})
