import { AppState } from '../types'

export const selectHospital = (state: AppState) => state.logisticsState.hospital
export const selectIsHospital = (state: AppState) =>
  state.logisticsState.isHospital
export const selectIsWeather = (state: AppState) =>
  state.logisticsState.isWeather
export const selectLocations = (state: AppState) =>
  state.logisticsState.locations
export const selectRequestStatus = (state: AppState) =>
  state.logisticsState.requestStatus
export const selectDeleteRequestStatus = (state: AppState) =>
  state.logisticsState.deleteRequestStatus
