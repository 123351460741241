import {
  PREFILL_COMPANY,
  SET_COMPANY_ID,
  SET_COMPANY_NAME,
  SET_COMPANY_LOGO,
  SET_COMPANY_ADDRESS,
  SET_COMPANY_WEBSITE,
  SET_ADDRESS_VISIBLE,
  SET_WEBSITE_VISIBLE,
  SET_SAVE_IN_CATALOG,
  SET_MEMBERS,
  SET_COMPANY_NOTES,
} from './actionTypes'
import { CompanyFormState, ActionTypes } from './types'

export const CompanyInitialState: CompanyFormState = {
  id: undefined,
  name: '',
  logo: '',
  website: '',
  address: '',
  websiteVisible: false,
  addressVisible: false,
  saveInCatalog: true,
  notes: '',
  primaryMembers: [],
}

export const companyFormReducer = (
  state: CompanyFormState,
  action: ActionTypes
): CompanyFormState => {
  switch (action.type) {
    case PREFILL_COMPANY:
      return { ...action.company }
    case SET_COMPANY_ID:
      return { ...state, id: action.id }
    case SET_COMPANY_NOTES:
      return { ...state, notes: action.notes }
    case SET_COMPANY_NAME:
      return { ...state, name: action.name }
    case SET_COMPANY_LOGO:
      return { ...state, logo: action.logo }
    case SET_COMPANY_ADDRESS:
      return { ...state, address: action.address }
    case SET_COMPANY_WEBSITE:
      return { ...state, website: action.website }
    case SET_ADDRESS_VISIBLE:
      return { ...state, addressVisible: action.addressVisible }
    case SET_WEBSITE_VISIBLE:
      return { ...state, websiteVisible: action.websiteVisible }
    case SET_SAVE_IN_CATALOG:
      return { ...state, saveInCatalog: action.saveInCatalog }
    case SET_MEMBERS:
      return { ...state, primaryMembers: action.members }
    default:
      return state
  }
}
