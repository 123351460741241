import { noop } from 'lodash'
import React, { FC, PropsWithChildren } from 'react'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
} from '@mui/material'

import { CrossCircleIcon } from '../../../../assets/icons'

import style from './style'

type PreviewWrapperProps = {
  disabled?: boolean
  title: string
  confirmLabel: string
  open: boolean
  onClose: typeof noop
  onSubmit: typeof noop
}

const PreviewWrapper: FC<PreviewWrapperProps & PropsWithChildren> = ({
  open,
  onClose,
  title,
  confirmLabel,
  onSubmit,
  children,
  disabled = false,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth sx={style.wrapper}>
      <DialogTitle sx={style.title}>
        {title}
        <IconButton onClick={onClose} sx={style.closeBtn}>
          <CrossCircleIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 3 }}>
        <Box display='flex' gap='12px' minWidth='348px'>
          <Button
            variant='outlined'
            onClick={onClose}
            sx={{ color: '#265A88', borderColor: '#265A88', minWidth: '148px' }}
          >
            Close
          </Button>
          <LoadingButton
            disabled={disabled}
            variant='contained'
            onClick={onSubmit}
            fullWidth
          >
            {confirmLabel}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default PreviewWrapper
