import { COLOR_TEXT_PRIMARY } from '../../themes/constants'

const style = {
  container: {
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: 400,
    cursor: 'initial',
    button: {
      border: 0,
      cursor: 'pointer',
      backgroundColor: 'transparent',
    },
    input: {
      border: 0,
      padding: '0px',
      color: 'text.primary',
      fontFamily: 'Avenir',
      fontSize: '16px',
      fontWeight: 400,
      borderBottom: `1px solid ${COLOR_TEXT_PRIMARY}`,
      '&:focus': {
        outline: 0,
      },
    },
    '> div': {
      display: 'flex',
      alignItems: 'center',
      '> div': {
        marginRight: '8px',
      },
    },
  },
}

export default style
