import moment from 'moment'

export const getDuration = (beginTime: string, endTime: string): string => {
  const beginMoment = moment(`2023-03-24 ${beginTime}`, 'YYYY-MM-DD h:mma')
  const endMoment = moment(`2023-03-24 ${endTime}`, 'YYYY-MM-DD h:mma')

  const duration = moment.duration(endMoment.diff(beginMoment))
  const hours = duration.hours()
  const minutes = duration.minutes()

  if (hours > 0 && minutes > 0) {
    return `${hours}h ${minutes} min`
  }
  if (hours && !minutes) {
    return `${hours}h`
  }

  return `${minutes} min`
}
