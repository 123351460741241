import { RequestStatuses } from '../../api/constants'
import { SignInStateType, SignInActions } from './types'
import {
  CHANGE_SIGN_IN_EMAIL,
  CHANGE_SIGN_IN_NAME,
  CHANGE_SIGN_IN_LAST_NAME,
  CHANGE_SIGN_IN_PASSWORD,
  SIGN_IN_REQUEST,
  SIGN_IN_REQUEST_SUCCESS,
  SIGN_IN_REQUEST_ERROR,
  RETURN_TO_INITIAL_SIGN_IN_REQUEST_STATE,
} from './actionTypes'

const initialState: SignInStateType = {
  signInEmail: '',
  // TODO temporary fields name and last name
  signInName: '',
  signInLastName: '',
  signInPassword: '',
  requestStatus: RequestStatuses.UNCALLED,
  fetchingError: null,
}

const signInReducer = (
  state = initialState,
  action: SignInActions
): SignInStateType => {
  switch (action.type) {
    case CHANGE_SIGN_IN_EMAIL:
      return { ...state, signInEmail: action.value }
    case CHANGE_SIGN_IN_NAME:
      return { ...state, signInName: action.value }
    case CHANGE_SIGN_IN_LAST_NAME:
      return { ...state, signInLastName: action.value }
    case CHANGE_SIGN_IN_PASSWORD:
      return { ...state, signInPassword: action.value }
    case SIGN_IN_REQUEST:
      return {
        ...state,
        requestStatus: RequestStatuses.PENDING,
        fetchingError: null,
      }
    case SIGN_IN_REQUEST_SUCCESS:
      return { ...state, requestStatus: RequestStatuses.SUCCEEDED }
    case SIGN_IN_REQUEST_ERROR:
      return {
        ...state,
        requestStatus: RequestStatuses.FAILED,
        fetchingError: action.error,
      }
    case RETURN_TO_INITIAL_SIGN_IN_REQUEST_STATE:
      return {
        ...state,
        requestStatus: RequestStatuses.UNCALLED,
        fetchingError: null,
      }
    default:
      return state
  }
}

export default signInReducer
