const style = {
  wrapper: {
    m: 'auto',
    py: 3,
    maxWidth: '186px',
    boxShadow: '0px 0px 24px 0px #85858526',
    borderRadius: '10px',
    border: '4px solid #85858526',
    alignItems: 'center',
  },
}

export default style
