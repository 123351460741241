import { FormControl, InputLabel, Typography } from '@mui/material'
import React, { FC, PropsWithChildren } from 'react'

type PreviewFormControlProps = {
  label: string
  required?: boolean
  helperText?: string
}

const PreviewFormControl: FC<PreviewFormControlProps & PropsWithChildren> = ({
  label,
  children,
  required = false,
  helperText,
}) => {
  return (
    <>
      <InputLabel required={required} sx={{ m: '0px' }}>
        {label}
      </InputLabel>
      <FormControl fullWidth size='small'>
        {children}
      </FormControl>
      {helperText && (
        <Typography variant='body1' color='text.secondary' maxWidth='421px'>
          {helperText}
        </Typography>
      )}
    </>
  )
}

export default PreviewFormControl
