const style = {
  wrapper: (isTyping: boolean) => ({
    position: 'relative',
    p: '14px 16px',
    transition: 'all 0.250s ease',
    paddingTop: isTyping ? '36px' : '14px',
    display: 'flex',
    flexDirection: 'column',
    width: '458px',

    '& .MuiInputBase-root ::placeholder': {
      opacity: 1,
    },

    '> .MuiInputBase-root': {
      p: '0px',
      fontSize: '14px',
      lineHeight: '20px',
      color: 'text.primary',
      '> input': {
        p: '0px',
      },
    },
  }),
  label: {
    color: 'text.primary',
    lineHeight: '20px',
    textAlign: 'left',
    mb: 0,
  },
  title: {
    flex: 1,
    p: 0,
    top: '8px',
    left: '16px',
    position: 'absolute',

    '> input': {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 900,
      textTransform: 'uppercase',
      color: 'text.secondary',
    },
  },
  titleWrapper: {
    top: '14px',
    right: '16px',
    position: 'absolute',
  },
  titleBtn: (isTitle: boolean) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 0,
    cursor: 'pointer',
    color: isTitle ? 'red' : 'primary.main',
  }),
}

export default style
