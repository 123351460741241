import React, { FC } from 'react'
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import SelectInput from './SelectInput'

import { CaretBottomIcon } from '../../assets/icons'

type CustomSelectProps = {
  placeHolder: string
  values: string[]
  onSelect: (value: string) => void
  defaultValue: string
  outlined?: boolean
}

const CustomSelect: FC<CustomSelectProps> = ({
  placeHolder,
  values,
  onSelect,
  defaultValue,
  outlined = false,
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    onSelect(event.target.value)
  }
  return (
    <Box
      sx={{
        border: outlined ? '1px solid #E2E2E2' : 'none',
        px: '8px',
        py: '2px',
      }}
    >
      <FormControl>
        <Select
          id='custom-select'
          value={defaultValue}
          fullWidth
          displayEmpty
          input={<SelectInput />}
          variant='standard'
          onChange={handleChange}
          MenuProps={{ disableScrollLock: true }}
          IconComponent={props => (
            <Box
              sx={{
                '> svg': {
                  path: {
                    stroke: outlined ? '#AFAFAF' : '#545454',
                  },
                },
              }}
            >
              <CaretBottomIcon {...props} />
            </Box>
          )}
        >
          <MenuItem value={placeHolder}>{placeHolder}</MenuItem>
          {values.map((item: string, idx: number) => (
            <MenuItem value={item} key={idx}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default CustomSelect
