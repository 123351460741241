import moment from 'moment'
import React, { FC, useState } from 'react'
import { noop } from 'lodash'

import { DatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TextField, TextFieldProps } from '@mui/material'
import CustomActionBar from './CustonActionBar/CustomActionBar'

import style from './style'

type DatePickerTypes = {
  onChange: typeof noop
  value: DateConstructor | null
  placeholder?: string
  format?: string
  width?: string
  defaultWidth?: string
  placeholderColor?: string
  placeholderProps?: { [key: string]: string | number }
}

const DatePickerInput: FC<DatePickerTypes> = ({
  onChange,
  value,
  placeholder = 'Add Date',
  format = 'dddd, MMM DD, yyyy',
  defaultWidth = '92px',
  width = '200px',
  placeholderProps = {},
}) => {
  const [open, setOpen] = useState(false)

  const handleDateChange = (e: Date | null) => {
    onChange(
      new Date(Date.UTC(moment(e).year(), moment(e).month(), moment(e).date()))
    )
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={value}
        inputFormat={format}
        onChange={handleDateChange}
        OpenPickerButtonProps={{ sx: { display: 'none' } }}
        disablePast
        components={{ ActionBar: CustomActionBar }}
        componentsProps={{
          actionBar: { actions: ['today'] },
        }}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
          <TextField
            {...{
              ...params,
              inputProps: {
                ...params.inputProps,
                placeholder,
              },
            }}
            onKeyDown={e => e.preventDefault()}
            onClick={() => setOpen(true)}
            size='small'
            InputProps={{
              sx: {
                ...style.input(placeholderProps),
                width: value ? width : defaultWidth,
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  )
}

export default DatePickerInput
