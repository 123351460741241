import { RequestStatus } from 'src/api/types'
import { CallsheetStatusType, CallsheetViewsType } from '../Callsheet/types'
import {
  SET_CALLSHEETS_FILTER,
  SET_CALLSHEETS_SORT_TAG,
  SET_CALLSHEETS_VIEW,
  SET_CALLSHEET_STATISTIC_ID,
  SET_CALLSHEET_STATISTIC_MODAL,
} from './actionTypes'

export enum SortTags {
  DATE_ASCENDING = 'Date Ascending',
  DATE_DESCENDING = 'Date Decending',
  STATUS = 'Status',
  NAME_A_Z = 'Name (A-Z)',
  NAME_Z_A = 'Name (Z-A)',
}

export interface GalleryState {
  view: CallsheetViewsType
  callsheetToDeleteId?: string
  callsheetStatisticId?: string
  callsheetStatisticModal: boolean
  deleteCallsheetModal: boolean
  isPermanentDelete: boolean
  filterTags: CallsheetStatusType[]
  deleteCallsheetRequestStatus: RequestStatus
  sortTag?: SortTags
}

export interface SetCallsheetsView {
  type: typeof SET_CALLSHEETS_VIEW
  view: CallsheetViewsType
}

export interface SetCallsheetStatisticId {
  type: typeof SET_CALLSHEET_STATISTIC_ID
  callsheetStatisticId: string
}

export interface SetCallsheetStatisticModal {
  type: typeof SET_CALLSHEET_STATISTIC_MODAL
}

export interface SetCallsheetsFilter {
  type: typeof SET_CALLSHEETS_FILTER
  filterTags: CallsheetStatusType[]
}

export interface SetCallsheetsSortTag {
  type: typeof SET_CALLSHEETS_SORT_TAG
  sortTag?: SortTags
}

export type GalleryActions =
  | SetCallsheetsView
  | SetCallsheetsFilter
  | SetCallsheetsSortTag
  | SetCallsheetStatisticId
  | SetCallsheetStatisticModal
