import { Stack, Typography } from '@mui/material'
import React, { FC } from 'react'

type HeaderSectionTypes = {
  title: string
  subtitle: string
}

const HeaderSection: FC<HeaderSectionTypes> = ({ title, subtitle }) => {
  return (
    <Stack alignItems='center' textAlign='center'>
      <Typography
        variant='h2'
        component='h2'
        color='text.primary'
        lineHeight='43.7px'
      >
        {title}
      </Typography>
      <Typography
        variant='subtitle1'
        color='text.secondary'
        maxWidth='421px'
        sx={{ mt: 0 }}
      >
        {subtitle}
      </Typography>
    </Stack>
  )
}

export default HeaderSection
