import produce from 'immer'
import { RequestStatuses } from 'src/api/constants'
import { CallsheetViews } from '../Callsheet/types'
import {
  SET_CALLSHEETS_FILTER,
  SET_CALLSHEETS_SORT_TAG,
  SET_CALLSHEETS_VIEW,
  SET_CALLSHEET_STATISTIC_MODAL,
  SET_CALLSHEET_STATISTIC_ID,
} from './actionTypes'

import { GalleryActions, GalleryState } from './types'

const galleryInitialState: GalleryState = {
  view: CallsheetViews.GALLERY,
  isPermanentDelete: false,
  deleteCallsheetModal: false,
  callsheetStatisticModal: false,
  deleteCallsheetRequestStatus: RequestStatuses.UNCALLED,
  filterTags: [],
}

const galleryReducer = produce(
  (draft = galleryInitialState, action: GalleryActions) => {
    switch (action.type) {
      case SET_CALLSHEETS_VIEW:
        draft.view = action.view
        break
      case SET_CALLSHEET_STATISTIC_ID:
        draft.callsheetStatisticId = action.callsheetStatisticId
        draft.callsheetStatisticModal = true
        break
      case SET_CALLSHEET_STATISTIC_MODAL:
        draft.callsheetStatisticModal = !draft.callsheetStatisticModal
        break
      case SET_CALLSHEETS_FILTER:
        draft.filterTags = action.filterTags
        break
      case SET_CALLSHEETS_SORT_TAG:
        draft.sortTag = action.sortTag
        break

      default:
        return draft
    }
  }
)

export default galleryReducer
