const style = {
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  statisticsItem: {},
  title: {
    fontSize: '12px',
    fontWeight: 900,
    color: 'text.disabled',
    lineHeight: '20px',
    mb: '4px',
  },
  numBox: {
    fontWeight: 900,
    fontSize: '24px',
    lineHeight: '32px',
  },
  days: {
    color: '#FB8333',
  },
  callTime: {
    color: '#1E486C',
  },
}

export default style
