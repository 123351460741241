const style = {
  title: {
    fontSize: '28px',
    lineHeight: '36px',
    fontWeight: 500,
    color: 'text.primary',
  },
  filter_wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  btn_wrapper: {
    mt: 4,
    minWidth: '280px',
    alignSelf: 'flex-end',
  },
  edit_wrapper: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: 0,
  },
}

export default style
