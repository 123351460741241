export const extractInstagramNickname = (link: string) => {
  const rx = /^(?:@|(?:https?:\/\/)?(?:www\.)?instagram\.com\/)?([^/?]+)/

  const match = rx.exec(link)
  return match && match[1]
}

export const extractUnsplashNickname = (link: string) => {
  const rx = /^(?:@|(?:https?:\/\/)?(?:www\.)?unsplash.com\/)?(@\w+)\/?$/

  const match = rx.exec(link)
  return match && match[1]
}
