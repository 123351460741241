import { DeleteCallsheetFlow, DeleteCallsheetFlowType } from './constants'

const style = {
  actionBox: {
    border: '1px solid #E2E2E2',
    borderRadius: '16px',
    boxShadow: '0px 5px 8px 0px #8585851A',
    display: 'flex',
    p: 1,
    gap: '27px',

    svg: {
      mt: 0,
    },
  },
  title: {
    position: 'relative',
    padding: '16px 18px',
    backgroundColor: '#F6F6F6',
    '& .MuiDialogTitle-root': {
      padding: '0px',
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 500,
    },
  },
  subtitle: {
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 900,
    color: 'text.primary',
  },
  subtitle2: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
    color: 'text.primary',
  },
  closeBtn: {
    position: 'absolute',
    top: '15px',
    right: '15px',
  },
  label: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: 'text.primary',
  },
  cancelBtn: { color: '#265A88', borderColor: '#265A88', minWidth: '148px' },
  confirmBtn: (option: DeleteCallsheetFlowType | undefined) => ({
    ml: '10px',
    textTransform: 'capitalize',
    backgroundColor:
      option === DeleteCallsheetFlow.DELETE_CALLSHEET
        ? 'error.main'
        : 'primary.main',
    '&:active': {
      backgroundColor:
        option === DeleteCallsheetFlow.DELETE_CALLSHEET
          ? 'error.main'
          : 'primary.main',
    },
    '&:hover': {
      backgroundColor:
        option === DeleteCallsheetFlow.DELETE_CALLSHEET
          ? 'error.main'
          : 'primary.main',
    },

    '&.Mui-disabled': {
      backgroundColor:
        option === DeleteCallsheetFlow.DELETE_CALLSHEET ? '#FF3B30' : '#AFAFAF',
      color: 'white',
      ...(option === DeleteCallsheetFlow.DELETE_CALLSHEET && { opacity: 0.65 }),
    },
  }),
}

export default style
