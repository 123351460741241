import { AxiosPromise } from 'axios'
import { GroupMember } from '../../store/GroupMembers/types'
import { apiClient } from '../clients'
import { METHODS, URLS } from '../constants'

export const addMemberApi = (
  userGroupId: string,
  contactId: string,
  hidePersonalInfo: boolean,
  callTime?: string,
  wrapTime?: string
): AxiosPromise =>
  apiClient.request({
    method: METHODS.POST,
    url: `${URLS.CALLSHEET}/member`,
    data: { userGroupId, contactId, callTime, wrapTime, hidePersonalInfo },
  })

export const updateMemberApi = (
  memberId: string,
  member: Omit<GroupMember, 'id'>
): AxiosPromise =>
  apiClient.request({
    method: METHODS.PATCH,
    url: `${URLS.CALLSHEET}/member/${memberId}`,
    data: member,
  })

export const deleteMemberApi = (id: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.DELETE,
    url: `${URLS.CALLSHEET}/member/${id}`,
  })

export const getMembersApi = (callsheetId: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.GET,
    url: `${URLS.CALLSHEET}/${callsheetId}/member`,
  })

export const updateMemberImageApi = (memberId: string) =>
  apiClient.request({
    method: METHODS.PATCH,
    url: `${URLS.CALLSHEET}/member/${memberId}/image`,
  })

export const deleteMemberImageApi = (memberId: string) =>
  apiClient.request({
    method: METHODS.DELETE,
    url: `${URLS.CALLSHEET}/member/${memberId}/image`,
  })
