import { Box, Button, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import React, { FC, useState } from 'react'
import { CallsheetViews } from 'src/store/Callsheet/types'
import { selectCallsheetsView } from '../../store/Gallery/selectors'
import { setCallsheetsView } from '../../store/Gallery/actions'
import { clearStore } from '../../store/rootActions'
import { getUserById } from '../../store/User/actions'
import { getTokensFromStorage } from '../../utils/tokens'

import CallsheetFilter from './CallsheetFilter'
import ListViewSelector from './ListViewSelector'
import CallsheetSort from './CallsheetSort'

import { PlusWhiteIcon } from '../../assets/icons'
import { PATHS } from '../../constants'

import style from './style'

const viewMap: { [key: string]: string } = {
  [CallsheetViews.EXPANDED_LIST]: 'Expanded',
  [CallsheetViews.CONDENSED_LIST]: 'Condensed',
}

const CallsheetsFilterSection: FC = () => {
  const dispatch = useDispatch()
  const view = useSelector(selectCallsheetsView)

  const [selectedSorting, setSorting] = useState<string[]>(['Date Ascending'])
  const history = useHistory()

  const handleCreateCallsheet = () => {
    dispatch(clearStore())
    const { token } = getTokensFromStorage()
    if (token) {
      dispatch(getUserById(token))
    }

    history.push(PATHS.PRIVATE.BUILDER)
  }

  return (
    <Box sx={style.wrapper}>
      <Box sx={style.viewToggle}>
        <Typography
          sx={style.toggleLabel(view === CallsheetViews.GALLERY)}
          onClick={() => dispatch(setCallsheetsView(CallsheetViews.GALLERY))}
        >
          Gallery View
        </Typography>
        {view === CallsheetViews.GALLERY ? (
          <Typography
            sx={style.toggleLabel(view !== CallsheetViews.GALLERY)}
            onClick={() =>
              dispatch(setCallsheetsView(CallsheetViews.CONDENSED_LIST))
            }
          >
            List View
          </Typography>
        ) : (
          <Box sx={style.toggleLabel(true)}>
            <ListViewSelector selectedTags={[viewMap[view]]} />
          </Box>
        )}
      </Box>
      <Box display='flex' alignItems='center' gap={1}>
        <CallsheetFilter label='Filter' />
        <CallsheetSort
          selectedTags={selectedSorting}
          setSelectedTags={setSorting}
        />
        <Button
          color='info'
          variant='contained'
          sx={style.builderBtn}
          onClick={handleCreateCallsheet}
        >
          <Box sx={style.builderBtnLarge}>
            <PlusWhiteIcon />
          </Box>
          <Typography variant='subtitle2' sx={style.builderBtnText}>
            Create Callsheet
          </Typography>
        </Button>
      </Box>
    </Box>
  )
}
export default CallsheetsFilterSection
