import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { setCallsheetId } from '../../store/Callsheet/actions'
import {
  CallsheetStatuses,
  CallsheetStatusType,
} from '../../store/Callsheet/types'
import { clearStore } from '../../store/rootActions'
import { getUserById } from '../../store/User/actions'
import { getTokensFromStorage } from '../../utils/tokens'

const useCallsheetCard = (status: CallsheetStatusType, id: string) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const resetStore = () => {
    dispatch(clearStore())
    const { token } = getTokensFromStorage()
    if (token) {
      dispatch(getUserById(token))
    }
  }
  const handleCardClick = () => {
    if (status === CallsheetStatuses.DRAFT) {
      resetStore()
      dispatch(setCallsheetId(id))
      history.push('/builder')
    }

    if (
      status === CallsheetStatuses.COMPLETED ||
      status === CallsheetStatuses.ACCEPTED ||
      status === CallsheetStatuses.TENTATIVE ||
      status === CallsheetStatuses.PUBLISHED ||
      status === CallsheetStatuses.PENDING ||
      status === CallsheetStatuses.LIVE
    ) {
      resetStore()
      history.push(`/callsheet/${id}`)
    }
  }

  const isClickable = status !== CallsheetStatuses.DELETED

  return { handleCardClick, isClickable }
}

export default useCallsheetCard
