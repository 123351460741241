import { cloneDeep } from 'lodash'
import { useSelector } from 'react-redux'
import React, { FC, useMemo } from 'react'
import { Grid, Stack } from '@mui/material'

import useCallsheetsCompanies from '../../../hooks/callsheet/useCallsheetsCompanies'
import CallsheetCardExpanded from '../../../components/CallsheetCardExpanded'
import CreateCallsheetCard from '../../../components/CreateCallsheetCard'
import CallsheetCard from '../../../components/CallsheetCard'
import CallsheetsList from '../../../components/CallsheetsList'
import Loader from '../../../components/Loader'

import { selectCallsheetsRequestStatus } from '../../../store/Callsheet/selectors'
import {
  selectCallsheetsFilterTags,
  selectCallsheetsSortTag,
  selectCallsheetsView,
} from '../../../store/Gallery/selectors'
import {
  Callsheet,
  CallsheetStatuses,
  CallsheetViews,
} from '../../../store/Callsheet/types'
import { SortTags } from '../../../store/Gallery/types'

import { applySort } from '../../../utils/galleryFilters'
import { RequestStatuses } from '../../../api/constants'

import style from './style'

const Callsheets: FC<{
  callsheets: Callsheet[]
}> = ({ callsheets }) => {
  const requestStatus = useSelector(selectCallsheetsRequestStatus)
  const { callsheetsCompanies } = useCallsheetsCompanies(callsheets)
  const filterTags = useSelector(selectCallsheetsFilterTags)
  const sortTag = useSelector(selectCallsheetsSortTag)
  const view = useSelector(selectCallsheetsView)

  const filteredCallsheets = useMemo(() => {
    const sorted = cloneDeep(callsheets)

    if (filterTags) {
      applySort[SortTags.STATUS](sorted)
    }

    if (sortTag) {
      applySort[sortTag](sorted)
    }

    return filterTags.length
      ? sorted.filter(sheet => filterTags.includes(sheet.status))
      : sorted
  }, [filterTags, callsheets, sortTag])

  if (
    requestStatus === RequestStatuses.UNCALLED ||
    requestStatus === RequestStatuses.PENDING
  ) {
    return <Loader />
  }

  return (
    <>
      <Grid container sx={style.gallery}>
        {view === CallsheetViews.GALLERY && (
          <>
            <CreateCallsheetCard />
            {filteredCallsheets.map(
              ({
                id,
                title,
                eventDate,
                crewCallTime,
                status,
                ownerId,
                deletedAt,
              }) => (
                <CallsheetCard
                  ownerId={ownerId}
                  key={id}
                  id={id!}
                  title={title}
                  eventDate={eventDate}
                  crewCallTime={crewCallTime}
                  status={status}
                  deletedAt={deletedAt}
                />
              )
            )}
          </>
        )}
        {view === CallsheetViews.CONDENSED_LIST && (
          <CallsheetsList callsheets={filteredCallsheets} />
        )}
        {view === CallsheetViews.EXPANDED_LIST && (
          <Stack gap='12px' width='100%'>
            {filteredCallsheets.map(
              (
                {
                  title,
                  eventDate,
                  status,
                  id,
                  crewCallTime,
                  ownerId,
                  clientCompanyId,
                  productCompanyId,
                },
                idx: number
              ) => (
                <CallsheetCardExpanded
                  ownerId={ownerId}
                  key={idx}
                  id={id!}
                  title={title}
                  eventDate={eventDate}
                  crewCallTime={crewCallTime}
                  status={status as CallsheetStatuses}
                  clientCompany={callsheetsCompanies[clientCompanyId]}
                  productCompany={callsheetsCompanies[productCompanyId]}
                />
              )
            )}
          </Stack>
        )}
      </Grid>
    </>
  )
}

export default Callsheets
