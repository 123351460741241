import React, { FC, useEffect, useState } from 'react'
import { noop } from 'lodash'
import { Box, InputLabel } from '@mui/material'
import ImageUploading, { ImageListType } from 'react-images-uploading'

import { prepareImageValue, stripBase64Data } from '../../../../../utils/image'
import CustomTextButton from '../../../../../components/CustomTextButton'
import UploadFileButton from '../../../../../components/UploadFileButton'

import { EditIcon, TrashcanIcon } from '../../../../../assets/icons'

import style from './style'

type ImageUploadFieldTypes = {
  label: string
  onChange: typeof noop
  defaultValue?: string
}

const ImageUploadField: FC<ImageUploadFieldTypes> = ({
  label,
  onChange,
  defaultValue,
}) => {
  const [image, setImage] = useState(prepareImageValue(defaultValue))

  useEffect(() => {
    setImage(prepareImageValue(defaultValue))
  }, [defaultValue])

  const handleChange = (imageList: ImageListType) => {
    setImage(imageList as never[])
    onChange(imageList[0] && stripBase64Data(imageList[0].dataURL))
  }

  return (
    <ImageUploading value={image} onChange={handleChange}>
      {({
        imageList,
        onImageUpload,
        onImageUpdate,
        onImageRemove,
        dragProps,
      }) => (
        <Box sx={{ mt: 1 }}>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='flex-start'
          >
            <Box>
              <InputLabel>{label} logo</InputLabel>
            </Box>
            {image.length ? (
              <Box display='flex' alignItems='center'>
                <CustomTextButton
                  title='Update logo'
                  Icon={EditIcon}
                  onClick={() => onImageUpdate(0)}
                />
                <Box sx={{ mr: 3 }} />
                <CustomTextButton
                  title='Delete logo'
                  Icon={TrashcanIcon}
                  onClick={() => onImageRemove(0)}
                />
              </Box>
            ) : null}
          </Box>
          <Box
            className='upload__image-wrapper'
            sx={style.wrapper}
            {...dragProps}
          >
            {imageList.map(currentImage => (
              <div key={currentImage.dataURL} className='image-item'>
                <img
                  src={currentImage.dataURL}
                  style={{ maxHeight: '70px', maxWidth: '458px' }}
                  alt='company logo'
                />
              </div>
            ))}
            {!image.length && (
              <UploadFileButton
                onClick={onImageUpload}
                tip='Supports: JPEG, JPG, PNG'
              />
            )}
          </Box>
        </Box>
      )}
    </ImageUploading>
  )
}

export default ImageUploadField
