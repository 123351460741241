const style = {
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'space-between',
    height: '100%',
  },
  content: {
    width: '100%',
  },
  footer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'end',
    width: '100%',
    '> div': {
      width: '100%',
    },
  },
  labelStyles: {
    mt: 0,
    fontWeight: 400,
    fontSize: '14px',
  },
}

export default style
