import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCallsheetsCompanies } from 'src/store/Companies/selectors'
import { Callsheet } from '../../store/Callsheet/types'
import { Company } from '../../store/Companies/types'

const useCallsheetsCompanies = (callsheets: Callsheet[]) => {
  const companies: Company[] = useSelector(selectCallsheetsCompanies)

  const callsheetsCompanies = useMemo(() => {
    return companies.reduce((mappedObject, item) => {
      if (item.id) {
        mappedObject[item.id] = item
      }
      return mappedObject
    }, {} as { [k: string]: any })
  }, [callsheets, companies])

  return { callsheetsCompanies }
}

export default useCallsheetsCompanies
