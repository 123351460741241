const style = {
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    fontSize: '12px',
    fontWeight: 900,
    color: 'text.disabled',
    mb: '4px',
  },
  subtitle: {
    fontSize: '20px',
    fontWeight: 900,
    color: 'secondary.dark',
  },
}

export default style
