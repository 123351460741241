const PREFIX = 'USER/'

export const GET_USER = `${PREFIX}GET_USER` as const
export const GET_USER_BY_ID = `${PREFIX}GET_USER_BY_ID` as const
export const SAVE_USER = `${PREFIX}SAVE_USER` as const
export const USER_REQUEST = `${PREFIX}USER_REQUEST` as const
export const USER_REQUEST_ERROR = `${PREFIX}USER_REQUEST_ERROR` as const
export const USER_REQUEST_SUCCESS = `${PREFIX}USER_REQUEST_SUCCESS` as const
export const UPDATE_USER_REQUEST = `${PREFIX}UPDATE_USER_REQUEST` as const
export const CREATE_USER_REQUEST = `${PREFIX}UPDATE_USER_REQUEST` as const
