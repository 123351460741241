import React, { FC } from 'react'
import { noop } from 'lodash'
import { Box, Paper } from '@mui/material'

import { GridDisplayIcon, StackDisplayIcon } from '../../../../assets/icons'

import style from './style'

type ChangeDisplayBtnProps = { onToggle: typeof noop; isStack: boolean }

const ChangeDisplayBtn: FC<ChangeDisplayBtnProps> = ({ onToggle, isStack }) => {
  return (
    <Paper variant='outlined' sx={style.wrapper}>
      <Box sx={style.icons(isStack)}>
        <StackDisplayIcon onClick={onToggle} />
        <GridDisplayIcon onClick={onToggle} />
      </Box>
    </Paper>
  )
}

export default ChangeDisplayBtn
