import React, { FC } from 'react'
import { Box, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { RequestStatuses } from '../../../../../api/constants'
import Loader from '../../../../../components/Loader'
import {
  selectLocations,
  selectRequestStatus,
} from '../../../../../store/Logistics/selectors'
import { LocationItem } from '../../../../../store/Logistics/types'
import { getLocationName } from '../../../../../utils/location'
import { EMPTY_LOCATION_ITEM } from '../../AddLocationForm/constants'
import AddressCard from '../AddressCard'

type LocationsPreviewType = {
  isPreview?: boolean
}
const LocationsSection: FC<LocationsPreviewType> = ({ isPreview }) => {
  const locations = useSelector(selectLocations)
  const requestStatus = useSelector(selectRequestStatus)
  const locationsItems = locations.length
    ? locations
    : [EMPTY_LOCATION_ITEM, EMPTY_LOCATION_ITEM]

  return (
    <Box>
      {requestStatus === RequestStatuses.PENDING ? (
        <Loader />
      ) : (
        <Grid container spacing={2}>
          {locationsItems.map((item: LocationItem, i: number) => {
            const { title, name, city, state, zip, locationNotes, image } = item

            return (
              <Grid item xs={isPreview ? 3 : 6} key={i}>
                <AddressCard
                  title={title}
                  name={name}
                  address={getLocationName(city, state, zip)}
                  description={locationNotes}
                  image={image}
                  isPreview={isPreview}
                />
              </Grid>
            )
          })}
        </Grid>
      )}
    </Box>
  )
}

export default LocationsSection
