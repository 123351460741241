import { Box, Typography } from '@mui/material'
import React, { FC } from 'react'

import { getUTCMomentDate } from '../../../utils/getUTCDate'
import { CalendarIcon } from '../../../assets/icons'

import style from './style'

type HeaderProps = {
  title: string
  eventDate?: DateConstructor | null
  crewCallTime: string
}

const CallsheetCardHeader: FC<HeaderProps> = ({
  title,
  eventDate,
  crewCallTime,
}) => {
  return (
    <Box sx={style.header}>
      <Typography sx={style.title}>{title}</Typography>
      {eventDate ? (
        <Box sx={style.dateBox}>
          <CalendarIcon />
          <Typography sx={style.dateText}>
            {getUTCMomentDate(eventDate).format('MMMM, D')} at {crewCallTime}
          </Typography>
        </Box>
      ) : null}
    </Box>
  )
}

export default CallsheetCardHeader
