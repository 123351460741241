import React, { FC, PropsWithChildren, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Button,
  CssBaseline,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import {
  ArrowLineLeftIcon,
  ArrowLineRightIcon,
  BellIcon,
  CallsheetsLogo,
  PlusWhiteIcon,
} from '../../assets/icons'
import { PATHS } from '../../constants'
import { setCallsheet } from '../../store/Callsheet/actions'
import { INITIAL_CALLSHEET } from '../../store/Callsheet/reducer'
import { clearStore } from '../../store/rootActions'
import { getUserById } from '../../store/User/actions'
import { getTokensFromStorage } from '../../utils/tokens'
import { SIDE_MENU_ITEMS } from './constants'
import { Drawer, style } from './style'

const SideMenu: FC<PropsWithChildren> = ({ children }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(true)

  const handleCollapseMenu = () => {
    setOpen(!open)
  }

  const handleCrateCallsheetClick = (link: string) => () => {
    dispatch(clearStore())
    const { token } = getTokensFromStorage()
    if (token) {
      dispatch(getUserById(token))
    }
    history.push(link)
  }

  const handleLinkClick = (link: string) => () => {
    dispatch(setCallsheet(INITIAL_CALLSHEET))
    history.push(link)
  }

  return (
    <Box sx={style.container}>
      <CssBaseline />
      <Drawer variant='permanent' open={open}>
        <Box sx={style.content}>
          <Box sx={style.logo}>
            <CallsheetsLogo />
          </Box>
          <List sx={{ px: 1 }}>
            <Box sx={style.listBox}>
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={style.listItemButton(open)}
                  onClick={handleCollapseMenu}
                >
                  <Box>
                    {open ? (
                      <Box
                        display='flex'
                        justifyContent='center'
                        sx={{ pl: '18px' }}
                      >
                        <ArrowLineLeftIcon />
                        <Typography sx={{ ml: 1 }}>Collapse Menu</Typography>
                      </Box>
                    ) : (
                      <ArrowLineRightIcon />
                    )}
                  </Box>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding sx={{ display: 'block' }}>
                <Box sx={style.builderBtnBox}>
                  {open ? (
                    <Button
                      fullWidth
                      color='info'
                      variant='contained'
                      sx={style.builderBtn}
                      onClick={handleCrateCallsheetClick(PATHS.PRIVATE.BUILDER)}
                    >
                      <Box sx={style.builderBtnLarge}>
                        <PlusWhiteIcon />
                      </Box>
                      <Typography variant='subtitle2' sx={style.builderBtnText}>
                        Create Callsheet
                      </Typography>
                    </Button>
                  ) : (
                    <Box sx={style.builderBtnSmall}>
                      <IconButton
                        size='large'
                        sx={{ backgroundColor: 'info.main' }}
                        onClick={handleCrateCallsheetClick(
                          PATHS.PRIVATE.BUILDER
                        )}
                      >
                        <PlusWhiteIcon />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </ListItem>
            </Box>
            <Divider />
            <Box sx={style.listBox}>
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton sx={style.listItemButton(open)} disabled>
                  <ListItemIcon sx={style.listItemIcon(open)}>
                    <BellIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary='Activity'
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Box>
            <Divider />
            <Box sx={style.listBox}>
              {SIDE_MENU_ITEMS.map(item => {
                const { title, link, isDisabled, Icon } = item
                return (
                  <ListItem
                    key={title}
                    disablePadding
                    sx={{ display: 'block' }}
                  >
                    <ListItemButton
                      sx={style.listItemButton(open)}
                      onClick={handleLinkClick(link)}
                      disabled={isDisabled}
                    >
                      <ListItemIcon sx={style.listItemIcon(open)}>
                        <Icon />
                      </ListItemIcon>
                      <ListItemText
                        primary={title}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </Box>
          </List>
        </Box>
      </Drawer>
      <Box component='main' sx={{ flexGrow: 1 }}>
        {children}
      </Box>
    </Box>
  )
}

export default SideMenu
