import {
  SET_SMS_REMINDER,
  SET_PHONE,
  SET_SMS_TEXT,
  SWITCH_SMS_REMINDER,
  SET_SMS_PARAMETERS,
} from './actionTypes'
import {
  SetEmailReminder,
  SetSmsParameters,
  SetSmsPhone,
  SetSmsText,
  SmsParameters,
  SwitchSmsReminder,
} from './types'

export const setSmsPhone = (smsFrom: string): SetSmsPhone => ({
  type: SET_PHONE,
  smsFrom,
})

export const setSmsReminder = (smsReminder: number): SetEmailReminder => ({
  type: SET_SMS_REMINDER,
  smsReminder,
})

export const setSmsText = (smsContent: string): SetSmsText => ({
  type: SET_SMS_TEXT,
  smsContent,
})

export const switchSmsReminder = (): SwitchSmsReminder => ({
  type: SWITCH_SMS_REMINDER,
})

export const setSmsParameters = (
  smsParameters: SmsParameters
): SetSmsParameters => ({
  type: SET_SMS_PARAMETERS,
  smsParameters,
})
