import produce from 'immer'

import {
  SET_SMS_REMINDER,
  SET_PHONE,
  SET_SMS_TEXT,
  SWITCH_SMS_REMINDER,
  SET_SMS_PARAMETERS,
} from './actionTypes'
import { SmsParametersActions, SmsParametersState } from './types'

const smsParametersInitialState: SmsParametersState = {
  smsParameters: {
    smsContent: '',
    smsReminder: 12,
    smsFrom: '',
    isSmsReminder: false,
  },
}

const smsParametersReducer = produce(
  (draft = smsParametersInitialState, action: SmsParametersActions) => {
    switch (action.type) {
      case SET_SMS_PARAMETERS:
        draft.smsParameters = action.smsParameters
        break
      case SET_PHONE:
        draft.smsParameters.smsFrom = action.smsFrom
        break
      case SET_SMS_REMINDER:
        draft.smsParameters.smsReminder = action.smsReminder
        break
      case SWITCH_SMS_REMINDER:
        draft.smsParameters.isSmsReminder = !draft.smsParameters.isSmsReminder
        break
      case SET_SMS_TEXT:
        draft.smsParameters.smsContent = action.smsContent
        break
      default:
        return draft
    }
  }
)

export default smsParametersReducer
