import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Box } from '@mui/material'
import FinalTouchesForm from './FinalTouchesForm'
import { getCallsheetFinalTouchesRequest } from '../../../store/Callsheet/actions'

const FinalTouches: FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getCallsheetFinalTouchesRequest())
  }, [])

  return (
    <Box>
      <FinalTouchesForm />
    </Box>
  )
}

export default FinalTouches
