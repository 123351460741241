// @ts-nocheck
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectEmail, selectUserId } from '../store/User/selectors'
import { USERSNAP_GLOBAL_API_KEY } from '../constants'

export const useFeedback = (): (() => void) => {
  const [usersnapApi, setUsersnapApi] = useState(null)
  const userId = useSelector(selectUserId)
  const email = useSelector(selectEmail)
  useEffect(() => {
    let usersApi = null

    window.onUsersnapCXLoad = function (api) {
      api.init({ user: { userId, email } })
      usersApi = api
      setUsersnapApi(api)
    }

    const script = document.createElement('script')
    script.defer = 1
    script.src = `https://widget.usersnap.com/global/load/${USERSNAP_GLOBAL_API_KEY}?onload=onUsersnapCXLoad`
    document.head.appendChild(script)

    return () => {
      if (usersApi) {
        usersApi.destroy()
      }

      script.remove()
    }
  }, [userId, email])
}
