const style = {
  browseBtn: (size: string) => ({
    fontSize: size,
    color: 'primary.main',
    fontFamily: 'Avenir',
    fontWeight: '900',
  }),
  tip: (size: string) => ({
    fontSize: size,
    color: 'text.disabled',
  }),
}

export default style
