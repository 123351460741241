import { Stack, Table, TableContainer, Typography } from '@mui/material'
import React, { FC, PropsWithChildren, useMemo } from 'react'

import style from './style'

const subtitle = {
  company: (size: number) =>
    size > 1 ? `${size} Companies` : `${size} Company`,
  contact: (size: number) => (size > 1 ? `${size} People` : `${size} Person`),
}

const ListWrapper: FC<
  { listSize: number; variant: 'company' | 'contact' } & PropsWithChildren
> = ({ listSize, children, variant }) => {
  return (
    <>
      <Typography sx={style.infoTxt}>{subtitle[variant](listSize)}</Typography>
      <TableContainer component={Stack}>
        <Table
          sx={{
            minWidth: 650,
          }}
          aria-label='simple table'
        >
          {children}
        </Table>
      </TableContainer>
    </>
  )
}

export default ListWrapper
