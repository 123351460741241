import { call, put, takeLatest, select } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import {
  getCallsheetByIdApi,
  getCallsheets,
  getCallsheetsStatisticApi,
  getMemberTitlesApi,
  saveCallsheetApi,
  updateCallsheetApi,
} from '../../api/callsheet'
import { getContactsApi } from '../../api/contacts'
import { GetContactsResultType } from '../../api/contacts/types'

import { SampleError } from '../../api/types'
import { PATHS } from '../../constants'
import { STEPS } from '../../pages/BuilderPage/constants'
import { setIsUpdateOverview, setStep } from '../Builder/actions'
import { getCompanyRequestSuccess } from '../Companies/actions'
import { getContactsRequestSuccess } from '../Contacts/actions'
import { setNotificationErrorWorker } from '../Notification/sagas'
import {
  getCallsheetRequestError,
  getCallsheetRequestSuccess,
  getCallsheetsRequestError,
  getCallsheetsRequestSuccess,
  getCallsheetsStatisticsRequestSuccess,
  getMemberTitlesRequestError,
  getMemberTitlesRequestSuccess,
  saveCallsheetFinalTouchesRequestError,
  saveCallsheetFinalTouchesRequestSuccess,
  saveCallsheetRequestError,
  saveCallsheetRequestSuccess,
  updateCallsheetRequestError,
  updateCallsheetRequestSuccess,
} from './actions'
import {
  GET_CALLSHEETS_REQUEST,
  GET_CALLSHEETS_STATISTIC_REQUEST,
  GET_CALLSHEET_BY_ID_REQUEST,
  GET_CALLSHEET_FINAL_TOUCHES_REQUEST,
  GET_MEMBER_TITLES_REQUEST,
  SAVE_CALLSHEET_FINAL_TOUCHES_REQUEST,
  SAVE_CALLSHEET_REQUEST,
  SAVE_PRODUCTION_NOTES_REQUEST,
  UPDATE_CALLSHEET_OVERVIEW_REQUEST,
  UPDATE_CALLSHEET_REQUEST,
} from './actionTypes'
import { selectCallsheetId, selectCallsheetData } from './selectors'

import {
  Callsheet,
  GetCallsheetByIdRequestType,
  GetCallsheetsStatisticsRequestType,
  SaveCallsheetRequestType,
  UpdateCallsheetOverviewRequest,
  UpdateCallsheetRequestType,
} from './types'
import {
  GetCallsheetResultType,
  GetCallsheetsResultType,
  GetCallsheetsStatisticResultType,
  GetMemberTitlesResultType,
  SaveCallsheetResultType,
} from '../../api/callsheet/types'
import { SaveWalkieChannelsWorker } from '../WalkieChannels/sagas'
import { SaveAnnouncementRequestWorker } from '../Announcements/sagas'
import { SaveScheduleEventsRequestWorker } from '../Events/sagas'
import { SaveAttachmentsWorker } from '../Attachments/sagas'
import { getAttachmentsRequest } from '../Attachments/actions'
import { getWalkieChannelsRequest } from '../WalkieChannels/actions'
import { getEventsRequest } from '../Events/actions'
import { getAnnouncementRequest } from '../Announcements/actions'

function* getCallsheetCallbackSaga(
  callsheetId: string,
  eventDate?: DateConstructor | null,
  crewCallTime?: string
) {
  try {
    const {
      data: {
        data: { productCompany, clientCompany, ...callsheet },
      },
    }: AxiosResponse<GetCallsheetResultType> = yield call(
      getCallsheetByIdApi,
      callsheetId
    )
    const {
      data: { data },
    }: AxiosResponse<GetContactsResultType> = yield call(getContactsApi)

    const updatedCallsheet = {
      ...callsheet,
      ...(eventDate && { eventDate }),
      ...(crewCallTime && { crewCallTime }),
    }

    yield put(
      getCallsheetRequestSuccess({
        ...updatedCallsheet,
        productCompanyId: productCompany.id,
        clientCompanyId: clientCompany.id,
      })
    )
    yield put(
      getCompanyRequestSuccess(
        {
          ...productCompany,
          primaryMembers: data.filter(contact =>
            productCompany.primaryMembers?.includes(contact.id)
          ),
        },
        'production'
      )
    )

    yield put(getCompanyRequestSuccess(clientCompany, 'client'))
    yield put(getContactsRequestSuccess(data))
  } catch ({ response: data }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(getCallsheetRequestError(data as SampleError))
  }
}

export function* GetCallsheetsRequestWorker() {
  try {
    const {
      data: { data },
    }: AxiosResponse<GetCallsheetsResultType> = yield call(getCallsheets)

    yield put(getCallsheetsRequestSuccess(data))
  } catch ({ response: data }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(getCallsheetsRequestError(data as SampleError))
  }
}

export function* GetCallsheetByIdRequestWorker({
  payload: { id },
}: GetCallsheetByIdRequestType) {
  try {
    yield getCallsheetCallbackSaga(id)
  } catch ({ response: data }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(getCallsheetRequestError(data as SampleError))
  }
}

export function* GetCallsheetFinalTouchesRequestWorker() {
  try {
    yield put(getEventsRequest())
    yield put(getWalkieChannelsRequest())
    yield put(getAttachmentsRequest())
    yield put(getAnnouncementRequest())
  } catch ({ response: data }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(getCallsheetRequestError(data as SampleError))
  }
}

export function* SaveCallsheetRequestWorker({
  payload,
}: SaveCallsheetRequestType) {
  try {
    const { eventDate } = payload
    const timezoneOffset = new Date(
      eventDate as unknown as string
    ).getTimezoneOffset()
    const {
      data: {
        data: { id },
      },
    }: AxiosResponse<SaveCallsheetResultType> = yield call(saveCallsheetApi, {
      ...payload,
      timezoneOffset,
    })

    yield put(saveCallsheetRequestSuccess(id))
    yield put(setStep(STEPS.logistic))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(saveCallsheetRequestError(data as SampleError))
  }
}

export function* UpdateCallsheetRequestWorker({
  payload,
}: UpdateCallsheetRequestType) {
  const callsheetId: string = yield select(selectCallsheetId)
  const { eventDate } = payload
  const timezoneOffset = new Date(
    eventDate as unknown as string
  ).getTimezoneOffset()

  try {
    yield call(updateCallsheetApi, callsheetId, { ...payload, timezoneOffset })

    yield put(updateCallsheetRequestSuccess())
    yield put(setStep(STEPS.logistic))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(updateCallsheetRequestError(data as SampleError))
  }
}

export function* GetMemberTitlesRequestWorker() {
  try {
    const {
      data: { data },
    }: AxiosResponse<GetMemberTitlesResultType> = yield call(getMemberTitlesApi)
    yield put(getMemberTitlesRequestSuccess(data))
  } catch ({ response: data }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(getMemberTitlesRequestError(data as SampleError))
  }
}

export function* UpdateCallsheetProductionNotesRequestWorker() {
  const callsheetId: string = yield select(selectCallsheetId)
  const callsheet: Callsheet = yield select(selectCallsheetData)
  const { productionNoteDetails, productionNoteTitle } = callsheet

  try {
    yield call(updateCallsheetApi, callsheetId, {
      ...callsheet,
      ...(productionNoteDetails ? { productionNoteDetails } : {}),
      ...(productionNoteTitle ? { productionNoteTitle } : {}),
    })

    yield put(updateCallsheetRequestSuccess())
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(updateCallsheetRequestError(data as SampleError))
  }
}

export function* SaveCallsheetFinalTouchesRequestWorker() {
  try {
    yield call(SaveWalkieChannelsWorker)
    yield call(SaveAnnouncementRequestWorker)
    yield call(SaveScheduleEventsRequestWorker)
    yield call(UpdateCallsheetProductionNotesRequestWorker)
    yield call(SaveAttachmentsWorker)
    yield put(saveCallsheetFinalTouchesRequestSuccess())
    yield put(setStep(STEPS.publishAndSend))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(saveCallsheetFinalTouchesRequestError())
  }
}

export function* UpdateCallsheetOverviewWorker({
  payload: { callsheetId, history, eventDate, crewCallTime },
}: UpdateCallsheetOverviewRequest) {
  try {
    yield put(setIsUpdateOverview(true))

    yield getCallsheetCallbackSaga(callsheetId, eventDate, crewCallTime)

    yield call(history.push, PATHS.PRIVATE.BUILDER)
    yield call(setStep, STEPS.overview)
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
  }
}

export function* GetCallsheetsStatisticRequestWorker({
  payload: { ids },
}: GetCallsheetsStatisticsRequestType) {
  try {
    const {
      data: { data },
    }: AxiosResponse<GetCallsheetsStatisticResultType> = yield call(
      getCallsheetsStatisticApi,
      ids
    )
    yield put(getCallsheetsStatisticsRequestSuccess(data))
  } catch ({ response: data }) {
    yield setNotificationErrorWorker(data as SampleError)
  }
}

export function* requestCallsheetWatcher(): Generator {
  yield takeLatest(GET_CALLSHEETS_REQUEST, GetCallsheetsRequestWorker)
  yield takeLatest(
    GET_CALLSHEETS_STATISTIC_REQUEST,
    GetCallsheetsStatisticRequestWorker
  )
  yield takeLatest(GET_CALLSHEET_BY_ID_REQUEST, GetCallsheetByIdRequestWorker)
  yield takeLatest(SAVE_CALLSHEET_REQUEST, SaveCallsheetRequestWorker)
  yield takeLatest(UPDATE_CALLSHEET_REQUEST, UpdateCallsheetRequestWorker)
  yield takeLatest(
    SAVE_PRODUCTION_NOTES_REQUEST,
    UpdateCallsheetProductionNotesRequestWorker
  )
  yield takeLatest(GET_MEMBER_TITLES_REQUEST, GetMemberTitlesRequestWorker)

  yield takeLatest(
    SAVE_CALLSHEET_FINAL_TOUCHES_REQUEST,
    SaveCallsheetFinalTouchesRequestWorker
  )
  yield takeLatest(
    GET_CALLSHEET_FINAL_TOUCHES_REQUEST,
    GetCallsheetFinalTouchesRequestWorker
  )
  yield takeLatest(
    UPDATE_CALLSHEET_OVERVIEW_REQUEST,
    UpdateCallsheetOverviewWorker
  )
}
