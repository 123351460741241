const style = {
  searchBox: {
    mr: '20px',
  },
  input: {
    width: 264,
    height: 32,
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    border: '1px solid #E2E2E2',
    input: {
      padding: '0px',
    },
    '& .MuiInputBase-input': {
      width: 220,
      fontSize: '12px',
    },
    '& .MuiIconButton-root': {},
  },
  inputIcon: {
    color: 'text.disabled',
  },
}

export default style
