const style = {
  tabs: {
    minHeight: 'auto',
    height: '24px',
  },
  tab: {
    p: '0px 0px 4px 0px',
    mr: 3,
    width: 'auto',
    minWidth: 'auto',
    minHeight: 'auto',
    height: '24px',
  },
}

export default style
