import React, { FC, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'

import { useToggle } from '../../../../hooks/useToggle'

import EmailPreviewForm from '../EmailPreviewForm'
import PrepareToSend from '../PrepareToSend'
import PreviewWrapper from '../PreviewWrapper'
import LabeledContainer from '../LabeledContainer'

import { selectEmailParameters } from '../../../../store/EmailParameters/selectors'
import { EmailParameters } from '../../../../store/EmailParameters/types'

import {
  setEmailDescription,
  setEmailFrom,
  setEmailSubject,
} from '../../../../store/EmailParameters/actions'
import { getReminderOption } from '../../../../utils/getReminderOption'

import { EmailIcon } from '../../../../assets/icons'

const EmailSection: FC<{ onSave: (e: boolean) => void }> = ({ onSave }) => {
  const dispatch = useDispatch()
  const emailParameters = useSelector(selectEmailParameters)
  const [parameters, setParamaters] = useState<EmailParameters>(emailParameters)
  const { emailAttachment, isEmailReminder, emailReminder } = emailParameters

  const { emailFrom, emailContent, emailSubject } = parameters

  const [emailPreview, setEmailPreview] = useToggle()

  const isDisabled = !emailFrom || !emailContent || !emailSubject

  useEffect(() => {
    onSave(isDisabled)
  }, [])

  const handleSubmit = () => {
    setEmailPreview()
    dispatch(setEmailDescription(parameters.emailContent))
    dispatch(setEmailSubject(parameters.emailSubject))
    dispatch(setEmailFrom(parameters.emailFrom))
    onSave(false)
  }

  const handleClose = () => {
    setEmailPreview()
    onSave(isDisabled)
  }

  return (
    <>
      <PreviewWrapper
        confirmLabel='Save email'
        open={emailPreview}
        onClose={handleClose}
        onSubmit={handleSubmit}
        title='Email preview'
        disabled={isDisabled}
      >
        <EmailPreviewForm
          subject={emailSubject}
          description={emailContent}
          emailFrom={emailFrom}
          onDescriptionChange={(e: string) =>
            setParamaters(prev => ({ ...prev, emailContent: e }))
          }
          onSubjectChange={(e: string) =>
            setParamaters(prev => ({ ...prev, emailSubject: e }))
          }
          onEmailFromChange={(e: string) =>
            setParamaters(prev => ({ ...prev, emailFrom: e }))
          }
        />
      </PreviewWrapper>
      <PrepareToSend
        Icon={EmailIcon}
        title='Email Confirmation'
        onClick={setEmailPreview}
        edit={!isDisabled}
      >
        <Box display='flex' gap='60px'>
          <LabeledContainer label='Sending To'>
            <Typography variant='h5' color='success.main'>
              All Reciepients
            </Typography>
          </LabeledContainer>
          <LabeledContainer label='PDF file for email:'>
            <Typography variant='h5' color='text.primary'>
              {emailAttachment ? 'Yes' : 'No'}
            </Typography>
          </LabeledContainer>
          {isEmailReminder && (
            <LabeledContainer label='Reminder:'>
              <Typography variant='h5' color='text.primary'>
                {getReminderOption(emailReminder ?? 0)} Before
              </Typography>
            </LabeledContainer>
          )}
        </Box>
      </PrepareToSend>
    </>
  )
}

export default EmailSection
