const style = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: '16px',
    height: '98px',
  },
  browseBtn: {
    fontSize: '15px',
    color: 'primary.main',
    fontFamily: 'Avenir',
    fontWeight: '900',
  },
  tip: {
    fontSize: '14px',
    color: 'text.disabled',
  },
}

export default style
