const PREFIX = 'LOGISTICS/'

export const GET_LOCATIONS_REQUEST = `${PREFIX}GET_LOCATIONS_REQUEST` as const
export const GET_LOCATIONS_REQUEST_SUCCESS =
  `${PREFIX}GET_LOCATIONS_REQUEST_SUCCESS` as const
export const GET_LOCATIONS_REQUEST_ERROR =
  `${PREFIX}GET_LOCATIONS_REQUEST_ERROR` as const
export const ADD_LOCATIONS_REQUEST = `${PREFIX}ADD_LOCATIONS_REQUEST` as const
export const ADD_LOCATIONS_REQUEST_SUCCESS =
  `${PREFIX}ADD_LOCATIONS_REQUEST_SUCCESS` as const
export const ADD_LOCATIONS_REQUEST_ERROR =
  `${PREFIX}ADD_LOCATIONS_REQUEST_ERROR` as const
export const UPDATE_LOCATION_REQUEST =
  `${PREFIX}UPDATE_LOCATION_REQUEST` as const
export const UPDATE_LOCATION_REQUEST_SUCCESS =
  `${PREFIX}UPDATE_LOCATION_REQUEST_SUCCESS` as const
export const UPDATE_LOCATION_REQUEST_ERROR =
  `${PREFIX}UPDATE_LOCATION_REQUEST_ERROR` as const
export const DELETE_LOCATION_REQUEST =
  `${PREFIX}DELETE_LOCATION_REQUEST` as const
export const DELETE_LOCATION_REQUEST_SUCCESS =
  `${PREFIX}DELETE_LOCATION_REQUEST_SUCCESS` as const
export const DELETE_LOCATION_REQUEST_ERROR =
  `${PREFIX}DELETE_LOCATION_REQUEST_ERROR` as const
export const SET_HOSPITAL = `${PREFIX}SET_HOSPITAL` as const
export const SET_IS_HOSPITAL = `${PREFIX}SET_IS_HOSPITAL` as const
export const SET_IS_WEATHER = `${PREFIX}SET_IS_WEATHER` as const
export const SET_LOCATIONS = `${PREFIX}SET_LOCATIONS` as const
