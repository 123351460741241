import { useDispatch } from 'react-redux'
import { ButtonStatuses } from 'src/pages/CallsheetViewPage/ChangeStatusButton/constants'
import { CallsheetStatuses } from 'src/store/Callsheet/types'
import {
  acceptCallsheetRequest,
  declineCallsheetRequest,
  tentativeCallsheetRequest,
} from 'src/store/CallsheetStatus/actions'

const useChangeStatus = () => {
  const dispatch = useDispatch()

  const onDecline = () => dispatch(declineCallsheetRequest())
  const onAccept = () => dispatch(acceptCallsheetRequest())
  const onTentative = () => dispatch(tentativeCallsheetRequest())

  const actions = {
    [CallsheetStatuses.ACCEPTED]: onAccept,
    [CallsheetStatuses.DECLINED]: onDecline,
    [CallsheetStatuses.TENTATIVE]: onTentative,
  }

  const onChangeStatus = (status: ButtonStatuses) => {
    return actions[status]()
  }

  return { onDecline, onTentative, onAccept, onChangeStatus }
}

export default useChangeStatus
