import { noop } from 'lodash'
import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Box,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  ListItem,
} from '@mui/material'

import { SelectChangeEvent } from '@mui/material/Select'
import SelectFilterInput from '../../SelectFilterInput'
import { DEFAULT_TAG, MenuProps, TAGS } from './constants'
import { CallsheetViews } from '../../../store/Callsheet/types'
import { selectCallsheetsView } from '../../../store/Gallery/selectors'
import { setCallsheetsView } from '../../../store/Gallery/actions'
import { CaretBottomIcon } from '../../../assets/icons'

import style from './style'

type FilterProps = {
  label?: string
  selectedTags: string[]

  defaultTag?: string
}

const viewMap: { [key: string]: CallsheetViews } = {
  Expanded: CallsheetViews.EXPANDED_LIST,
  Condensed: CallsheetViews.CONDENSED_LIST,
}

const ListViewSelector: FC<FilterProps> = ({
  label = 'List View',
  selectedTags,

  defaultTag = DEFAULT_TAG,
}) => {
  const dispatch = useDispatch()
  const [viewTags, setSelectedTags] = useState(selectedTags)

  const handleChange = (event: SelectChangeEvent<typeof selectedTags>) => {
    const {
      target: { value },
    } = event

    const selected = typeof value === 'string' ? value.split(',') : value
    if (typeof value === 'string') {
      dispatch(setCallsheetsView(viewMap[value]))
    }

    setSelectedTags(selected)
  }

  const renderValue = (selected: string[]) => {
    return selectedTags.length === 0 ? defaultTag : selected.join(', ')
  }

  const isSelected = (tag: string) => selectedTags.indexOf(tag) > -1

  return (
    <div>
      <FormControl>
        <Select
          id='view-select'
          displayEmpty
          value={viewTags}
          onChange={handleChange}
          MenuProps={MenuProps}
          renderValue={renderValue}
          input={
            <SelectFilterInput
              label={label}
              inputStyleProps={{ width: '175px', p: '0px' }}
            />
          }
          IconComponent={props => (
            <Box {...props} sx={style.icon}>
              <CaretBottomIcon />
            </Box>
          )}
        >
          {[
            ...TAGS.map(tag => (
              <MenuItem key={tag} value={tag} sx={style.item(isSelected(tag))}>
                <ListItem
                  key={tag}
                  secondaryAction={isSelected(tag) && <span>•</span>}
                >
                  <ListItemText primary={`${tag} List`} />
                </ListItem>
              </MenuItem>
            )),
          ]}
        </Select>
      </FormControl>
    </div>
  )
}

export default ListViewSelector
