import { SampleError } from '../../api/types'
import {
  SET_FAVORITE,
  SET_GROUPS,
  SET_GROUP_CALL_TIME,
  SET_GROUPS_DISPLAY,
  GET_GROUPS_REQUEST,
  GET_GROUPS_REQUEST_SUCCESS,
  GET_GROUPS_REQUEST_ERROR,
  UPDATE_GROUPS_REQUEST,
  UPDATE_GROUPS_REQUEST_ERROR,
  UPDATE_GROUPS_REQUEST_SUCCESS,
  SAVE_GROUPS_REQUEST,
  ADD_GROUP_REQUEST_SUCCESS,
} from './actionTypes'
import {
  GroupType,
  SetGroups,
  SetFavorite,
  SetGroupCallTime,
  SetGroupsDisplay,
  GetGroupsRequestType,
  GetGroupsRequestSuccessType,
  GetGroupsRequestErrorType,
  UpdateGroupsRequestType,
  UpdateGroupsRequestErrorType,
  UpdateGroupsRequestSuccessType,
  SaveGroupsRequestType,
  AddGroupRequestSuccessType,
} from './types'

export const setGroups = (groups: GroupType[], id?: string): SetGroups => ({
  type: SET_GROUPS,
  groups,
  id,
})

export const getGroupsRequest = (): GetGroupsRequestType => ({
  type: GET_GROUPS_REQUEST,
})

export const getGroupsRequestSuccess = (
  groups: GroupType[]
): GetGroupsRequestSuccessType => ({
  type: GET_GROUPS_REQUEST_SUCCESS,
  groups,
})

export const getGroupsRequestError = (
  error: SampleError
): GetGroupsRequestErrorType => ({
  type: GET_GROUPS_REQUEST_ERROR,
  error,
})

export const saveGroupsRequest = (): SaveGroupsRequestType => ({
  type: SAVE_GROUPS_REQUEST,
})

export const addGroupRequestSuccess = (
  title: string,
  id: string
): AddGroupRequestSuccessType => ({
  type: ADD_GROUP_REQUEST_SUCCESS,
  title,
  id,
})

export const updateGroupsRequest = (
  id: string,
  title: string,
  callTime: string,
  isPrimary: boolean
): UpdateGroupsRequestType => ({
  type: UPDATE_GROUPS_REQUEST,
  payload: {
    id,
    title,
    callTime,
    isPrimary,
  },
})

export const updateGroupsRequestSuccess =
  (): UpdateGroupsRequestSuccessType => ({
    type: UPDATE_GROUPS_REQUEST_SUCCESS,
  })

export const updateGroupsRequestError = (
  error: SampleError
): UpdateGroupsRequestErrorType => ({
  type: UPDATE_GROUPS_REQUEST_ERROR,
  error,
})

export const setFavorite = (title: string): SetFavorite => ({
  type: SET_FAVORITE,
  title,
})

export const setGroupsDisplay = (): SetGroupsDisplay => ({
  type: SET_GROUPS_DISPLAY,
})

export const setGroupCallTime = (
  title: string,
  callTime: string
): SetGroupCallTime => ({
  type: SET_GROUP_CALL_TIME,
  title,
  callTime,
})
