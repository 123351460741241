const style = {
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    p: 1,
  },
}

export default style
