import { noop } from 'lodash'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Typography } from '@mui/material'
import React, {
  FunctionComponent,
  SVGProps,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import ActionConfirmDialog from '../../components/DeleteCallsheetForm/ActionConfirmDialog'
import CallsheetConfirmationModal from '../../components/CallsheetConfirmationModal'
import ChangeStatusButton from './ChangeStatusButton'
import Loader from '../../components/Loader'

import { getWalkieChannelsRequest } from '../../store/WalkieChannels/actions'
import { getAnnouncementRequest } from '../../store/Announcements/actions'
import {
  setCallsheetActionModal,
  setIsCallsheetConfirmModal,
} from '../../store/Builder/actions'
import { setConfirmationStep } from '../../store/CallsheetStatus/actions'
import { getAttachmentsRequest } from '../../store/Attachments/actions'
import { getCallsheetRequest } from '../../store/Callsheet/actions'
import { getLocationsRequest } from '../../store/Logistics/actions'
import { getEventsRequest } from '../../store/Events/actions'
import { getGroupsRequest } from '../../store/Groups/actions'
import { selectCallsheetStatusRequest } from '../../store/CallsheetStatus/selectors'
import {
  selectCallsheetActionConfirmModal,
  selectIsCallsheetConfirmModal,
} from '../../store/Builder/selectors'
import {
  selectCallsheetId,
  selectCallsheetMemberStatus,
  selectCallsheetStatus,
  selectCallsheetTitle,
  selectCrewCallTime,
  selectIsAdmin,
  selectRequestStatus,
} from '../../store/Callsheet/selectors'
import { CallsheetStatuses } from '../../store/Callsheet/types'
import { CallsheetViewActions } from '../../store/Builder/types'

import CallsheetView, { CALLSHEET_VIEW_ID } from './CallsheetView/CallsheetView'
import { generatePdf } from '../../utils/downloadFile'
import { STEPS } from '../../components/CallsheetConfirmationModal/constants'
import { RequestStatuses } from '../../api/constants'
import { MODAL_ACTIONS, PATHS } from '../../constants'
import {
  CalendarIcon,
  HelpCircleOutlineIcon,
  LogOutOutlineIcon,
  XCircleIcon,
} from '../../assets/icons'
import style from './style'

const actionIcons: {
  [key: string]: FunctionComponent<SVGProps<SVGSVGElement>>
} = {
  [CallsheetViewActions.DECLINE]: XCircleIcon,
  [CallsheetViewActions.TENTATIVE]: HelpCircleOutlineIcon,
}

const CallsheetViewPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params: { id: string } = useParams()
  const callsheetId = useSelector(selectCallsheetId)
  const callsheetTitle = useSelector(selectCallsheetTitle)
  const callsheetStatus = useSelector(selectCallsheetStatus)
  const callsheetMemberStatus = useSelector(selectCallsheetMemberStatus)
  const { open, action } = useSelector(selectCallsheetActionConfirmModal)
  // TODO temporary add crew call time instead personal call time
  const crewCallTime = useSelector(selectCrewCallTime)
  const requestStatus = useSelector(selectRequestStatus)
  const callsheetStatusRequest = useSelector(selectCallsheetStatusRequest)
  const isCallsheetConfirmModal = useSelector(selectIsCallsheetConfirmModal)
  const isAdmin = useSelector(selectIsAdmin)

  const [isPdfGenerating, setIsPdfGenerating] = useState(false)

  useEffect(() => {
    if (params.id && callsheetStatusRequest !== CallsheetStatuses.PENDING) {
      dispatch(getCallsheetRequest(params.id))
    }
  }, [callsheetStatusRequest])

  useEffect(() => {
    if (callsheetId) {
      dispatch(getLocationsRequest())
      dispatch(getGroupsRequest())

      dispatch(getEventsRequest())
      dispatch(getAnnouncementRequest())
      dispatch(getWalkieChannelsRequest())
      dispatch(getAttachmentsRequest())
    }
  }, [callsheetId])

  const handleReturnBtn = () => {
    history.push(PATHS.PRIVATE.CALLSHEETS)
  }

  const handleConfirmationModalClose = () => {
    dispatch(setCallsheetActionModal(false))
    dispatch(setConfirmationStep(STEPS.timeAndLocation))
  }

  const modalMessage = useMemo(() => {
    return MODAL_ACTIONS[action](callsheetTitle)
  }, [action, open])

  const adminActionPanel = () => {
    return (
      <Box sx={style.actionsBox}>
        <Button
          variant='outlined'
          sx={style.btnWithIcon}
          onClick={handleReturnBtn}
        >
          Return to Gallery
          <LogOutOutlineIcon />
        </Button>
        <LoadingButton
          loading={isPdfGenerating}
          variant='contained'
          onClick={() =>
            generatePdf(CALLSHEET_VIEW_ID, callsheetTitle, setIsPdfGenerating)
          }
        >
          Download PDF
        </LoadingButton>
      </Box>
    )
  }

  return (
    <Box>
      {requestStatus === RequestStatuses.PENDING ? (
        <Loader />
      ) : (
        <Box sx={style.wrapper}>
          <Box sx={style.container}>
            <Box sx={style.header}>
              <Box>
                <Typography variant='h3'>{callsheetTitle}</Typography>
              </Box>
              {isAdmin ? (
                adminActionPanel()
              ) : (
                <Box sx={style.actionsBox}>
                  <Button
                    variant='outlined'
                    onClick={noop}
                    sx={style.btnWithIcon}
                  >
                    Add to Calendar
                    <CalendarIcon />
                  </Button>
                  <LoadingButton
                    loading={isPdfGenerating}
                    variant='contained'
                    onClick={() =>
                      generatePdf(
                        CALLSHEET_VIEW_ID,
                        callsheetTitle,
                        setIsPdfGenerating
                      )
                    }
                  >
                    Download PDF
                  </LoadingButton>
                  <Button
                    variant='outlined'
                    onClick={handleReturnBtn}
                    sx={style.btnWithIcon}
                  >
                    Return to Gallery
                    <LogOutOutlineIcon />
                  </Button>
                  <ChangeStatusButton
                    status={isAdmin ? callsheetStatus : callsheetMemberStatus}
                    id={callsheetId}
                  />
                </Box>
              )}
            </Box>
            {callsheetId ? <CallsheetView callTime={crewCallTime} /> : null}
          </Box>
        </Box>
      )}
      <CallsheetConfirmationModal
        open={isCallsheetConfirmModal}
        onClose={() => dispatch(setIsCallsheetConfirmModal(false))}
        title={callsheetTitle}
      />
      <ActionConfirmDialog
        open={open}
        Icon={actionIcons[action]}
        onClose={handleConfirmationModalClose}
        message={modalMessage}
      />
    </Box>
  )
}

export default CallsheetViewPage
