import React, { ChangeEventHandler, FC, useEffect } from 'react'
import { LoadingButton } from '@mui/lab'
import { Box, Divider } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { RequestStatuses } from '../../../../api/constants'
import {
  saveCallsheetRequest,
  setCallsheetTitle,
  updateCallsheetRequest,
} from '../../../../store/Callsheet/actions'
import {
  selectCallsheetTitle,
  selectEventDate,
  selectCrewCallTime,
  selectProductCompanyId,
  selectClientCompanyId,
  selectRequestStatus,
  selectCallsheetId,
} from '../../../../store/Callsheet/selectors'
import { getContactsRequest } from '../../../../store/Contacts/actions'

import HeaderSection from './HeaderSection'
import CompanyAddField from './CompanyAddField'
import DateTimeSection from './DateTimeSection'
import CustomTextField from '../../../../components/CustomTextField'

import style from './style'

const CallsheetForm: FC = () => {
  const callsheetId = useSelector(selectCallsheetId)
  const title = useSelector(selectCallsheetTitle)
  const eventDate = useSelector(selectEventDate)
  const crewCallTime = useSelector(selectCrewCallTime)
  const productCompanyId = useSelector(selectProductCompanyId)
  const clientCompanyId = useSelector(selectClientCompanyId)
  const requestStatus = useSelector(selectRequestStatus)

  const dispatch = useDispatch()

  const handleCallsheetTitleChange: ChangeEventHandler<HTMLTextAreaElement> = ({
    target: { value },
  }) => {
    dispatch(setCallsheetTitle(value))
  }

  const handleStepSubmit = () => {
    const callsheet = {
      title,
      eventDate,
      crewCallTime,
      productCompanyId,
      clientCompanyId,
    }

    if (callsheetId) {
      dispatch(updateCallsheetRequest(callsheet))
      return
    }

    dispatch(saveCallsheetRequest(callsheet))
  }

  useEffect(() => {
    dispatch(getContactsRequest())
  }, [])

  const isSubmitEnable =
    eventDate && title && crewCallTime && productCompanyId && clientCompanyId

  return (
    <Box sx={style.container}>
      <HeaderSection
        info='PROJECT OVERVIEW'
        title='Call Sheet Overview'
        subtitle='Add as much information as you can to get started.'
      />
      <CustomTextField
        required
        id='callsheet-title'
        label='CallSheet Title'
        placeholder='Type title...'
        onInputChange={handleCallsheetTitleChange}
        value={title}
      />
      <Divider sx={{ my: 3 }} />
      <CompanyAddField />
      <Divider sx={{ my: 3 }} />
      <DateTimeSection />
      <LoadingButton
        variant='contained'
        fullWidth
        disabled={!isSubmitEnable}
        onClick={handleStepSubmit}
        loading={requestStatus === RequestStatuses.PENDING}
      >
        Continue
      </LoadingButton>
    </Box>
  )
}

export default CallsheetForm
