const PREFIX = 'BUILDER/'

export const SET_IS_COMPANY_PANEL = `${PREFIX}SET_IS_COMPANY_PANEL` as const
export const SET_IS_MEMBERS_PANEL = `${PREFIX}SET_IS_MEMBERS_PANEL` as const
export const SET_IS_MEMBER_PROFILE_PANEL =
  `${PREFIX}SET_IS_MEMBER_PROFILE_PANEL` as const
export const SET_IS_ADD_CONTACT_PANEL =
  `${PREFIX}SET_IS_ADD_CONTACT_PANEL` as const
export const SET_IS_LOCATION_PANEL = `${PREFIX}SET_IS_LOCATION_PANEL` as const
export const SET_IS_USER_GROUP_PANEL =
  `${PREFIX}SET_IS_USER_GROUP_PANEL` as const
export const SET_IS_GROUP_MEMBERS_PANEL =
  `${PREFIX}SET_IS_GROUP_MEMBERS_PANEL` as const
export const SET_STEP = `${PREFIX}SET_STEP` as const
export const SET_COMPANY_TYPE = `${PREFIX}SET_COMPANY_TYPE` as const
export const SET_IS_PERSISTENT_PANEL =
  `${PREFIX}SET_IS_PERSISTENT_PANEL` as const

export const SET_IS_CALLSHEET_CONFIRM_MODAL =
  `${PREFIX}SET_IS_CALLSHEET_CONFIRM_MODAL` as const
export const SET_IS_CALLSHEET_DECLINE_MODAL =
  `${PREFIX}SET_IS_CALLSHEET_DECLINE_MODAL` as const
export const SET_IS_CONFIRM_DELETE_CALLSHEET_DIALOG =
  `${PREFIX}SET_IS_CONFIRM_DELETE_CALLSHEET_DIALOG` as const

export const SET_IS_CONFIRM_DELETE_ACTION_MODAL =
  `${PREFIX}SET_IS_CONFIRM_DELETE_ACTION_MODAL` as const

export const SET_CALLSHEET_ACTION_MODAL =
  `${PREFIX}SET_CALLSHEET_ACTION_MODAL` as const

export const SET_IS_UPDATE_OVERVIEW = `${PREFIX}SET_IS_UPDATE_OVERVIEW` as const

export const SET_IS_CONTACT_DETAILS_PANEL =
  `${PREFIX}SET_IS_CONTACT_DETAILS_PANEL` as const
export const SET_IS_COMPANY_DETAILS_PANEL =
  `${PREFIX}SET_IS_COMPANY_DETAILS_PANEL` as const

export const SET_IS_CONFIRM_DELETE_COMPANY_MODAL =
  `${PREFIX}SET_IS_CONFIRM_DELETE_COMPANY_MODAL` as const

export const SET_IS_CONFIRM_DELETE_CONTACT_MODAL =
  `${PREFIX}SET_IS_CONFIRM_DELETE_CONTACT_MODAL` as const
