const style = {
  label: {
    color: 'text.primary',
    lineHeight: '20px',
    textAlign: 'left',
    mb: 0,
  },
}

export default style
