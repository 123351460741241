import React, { FC } from 'react'
import { Stack } from '@mui/material'
import { GroupMember } from 'src/store/GroupMembers/types'
import RecipientListItem from '../RecipientListItem'

type RecipientsBoxProps = {
  recipients: GroupMember[]
  isMissingPhone?: boolean
}

const RecipientsBox: FC<RecipientsBoxProps> = ({
  recipients,
  isMissingPhone = false,
}) => (
  <Stack gap='10px'>
    {recipients.map(recipient => (
      <RecipientListItem
        key={recipient.id}
        isMissingPhone={isMissingPhone}
        member={recipient}
      />
    ))}
  </Stack>
)

export default RecipientsBox
