import { call, put, select, takeLatest, throttle } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'

import {
  addContactApi,
  addContactImageApi,
  deleteContactApi,
  getContactImageApi,
  getContactsApi,
  getContactsByEmailAndNameApi,
  updateContactApi,
} from '../../api/contacts'
import {
  AddContactResultType,
  GetContactsResultType,
  UpdateContactResultType,
  GetContactsTagsResultType,
} from '../../api/contacts/types'
import { getContactsTagsApi } from '../../api/tags'
import { SampleError } from '../../api/types'
import {
  setIsAddContactPanel,
  setIsConfirmDeleteContactModal,
  setIsContactDetailsPanel,
  setIsMemberProfilePanel,
} from '../Builder/actions'
import { setNotificationErrorWorker } from '../Notification/sagas'
import { selectUserId } from '../User/selectors'
import {
  getContactsRequestSuccess,
  getContactsRequestError,
  addContactRequestSuccess,
  addContactRequestError,
  updateContactRequestError,
  updateContactRequestSuccess,
  getContactsTagsRequestSuccess,
  getContactsTagsRequestError,
  updateContactImageRequest,
  deleteContactRequestSuccess,
  deleteContactRequestError,
  getContactsRequest,
  getContactsByNameEmailRequestSuccess,
  getContactsByNameEmailRequestError,
} from './actions'
import {
  ADD_CONTACT_REQUEST,
  GET_CONTACTS_REQUEST,
  UPDATE_CONTACT_REQUEST,
  GET_CONTACTS_TAGS_REQUEST,
  UPDATE_CONTACT_IMAGE_REQUEST,
  DELETE_CONTACT_REQUEST,
  GET_CONTACTS_BY_NAME_EMAIL_REQUEST,
} from './actionTypes'
import {
  AddContactRequestType,
  DeleteContactRequestType,
  GetContactsByNameEmailRequestType,
  UpdateContactImageRequestType,
  UpdateContactRequestType,
} from './types'
import { PANELS } from '../../constants'

export function* GetContactsRequestWorker() {
  try {
    const {
      data: { data },
    }: AxiosResponse<GetContactsResultType> = yield call(getContactsApi)
    yield put(getContactsRequestSuccess(data))
  } catch (err) {
    yield put(getContactsRequestError(err as SampleError))
  }
}

export function* AddContactRequestWorker({ payload }: AddContactRequestType) {
  const userId: string = yield select(selectUserId)
  const { image, ...contact } = payload
  try {
    const {
      data: { data },
    }: AxiosResponse<AddContactResultType> = yield call(
      addContactApi,
      userId,
      contact
    )

    yield put(addContactRequestSuccess(data.id))
    if (image) {
      yield put(updateContactImageRequest(image, data.id))
    }
    yield call(GetContactsRequestWorker)
    yield put(setIsAddContactPanel(false))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(addContactRequestError())
  }
}

export function* UpdateContactRequestWorker({
  payload: { contact, panel },
}: UpdateContactRequestType) {
  const userId: string = yield select(selectUserId)
  const { image, ...contactInfo } = contact
  try {
    const {
      data: { data },
    }: AxiosResponse<UpdateContactResultType> = yield call(
      updateContactApi,
      userId,
      contactInfo
    )
    if (image && contactInfo.id) {
      yield put(updateContactImageRequest(image, contactInfo.id))
    }
    yield put(updateContactRequestSuccess(data.id))

    if (panel === PANELS.MEMBER) {
      yield put(setIsMemberProfilePanel(false))
    }
    if (panel === PANELS.CONTACT) {
      yield put(getContactsRequest())
      yield put(setIsAddContactPanel(false))
    }
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(updateContactRequestError())
  }
}

export function* GetContactsTagsRequestWorker() {
  const userId: string = yield select(selectUserId)

  try {
    const {
      data: { data },
    }: AxiosResponse<GetContactsTagsResultType> = yield call(
      getContactsTagsApi,
      userId
    )
    yield put(getContactsTagsRequestSuccess(data))
  } catch (err) {
    yield put(getContactsTagsRequestError(err as SampleError))
  }
}

export function* UpdateContactImageRequestWorker({
  payload,
}: UpdateContactImageRequestType) {
  const userId: string = yield select(selectUserId)
  const { contactId, image } = payload
  try {
    const { blob, format, id } = image
    if (blob && format && !id) {
      const file: Blob = yield blob
      yield call(addContactImageApi, userId, contactId, file, format)
    }
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
  }
}

export function* DeleteContactRequestWorker({
  payload,
}: DeleteContactRequestType) {
  const userId: string = yield select(selectUserId)
  const { contactId } = payload
  try {
    const {
      data: { data },
    }: AxiosResponse<UpdateContactResultType> = yield call(
      deleteContactApi,
      contactId,
      userId
    )
    yield put(deleteContactRequestSuccess())
    yield put(setIsConfirmDeleteContactModal(false))
    yield put(setIsContactDetailsPanel(false))
    yield call(GetContactsRequestWorker)
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(deleteContactRequestError(data as SampleError))
  }
}

export function* GetContactsByNameEmailRequestWorker({
  payload: { value },
}: GetContactsByNameEmailRequestType) {
  try {
    const {
      data: { data },
    }: AxiosResponse<GetContactsResultType> = yield call(
      getContactsByEmailAndNameApi,
      value
    )
    yield put(getContactsByNameEmailRequestSuccess(data))
  } catch (err) {
    yield put(getContactsByNameEmailRequestError(err as SampleError))
  }
}

export function* requestContactsWatcher(): Generator {
  yield takeLatest(GET_CONTACTS_REQUEST, GetContactsRequestWorker)
  yield throttle(
    500,
    GET_CONTACTS_BY_NAME_EMAIL_REQUEST,
    GetContactsByNameEmailRequestWorker
  )

  yield takeLatest(DELETE_CONTACT_REQUEST, DeleteContactRequestWorker)
  yield takeLatest(ADD_CONTACT_REQUEST, AddContactRequestWorker)
  yield takeLatest(UPDATE_CONTACT_REQUEST, UpdateContactRequestWorker)
  yield takeLatest(
    UPDATE_CONTACT_IMAGE_REQUEST,
    UpdateContactImageRequestWorker
  )

  yield takeLatest(GET_CONTACTS_TAGS_REQUEST, GetContactsTagsRequestWorker)
}
