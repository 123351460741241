const style = {
  root: (fullwidth: boolean) => ({
    display: 'flex',
    flexShrink: 0,
    width: 36,
    height: 36,
    background: '#FFFFFF',
    border: '1px solid #E2E2E2',
    borderRadius: '8px',
    alignItems: 'center',
    justifyContent: 'center',

    '&:not(:last-child)': {
      margin: '0 8px 0 0',
    },
    '&:disabled': {
      opacity: 0.3,
      cursor: 'unset',
    },
    ...(fullwidth && {
      width: '100%',
      gap: 0,
      px: 1,
      py: '10px',
      justifyContent: 'start',
    }),
  }),
}

export default style
