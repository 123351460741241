const PREFIX = 'ANNOUNCEMENTS/'

export const ADD_ANNOUNCEMENT = `${PREFIX}ADD_ANNOUNCEMENT` as const
export const SET_ANNOUNCEMENT = `${PREFIX}SET_ANNOUNCEMENT` as const
export const SELECT_ANNOUNCEMENT = `${PREFIX}SELECT_ANNOUNCEMENT` as const
export const SWITCH_COMBINED_ANNOUNCEMENT =
  `${PREFIX}SWITCH_COMBINED_ANNOUNCEMENT` as const
export const SET_COMBINED_ANNOUNCEMENT =
  `${PREFIX}SET_COMBINED_ANNOUNCEMENT` as const
export const DELETE_ANNOUNCEMENT = `${PREFIX}DELETE_ANNOUNCEMENT` as const

export const GET_ANNOUNCEMENT_REQUEST =
  `${PREFIX}GET_ANNOUNCEMENT_REQUEST` as const
export const GET_ANNOUNCEMENT_REQUEST_SUCCESS =
  `${PREFIX}GET_ANNOUNCEMENT_REQUEST_SUCCESS` as const
export const GET_ANNOUNCEMENT_REQUEST_ERROR =
  `${PREFIX}GET_ANNOUNCEMENT_REQUEST_ERROR` as const

export const DELETE_ANNOUNCEMENT_REQUEST =
  `${PREFIX}DELETE_ANNOUNCEMENT_REQUEST` as const
export const ADD_ANNOUNCEMENT_REQUEST =
  `${PREFIX}ADD_ANNOUNCEMENT_REQUEST` as const
export const UPDATE_ANNOUNCEMENT_REQUEST =
  `${PREFIX}UPDATE_ANNOUNCEMENT_REQUEST` as const

export const SAVE_ANNOUNCEMENT_REQUEST =
  `${PREFIX}SAVE_ANNOUNCEMENT_REQUEST` as const
export const SAVE_ANNOUNCEMENT_REQUEST_SUCCESS =
  `${PREFIX}SAVE_ANNOUNCEMENT_REQUEST_SUCCESS` as const
export const SAVE_ANNOUNCEMENT_REQUEST_ERROR =
  `${PREFIX}SAVE_ANNOUNCEMENT_REQUEST_ERROR` as const
