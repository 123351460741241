import React, { FC, ReactElement } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from '@mui/material'
import { CSSProperties } from '@mui/material/styles/createTypography'
import { CrossCircleIcon } from '../../../assets/icons'
import style from './style'

type ConfirmModalFormProps = {
  DialogContentChildren: ReactElement
  DialogActionsChildren: ReactElement
  onClose: () => void
  open: boolean
  title: string
  maxWidth?: string
  dialogContentStyle?: CSSProperties
}

const ConfirmModalWrapper: FC<ConfirmModalFormProps> = ({
  onClose,
  open,
  DialogContentChildren,
  DialogActionsChildren,
  title,
  maxWidth,
  dialogContentStyle,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      sx={style.wrapper(maxWidth)}
    >
      <DialogTitle sx={style.title}>
        {title}
        <IconButton onClick={onClose} sx={style.closeBtn}>
          <CrossCircleIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mb: '70px', ...(dialogContentStyle ?? {}) }}>
        {DialogContentChildren}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 3 }}>{DialogActionsChildren}</DialogActions>
    </Dialog>
  )
}

export default ConfirmModalWrapper
