import { Contact } from '../../../../../store/Contacts/types'
import {
  ADD_CONTACTS_TO_LIST,
  ADD_CONTACTS_LIST_ITEM,
  DELETE_CONTACTS_LIST_ITEM,
  ADD_MEMBERS_LIST_ITEM,
  DELETE_MEMBERS_LIST_ITEM,
} from './actionTypes'

import {
  AddContactsToList,
  AddContactsListItem,
  DeleteContactsListItem,
  AddMembersListItem,
  DeleteMembersListItem,
} from './types'

export const addContactsToList = (contacts: Contact[]): AddContactsToList => ({
  type: ADD_CONTACTS_TO_LIST,
  contacts,
})

export const addContactsListItem = (
  contactsListItem: Contact
): AddContactsListItem => ({
  type: ADD_CONTACTS_LIST_ITEM,
  contactsListItem,
})

export const deleteContactsListItem = (
  email: string
): DeleteContactsListItem => ({
  type: DELETE_CONTACTS_LIST_ITEM,
  email,
})

export const addMembersListItem = (
  membersListItem: Contact
): AddMembersListItem => ({
  type: ADD_MEMBERS_LIST_ITEM,
  membersListItem,
})

export const deleteMembersListItem = (
  email: string
): DeleteMembersListItem => ({
  type: DELETE_MEMBERS_LIST_ITEM,
  email,
})
