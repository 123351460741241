const style = {
  imageBox: {
    height: '48px',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  placeholderBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    height: '48px',
  },
  imagePlaceholder: {
    color: 'text.disabled',
    fontSize: '8px',
  },
  label: {
    mt: '16px',
    mb: '4px',
    color: '#517B9F',
    fontSize: '12px',
  },
  namePlaceholder: {
    width: '199px',
    height: '28px',
    borderRadius: '8px',
    backgroundColor: '#D3DEE7',
  },
  primaryPlaceholder: {
    width: '152px',
    height: '20px',
    borderRadius: '8px',
    backgroundColor: '#D3DEE7',
  },
  secondaryPlaceholder: {
    width: '131px',
    height: '20px',
    borderRadius: '8px',
    backgroundColor: '#D3DEE7',
  },
  companyTitle: {
    fontFamily: 'Avenir',
    fontWeight: '900',
    fontSize: '18px',
    color: '#163651',
    lineHeight: '26px',
  },
  title: {
    fontFamily: 'Avenir',
    fontWeight: '900',
    fontSize: '12px',
    color: '#163651',
  },
}

export default style
