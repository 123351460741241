const style = {
  item: (selected: boolean) => ({
    span: {
      color: 'text.primary',
      fontWeight: selected ? 800 : 500,
      fontSize: '14px',
      lineHeight: '20px',
    },
    height: '34px',
    p: 0,
    display: 'flex',
  }),

  text: {
    fontWeight: 900,
  },
  icon: {
    right: '4px !important',
    top: '0px !important',
    '> svg': {
      width: '10px',
      path: {
        stroke: '#1F1F1F',
      },
    },
  },
}

export default style
