import React, { FC, useEffect } from 'react'
import { Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { RequestStatuses } from '../../../api/constants'
import Loader from '../../../components/Loader'
import { setIsCompanyPanel } from '../../../store/Builder/actions'
import { selectRequestStatus } from '../../../store/Callsheet/selectors'
import { getContactsRequest } from '../../../store/Contacts/actions'
import {
  selectIsCompanyPanel,
  selectStep,
} from '../../../store/Builder/selectors'
import SidePanel from '../../../components/SidePanel'
import { STEPS } from '../constants'
import CompanyForm from './CompanyForm'
import LogisticsForm from './LogisticsForm'
import OverviewResult from './OverviewResult'
import CallsheetForm from './CallsheetForm'

const ProjectOverview: FC = () => {
  const step = useSelector(selectStep)
  const requestStatus = useSelector(selectRequestStatus)
  const isCompanyPanel = useSelector(selectIsCompanyPanel)

  const dispatch = useDispatch()

  const handleCompanyPanel = () => {
    dispatch(setIsCompanyPanel(false))
  }

  useEffect(() => {
    dispatch(getContactsRequest())
  }, [])

  return (
    <Box display='flex'>
      {requestStatus === RequestStatuses.PENDING ? (
        <Loader />
      ) : (
        <>
          {step === STEPS.overview ? <CallsheetForm /> : null}
          {step === STEPS.logistic ? <LogisticsForm /> : null}
          <OverviewResult />
        </>
      )}
      <SidePanel isOpen={isCompanyPanel} onClose={handleCompanyPanel}>
        <CompanyForm />
      </SidePanel>
    </Box>
  )
}

export default ProjectOverview
