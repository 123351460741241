import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import style from './style'

const Loader = () => {
  return (
    <Box sx={style.loader}>
      <CircularProgress />
    </Box>
  )
}

export default Loader
