import { AppState } from '../types'

export const selectCallsheetToDelete = (state: AppState) =>
  state.callsheetDeleteState.callsheetToDelete
export const selectDeleteCallsheetRequestStatus = (state: AppState) =>
  state.callsheetDeleteState.deleteCallsheetRequestStatus

export const selectIsDeleteCallsheetModal = (state: AppState): boolean =>
  state.callsheetDeleteState.deleteCallsheetModal

export const selectCallsheetToAction = (state: AppState): string =>
  state.callsheetDeleteState.callsheetToActionId

export const selectIsPermanentDelete = (state: AppState): boolean =>
  state.callsheetDeleteState.isPermanentDelete
