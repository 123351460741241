import { noop, toLower } from 'lodash'
import { useSelector } from 'react-redux'
import React, { ChangeEventHandler, FC, useEffect, useState } from 'react'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'

import { CallTime } from '../../../../../store/GroupMembers/types'
import { ScheduleEvent } from '../../../../../store/Events/types'
import { selectEvents } from '../../../../../store/Events/selectors'
import { validate } from '../../../../../utils/eventsHelpers'
import TimePickerDropdown from '../../../../../components/TimePickerDropdown'

import { TrashcanIcon } from '../../../../../assets/icons'
import { CALLTIME_PLACEHOLDER } from '../../../../../constants'

import style from './style'

const DEFAULT_EVENT = { startDate: '', name: '', endDate: '', tmpId: '' }

type AddEventFieldProps = {
  onSave: typeof noop
  onDelete: typeof noop
  defaultEvent?: ScheduleEvent
  isPreview?: boolean
}

const AddEventField: FC<AddEventFieldProps> = ({
  onSave,
  defaultEvent,
  onDelete,
  isPreview,
}) => {
  const events = useSelector(selectEvents)
  const [error, setError] = useState<boolean>(false)
  const [event, setEvent] = useState<ScheduleEvent>(
    defaultEvent ?? DEFAULT_EVENT
  )

  useEffect(() => {
    setEvent(defaultEvent ?? DEFAULT_EVENT)
  }, [defaultEvent])

  const handleEventTime =
    (time: CallTime): ChangeEventHandler<HTMLTextAreaElement> =>
    ({ target: { value } }) => {
      if (time === CallTime.START_TIME) {
        setEvent(prev => ({ ...prev, startDate: value }))
        return
      }
      setEvent(prev => ({ ...prev, endDate: value }))
    }

  const handleEventTitleChange: ChangeEventHandler<HTMLTextAreaElement> = ({
    target: { value },
  }) => {
    if (error) {
      setError(false)
    }
    setEvent(prev => ({ ...prev, name: value }))
  }

  const handleSave = () => {
    if (validate(event, events)) {
      setError(true)
      return
    }
    onSave(event.name, event.startDate, event.endDate)
  }

  const isDisabled = !event.startDate || !event.endDate || !event.name || error
  return (
    <Box sx={style.wrapper(isPreview)}>
      <Stack gap={0}>
        <Box display='flex' justifyContent='space-between'>
          <Box sx={style.timeWrapper}>
            <TimePickerDropdown
              placeholder={CALLTIME_PLACEHOLDER}
              value={toLower(event.startDate)}
              endLimit={
                event.endDate === '' ? '11:45pm' : toLower(event.endDate)
              }
              onChange={handleEventTime(CallTime.START_TIME)}
            />
            <Typography variant='body2'>To</Typography>
            <TimePickerDropdown
              placeholder={CALLTIME_PLACEHOLDER}
              value={toLower(event.endDate)}
              beginLimit={
                event.startDate === '' ? '12:00am' : toLower(event.startDate)
              }
              onChange={handleEventTime(CallTime.WRAP_TIME)}
            />
          </Box>
          <Box sx={style.btnWrapper}>
            <TrashcanIcon onClick={onDelete} />
            <Button
              variant='contained'
              disabled={isDisabled}
              onClick={handleSave}
              sx={style.saveBtn}
            >
              Save event
            </Button>
          </Box>
        </Box>
        <TextField
          value={event.name}
          onChange={handleEventTitleChange}
          error={error}
          helperText={error ? 'Already had an event with this name' : ''}
        />
      </Stack>
    </Box>
  )
}

export default AddEventField
