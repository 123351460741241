import React, { FC } from 'react'
import MainRouter from './router/MainRouter'

import './App.css'

const App: FC = () => (
  <div className='app'>
    <MainRouter />
  </div>
)

export default App
