import React, { FC } from 'react'
import { noop } from 'lodash'
import { Box } from '@mui/material'
import { EditText } from 'react-edit-text'
import { EditIcon } from '../../assets/icons'

import style from './style'

type EditButtonType = {
  onSave: typeof noop
  placeholder?: string
  defaultValue?: string
  error: boolean
}

const EditButton: FC<EditButtonType> = ({
  onSave,
  defaultValue = '',
  placeholder = 'Enter the title',
  error,
}) => {
  return (
    <Box
      display='flex'
      sx={{ ...style.container, color: error ? 'error.main' : 'text.primary' }}
    >
      <EditText
        showEditButton
        onSave={onSave}
        placeholder={placeholder}
        defaultValue={defaultValue}
        editButtonContent={<EditIcon />}
      />
    </Box>
  )
}

export default EditButton
