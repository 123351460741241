import { SampleError } from 'src/api/types'
import {
  ADD_EVENT,
  DELETE_EVENT,
  GET_EVENTS_REQUEST,
  GET_EVENTS_REQUEST_ERROR,
  GET_EVENTS_REQUEST_SUCCESS,
  SAVE_EVENTS_REQUEST,
  SAVE_EVENTS_REQUEST_ERROR,
  SAVE_EVENTS_REQUEST_SUCCESS,
  UPDATE_EVENT,
} from './actionTypes'
import {
  AddEvent,
  DeleteEvent,
  GetEventsRequestErrorType,
  GetEventsRequestSuccessType,
  GetEventsRequestType,
  SaveEventsRequestErrorType,
  SaveEventsRequestSuccessType,
  SaveEventsRequestType,
  ScheduleEvent,
  UpdateEvent,
} from './types'

export const getEventsRequest = (): GetEventsRequestType => ({
  type: GET_EVENTS_REQUEST,
})

export const getEventsSuccessRequest = (
  events: ScheduleEvent[]
): GetEventsRequestSuccessType => ({
  type: GET_EVENTS_REQUEST_SUCCESS,
  events,
})

export const getEventsErrorRequest = (
  error: SampleError | null
): GetEventsRequestErrorType => ({
  type: GET_EVENTS_REQUEST_ERROR,
  error,
})

export const addEvent = (id: string, event: ScheduleEvent): AddEvent => ({
  type: ADD_EVENT,
  id,
  event,
})

export const updateEvent = (id: string, event: ScheduleEvent): UpdateEvent => ({
  type: UPDATE_EVENT,
  id,
  event,
})

export const deleteEvent = (id: string): DeleteEvent => ({
  type: DELETE_EVENT,
  id,
})

export const saveEventsRequest = (): SaveEventsRequestType => ({
  type: SAVE_EVENTS_REQUEST,
})

export const saveEventsRequestSuccess = (): SaveEventsRequestSuccessType => ({
  type: SAVE_EVENTS_REQUEST_SUCCESS,
})

export const saveEventsRequestError = (
  error: SampleError
): SaveEventsRequestErrorType => ({
  type: SAVE_EVENTS_REQUEST_ERROR,
  error,
})
