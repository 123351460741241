import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { CallsheetStatuses } from '../../store/Callsheet/types'
import {
  acceptConfirmationRequest,
  declineConfirmationRequest,
  setConfirmationStep,
  tentativeConfimationRequest,
} from '../../store/CallsheetStatus/actions'
import { STEPS } from './constants'

const useCallsheetConfirmation = () => {
  const dispatch = useDispatch()
  const [statusChangeType, setStatusChangeType] = useState<
    CallsheetStatuses.TENTATIVE | CallsheetStatuses.ACCEPTED
  >(CallsheetStatuses.ACCEPTED)
  const onDecline = () => {
    dispatch(declineConfirmationRequest())
  }

  const onTentative = () => {
    setStatusChangeType(CallsheetStatuses.TENTATIVE)
    dispatch(setConfirmationStep(STEPS.announcements))
  }

  const onAccept = () => {
    setStatusChangeType(CallsheetStatuses.ACCEPTED)
    dispatch(setConfirmationStep(STEPS.announcements))
  }

  const onSubmit = () => {
    if (statusChangeType === CallsheetStatuses.TENTATIVE) {
      dispatch(tentativeConfimationRequest())
    } else {
      dispatch(acceptConfirmationRequest())
    }
  }
  return { onAccept, onTentative, onDecline, onSubmit }
}

export default useCallsheetConfirmation
