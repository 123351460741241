import { CallsheetStatuses } from 'src/store/Callsheet/types'
import { COLOR_ERROR_MAIN, COLOR_TEXT_SECONDARY } from '../../themes/constants'

const style = {
  wrapper: {
    px: 3,
    pb: 3,
  },
  container: {
    maxWidth: '1350px',
    m: '0 auto',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '64px',
    mb: 2,
    borderBottom: '1px solid rgba(31, 31, 31, 0.08)',
  },
  title: {
    fontSize: '28px',
    fontWeight: 500,
    lineHeight: '36px',
    mb: 1,
  },
  btnWithIcon: {
    svg: {
      path: { stroke: '#265A88' },
      ml: 0,
      mb: '4px',
    },
  },
  actionsBox: {
    display: 'flex',
    gap: 2,
  },
  finalTouchesBox: {
    width: '100%',
  },
  notesItem: {
    p: 1,
  },
  annoItem: (important?: boolean) => ({
    p: 1,
    mb: '12px',
    color: important ? COLOR_ERROR_MAIN : COLOR_TEXT_SECONDARY,
    border: important ? `1px solid ${COLOR_ERROR_MAIN}` : '1px solid #E2E2E2',
  }),
  channelItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: 0,
    p: 1,
  },
  attachmentItem: {
    display: 'flex',
    mb: 0,
    p: 0,
  },

  statusBtn: (status: string) => ({
    px: '60px',
    fontWeight: 900,
    lineHeight: '20px',
    color: 'white',

    ...(status === CallsheetStatuses.DECLINED && {
      fontWeight: 500,
      backgroundColor: 'white',
      color: '#CC2E26',
      border: '1px solid #CC2E26',
      boxShadow: 'none',
      ':hover': {
        backgroundColor: 'white',
      },
      svg: {
        alignSelf: 'center',
        path: {
          stroke: '#CC2E26',
        },
      },
    }),

    ...(status === CallsheetStatuses.TENTATIVE && {
      fontWeight: 500,
      backgroundColor: 'white',
      color: '#FA6400',
      border: '1px solid #FA6400',
      boxShadow: 'none',
      ':hover': {
        backgroundColor: 'white',
      },
    }),

    ...(status === CallsheetStatuses.ACCEPTED && {
      backgroundColor: '#2C9A5A',

      ':hover': {
        backgroundColor: '#2C9A5A',
      },
    }),
  }),
}

export default style
