import React, { FC, useEffect } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import GroupContactItem from '../../pages/BuilderPage/ManageUserGroups/AddGroupMemberForm/GroupContactItem'
import { Callsheet, CallsheetStatuses } from '../../store/Callsheet/types'
import { setCallsheetStatisticModal } from '../../store/Gallery/actions'
import ControlButtons from '../ControlButtons'
import HeadText from '../HeadText'
import StatisticSection from './StatisticSection'
import { getMembersRequest } from '../../store/GroupMembers/actions'

import style from './style'
import { selectGroupMembers } from '../../store/GroupMembers/selectors'

type CallsheetStatisticType = {
  callsheet?: Callsheet
}
const CallsheetStatistic: FC<CallsheetStatisticType> = ({ callsheet }) => {
  const dispatch = useDispatch()
  const members = useSelector(selectGroupMembers)
  const { title, id } = callsheet ?? {}

  const accepted = members.filter(
    member => member.status === CallsheetStatuses.ACCEPTED
  )
  const declined = members.filter(
    member => member.status === CallsheetStatuses.DECLINED
  )
  const tentative = members.filter(
    member => member.status === CallsheetStatuses.TENTATIVE
  )
  const pending = members.filter(
    member => member.status === CallsheetStatuses.PENDING
  )

  useEffect(() => {
    if (id) {
      dispatch(getMembersRequest(id))
    }
  }, [id])

  return (
    <>
      <Stack sx={style.wrapper}>
        <Box sx={style.content}>
          <HeadText
            title={`${title} Statistics` || ''}
            subtitle='Here you can see a statistic about this CallSheet'
          />
          <StatisticSection id={id} />
          <Box sx={style.membersContainer}>
            {accepted.length ? (
              <>
                <Typography variant='h5' mb='12px'>
                  Accepted Users
                </Typography>
                {accepted.map(member => (
                  <GroupContactItem
                    key={member.id}
                    contact={{ ...member, isFriend: false }}
                    isStatistic
                  />
                ))}
              </>
            ) : null}
            {declined.length ? (
              <Box sx={{ mt: 2 }}>
                <Typography variant='h5' mb='12px'>
                  Declined Users
                </Typography>
                {declined.map(member => (
                  <GroupContactItem
                    key={member.id}
                    contact={{ ...member, isFriend: false }}
                    isStatistic
                  />
                ))}
              </Box>
            ) : null}
            {tentative.length ? (
              <Box sx={{ mt: 2 }}>
                <Typography variant='h5' mb='12px'>
                  Marked Tentative Users
                </Typography>
                {tentative.map(member => (
                  <GroupContactItem
                    key={member.id}
                    contact={{ ...member, isFriend: false }}
                    isStatistic
                  />
                ))}
              </Box>
            ) : null}
            {pending.length ? (
              <Box sx={{ mt: 2 }}>
                <Typography variant='h5' mb='12px'>
                  No Response
                </Typography>
                {pending.map(member => (
                  <GroupContactItem
                    key={member.id}
                    contact={{ ...member, isFriend: false }}
                    isStatistic
                  />
                ))}
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box sx={style.btnContainer}>
          <ControlButtons
            cancelTitle='Close'
            handleCancelBtn={() => dispatch(setCallsheetStatisticModal())}
          />
        </Box>
      </Stack>
    </>
  )
}

export default CallsheetStatistic
