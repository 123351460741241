import React, { FC, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Button, Chip, Divider, Paper, Typography } from '@mui/material'

import CustomScroll from '../../../../components/CustomScroll'
import RecipientsBox from './RecipientsBox/RecipientsBox'

import { selectGroupMembers } from '../../../../store/GroupMembers/selectors'
import { setStep } from '../../../../store/Builder/actions'

import { STEPS } from '../../constants'

import style from './style'

const MAX_CONTAINER_HEIGHT = 350

const RecipientsList: FC = () => {
  const dispatch = useDispatch()
  const recipients = useSelector(selectGroupMembers)

  const missingPhoneRecipients = recipients.filter(item => !item.phone)
  const allOtherRecipients = recipients.filter(item => item.phone)

  const isOverFlow =
    (recipients.length + missingPhoneRecipients.length) * 58 >=
    MAX_CONTAINER_HEIGHT

  const handleEdit = () => {
    dispatch(setStep(STEPS.userGroups))
  }

  const recipientsBox = useMemo(() => {
    return () => (
      <>
        {!!missingPhoneRecipients.length && (
          <>
            <Typography variant='h5' color='#CC2E26' mb='10px'>
              Missing Phone Number
            </Typography>
            <RecipientsBox recipients={missingPhoneRecipients} isMissingPhone />
            {allOtherRecipients.length > 0 && (
              <Typography variant='h5' color='primary.text' mb='10px' mt='16px'>
                All Other Recipients
              </Typography>
            )}
          </>
        )}
        <RecipientsBox recipients={allOtherRecipients} />
      </>
    )
  }, [recipients])

  return (
    <Paper variant='outlined' sx={style.wrapper}>
      <Box sx={style.header}>
        <Box display='flex' gap='12px' alignItems='center'>
          <Typography sx={style.title}>Recipients</Typography>
          <Chip label={recipients.length} sx={style.pill} />
        </Box>
        <Button variant='outlined' sx={style.editBtn} onClick={handleEdit}>
          Edit Recipients
        </Button>
      </Box>
      <Divider />
      {isOverFlow ? (
        <CustomScroll height={MAX_CONTAINER_HEIGHT}>
          {recipientsBox()}
        </CustomScroll>
      ) : (
        recipientsBox()
      )}
    </Paper>
  )
}

export default RecipientsList
