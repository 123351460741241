export interface ParsedAddressComponents {
  city: string
  state: string
  zip: string
  country: string
}

export const parseAddressComponents = (
  addressComponents: google.maps.GeocoderAddressComponent[] | undefined
): ParsedAddressComponents => {
  if (addressComponents) {
    return addressComponents.reduce(
      (result, component) => {
        if (component.types.includes('country')) {
          result.country = component.long_name
        }
        if (component.types.includes('administrative_area_level_1')) {
          result.state = component.long_name
        }
        if (component.types.includes('locality')) {
          result.city = component.long_name
        }
        if (component.types.includes('postal_code')) {
          result.zip = component.long_name
        }
        return result
      },
      { city: '', state: '', zip: '', country: '' }
    )
  }
  return { city: '', state: '', zip: '', country: '' }
}

export const returnSubstringBeforeComa = (substring: string): string =>
  substring.slice(0, substring.indexOf(','))

export const getLocationName = (
  city: string,
  state: string,
  zip: string
): string => {
  if (!city && !state && !zip) {
    return 'No location'
  }

  return [city, state, zip].filter(Boolean).join(', ')
}
