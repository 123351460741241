const style = {
  wrapper: {
    borderRadius: '4px',
  },
  icons: (isStackDisplay: boolean) => ({
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    gap: 0,
    justifyContent: 'space-between',
    '> svg': {
      cursor: 'pointer',
    },
    '> svg:first-of-type': {
      '> path': {
        stroke: isStackDisplay ? '#265A88' : '#1F1F1F',
      },
    },
    '> svg:last-of-type': {
      '> path': {
        stroke: !isStackDisplay ? '#265A88' : '#1F1F1F',
      },
    },
  }),
}

export default style
