const PREFIX = 'WALKIE_CHANNELS/'

export const ADD_CHANNEL = `${PREFIX}ADD_CHANNEL` as const
export const DELETE_CHANNEL = `${PREFIX}DELETE_CHANNEL` as const
export const UPDATE_CHANNEL = `${PREFIX}UPDATE_CHANNEL` as const

export const GET_CHANNEL_REQUEST = `${PREFIX}GET_CHANNEL_REQUEST` as const
export const GET_CHANNEL_REQUEST_SUCCESS =
  `${PREFIX} GET_CHANNEL_REQUEST_SUCCESS` as const
export const GET_CHANNEL_REQUEST_ERROR =
  `${PREFIX}GET_CHANNEL_REQUEST_ERROR` as const

export const ADD_CHANNEL_REQUEST = `${PREFIX}ADD_CHANNEL_REQUEST` as const
export const DELETE_CHANNEL_REQUEST = `${PREFIX}DELETE_CHANNEL_REQUEST` as const
export const UPDATE_CHANNEL_REQUEST = `${PREFIX}UPDATE_CHANNEL_REQUEST` as const

export const SAVE_CHANNEL_REQUEST = `${PREFIX}SAVE_CHANNEL_REQUEST` as const
export const SAVE_CHANNEL_REQUEST_SUCCESS =
  `${PREFIX} SAVE_CHANNEL_REQUEST_SUCCESS` as const
export const SAVE_CHANNEL_REQUEST_ERROR =
  `${PREFIX}SAVE_CHANNEL_REQUEST_ERROR` as const
