const style = {
  wrapper: {
    width: '565px',
    minHeight: '76px',
    padding: 3,
    boxShadow: '0px 0px 16px 0px #85858526',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '14px',
    svg: {
      path: {
        stroke: '#AFAFAF',
      },
    },
  },
  title: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '28px',
  },
}

export default style
