import {
  CallsheetStatuses,
  CallsheetStatusType,
} from '../../store/Callsheet/types'

const style = {
  card: (status: CallsheetStatusType, isClickable: boolean) => ({
    width: 270,
    height: 190,
    border: '1px solid #E2E2E2',
    position: 'relative',
    borderRadius: '8px',
    boxShadow: '0px 5px 8px rgba(133, 133, 133, 0.1)',
    opacity: status === CallsheetStatuses.DECLINED ? 0.5 : 1,
    mr: 3,
    mb: 3,
    ...(isClickable && { cursor: 'pointer' }),
  }),
  optionsBtn: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    zIndex: 100,
  },
  body: {
    display: 'flex',
    padding: '4px 12px 12px 12px',
  },
  author: {
    pl: 1,
    fontSize: '10px',
    fontWeight: 900,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  footer: {
    padding: '12px',
  },
}

export default style
