import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { updateCallsheetOverviewRequest } from 'src/store/Callsheet/actions'
import { Callsheet } from 'src/store/Callsheet/types'
import {
  deleteCallsheetRequest,
  updateCallsheetEventDateRequest,
} from '../../store/CallsheetDeleteState/actions'

import {
  btnTitles,
  DeleteCallsheetFlow,
  DeleteCallsheetFlowType,
} from './constants'

const useDeleteFlow = (
  date: DateConstructor | null,
  name: string,
  time: string,
  id?: string,
  callsheet?: Callsheet
) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [option, setOption] = useState<DeleteCallsheetFlowType | undefined>()

  const deleteActions = {
    [DeleteCallsheetFlow.CONFIRM]: () =>
      date &&
      callsheet &&
      dispatch(
        updateCallsheetEventDateRequest({
          id,
          eventDate: date,
          status: callsheet.status,
        })
      ),
    [DeleteCallsheetFlow.CONTINUE_TO_BUILDER]: () =>
      id && dispatch(updateCallsheetOverviewRequest(id, date, time, history)),
    [DeleteCallsheetFlow.DELETE_CALLSHEET]: () =>
      id && dispatch(deleteCallsheetRequest(id)),
  }

  const btnTitle = useMemo(() => {
    if (option) {
      return btnTitles[option]
    }
    return btnTitles[DeleteCallsheetFlow.CONFIRM]
  }, [time, date, name, option])

  const onConfirmBtn = () => {
    if (id && option) {
      return deleteActions[option]()
    }
  }

  const onSelect = (optionType: DeleteCallsheetFlowType) => () => {
    if (optionType === DeleteCallsheetFlow.CONFIRM && time) {
      setOption(DeleteCallsheetFlow.CONTINUE_TO_BUILDER)
      return
    }
    setOption(optionType)
  }

  return {
    onSelect,
    onConfirmBtn,
    btnTitle,
    option,
    setOption,
  }
}

export default useDeleteFlow
