export const GOOGLE_API_PLACES_URL = `https://maps.googleapis.com/maps/api/place/details/json`

export enum METHODS {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export enum RequestStatuses {
  UNCALLED = 'UNCALLED',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  SUCCEEDED = 'SUCCEEDED',
}

export const URLS = {
  SIGN_IN: '/user/mock',
  USER: '/user',
  CALLSHEET: '/callsheet',
  MEMBER_TITLES: '/callsheet/member/title',
  COMPANY: '/company',
  COMPANY_MEMBER: '/company/member',
  USER_GROUP: '/callsheet/user-group',
  NETWORK: '/network',
  CONTACT: '/contact',
  TAGS: '/tags',
}
