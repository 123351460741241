import React, { FC } from 'react'
import { Backdrop, Box, Card, Divider, Typography } from '@mui/material'
import { useToggle } from '../../../../../hooks/useToggle'
import { addBase64Data } from '../../../../../utils/image'

import { MapPinIcon } from '../../../../../assets/icons'
import style from './style'

type AddressCardType = {
  name?: string
  title?: string
  address?: string
  description?: string
  image?: string
  onAddressClick?: () => void
  isPreview?: boolean
  openSitemap?: boolean
}

const AddressCard: FC<AddressCardType> = ({
  name,
  title,
  address,
  description,
  image,
  isPreview,
  onAddressClick,
  openSitemap = false,
}) => {
  const [isSitemap, setIsSitemap] = useToggle()
  const handleOpenSitemap = () => {
    if (openSitemap) {
      setIsSitemap()
    }
  }
  return (
    <Card sx={style.addressBox(isPreview)}>
      <Box sx={style.header}>
        {title ? (
          <Typography variant='subtitle1'>{title}</Typography>
        ) : (
          <Box sx={style.titlePlaceholder} />
        )}
        <Box display='flex' alignItems='center'>
          {image ? (
            <Typography
              variant='body2'
              color='primary.main'
              sx={{ mr: 0, ...(openSitemap && { cursor: 'pointer' }) }}
              onClick={handleOpenSitemap}
            >
              Sitemap
            </Typography>
          ) : null}
          <MapPinIcon />
        </Box>
      </Box>
      <Divider sx={{ my: '12px' }} />
      <Box
        sx={onAddressClick && { cursor: 'pointer' }}
        onClick={onAddressClick}
      >
        <Typography sx={style.label}>Address:</Typography>
        {address ? (
          <Box>
            <Typography variant='body2' component='div' sx={style.address}>
              {name}
            </Typography>
            <Typography component='div' sx={style.address}>
              {address}
            </Typography>
          </Box>
        ) : (
          <Box>
            <Box sx={style.addressPlaceholder} />
            <Box sx={style.addressPlaceholder} />
          </Box>
        )}
      </Box>
      <Box sx={{ mt: '14px' }}>
        <Typography sx={style.label}>Description:</Typography>
        {description ? (
          <Typography component='div' sx={style.description}>
            {description}
          </Typography>
        ) : (
          <Box sx={style.addressPlaceholder} />
        )}
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={isSitemap}
        onClick={setIsSitemap}
      >
        <img src={addBase64Data(image)} alt='sitemap' />
      </Backdrop>
    </Card>
  )
}

export default AddressCard
