const style = {
  wrapper: {
    textAlign: 'center',

    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        maxWidth: '549px',
        p: 3,
      },
    },
  },
  title: {
    fontWeight: 900,
    fontSize: '18px',
    lineHeight: '28px',
    color: 'text.primary',
    p: 3,
  },
  contentText: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: 'text.secondary',
    mt: '20px',
  },
  actionBox: { margin: '0 auto', width: '100%', maxWidth: '357px', mt: 3 },
}
export default style
