import { useSelector, useDispatch } from 'react-redux'
import React, { FC } from 'react'
import moment, { MomentInput } from 'moment'
import { Box, Divider, Stack, Typography } from '@mui/material'

import { selectIsConfirmDeleteContactModal } from '../../../store/Builder/selectors'
import {
  setIsAddContactPanel,
  setIsConfirmDeleteContactModal,
} from '../../../store/Builder/actions'
import {
  deleteContactRequest,
  setEditContact,
} from '../../../store/Contacts/actions'

import ContactDetailsFormWrapper from '../../../components/ContactDetailsForm/ContactDetailsFormWrapper'
import AvatarHeader from '../../../components/ContactDetailsForm/AvatarHeader'
import ConfirmDialog from '../../BuilderPage/ManageUserGroups/ConfirmDialog'
import SocialLink from '../../../components/SocialLink'
import { Contact } from '../../../store/Contacts/types'

import {
  extractInstagramNickname,
  extractUnsplashNickname,
} from '../../../utils/extractNickname'
import { InstagramIcon, UnsplashIcon, WebsiteIcon } from '../../../assets/icons'

import style from './style'

export const InfoBox = ({ label, value }: { label: string; value: string }) => (
  <Box>
    <Typography variant='subtitle2' color='text.primary'>
      {label}
    </Typography>
    <Typography color='text.primary' mt='4px'>
      {value}
    </Typography>
  </Box>
)

const ContactDetails: FC<{
  selectedContact: Contact
  onClose: () => void
  logoSrc: string
}> = ({ selectedContact, onClose, logoSrc }) => {
  const dispatch = useDispatch()
  const isConfirmDeleteContactModal = useSelector(
    selectIsConfirmDeleteContactModal
  )
  const {
    id,
    middleName,
    title,
    email,
    phone,
    firstName,
    lastName,
    instagramLink,
    unsplashLink,
    website2,
    website,
    websiteAlias2,
    websiteAlias,
    notes,
    createdAt,
  } = selectedContact

  const handleDetailsClose = () => {
    onClose()
  }

  const handleEdit = () => {
    dispatch(setEditContact(selectedContact))
    dispatch(setIsAddContactPanel(true))
  }

  const handleDeleteContact = () => {
    dispatch(deleteContactRequest(id))
  }

  const isLinks = instagramLink || unsplashLink || website || website2

  return (
    <>
      <ContactDetailsFormWrapper
        onBackClick={handleDetailsClose}
        onConfirm={handleDetailsClose}
        backBtnTitle='Back to Contacts'
        editButtonProps={{
          onEdit: handleEdit,
          onDelete: () => dispatch(setIsConfirmDeleteContactModal(true)),
        }}
      >
        <AvatarHeader
          bio={notes}
          initialsProps={style.initials}
          titleProps={style.title}
          firstName={firstName}
          middleName={middleName}
          lastName={lastName}
          title={title}
          logo={logoSrc}
        />
        <Divider />
        <Box sx={{ py: 3 }}>
          <Typography variant='h5' color='text.primary'>
            Information
          </Typography>
          <Stack spacing={1} mt={0}>
            <InfoBox label='Emails' value={email} />
            {phone && <InfoBox label='Phone number' value={phone} />}
          </Stack>
        </Box>
        <Divider />
        {isLinks && (
          <Stack sx={{ pt: 3 }} gap={0}>
            <Typography variant='h5' color='text.primary'>
              Links
            </Typography>
            {instagramLink && (
              <SocialLink link={instagramLink} type='website' fullwidth>
                <InstagramIcon />
                {extractInstagramNickname(instagramLink)}
              </SocialLink>
            )}
            {unsplashLink && (
              <SocialLink link={unsplashLink} type='website' fullwidth>
                <UnsplashIcon />
                {extractUnsplashNickname(unsplashLink)}
              </SocialLink>
            )}
            {website && (
              <SocialLink link={website} type='website' fullwidth>
                <WebsiteIcon />
                {websiteAlias ?? website}
              </SocialLink>
            )}
            {website2 && (
              <SocialLink link={website2} type='website' fullwidth>
                <WebsiteIcon />
                {websiteAlias2 ?? website2}
              </SocialLink>
            )}
          </Stack>
        )}
        <Typography
          textAlign='center'
          mt={3}
          color='text.disabled'
        >{`Created at ${moment(createdAt as MomentInput).format(
          'MMMM D, YYYY'
        )}`}</Typography>
      </ContactDetailsFormWrapper>
      <ConfirmDialog
        confirmTitle='Delete'
        isOpen={isConfirmDeleteContactModal}
        handleCancel={() => dispatch(setIsConfirmDeleteContactModal(false))}
        handleConfirm={handleDeleteContact}
        title='Delete Contact'
        content='Are you sure you want to delete this contact?'
      />
    </>
  )
}

export default ContactDetails
