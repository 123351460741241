export const gridsLong = [
  { previewXs: 2.6, xs: 2.35, label: 'Name and Position' },
  { previewXs: 2.05, xs: 1.9, label: 'Phone Number' },
  { previewXs: 2.05, xs: 1.9, label: 'Email' },
  { previewXs: 2.1, xs: 1.9, label: 'Call Time' },
  { previewXs: 2, xs: 1.85, label: 'Wrap Time' },
  { previewXs: 1, xs: 1, label: 'Notes' },
]
export const gridsShort = [
  { previewXs: 4, xs: 4, label: 'Name and Position' },
  { previewXs: 3, xs: 3, label: 'Email' },
  { previewXs: 2.75, xs: 2.75, label: 'Call Time' },
  { previewXs: 2.25, xs: 2.25, label: 'Wrap Time' },
]
