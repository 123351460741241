import { Chip, Stack, Typography } from '@mui/material'
import React, { FC } from 'react'
import {
  CalendarIcon,
  ClockIcon,
  UsersIcon,
  LinkIcon,
} from '../../../assets/icons'

import style from './style'

const dataBoxes = {
  dateTime: {
    label: 'Date & Time:',
    chipColor: 'success.main',
    backgroundColor: '#D2F3DF',
    firstIcon: <CalendarIcon />,
    secondIcon: <ClockIcon />,
  },
  callSheet: {
    label: 'Call Sheet',
    chipColor: 'primary.main',
    backgroundColor: '#ECF6FF',
    firstIcon: <UsersIcon />,
    secondIcon: <LinkIcon />,
  },
}

const DataBox: FC<{
  variant: 'callSheet' | 'dateTime'
  firstText: string
  secondText: string
  link?: boolean
}> = ({ variant, firstText, secondText, link = false }) => {
  const { chipColor, backgroundColor, firstIcon, secondIcon, label } =
    dataBoxes[variant]
  return (
    <Stack sx={style.dataBox(backgroundColor, link)}>
      <Chip label={label} sx={style.chip(chipColor)} />
      <Typography variant='subtitle2' color='text.primary'>
        {firstIcon}
        {firstText}
      </Typography>
      <Typography
        variant='subtitle2'
        color={link ? '#265A88' : 'text.primary'}
        sx={{ textDecoration: link ? 'underline' : 'none' }}
      >
        {secondIcon}
        {secondText}
      </Typography>
    </Stack>
  )
}

export default DataBox
