import React, { FC, FunctionComponent, SVGProps } from 'react'
import { noop } from 'lodash'
import { Box, Paper, Typography } from '@mui/material'
import {
  CheckCircleGreenContainedIcon,
  CheckCircleIcon,
} from '../../assets/icons'
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox'

import style from './style'

type CheckboxPanelType = {
  id: string
  title: string
  description: string
  onChange?: typeof noop
  checked: boolean
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>
}

const CheckboxPanel: FC<CheckboxPanelType> = ({
  id,
  title,
  description,
  onChange = noop,
  checked,
  Icon,
}) => {
  return (
    <Paper
      sx={{
        ...style.wrapper,
        borderColor: checked ? 'success.main' : '#E2E2E2',
      }}
      variant='outlined'
    >
      <label htmlFor={id} style={style.label}>
        <Box display='flex' sx={style.content}>
          {Icon ? (
            <Box sx={style.iconContent}>
              <Icon />
            </Box>
          ) : null}
          <Box sx={style.textContent}>
            <Typography variant='subtitle2' sx={{ mb: '4px' }}>
              {title}
            </Typography>
            <Typography color='text.secondary'>{description}</Typography>
          </Box>
          <Box display='flex' alignItems='center' sx={{ width: '40px' }}>
            <CustomCheckbox
              id={id}
              onChange={onChange}
              icon={<CheckCircleIcon />}
              checkedIcon={<CheckCircleGreenContainedIcon />}
              checked={checked}
            />
          </Box>
        </Box>
      </label>
    </Paper>
  )
}

export default CheckboxPanel
