import { put } from 'redux-saga/effects'
import { ERROR_MESSAGES } from 'src/constants'
import { getResponseErrorCode, getResponseErrorMessage } from 'src/utils/api'
import { SampleError } from '../../api/types'
import { setNotification } from './actions'

export function* setNotificationErrorWorker(error: SampleError) {
  const statusCode = getResponseErrorCode(error) || 0
  yield put(
    setNotification({
      message:
        getResponseErrorMessage(error) ||
        error?.message ||
        ERROR_MESSAGES.DEFAULT,
      statusCode,
    })
  )
}
