import React, { FC } from 'react'
import { noop } from 'lodash'
import { Typography } from '@mui/material'
import { TrashcanIcon } from '../../../../../assets/icons'
import ContactPanel from '../../../../../components/ContactPanel'

type SelectedMemberTypes = {
  name: string
  logo?: string
  title?: string
  handleDeleteMember: typeof noop
}

const MemberItem: FC<SelectedMemberTypes> = ({
  name,
  logo,
  title,
  handleDeleteMember,
}) => {
  return (
    <ContactPanel
      logo={logo}
      name={name}
      btnProps={{
        title: 'Remove',
        color: 'error.main',
        Icon: TrashcanIcon,
        onBtnClick: handleDeleteMember,
      }}
    >
      <Typography variant='body1' color='text.disabled'>
        {title}
      </Typography>
    </ContactPanel>
  )
}

export default MemberItem
