const style = {
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 270,
    height: 190,
    padding: '12px',
    border: '1px solid #E2E2E2',
    cursor: 'pointer',
    borderRadius: '8px',
    boxShadow: '0px 5px 8px rgba(133, 133, 133, 0.1)',
    mr: 3,
    mb: 3,
    svg: {
      path: {
        stroke: '#545454',
      },
    },
  },
  title: {
    fontSize: '16px',
    fontWeight: 900,
    mb: '12px',
    color: 'text.secondary',
  },
}

export default style
