import { Chip } from '@mui/material'
import { lowerCase } from 'lodash'
import React, { FC } from 'react'
import { LiveIcon } from 'src/assets/icons'
import { CallsheetStatusType } from '../../../store/Callsheet/types'

import style from './style'

const icons: {
  [key: string]: React.ReactElement
} = {
  LIVE: <LiveIcon />,
}

type StatusChipProps = {
  status: CallsheetStatusType
  size?: 'medium' | 'small'
}

const StatusChip: FC<StatusChipProps> = ({ status, size = 'small' }) => {
  return (
    <Chip
      icon={icons[status]}
      variant='outlined'
      label={lowerCase(status)}
      size={size}
      sx={style.status(status)}
    />
  )
}

export default StatusChip
