import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import useCallsheetStatistic from '../../../../hooks/callsheet/useCallsheetStatistic'

import style from './style'

const CallsheetCompletedFooter: FC<{ id: string }> = ({ id }) => {
  const { total = 0, viewed = 0 } = useCallsheetStatistic(id) ?? {}
  return (
    <Box sx={style.content}>
      <Box sx={style.statisticsItem}>
        <Typography sx={style.title}>Attended:</Typography>
        <Box sx={style.numBox}>
          <Box sx={style.attended}>{`${viewed}/${total}`}</Box>
        </Box>
      </Box>
      <Box sx={style.statisticsItem}>
        <Typography sx={style.title}>views:</Typography>
        <Box sx={style.numBox}>
          <Box sx={style.views}>{viewed}</Box>
        </Box>
      </Box>
    </Box>
  )
}

export default CallsheetCompletedFooter
