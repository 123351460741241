const style = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '16px',
    padding: '12px',
    border: '1px dashed #AFAFAF',
    height: '80px',
  },
  buttonTitle: {
    maxHeight: '42px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  button: {
    maxWidth: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '20px',
    textAlign: 'left',
  },
}

export default style
