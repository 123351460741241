import React from 'react'
import { Box, Typography } from '@mui/material'

import style from './style'

const CallsheetPendingFooter = () => {
  return (
    <Box sx={style.content}>
      <Typography sx={style.title}>Callsheet</Typography>
      <Typography sx={style.subtitle}>View Invitation</Typography>
    </Box>
  )
}

export default CallsheetPendingFooter
