import { noop } from 'lodash'
import React, { FC } from 'react'

import { Grid, TextField } from '@mui/material'

import { WalkieChannel } from '../../../../../store/WalkieChannels/types'

import { TrashcanIcon } from '../../../../../assets/icons'

const Channel: FC<
  WalkieChannel & {
    error?: boolean
    onDelete: typeof noop
    onChange: (valueType: keyof WalkieChannel, value: string) => void
  }
> = ({ name, channel, onDelete, onChange, error = false }) => {
  const handleValueChange =
    (valueType: keyof WalkieChannel) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(valueType, e.target.value)
    }

  return (
    <Grid
      container
      alignItems='center'
      justifyContent='space-between'
      minWidth='458px'
    >
      <Grid item xs={7.5}>
        <TextField
          sx={{ '& .MuiInputBase-root ::placeholder': { opacity: 1 } }}
          error={error}
          value={name}
          fullWidth
          onChange={handleValueChange('name')}
          placeholder='[Channel name]'
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          error={error}
          value={channel}
          onChange={handleValueChange('channel')}
          placeholder=''
          type='number'
        />
      </Grid>
      <Grid item xs={1} sx={{ cursor: 'pointer' }}>
        <TrashcanIcon onClick={onDelete} />
      </Grid>
    </Grid>
  )
}

export default Channel
