import { SampleError } from 'src/api/types'
import {
  ADD_CHANNEL,
  DELETE_CHANNEL,
  GET_CHANNEL_REQUEST,
  GET_CHANNEL_REQUEST_ERROR,
  GET_CHANNEL_REQUEST_SUCCESS,
  SAVE_CHANNEL_REQUEST,
  SAVE_CHANNEL_REQUEST_ERROR,
  SAVE_CHANNEL_REQUEST_SUCCESS,
  UPDATE_CHANNEL,
} from './actionTypes'
import {
  AddWalkieChannel,
  DeleteWalkieChannel,
  GetWalkieChannelRequestErrorType,
  GetWalkieChannelRequestSuccessType,
  GetWalkieChannelRequestType,
  SaveWalkieChannelRequestErrorType,
  SaveWalkieChannelRequestSuccessType,
  SaveWalkieChannelRequestType,
  UpdateWalkieChannel,
  WalkieChannel,
} from './types'

export const getWalkieChannelsRequest = (): GetWalkieChannelRequestType => ({
  type: GET_CHANNEL_REQUEST,
})

export const getWalkieChannelsSuccessRequest = (
  walkieChannels: WalkieChannel[]
): GetWalkieChannelRequestSuccessType => ({
  type: GET_CHANNEL_REQUEST_SUCCESS,
  walkieChannels,
})

export const getWalkieChannelsError = (
  error: SampleError
): GetWalkieChannelRequestErrorType => ({
  type: GET_CHANNEL_REQUEST_ERROR,
  error,
})

export const addWalkieChannel = (
  walkieChannel: WalkieChannel
): AddWalkieChannel => ({
  type: ADD_CHANNEL,
  walkieChannel,
})

export const saveWalkieChannelsRequest = (): SaveWalkieChannelRequestType => ({
  type: SAVE_CHANNEL_REQUEST,
})

export const saveWalkieChannelsRequestSuccess =
  (): SaveWalkieChannelRequestSuccessType => ({
    type: SAVE_CHANNEL_REQUEST_SUCCESS,
  })

export const saveWalkieChannelsRequestError = (
  error: SampleError
): SaveWalkieChannelRequestErrorType => ({
  type: SAVE_CHANNEL_REQUEST_ERROR,
  error,
})

export const deleteWalkieChannel = (id: string): DeleteWalkieChannel => ({
  type: DELETE_CHANNEL,
  id,
})

export const updateWalkieChannel = (
  id: string,
  valueType: keyof WalkieChannel,
  value: string
): UpdateWalkieChannel => ({
  type: UPDATE_CHANNEL,
  id,
  valueType,
  value,
})
