const style = {
  button: {
    padding: '0px',
    height: 'auto',
  },

  text: (disabled: boolean, color: string) => ({
    mr: 0,
    fontSize: '12px',
    fontWeight: 900,
    color: disabled ? 'text.disabled' : color,
  }),
}

export default style
