import React, { FC } from 'react'
import { Box, Divider, Typography } from '@mui/material'
import useCallsheetCard from '../../hooks/callsheet/useCallsheetCard'
import { useOptions } from '../../hooks/callsheet/useOptions'
import { OptionsVerticalIcon } from '../../assets/icons'
import {
  CallsheetStatuses,
  CallsheetStatusType,
} from '../../store/Callsheet/types'
import OptionsButton from '../OptionsButton/OptionsButton'
import CallsheetCardFooter from './CallsheetCardFooter'
import CallsheetCardHeader from './CallsheetCardHeader'
import StatusChip from './StatusChip'
import style from './style'

export type CallsheetCardTypes = {
  id: string
  title: string
  deletedAt?: DateConstructor | null
  eventDate: DateConstructor | null
  crewCallTime: string
  status?: CallsheetStatusType
  ownerId?: string
}

const CallsheetCard: FC<CallsheetCardTypes> = ({
  id,
  title,
  eventDate,
  crewCallTime,
  status = CallsheetStatuses.DRAFT,
  ownerId,
  deletedAt,
}) => {
  const { handleCardClick, isClickable } = useCallsheetCard(status, id)
  const { optionsMenuItems } = useOptions({ id, status, ownerId, title })
  const isShowAuthor =
    status === CallsheetStatuses.PENDING ||
    status === CallsheetStatuses.ACCEPTED ||
    status === CallsheetStatuses.TENTATIVE ||
    status === CallsheetStatuses.DECLINED

  return (
    <Box sx={style.card(status, isClickable)}>
      <Box sx={style.optionsBtn}>
        <OptionsButton menuItems={optionsMenuItems}>
          <OptionsVerticalIcon />
        </OptionsButton>
      </Box>
      <Box onClick={handleCardClick}>
        <CallsheetCardHeader
          title={title}
          crewCallTime={crewCallTime}
          eventDate={eventDate}
        />
        <Box sx={style.body}>
          <StatusChip status={status} />
          {isShowAuthor ? (
            <Typography sx={style.author}>By: Brooklyn Simons</Typography>
          ) : null}
        </Box>
        <Divider />
        <Box sx={style.footer}>
          <CallsheetCardFooter id={id} status={status} deletedAt={deletedAt} />
        </Box>
      </Box>
    </Box>
  )
}

export default CallsheetCard
