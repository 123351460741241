import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter as Router } from 'react-router-dom'
import smoothScroll from 'smoothscroll-polyfill'

import App from './App'

import './index.css'
import './assets/fonts/Avenir/stylesheet.css'
import AppTheme from './themes/AppTheme'

import store from './store/store'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement!)

root.render(
  <ThemeProvider theme={AppTheme}>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </ThemeProvider>
)

/* For Safari */
smoothScroll.polyfill()
