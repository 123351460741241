const style = {
  wrapper: (isPreview?: boolean) => ({
    border: '1px solid #E2E2E2',
    minWidth: isPreview ? '400px' : '546px',
    boxShadow: '0px 5px 8px 0px #8585851A',
    borderRadius: '16px',
    padding: 1,
    marginBottom: 4,
  }),
  saveBtn: {
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 900,
    padding: '2px 12px',
    height: '24px',
    borderRadius: '4px',
  },
  btnWrapper: {
    '> svg': {
      cursor: 'pointer',
    },

    display: 'flex',
    justifyContent: 'space-between',
    gap: 0,
    alignItems: 'center',
  },

  timeWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    gap: 0,
  },
}

export default style
