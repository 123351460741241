import { Box, Typography } from '@mui/material'
import { noop } from 'lodash'
import React, { FC } from 'react'
import { CalendarIcon, ClockIcon } from 'src/assets/icons'
import DatePickerInput from 'src/components/DatePickerInput'
import TimePickerDropdown from 'src/components/TimePickerDropdown'
import DeleteAction from '../DeleteAction'

import style from './style'

type OptionDateAndTimeProps = {
  checked: boolean
  time: string
  onTimeChange: typeof noop
  date: DateConstructor | null
  onDateChange: typeof noop
  onSelect: typeof noop
}

const OptionDateAndTime: FC<OptionDateAndTimeProps> = ({
  checked,
  onSelect,
  time,
  date,
  onTimeChange,
  onDateChange,
}) => {
  return (
    <DeleteAction
      title='Change date and time'
      text='Instead of deleting a CallSheet you can just change the start day and time'
      checked={checked}
      onSelect={onSelect}
    >
      <Box display='flex' justifyContent='space-between'>
        <Box display='flex' gap={1} alignItems='center'>
          <CalendarIcon />
          <Typography sx={style.label}>Event date</Typography>
        </Box>
        <DatePickerInput
          width='140px'
          defaultWidth='140px'
          placeholderProps={{ color: '#2A7BC0', opacity: 1 }}
          placeholder='Change Date'
          format='MM/DD/YYYY'
          onChange={onDateChange}
          value={date}
        />
      </Box>
      <Box display='flex' justifyContent='space-between'>
        <Box display='flex' gap={1} alignItems='center'>
          <ClockIcon />
          <Typography sx={style.label}>General Call Time</Typography>
        </Box>
        <TimePickerDropdown
          value={time}
          onChange={onTimeChange}
          placeholder='Change Time'
          inputProps={{ minWidth: '140px', textAlign: 'center' }}
        />
      </Box>
    </DeleteAction>
  )
}

export default OptionDateAndTime
