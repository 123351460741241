import { noop } from 'lodash'
import { Box, Divider, Paper, Typography } from '@mui/material'
import React, {
  FC,
  FunctionComponent,
  PropsWithChildren,
  SVGProps,
} from 'react'

import { ArrowRightIcon, EditIcon } from '../../../../assets/icons'
import CustomTextButton from '../../../../components/CustomTextButton'

import style from './style'

type PrepareToSendProps = {
  title: string
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>
  onClick: typeof noop
  optional?: boolean
  edit?: boolean
}

const PrepareToSend: FC<PrepareToSendProps & PropsWithChildren> = ({
  title,
  Icon,
  onClick,
  edit = false,
  optional = false,
  children,
}) => {
  return (
    <Paper variant='outlined' sx={style.wrapper}>
      <Box sx={style.content}>
        <Box sx={style.titleWrapper}>
          <Icon />
          <Typography sx={style.title}>{title}</Typography>
          {optional && (
            <Typography variant='body1' color='text.disabled'>
              Optional
            </Typography>
          )}
        </Box>
        {edit ? (
          <Box
            display='flex'
            gap={0}
            alignItems='center'
            sx={{ cursor: 'pointer' }}
            onClick={onClick}
          >
            <Typography variant='body2' color='#265A88'>
              Edit
            </Typography>
            <EditIcon />
          </Box>
        ) : (
          <CustomTextButton
            title='Prepare to Send'
            Icon={ArrowRightIcon}
            onClick={onClick}
          />
        )}
      </Box>
      {edit && (
        <>
          <Divider sx={{ my: '12px' }} />
          {children}
        </>
      )}
    </Paper>
  )
}

export default PrepareToSend
