import React, { FC, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import { useToggle } from '../../../../hooks/useToggle'

import LabeledContainer from '../LabeledContainer'
import PreviewWrapper from '../PreviewWrapper'
import SmsPreviewForm from '../SmsPreviewForm'
import PrepareToSend from '../PrepareToSend'
import { selectSmsParameters } from '../../../../store/SmsParameters/selectors'
import { selectGroupMembers } from '../../../../store/GroupMembers/selectors'
import {
  setSmsPhone,
  setSmsText,
} from '../../../../store/SmsParameters/actions'
import { SmsParameters } from '../../../../store/SmsParameters/types'

import { getReminderOption } from '../../../../utils/getReminderOption'

import { PhoneIcon } from '../../../../assets/icons'

import style from './style'

const SmsSection: FC = () => {
  const dispatch = useDispatch()
  const recipients = useSelector(selectGroupMembers)
  const smsParameters = useSelector(selectSmsParameters)
  const [smsPreview, setSmsPreview] = useToggle()
  const [parameters, setParamaters] = useState<SmsParameters>(smsParameters)
  const { smsContent, smsFrom } = parameters
  const { isSmsReminder, smsReminder } = smsParameters

  const contactsWithNumber = recipients.filter(
    recipient => !!recipient.phone
  ).length

  const handleSubmit = () => {
    setSmsPreview()
    dispatch(setSmsPhone(parameters.smsFrom))
    dispatch(setSmsText(parameters.smsContent))
  }

  const isDisabled = !smsContent || !smsFrom

  return (
    <>
      <PreviewWrapper
        confirmLabel='Save SMS'
        open={smsPreview}
        onClose={setSmsPreview}
        onSubmit={handleSubmit}
        disabled={isDisabled}
        title='SMS preview'
      >
        <SmsPreviewForm
          smsText={smsContent}
          phone={smsFrom}
          onSmsContentChange={(e: string) =>
            setParamaters(prev => ({ ...prev, smsContent: e }))
          }
          onPhoneChange={(e: string) =>
            setParamaters(prev => ({ ...prev, smsFrom: e }))
          }
        />
      </PreviewWrapper>
      <PrepareToSend
        edit={!isDisabled}
        optional
        Icon={PhoneIcon}
        title='SMS Messages'
        onClick={setSmsPreview}
      >
        <Box display='flex' gap='60px'>
          <LabeledContainer label='Sent From'>
            <Typography variant='h5' color='#265A88'>
              My Phone
            </Typography>
          </LabeledContainer>
          <LabeledContainer label='Contacts with Number:'>
            <Typography
              variant='h5'
              color='text.primary'
              sx={style.contactsNumber}
            >
              <span>{contactsWithNumber}</span>/{recipients.length}
            </Typography>
          </LabeledContainer>
          {isSmsReminder && (
            <LabeledContainer label='Reminder:'>
              <Typography variant='h5' color='text.primary'>
                {getReminderOption(smsReminder)} Before
              </Typography>
            </LabeledContainer>
          )}
        </Box>
      </PrepareToSend>
    </>
  )
}

export default SmsSection
