import { AppState } from '../types'

export const selectCallsheetStatusRequest = (state: AppState) =>
  state.callsheetStatusState.requestStatus

export const selectConfirmationStep = (state: AppState) =>
  state.callsheetStatusState.confirmationStep

export const selectAnnouncementsToAccept = (state: AppState) =>
  state.callsheetStatusState.announcementsToAccept

export const selectCallsheetToAction = (state: AppState): string =>
  state.callsheetStatusState.callsheetToChangeId
