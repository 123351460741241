import { Checkbox } from '@mui/material'
import { CheckboxProps } from '@mui/material/Checkbox/Checkbox'
import React, { FC } from 'react'
import { CheckboxCheckedIcon, CheckboxIcon } from '../../assets/icons'

const CustomCheckbox: FC<CheckboxProps> = ({
  onChange,
  checkedIcon = <CheckboxCheckedIcon />,
  icon = <CheckboxIcon />,
  ...rest
}) => {
  return (
    <Checkbox
      {...rest}
      checkedIcon={checkedIcon}
      icon={icon}
      onChange={onChange}
    />
  )
}

export default CustomCheckbox
