const style = {
  input: (placeholderProps: { [key: string]: string | number }) => ({
    borderRadius: '4px',
    height: '32px',
    fontSize: '14px',
    color: 'primary.light',
    input: {
      textAlign: 'center',
      cursor: 'pointer',
      '&::placeholder': {
        color: 'text.primary',
        ...placeholderProps,
      },
    },
  }),
}

export default style
