const style = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    border: '2px dashed #E2E2E2',
    borderRadius: '16px',
    padding: '12px',
    height: '98px',
  },
}

export default style
