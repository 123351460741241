const style = {
  container: {
    px: 1,
    py: 0,
    display: 'flex',
    justifyContent: 'space-between',
    gap: 0,
  },
}

export default style
