const style = {
  statusWrapper: (color: string, round: boolean, clickable: boolean) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    borderRadius: round ? '36px' : '6px',
    border: `1px solid ${color}`,
    padding: round ? '4px 11.5px' : '4px 8px',

    svg: {
      path: {
        stroke: color,
      },
    },
    ...(clickable && { cursor: 'pointer' }),
  }),
  status: (color: string) => ({
    color,
    fontWeight: 500,
    fontSize: '14px',
  }),
}

export default style
