import { RequestStatuses } from 'src/api/constants'
import { AppState } from '../types'

export const selectPublishDetailsRequestStatus = (
  state: AppState
): RequestStatuses => state.publishDetailsState.requestStatus

export const selectPublishDetailsSaveRequestStatus = (
  state: AppState
): RequestStatuses => state.publishDetailsState.saveRequestStatus
