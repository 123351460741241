import produce from 'immer'
import { RequestStatuses } from '../../api/constants'
import {
  ADD_CONTACT_REQUEST,
  ADD_CONTACT_REQUEST_ERROR,
  ADD_CONTACT_REQUEST_SUCCESS,
  DELETE_CONTACT_REQUEST,
  DELETE_CONTACT_REQUEST_ERROR,
  DELETE_CONTACT_REQUEST_SUCCESS,
  GET_CONTACTS_BY_NAME_EMAIL_REQUEST,
  GET_CONTACTS_BY_NAME_EMAIL_REQUEST_ERROR,
  GET_CONTACTS_BY_NAME_EMAIL_REQUEST_SUCCESS,
  GET_CONTACTS_REQUEST,
  GET_CONTACTS_REQUEST_ERROR,
  GET_CONTACTS_REQUEST_SUCCESS,
  GET_CONTACTS_TAGS_REQUEST,
  GET_CONTACTS_TAGS_REQUEST_ERROR,
  GET_CONTACTS_TAGS_REQUEST_SUCCESS,
  SET_CONTACTS,
  SET_CONTACT_IMAGE,
  SET_EDIT_CONTACT,
  SET_SELECTED_CONTACT,
} from './actionTypes'
import { Contact, ContactsActions, ContactsState } from './types'

const ContactsInitialState: ContactsState = {
  searchContacts: [],
  contacts: [],
  tags: [],
  requestStatus: RequestStatuses.UNCALLED,
  requestTagsStatus: RequestStatuses.UNCALLED,
  deleteContactRequestStatus: RequestStatuses.UNCALLED,
  searchContactsRequestStatus: RequestStatuses.UNCALLED,
}

const ContactsReducer = produce(
  (draft = ContactsInitialState, action: ContactsActions) => {
    switch (action.type) {
      case DELETE_CONTACT_REQUEST:
        draft.deleteContactRequestStatus = RequestStatuses.PENDING
        break
      case DELETE_CONTACT_REQUEST_SUCCESS:
        draft.deleteContactRequestStatus = RequestStatuses.SUCCEEDED
        break
      case DELETE_CONTACT_REQUEST_ERROR:
        draft.deleteContactRequestStatus = RequestStatuses.FAILED
        break
      case GET_CONTACTS_REQUEST:
      case ADD_CONTACT_REQUEST:
        draft.requestStatus = RequestStatuses.PENDING
        break
      case GET_CONTACTS_REQUEST_ERROR:
      case ADD_CONTACT_REQUEST_ERROR:
        draft.requestStatus = RequestStatuses.FAILED
        break
      case ADD_CONTACT_REQUEST_SUCCESS:
        draft.requestStatus = RequestStatuses.SUCCEEDED
        break
      case GET_CONTACTS_REQUEST_SUCCESS:
        draft.contacts = action.contacts
        draft.requestStatus = RequestStatuses.SUCCEEDED
        break
      case GET_CONTACTS_TAGS_REQUEST:
        draft.requestTagsStatus = RequestStatuses.PENDING
        break
      case GET_CONTACTS_TAGS_REQUEST_SUCCESS:
        draft.tags = action.tags
        draft.requestTagsStatus = RequestStatuses.SUCCEEDED
        break
      case GET_CONTACTS_TAGS_REQUEST_ERROR:
        draft.requestTagsStatus = RequestStatuses.FAILED
        break
      case SET_CONTACTS:
        draft.contacts = action.contacts
        break
      case SET_EDIT_CONTACT:
        draft.editContact = action.editContact
        break
      case GET_CONTACTS_BY_NAME_EMAIL_REQUEST:
        draft.searchContactsRequestStatus = RequestStatuses.PENDING
        break
      case GET_CONTACTS_BY_NAME_EMAIL_REQUEST_SUCCESS:
        draft.searchContacts = action.contacts
        draft.searchContactsRequestStatus = RequestStatuses.SUCCEEDED
        break
      case GET_CONTACTS_BY_NAME_EMAIL_REQUEST_ERROR:
        draft.searchContactsRequestStatus = RequestStatuses.FAILED
        break
      case SET_SELECTED_CONTACT:
        draft.selectedContactId = action.selectedContactId
        break
      default:
        return draft
    }
  }
)

export default ContactsReducer
