import React, { FC } from 'react'
import { noop } from 'lodash'
import { Box, Card, IconButton, Typography } from '@mui/material'
import { EditIcon } from '../../../../../assets/icons'

import style from './style'

type CompanyCardTypes = {
  name: string
  onClick: typeof noop
}

const CompanyCard: FC<CompanyCardTypes> = ({ name, onClick }) => {
  return (
    <Card variant='outlined' sx={style.card}>
      <Box sx={style.content}>
        <Typography sx={style.name}>{name}</Typography>
        <Box display='flex' justifyContent='space-between'>
          <IconButton onClick={onClick} sx={style.editBtn}>
            <EditIcon />
          </IconButton>
        </Box>
      </Box>
    </Card>
  )
}

export default CompanyCard
