const style = {
  wrapper: (maxWidth?: string) => ({
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        maxWidth: maxWidth ?? '1100px',
      },
    },
  }),
  title: {
    position: 'relative',
    padding: '16px 18px',
    backgroundColor: '#F6F6F6',
    '& .MuiDialogTitle-root': {
      padding: '0px',
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 500,
    },
  },
  closeBtn: {
    position: 'absolute',
    top: '15px',
    right: '15px',
  },
}

export default style
