const PREFIX = 'EMAIL_PARAMETERS/'

export const SET_EMAIL_SUBJECT = `${PREFIX}SET_EMAIL_SUBJECT` as const
export const SET_EMAIL_DESCRIPTION = `${PREFIX}SET_EMAIL_DESCRIPTION` as const
export const SET_EMAIL_REMINDER = `${PREFIX}SET_EMAIL_REMINDER` as const
export const SWITCH_EMAIL_REMINDER = `${PREFIX}SWITCH_EMAIL_REMINDER ` as const
export const SET_EMAIL_FROM = `${PREFIX}SET_EMAIL_FROM` as const
export const SET_EMAIL_ATTACHMENT = `${PREFIX}SET_EMAIL_ATTACHMENT` as const

export const SET_EMAIL_PARAMETERS = `${PREFIX}SET_EMAIL_PARAMETERS` as const
