import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Box, Divider, Paper, Stack, Typography } from '@mui/material'
import { selectSmsParameters } from '../../../../../store/SmsParameters/selectors'

import { ReactComponent as ChatPlaceholder } from './assets/chat.svg'
import { SELECT_PHONE_PLACEHOLDER } from '../../../../../constants'

import style from './style'

const SmsResult: FC<{ text: string }> = ({ text }) => {
  const { smsFrom } = useSelector(selectSmsParameters)
  return (
    <Paper variant='outlined' sx={style.wrapper}>
      <Box sx={style.avatarBox}>
        <Stack alignItems='center'>
          <Box sx={style.avatar}></Box>
          <Typography sx={style.phone}>
            {smsFrom || SELECT_PHONE_PLACEHOLDER}
          </Typography>
        </Stack>
      </Box>
      <Divider />
      <Box sx={style.chatWrapper}>
        {text ? (
          <Box sx={style.msgWrapper}>
            <Typography sx={style.message}>{text}</Typography>
          </Box>
        ) : (
          <ChatPlaceholder />
        )}
      </Box>
    </Paper>
  )
}

export default SmsResult
