import { SampleError } from 'src/api/types'
import {
  DOWNLOAD_ATTACHMENTS_REQUEST,
  GET_ATTACHMENTS_REQUEST,
  GET_ATTACHMENTS_REQUEST_ERROR,
  GET_ATTACHMENTS_REQUEST_SUCCESS,
  SAVE_ATTACHMENTS_REQUEST,
  SAVE_ATTACHMENTS_REQUEST_SUCCESS,
  SET_ATTACHMENTS,
} from './actionTypes'
import {
  Attachment,
  DownloadAttachmentsRequestType,
  GetAttachmentsRequestErrorType,
  GetAttachmentsRequestSuccessType,
  GetAttachmentsRequestType,
  SaveAttachmentsRequestSuccessType,
  SaveAttachmentsRequestType,
  SetAttachments,
} from './types'

export const setAttachments = (attachments: Attachment[]): SetAttachments => ({
  type: SET_ATTACHMENTS,
  attachments,
})

export const getAttachmentsRequest = (): GetAttachmentsRequestType => ({
  type: GET_ATTACHMENTS_REQUEST,
})

export const saveAttachmentsRequest = (): SaveAttachmentsRequestType => ({
  type: SAVE_ATTACHMENTS_REQUEST,
})

export const saveAttachmentsRequestSuccess =
  (): SaveAttachmentsRequestSuccessType => ({
    type: SAVE_ATTACHMENTS_REQUEST_SUCCESS,
  })

export const downloadAttachmentsRequest = (
  id: string,
  format: string,
  name: string
): DownloadAttachmentsRequestType => ({
  type: DOWNLOAD_ATTACHMENTS_REQUEST,
  payload: { id, format, name },
})

export const getAttachmentsRequestSuccess = (
  attachments: Attachment[]
): GetAttachmentsRequestSuccessType => ({
  type: GET_ATTACHMENTS_REQUEST_SUCCESS,
  attachments,
})

export const getAttachmentsRequestError = (
  error: SampleError
): GetAttachmentsRequestErrorType => ({
  type: GET_ATTACHMENTS_REQUEST_ERROR,
  error,
})
