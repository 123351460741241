const style = {
  wrapper: {
    justifyContent: 'space-between',
    height: '100%',
  },
  groupsWrapper: {
    lineHeight: '24px',
    mt: 5,
    gap: '12px',
    '> h6': {
      fontWeight: 900,
    },
  },
  addBtn: {
    pl: '5px',
  },
  groups: {
    paddingRight: 1,
    maxHeight: 'calc(100vh - 500px)',
    overflow: 'auto',
    gap: 0,
  },
}

export default style
