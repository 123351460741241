const style = {
  stepper: (builderStepper: boolean) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    ...(builderStepper && {
      gridTemplateColumns: '159px 0px 159px 159px 159px',
    }),
    justifyContent: 'center',
  }),
  progressBar: {
    width: '408px',
    paddingBottom: '56px',
    margin: '0 auto',
    pt: '62px',
  },
  step: {
    '& .Mui-completed': {
      '> svg': {
        fill: 'success.main',
      },
    },
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      fontWeight: 900,
      marginTop: '-64px',
    },
    '& .MuiStepLabel-label.Mui-active': {
      color: 'secondary.dark',
    },
    '& .MuiStepLabel-label.Mui-disabled': {
      color: 'secondary.light',
    },
    '& .MuiStepLabel-label.Mui-completed': {
      color: 'success.main',
    },
  },
}

export default style
