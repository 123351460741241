import { noop, unionBy, uniq, uniqBy } from 'lodash'
import { useDispatch } from 'react-redux'
import React, { FC, useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import ImageUploading, {
  ImageListType,
  ImageType,
} from 'react-images-uploading'

import UploadFileButton from '../../../../components/UploadFileButton'
import { downloadAttachmentsRequest } from '../../../../store/Attachments/actions'
import { Attachment } from '../../../../store/Attachments/types'

import {
  attachmentName,
  prepareFiles,
} from '../../../../utils/attachmentHelpers'
import {
  DocumentOutlineIcon,
  EditIcon,
  TrashcanIcon,
} from '../../../../assets/icons'
import { convertBytesToKb } from '../../../../utils/getFileSize'
import { download } from '../../../../utils/downloadFile'
import { MAX_ATTACHMENTS_NUMBER, MAX_FILE_SIZE } from '../../../../constants'
import style from './style'

type FileUploadFieldTypes = {
  onChange: typeof noop
  defaultValue: Attachment[]
}

const FileUploadField: FC<FileUploadFieldTypes> = ({
  onChange,
  defaultValue,
}) => {
  const [files, setFile] = useState(prepareFiles(defaultValue))
  const [isDuplicateError, setDuplicateError] = useState<boolean>(false)

  const dispatch = useDispatch()

  const handleChange = (imageList: ImageListType) => {
    if (isDuplicateError) setDuplicateError(false)
    if (imageList.length !== uniqBy(imageList, attachmentName).length) {
      setDuplicateError(true)
      return
    }
    setFile(imageList as never[])
    onChange(imageList)
  }

  const handleDownload = (img: ImageType) => () => {
    const { id, file, format, name } = img

    if (id) {
      dispatch(
        downloadAttachmentsRequest(id, format ?? file?.type, name ?? file?.name)
      )
    } else {
      if (!file) {
        return
      }
      download(file, file.name)
    }
  }

  return (
    <ImageUploading
      value={files}
      onChange={handleChange}
      acceptType={['png', 'jpg', 'pdf', 'jpeg']}
      multiple
      allowNonImageType
      maxFileSize={MAX_FILE_SIZE}
      maxNumber={MAX_ATTACHMENTS_NUMBER}
    >
      {({
        imageList,
        onImageUpload,
        onImageUpdate,
        onImageRemove,
        dragProps,
        errors,
      }) => (
        <Box>
          {imageList.map((img, idx) => (
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='flex-start'
              key={idx}
            >
              <Box sx={style.fileWrapper}>
                <Box display='flex' gap='12px'>
                  <DocumentOutlineIcon onClick={handleDownload(img)} />
                  <Stack alignItems='flex-start'>
                    <Typography
                      variant='subtitle2'
                      color='primary.main'
                      lineHeight='20px'
                      textAlign='left'
                    >
                      {`File name #${img.file?.name}`}
                    </Typography>
                    <Typography variant='body1' color='text.disabled'>
                      {`${convertBytesToKb(img.size ?? img.file?.size)}KB`}
                    </Typography>
                  </Stack>
                </Box>
                <Box sx={style.btnWrapper}>
                  <EditIcon onClick={() => onImageUpdate(idx)} />
                  <TrashcanIcon onClick={() => onImageRemove(idx)} />
                </Box>
              </Box>
            </Box>
          ))}
          <Box
            className='upload__image-wrapper'
            sx={style.wrapper(files.length ? '118px' : '76px')}
            {...dragProps}
          >
            <UploadFileButton
              onClick={onImageUpload}
              tip='Supports: JPEG, JPG, PNG, PDF'
              size={files.length ? 'large' : 'small'}
            />
          </Box>
          {errors?.maxNumber && (
            <Typography color='error.main' textAlign='left'>
              The maximum number of attachments is 5.
            </Typography>
          )}
          {errors?.maxFileSize && (
            <Typography color='error.main' textAlign='left'>
              The attachment size exceeds the allowable limit of 5 MB.
            </Typography>
          )}
          {isDuplicateError && (
            <Typography color='error.main' textAlign='left'>
              The attachment with the same name has already been created.
            </Typography>
          )}
        </Box>
      )}
    </ImageUploading>
  )
}

export default FileUploadField
