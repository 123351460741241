import { noop } from 'lodash'
import React, { FC } from 'react'

import { Box, Typography } from '@mui/material'
import { Contact } from '../../../../../store/Contacts/types'
import {
  addContactsListItem,
  deleteMembersListItem,
} from '../localStore/actions'
import MemberItem from '../MemberItem'

type MembersBoxProps = {
  members: Contact[]
  membersFormDispatch: typeof noop
}

const MembersBox: FC<MembersBoxProps> = ({ members, membersFormDispatch }) => {
  const handleDeleteMember = (member: Contact) => () => {
    membersFormDispatch(deleteMembersListItem(member.email))
    membersFormDispatch(addContactsListItem(member))
  }

  return (
    <div>
      {members.length ? (
        <Box sx={{ mb: 3 }}>
          <Typography variant='h5' component='h5' sx={{ mb: '12px' }}>
            Added to production
          </Typography>
          {members.map(member => {
            const { logo, firstName, lastName, title, email } = member
            return (
              <MemberItem
                key={email}
                logo={logo}
                name={`${firstName} ${lastName}`}
                title={title}
                handleDeleteMember={handleDeleteMember(member)}
              />
            )
          })}
        </Box>
      ) : null}
    </div>
  )
}

export default MembersBox
