import { LoadingButton } from '@mui/lab'
import React, { FC, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack, Typography, Box } from '@mui/material'

import { uniqBy } from 'lodash'
import { RequestStatuses } from '../../../../api/constants'
import { useToggle } from '../../../../hooks/useToggle'

import { selectIsGroupMembersPanel } from '../../../../store/Builder/selectors'
import { setIsUserGroupPanel } from '../../../../store/Builder/actions'
import {
  saveGroupsRequest,
  setGroupsDisplay,
} from '../../../../store/Groups/actions'
import { selectGroupMembers } from '../../../../store/GroupMembers/selectors'
import {
  selectGroups,
  selectGroupsRequestUpdateStatus,
} from '../../../../store/Groups/selectors'
import { GroupType } from '../../../../store/Groups/types'

import { isMissingDetails } from '../../../../utils/groupMemberHelpers'
import { validateUserGroups } from '../../../../utils/groupHelpers'

import ChangeDisplayBtn from '../ChangeDisplayBtn'
import GroupsFilter from '../../ProjectOverview/GroupsFilter'
import Team from '../Team'

import { EditIcon } from '../../../../assets/icons'

import style from './style'

const TeamInvitesBox: FC<{ groups: GroupType[] }> = ({ groups }) => {
  const [isStackDisplay, toggleDisplay] = useToggle(true)
  const isGroupMembersPanel = useSelector(selectIsGroupMembersPanel)
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [isSelectedByMissing, setSelectedByMissing] = useState<boolean>(false)
  const groupMembers = useSelector(selectGroupMembers)
  const createdGroups = useSelector(selectGroups)
  const requestUpdateStatus = useSelector(selectGroupsRequestUpdateStatus)
  const groupTitles = createdGroups.map(group => group.title)

  const dispatch = useDispatch()

  const handleUserGroupPanel = () => {
    dispatch(setIsUserGroupPanel(true))
  }

  const handleToggleDisplay = () => {
    toggleDisplay()
    dispatch(setGroupsDisplay())
  }

  const handleSubmit = () => {
    dispatch(saveGroupsRequest())
  }

  const filteredGroups = useMemo(() => {
    if (!selectedTags.length && !isSelectedByMissing) return groups
    const filteredByTags = selectedTags.length
      ? groups.filter(group => selectedTags.includes(group.title))
      : []
    const filteredByMissingDetails = isSelectedByMissing
      ? groups.filter(group => isMissingDetails(groupMembers, group))
      : []

    return uniqBy([...filteredByTags, ...filteredByMissingDetails], 'title')
  }, [selectedTags, isSelectedByMissing, createdGroups, groupMembers])

  const isDisabled = !validateUserGroups(createdGroups, groupMembers)
  return (
    <Stack gap='12px' sx={{ mb: 4 }}>
      <Box sx={style.filter_wrapper}>
        <Typography sx={style.title}>Groups</Typography>
        <Box display='flex' alignItems='center' gap={1}>
          <Box onClick={handleUserGroupPanel} sx={style.edit_wrapper}>
            <Typography variant='body2' color='primary.main'>
              Edit groups
            </Typography>
            <EditIcon />
          </Box>
          {!isGroupMembersPanel && (
            <ChangeDisplayBtn
              onToggle={handleToggleDisplay}
              isStack={isStackDisplay}
            />
          )}
          <GroupsFilter
            setSelectedTagByMissing={setSelectedByMissing}
            isSelectedByMissing={isSelectedByMissing}
            tags={groupTitles}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: `repeat(${
            isStackDisplay || isGroupMembersPanel ? '1' : '2'
          }, 1fr)`,
          gap: 1,
        }}
      >
        {filteredGroups.map((group, idx) => (
          <Team
            title={group.title}
            key={`${group.title}${idx}`}
            callTime={group.callTime}
          />
        ))}
      </Box>
      <Box sx={style.btn_wrapper}>
        <LoadingButton
          disabled={isDisabled}
          variant='contained'
          fullWidth
          onClick={handleSubmit}
          loading={requestUpdateStatus === RequestStatuses.PENDING}
        >
          Save & Continue
        </LoadingButton>
      </Box>
    </Stack>
  )
}

export default TeamInvitesBox
