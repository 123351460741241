import { Box, InputLabel, Paper, Typography } from '@mui/material'
import { cloneDeep } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PlusCircle } from '../../../../assets/icons'
import { setIsLocationPanel } from '../../../../store/Builder/actions'
import {
  deleteLocationRequest,
  setLocations,
} from '../../../../store/Logistics/actions'
import { selectLocations } from '../../../../store/Logistics/selectors'
import { LocationItem } from '../../../../store/Logistics/types'
import ControlButtons from '../../../../components/ControlButtons'
import CustomTextButton from '../../../../components/CustomTextButton'
import TextHead from '../../../../components/HeadText/HeadText'
import style from '../CompanyForm/style'
import { EMPTY_LOCATION_ITEM, MAX_LOCATIONS } from './constants'
import LocationInputCard from './LocationInputCard'
import { ErrorsFieldsTypes } from './types'

const AddLocationForm = () => {
  const locations = useSelector(selectLocations)
  const dispatch = useDispatch()
  const [errors, setErrors] = useState<ErrorsFieldsTypes[]>([])
  const [locationItems, setLocationsState] = useState<LocationItem[]>([
    { ...EMPTY_LOCATION_ITEM, tmpId: uuidv4() },
  ])

  const handleAddCard = () => {
    if (locationItems.length === MAX_LOCATIONS) {
      return
    }

    setLocationsState([
      ...locationItems,
      { ...EMPTY_LOCATION_ITEM, tmpId: uuidv4() },
    ])
  }

  const handleRemoveCard = (location: LocationItem) => () => {
    if (location.id) {
      dispatch(deleteLocationRequest(location.id))
      return
    }

    const filtered = locationItems.filter(
      curLocation => curLocation.tmpId !== location.tmpId
    )
    setLocationsState(filtered)
  }

  const updateLocation = (location: LocationItem) => {
    const updatedLocations = locationItems.map(locationItem => {
      if (locationItem.tmpId === location.tmpId) {
        return location
      }
      return locationItem
    })

    setLocationsState(updatedLocations)
  }

  const closeSidePanel = () => {
    dispatch(setIsLocationPanel(false))
  }

  const clearErrorField = (field: keyof ErrorsFieldsTypes, i: number) => {
    if (errors[i] && errors[i][field]) {
      const clonedErrors = cloneDeep(errors)
      clonedErrors[i][field] = false
      setErrors(clonedErrors)
    }
  }

  const isErrorFields = () => {
    const errorsList = locationItems.map(item => ({
      title: !item.title,
      address: !item.address,
    }))
    setErrors(errorsList)
    return errorsList.some(error => error.address || error.title)
  }

  const saveLocations = () => {
    if (isErrorFields()) {
      return
    }

    dispatch(setLocations(locationItems))
    closeSidePanel()
  }

  useEffect(() => {
    if (locations.length) {
      setLocationsState(locations)
    }
  }, [locations])

  return (
    <Box sx={style.wrapper}>
      <Box sx={style.content}>
        <TextHead
          title='Call sheet locations'
          subtitle='Please, add at least one location and it’s possible to add additional locations '
        />
        <Box sx={style.locationsWrapper}>
          <Box sx={style.locationsBox}>
            <InputLabel>Location</InputLabel>
            <Box>
              {locationItems.map((location, i) => (
                <LocationInputCard
                  key={location.tmpId}
                  index={i}
                  locationItem={location}
                  handleRemove={handleRemoveCard(location)}
                  updateLocation={updateLocation}
                  isRemoveButton={i !== 0}
                  errorFields={errors[i]}
                  clearErrorField={clearErrorField}
                />
              ))}
            </Box>
          </Box>
          <Box sx={style.addButton}>
            <CustomTextButton
              title='Add additional location'
              Icon={PlusCircle}
              onClick={handleAddCard}
              disabled={locationItems.length === MAX_LOCATIONS}
            />
          </Box>
          {locationItems.length === MAX_LOCATIONS ? (
            <Paper sx={style.limitBox} variant='outlined'>
              <Typography
                variant='subtitle2'
                color='error.main'
                sx={style.limitText}
              >
                You have reached the limit (4) of locations. Please, edit the
                current one or delete it to add a new location.
              </Typography>
            </Paper>
          ) : null}
        </Box>
      </Box>
      <Box sx={style.footer}>
        <ControlButtons
          confirmTitle='Add Location'
          handleCancelBtn={closeSidePanel}
          handleConfirmBtn={saveLocations}
        />
      </Box>
    </Box>
  )
}

export default AddLocationForm
