import { Box, Typography } from '@mui/material'

import React, { FC, FunctionComponent, SVGProps } from 'react'
import { useDispatch } from 'react-redux'

import { clearStore } from '../../store/rootActions'
import { deleteTokens } from '../../utils/tokens'
import OptionsButton from '../OptionsButton'
import AvatarBox from '../AvatarBox'

import style from './style'

type DashboardHeaderProps = {
  title: string
  outlined?: boolean
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>
  SearchField: React.ElementType
}

const DashboardHeader: FC<DashboardHeaderProps> = ({
  title,
  Icon,
  outlined = true,
  SearchField,
}) => {
  const dispatch = useDispatch()
  const optionsMenuItems = [
    {
      title: 'Sign out',
      onClick: () => {
        deleteTokens()
        dispatch(clearStore())
      },
    },
  ]
  return (
    <Box sx={style.header}>
      <Box sx={style.titleBox}>
        <Typography sx={style.title(outlined)}>{title}</Typography>
        {Icon && <Icon />}
      </Box>
      <Box sx={style.searchBox}>
        <SearchField />
        <OptionsButton menuItems={optionsMenuItems}>
          <AvatarBox />
        </OptionsButton>
      </Box>
    </Box>
  )
}

export default DashboardHeader
