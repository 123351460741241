import React, { ChangeEventHandler, useState } from 'react'
import moment, { MomentInput } from 'moment'

import { Box, InputLabel } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  setEventDate,
  setCrewCallTime,
} from '../../../../../store/Callsheet/actions'
import {
  selectEventDate,
  selectCrewCallTime,
} from '../../../../../store/Callsheet/selectors'

import DateTimeField from '../DateTimeField/DateTimeField'
import DatePickerInput from '../../../../../components/DatePickerInput'
import TimePickerDropdown from '../../../../../components/TimePickerDropdown'
import { getUTCDate } from '../../../../../utils/getUTCDate'
import { CalendarIcon, ClockIcon } from '../../../../../assets/icons'

const DateTimeSection = () => {
  const eventDate = useSelector(selectEventDate)
  const [pickerDate, setDate] = useState(eventDate)
  const crewCallTime = useSelector(selectCrewCallTime)
  const dispatch = useDispatch()

  const handleDateChange = (date: DateConstructor) => {
    dispatch(setEventDate(date))
    setDate(date)
  }
  const handleTimeChange: ChangeEventHandler<HTMLTextAreaElement> = ({
    target: { value },
  }) => {
    dispatch(setCrewCallTime(value))
  }

  return (
    <>
      <Box mb={3}>
        <InputLabel required>Event Date</InputLabel>
        <DateTimeField title='Date' Icon={CalendarIcon}>
          <DatePickerInput onChange={handleDateChange} value={pickerDate} />
        </DateTimeField>
      </Box>
      <Box mb={3}>
        <InputLabel required>General Call Time</InputLabel>
        <DateTimeField title='Time' Icon={ClockIcon}>
          <TimePickerDropdown
            onChange={handleTimeChange}
            value={crewCallTime}
            placeholder='Add Time'
          />
        </DateTimeField>
      </Box>
    </>
  )
}

export default DateTimeSection
