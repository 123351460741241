const style = {
  controlBtn: {
    display: 'flex',
    justifyContent: 'center',
    gap: '12px',
    button: { maxWidth: '290px' },
  },
  draftBtn: {
    padding: '4px 48px',
  },
}

export default style
