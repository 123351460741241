import { LoadingButton } from '@mui/lab'
import React, { FC, FunctionComponent, SVGProps } from 'react'
import { Typography } from '@mui/material'
import { noop } from 'lodash'
import style from './style'

type CustomTextButtonTypes = {
  title?: string
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>
  onClick?: typeof noop
  color?: string
  disabled?: boolean
  loading?: boolean
}

const CustomTextButton: FC<CustomTextButtonTypes> = ({
  title,
  Icon,
  onClick,
  color = 'primary.main',
  disabled = false,
  loading = false,
}) => {
  return (
    <LoadingButton
      variant='text'
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      sx={style.button}
    >
      {!loading ? (
        <>
          <Typography sx={style.text(disabled, color)}>{title}</Typography>
          {Icon && <Icon />}
        </>
      ) : null}
    </LoadingButton>
  )
}

export default CustomTextButton
