import React, { useEffect } from 'react'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, InputLabel } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { RequestStatuses } from '../../../../api/constants'
import { CloudIcon, EditIcon, SquareCrossIcon } from '../../../../assets/icons'
import { setIsLocationPanel, setStep } from '../../../../store/Builder/actions'
import { selectIsLocationPanel } from '../../../../store/Builder/selectors'
import { selectCallsheetId } from '../../../../store/Callsheet/selectors'
import {
  addLocationsRequest,
  getLocationsRequest,
  setIsHospital,
  setIsWeather,
} from '../../../../store/Logistics/actions'
import {
  selectIsHospital,
  selectIsWeather,
  selectLocations,
  selectRequestStatus,
} from '../../../../store/Logistics/selectors'
import AddButton from '../../../../components/AddButton'
import CheckboxPanel from '../../../../components/CheckboxPanel'
import CustomTextButton from '../../../../components/CustomTextButton'
import SidePanel from '../../../../components/SidePanel'
import { STEPS } from '../../constants'
import AddLocationForm from '../AddLocationForm'
import HeaderSection from '../CallsheetForm/HeaderSection'
import style from '../CallsheetForm/style'

const LogisticsForm = () => {
  const callsheetId = useSelector(selectCallsheetId)
  const isLocationPanel = useSelector(selectIsLocationPanel)
  const isWeather = useSelector(selectIsWeather)
  const isHospital = useSelector(selectIsHospital)
  const locations = useSelector(selectLocations)
  const requestStatus = useSelector(selectRequestStatus)
  const dispatch = useDispatch()

  const handleChangeHospital = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch(setIsHospital(checked))
  }

  const handleChangeWeather = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch(setIsWeather(checked))
  }

  const handleLocationPanel = (status: boolean) => () => {
    dispatch(setIsLocationPanel(status))
  }

  const handleEditLocations = () => {
    dispatch(setIsLocationPanel(true))
  }

  const handleBackClick = () => {
    dispatch(setStep(STEPS.overview))
  }

  const handleContinueClick = () => {
    dispatch(addLocationsRequest(locations))
  }

  useEffect(() => {
    if (callsheetId) {
      dispatch(getLocationsRequest())
    }
  }, [callsheetId])

  return (
    <Box sx={style.container}>
      <HeaderSection
        info='PROJECT OVERVIEW'
        title='Call Sheet Logistics'
        subtitle='Add as much information as you can to get started.'
      />
      <Box sx={{ mt: 3 }}>
        <Box sx={style.locationLabelBox}>
          <InputLabel required>Location</InputLabel>
          {locations.length ? (
            <CustomTextButton
              title='Edit/Add locations'
              Icon={EditIcon}
              onClick={handleEditLocations}
            />
          ) : null}
        </Box>
        {locations.length ? (
          <CheckboxPanel
            id='locations-panel'
            title={`${locations.length} Location${
              locations.length > 1 ? 's' : ''
            } Added Successfully`}
            description='You can view the locations and a site map to the right in the preview area.'
            checked
          />
        ) : (
          <AddButton title='Add location' onClick={handleLocationPanel(true)} />
        )}
      </Box>
      <Box sx={{ mt: 3 }}>
        <InputLabel>Hospital (optional)</InputLabel>
        <CheckboxPanel
          id='hospital-panel'
          title='Include Nearest Hospital Address'
          description='The nearest hospital will be automatically added due to the project location'
          onChange={handleChangeHospital}
          Icon={SquareCrossIcon}
          checked={isHospital}
        />
      </Box>
      <Box sx={{ mt: 3 }}>
        <InputLabel>Weather (optional)</InputLabel>
        <CheckboxPanel
          id='weather-panel'
          title='Include Live Weather Information'
          description='The weather will be filled automatically due to the location that you have selected earlier'
          onChange={handleChangeWeather}
          Icon={CloudIcon}
          checked={isWeather}
        />
      </Box>
      <Grid container sx={{ mt: 2 }} spacing={1}>
        <Grid item xs={6}>
          <Button
            variant='outlined'
            fullWidth
            onClick={handleBackClick}
            sx={{ color: 'text.primary', borderColor: 'text.primary' }}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={6}>
          <LoadingButton
            disabled={!locations.length}
            variant='contained'
            fullWidth
            onClick={handleContinueClick}
            loading={requestStatus === RequestStatuses.PENDING}
          >
            Continue
          </LoadingButton>
        </Grid>
      </Grid>
      <SidePanel isOpen={isLocationPanel} onClose={handleLocationPanel(false)}>
        <AddLocationForm />
      </SidePanel>
    </Box>
  )
}

export default LogisticsForm
