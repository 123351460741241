const PREFIX = 'SMS_PARAMETERS/'

export const SET_PHONE = `${PREFIX}SET_PHONE` as const
export const SET_SMS_REMINDER = `${PREFIX}SET_SMS_REMINDER` as const
export const SET_REMIND_BEFORE = `${PREFIX}SET_REMIND_BEFORE` as const
export const SET_SMS_TEXT = `${PREFIX}SET_SMS_TEXT` as const

export const SAVE_PUBLISH_DETAILS_REQUEST =
  `SAVE_PUBLISH_DETAILS_REQUEST` as const
export const SAVE_PUBLISH_DETAILS_REQUEST_SUCCESS =
  `SAVE_PUBLISH_DETAILS_REQUEST_SUCCESS` as const
export const SAVE_PUBLISH_DETAILS_REQUEST_ERROR =
  `SAVE_PUBLISH_DETAILS_REQUEST_ERROR` as const

export const GET_PUBLISH_DETAILS_REQUEST =
  `GET_PUBLISH_DETAILS_REQUEST` as const
export const GET_PUBLISH_DETAILS_REQUEST_SUCCESS =
  `GET_PUBLISH_DETAILS_REQUEST_SUCCESS` as const
export const GET_PUBLISH_DETAILS_REQUEST_ERROR =
  `GET_PUBLISH_DETAILS_REQUEST_ERROR` as const
