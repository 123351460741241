import {
  SET_COMPANY_TYPE,
  SET_IS_MEMBER_PROFILE_PANEL,
  SET_IS_ADD_CONTACT_PANEL,
  SET_IS_COMPANY_PANEL,
  SET_IS_GROUP_MEMBERS_PANEL,
  SET_IS_LOCATION_PANEL,
  SET_IS_MEMBERS_PANEL,
  SET_IS_PERSISTENT_PANEL,
  SET_IS_USER_GROUP_PANEL,
  SET_STEP,
  SET_IS_UPDATE_OVERVIEW,
  SET_IS_CALLSHEET_CONFIRM_MODAL,
  SET_IS_CALLSHEET_DECLINE_MODAL,
  SET_IS_CONFIRM_DELETE_ACTION_MODAL,
  SET_CALLSHEET_ACTION_MODAL,
  SET_IS_CONFIRM_DELETE_CALLSHEET_DIALOG,
  SET_IS_CONTACT_DETAILS_PANEL,
  SET_IS_CONFIRM_DELETE_CONTACT_MODAL,
  SET_IS_COMPANY_DETAILS_PANEL,
  SET_IS_CONFIRM_DELETE_COMPANY_MODAL,
} from './actionTypes'
import {
  SetStep,
  SetCompanyType,
  SetIsMemberProfilePanel,
  SetIsAddContactPanel,
  SetIsCompanyPanel,
  SetIsGroupMembersPanel,
  SetIsLocationPanel,
  SetIsMembersPanel,
  SetIsPersistentPanel,
  SetIsUserGroupPanel,
  SetIsUpdateOverview,
  SetIsCallsheetConfirmModal,
  SetIsCallsheetDeclineModal,
  SetIsConfirmDeleteActionModal,
  SetCallsheetActionConfirmDialog,
  CallsheetGalleryActions,
  SetIsConfirmDeleteCallsheetDialog,
  CallsheetViewActions,
  SetIsContactDetailsPanel,
  SetIsCompanyDetailsPanel,
  SetIsConfirmDeleteCompanyModal,
  SetIsConfirmDeleteContactModal,
} from './types'

export const setStep = (step: number): SetStep => ({
  type: SET_STEP,
  step,
})

export const setIsContactDetailsPanel = (
  isContactDetailsPanel: boolean
): SetIsContactDetailsPanel => ({
  type: SET_IS_CONTACT_DETAILS_PANEL,
  isContactDetailsPanel,
})

export const setIsCompanyDetailsPanel = (
  isCompanyDetailsPanel: boolean
): SetIsCompanyDetailsPanel => ({
  type: SET_IS_COMPANY_DETAILS_PANEL,
  isCompanyDetailsPanel,
})

export const setIsPersistentPanel = (
  isPersistentPanel: boolean
): SetIsPersistentPanel => ({
  type: SET_IS_PERSISTENT_PANEL,
  isPersistentPanel,
})

export const setCompanyType = (companyType: string): SetCompanyType => ({
  type: SET_COMPANY_TYPE,
  companyType,
})

export const setIsCompanyPanel = (
  isCompanyPanel: boolean
): SetIsCompanyPanel => ({
  type: SET_IS_COMPANY_PANEL,
  isCompanyPanel,
})

export const setIsMembersPanel = (
  isMembersPanel: boolean
): SetIsMembersPanel => ({
  type: SET_IS_MEMBERS_PANEL,
  isMembersPanel,
})

export const setIsMemberProfilePanel = (
  isMemberProfilePanel: boolean
): SetIsMemberProfilePanel => ({
  type: SET_IS_MEMBER_PROFILE_PANEL,
  isMemberProfilePanel,
})

export const setIsAddContactPanel = (
  isAddContactPanel: boolean
): SetIsAddContactPanel => ({
  type: SET_IS_ADD_CONTACT_PANEL,
  isAddContactPanel,
})

export const setIsLocationPanel = (
  isLocationPanel: boolean
): SetIsLocationPanel => ({
  type: SET_IS_LOCATION_PANEL,
  isLocationPanel,
})

export const setIsUserGroupPanel = (
  isUserGroupPanel: boolean
): SetIsUserGroupPanel => ({
  type: SET_IS_USER_GROUP_PANEL,
  isUserGroupPanel,
})

export const setIsGroupMembersPanel = (
  isGroupMembersPanel: boolean
): SetIsGroupMembersPanel => ({
  type: SET_IS_GROUP_MEMBERS_PANEL,
  isGroupMembersPanel,
})

export const setIsCallsheetConfirmModal = (
  isCallsheetConfirmModal: boolean
): SetIsCallsheetConfirmModal => ({
  type: SET_IS_CALLSHEET_CONFIRM_MODAL,
  isCallsheetConfirmModal,
})

export const setIsCallsheetDeclineModal = (
  isCallsheetDeclineModal: boolean
): SetIsCallsheetDeclineModal => ({
  type: SET_IS_CALLSHEET_DECLINE_MODAL,
  isCallsheetDeclineModal,
})

export const setIsConfirmDeleteActionModal = (
  isConfirmDeleteActionModal: boolean
): SetIsConfirmDeleteActionModal => ({
  type: SET_IS_CONFIRM_DELETE_ACTION_MODAL,
  isConfirmDeleteActionModal,
})

export const setIsConfirmDeleteCallsheetDialog = (
  isConfirmDeleteCallsheetDialog: boolean
): SetIsConfirmDeleteCallsheetDialog => ({
  type: SET_IS_CONFIRM_DELETE_CALLSHEET_DIALOG,
  isConfirmDeleteCallsheetDialog,
})

export const setIsConfirmDeleteCompanyModal = (
  isConfirmDeleteCompanyModal: boolean
): SetIsConfirmDeleteCompanyModal => ({
  type: SET_IS_CONFIRM_DELETE_COMPANY_MODAL,
  isConfirmDeleteCompanyModal,
})

export const setIsUpdateOverview = (
  isUpdateOverview: boolean
): SetIsUpdateOverview => ({
  type: SET_IS_UPDATE_OVERVIEW,
  isUpdateOverview,
})

export const setCallsheetActionModal = (
  open: boolean,
  action?: CallsheetGalleryActions | CallsheetViewActions
): SetCallsheetActionConfirmDialog => ({
  type: SET_CALLSHEET_ACTION_MODAL,
  action,
  open,
})

export const setIsConfirmDeleteContactModal = (
  isConfirmDeleteContactModal: boolean
): SetIsConfirmDeleteContactModal => ({
  type: SET_IS_CONFIRM_DELETE_CONTACT_MODAL,
  isConfirmDeleteContactModal,
})
