import produce from 'immer'
import { RequestStatuses } from 'src/api/constants'
import {
  DELETE_CALLSHEET_REQUEST,
  DELETE_CALLSHEET_REQUEST_ERROR,
  DELETE_CALLSHEET_REQUEST_SUCCESS,
  SET_CALLSHEET_TO_DELETE,
  SET_DELETE_CALLSHEET_MODAL,
  SET_IS_PERMANENT_DELETE,
  UPDATE_CALLSHEET_EVENT_DATE_REQUEST,
} from './actionTypes'

import { CallsheetDeleteState, CallsheetDeleteStateActions } from './types'

const callsheetDeleteInitialState: CallsheetDeleteState = {
  isPermanentDelete: false,
  deleteCallsheetModal: false,
  deleteCallsheetRequestStatus: RequestStatuses.UNCALLED,
}

const callsheetDeleteStateReducer = produce(
  (
    draft = callsheetDeleteInitialState,
    action: CallsheetDeleteStateActions
  ) => {
    switch (action.type) {
      case SET_CALLSHEET_TO_DELETE:
        draft.callsheetToDelete = {
          id: action.callsheetToDeleteId,
          title: action.title,
        }
        break
      case SET_DELETE_CALLSHEET_MODAL:
        draft.deleteCallsheetModal = action.deleteCallsheetModal
        break
      case DELETE_CALLSHEET_REQUEST:
      case UPDATE_CALLSHEET_EVENT_DATE_REQUEST:
        draft.deleteCallsheetRequestStatus = RequestStatuses.PENDING
        break
      case DELETE_CALLSHEET_REQUEST_SUCCESS:
        draft.deleteCallsheetRequestStatus = RequestStatuses.SUCCEEDED
        break
      case DELETE_CALLSHEET_REQUEST_ERROR:
        draft.deleteCallsheetRequestStatus = RequestStatuses.FAILED
        break
      case SET_IS_PERMANENT_DELETE:
        draft.isPermanentDelete = action.isPermanentDelete
        break
      default:
        return draft
    }
  }
)

export default callsheetDeleteStateReducer
