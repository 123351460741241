import { RequestStatuses } from '../../api/constants'
import {
  setGroupCallTimeByTitle,
  setGroupFavoriteByTitle,
} from '../../utils/groupHelpers'

import {
  SET_FAVORITE,
  SET_GROUPS,
  SET_GROUP_CALL_TIME,
  SET_GROUPS_DISPLAY,
  GET_GROUPS_REQUEST,
  GET_GROUPS_REQUEST_ERROR,
  GET_GROUPS_REQUEST_SUCCESS,
  UPDATE_GROUPS_REQUEST,
  UPDATE_GROUPS_REQUEST_ERROR,
  UPDATE_GROUPS_REQUEST_SUCCESS,
  SAVE_GROUPS_REQUEST,
  ADD_GROUP_REQUEST_SUCCESS,
} from './actionTypes'
import { GroupsState, BuilderActions } from './types'

const groupsInitialState: GroupsState = {
  initialGroups: [],
  groups: [],
  groupsToDelete: [],
  isStackGroupsDisplay: true,
  requestStatus: RequestStatuses.UNCALLED,
  requestUpdateStatus: RequestStatuses.UNCALLED,
}

const groupsReducer = (
  state = groupsInitialState,
  action: BuilderActions
): GroupsState => {
  switch (action.type) {
    case GET_GROUPS_REQUEST:
      return { ...state, requestStatus: RequestStatuses.PENDING }
    case GET_GROUPS_REQUEST_SUCCESS:
      return {
        ...state,
        isStackGroupsDisplay: true,
        groupsToDelete: [],
        initialGroups: [
          ...action.groups.map(item => ({ ...item, isSelected: true })),
        ],
        groups: [...action.groups.map(item => ({ ...item, isSelected: true }))],
        requestStatus: RequestStatuses.SUCCEEDED,
      }
    case GET_GROUPS_REQUEST_ERROR:
      return {
        ...state,
        isStackGroupsDisplay: true,
        requestStatus: RequestStatuses.FAILED,
      }
    case UPDATE_GROUPS_REQUEST:
      return {
        ...state,
        requestUpdateStatus: RequestStatuses.PENDING,
      }
    case UPDATE_GROUPS_REQUEST_SUCCESS:
      return {
        ...state,
        groupsToDelete: [],
        requestUpdateStatus: RequestStatuses.SUCCEEDED,
      }
    case UPDATE_GROUPS_REQUEST_ERROR:
      return { ...state, requestUpdateStatus: RequestStatuses.FAILED }
    case SET_GROUPS:
      state.groups = [...action.groups]
      state.groupsToDelete =
        action.id && state.initialGroups.some(item => item.id === action.id)
          ? [...state.groupsToDelete, action.id]
          : [...state.groupsToDelete]
      return { ...state }
    case SAVE_GROUPS_REQUEST:
      return { ...state, requestUpdateStatus: RequestStatuses.PENDING }
    case ADD_GROUP_REQUEST_SUCCESS:
      return {
        ...state,
        groups: state.groups.map(group =>
          group.title === action.title ? { ...group, id: action.id } : group
        ),
      }
    case SET_FAVORITE:
      state.groups = setGroupFavoriteByTitle(state.groups, action.title)
      return { ...state }

    case SET_GROUPS_DISPLAY:
      state.isStackGroupsDisplay = !state.isStackGroupsDisplay
      return { ...state }

    case SET_GROUP_CALL_TIME:
      return {
        ...state,
        groups: setGroupCallTimeByTitle(
          state.groups,
          action.title,
          action.callTime
        ),
      }

    default:
      return state
  }
}

export default groupsReducer
