import {
  call,
  put,
  takeLatest,
  select,
  takeEvery,
  SelectEffect,
  CallEffect,
  PutEffect,
} from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { addMemberApi, deleteMemberApi } from '../../api/members'
import { STEPS } from '../../pages/BuilderPage/constants'
import { getGroupIdByTitle } from '../../utils/groupHelpers'
import { SELECT_GROUP_PLACEHOLDER } from '../../utils/groupMemberHelpers'
import { setStep } from '../Builder/actions'
import { getMembersRequest, memberRequestError } from '../GroupMembers/actions'
import { selectGroupMembers } from '../GroupMembers/selectors'
import { GroupMember } from '../GroupMembers/types'
import { GET_GROUPS_REQUEST, SAVE_GROUPS_REQUEST } from './actionTypes'
import {
  getUserGroupsApi,
  addUserGroupApi,
  deleteUserGroupApi,
  updateUserGroupApi,
} from '../../api/groups'
import {
  AddUserGroupResultType,
  GetGroupsResultType,
} from '../../api/groups/types'
import { SampleError } from '../../api/types'
import {
  getGroupsRequestError,
  getGroupsRequestSuccess,
  addGroupRequestSuccess,
  updateGroupsRequestError,
  updateGroupsRequestSuccess,
} from './actions'
import { GroupType } from './types'

import {
  selectAddedGroups,
  selectGroups,
  selectGroupsToDelete,
  selectGroupsToUpdate,
} from './selectors'
import { selectCallsheetId } from '../Callsheet/selectors'
import { setNotificationErrorWorker } from '../Notification/sagas'

export function* GetUserGroupsRequestWorker() {
  try {
    const callsheetId: string = yield select(selectCallsheetId)
    const {
      data: { data },
    }: AxiosResponse<GetGroupsResultType> = yield call(
      getUserGroupsApi,
      callsheetId
    )
    yield put(getGroupsRequestSuccess(data))
    if (data.length) yield put(getMembersRequest(callsheetId))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(getGroupsRequestError(data as SampleError))
    yield put(memberRequestError())
  }
}

export function* AddUserGroupsRequestWorker() {
  const callsheetId: string = yield select(selectCallsheetId)
  const addedGroups: GroupType[] = yield select(selectAddedGroups)

  if (addedGroups.length) {
    for (let i = 0; i < addedGroups.length; i += 1) {
      const { id, title, callTime, isPrimary } = addedGroups[i]
      if (!id) {
        const {
          data: { data },
        }: AxiosResponse<AddUserGroupResultType> = yield call(
          addUserGroupApi,
          callsheetId as unknown as string,
          title,
          callTime,
          isPrimary
        )

        yield put(addGroupRequestSuccess(title, data.id))
      }
    }
  }
}

export function* DeleteUserGroupsRequestWorker() {
  const deletedGroups: string[] = yield select(selectGroupsToDelete)

  if (deletedGroups.length) {
    for (let i = 0; i < deletedGroups.length; i += 1) {
      yield call(deleteUserGroupApi, deletedGroups[i])
    }
  }
}

export function* UpdateUserGroupsRequestWorker(): Generator<
  SelectEffect | CallEffect | PutEffect,
  void,
  GroupType[]
> {
  const updatedGroups = yield select(selectGroupsToUpdate)

  if (updatedGroups.length) {
    updatedGroups.sort(
      (a: GroupType, b: GroupType) => Number(a.isPrimary) - Number(b.isPrimary)
    )
    for (let i = 0; i < updatedGroups.length; i += 1) {
      const { id, title, callTime, isPrimary } = updatedGroups[i]
      if (id) {
        yield call(updateUserGroupApi, id, title, callTime, isPrimary)
      }
    }
  }
}

export function* DeleteUserGroupsMembersRequestWorker() {
  const members: GroupMember[] = yield select(selectGroupMembers)

  if (members.length) {
    for (let i = 0; i < members.length; i += 1) {
      const { id, groupTitle, userGroupId, newUserGroupId } = members[i]

      if (
        userGroupId &&
        ((newUserGroupId && newUserGroupId !== userGroupId) ||
          groupTitle === SELECT_GROUP_PLACEHOLDER)
      ) {
        if (userGroupId) {
          yield call(deleteMemberApi, id)
        }
      }
    }
  }
}

export function* AddUserGroupsMembersRequestWorker() {
  const members: GroupMember[] = yield select(selectGroupMembers)
  const groups: GroupType[] = yield select(selectGroups)

  if (members.length) {
    for (let i = 0; i < members.length; i += 1) {
      const {
        groupTitle,
        id,
        contactId,
        callTime,
        wrapTime,
        isUpdated,
        hidePersonalInfo,
      } = members[i]

      if ((!contactId || isUpdated) && groupTitle) {
        const userGroupId = getGroupIdByTitle(groups, groupTitle)
        if (userGroupId) {
          yield call(
            addMemberApi,
            userGroupId,
            isUpdated ? contactId ?? id : id,
            hidePersonalInfo,
            callTime,
            wrapTime
          )
        }
      }
    }
  }
}

export function* SaveUserGroupsRequestWorker() {
  try {
    yield call(DeleteUserGroupsRequestWorker)
    yield call(UpdateUserGroupsRequestWorker)
    yield call(AddUserGroupsRequestWorker)
    yield call(DeleteUserGroupsMembersRequestWorker)
    yield call(AddUserGroupsMembersRequestWorker)

    yield put(updateGroupsRequestSuccess())
    yield put(setStep(STEPS.finalTouches))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(updateGroupsRequestError(data as SampleError))
  }
}

export function* requestUserGroupsWatcher(): Generator {
  yield takeLatest(GET_GROUPS_REQUEST, GetUserGroupsRequestWorker)
  yield takeEvery(SAVE_GROUPS_REQUEST, SaveUserGroupsRequestWorker)
}
