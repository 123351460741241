const style = {
  container: {
    textAlign: 'center',
    width: '458px',
  },
  locationLabelBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
}

export default style
