const style = {
  contactsContainer: {
    maxHeight: 'calc(100vh - 360px)',
    overflowX: 'auto',
    gap: 2,
    paddingRight: 1,
  },
  filterSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}

export default style
