import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  acceptCallsheetApi,
  declineCallsheetApi,
  duplicateCallsheetApi,
  pendingCallsheetApi,
  publishCallsheetApi,
  restoreCallsheetApi,
  tentativeCallsheetApi,
} from 'src/api/callsheet'
import { SampleError } from 'src/api/types'
import { STEPS } from 'src/components/CallsheetConfirmationModal/constants'
import { PATHS } from 'src/constants'
import {
  setCallsheetActionModal,
  setIsCallsheetDeclineModal,
} from '../Builder/actions'
import { CallsheetGalleryActions, CallsheetViewActions } from '../Builder/types'
import { GetCallsheetsRequestWorker } from '../Callsheet/sagas'
import { selectCallsheetId } from '../Callsheet/selectors'
import { setNotificationErrorWorker } from '../Notification/sagas'
import {
  callsheetChangeStatusRequestError,
  callsheetChangeStatusRequestSuccess,
  publishCallsheetRequestError,
  publishCallsheetRequestSuccess,
  setConfirmationStep,
} from './actions'
import {
  ACCEPT_CALLSHEET_REQUEST,
  ACCEPT_CONFIRMATION_REQUEST,
  DECLINE_CALLSHEET_REQUEST,
  DECLINE_CONFIRMATION_REQUEST,
  DUPLICATE_CALLSHEET_REQUEST,
  PENDING_CALLSHEET_REQUEST,
  PUBLISH_CALLSHEET_REQUEST,
  RESTORE_CALLSHEET_REQUEST,
  TENTATIVE_CALLSHEET_REQUEST,
  TENTATIVE_CONFIRMATION_REQUEST,
} from './actionTypes'
import {
  DuplicateCallsheetRequestType,
  PublishCallsheetRequestType,
  RestoreCallsheetRequestType,
} from './types'

export function* DeclineCallsheetRequestWorker() {
  const callsheetId: string = yield select(selectCallsheetId)

  try {
    yield call(declineCallsheetApi, callsheetId)
    yield put(callsheetChangeStatusRequestSuccess())
    yield put(setCallsheetActionModal(true, CallsheetViewActions.DECLINE))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(callsheetChangeStatusRequestError(data as SampleError))
  }
}

export function* DeclineConfirmationRequestWorker() {
  const callsheetId: string = yield select(selectCallsheetId)

  try {
    yield call(declineCallsheetApi, callsheetId)
    yield put(callsheetChangeStatusRequestSuccess())
    yield put(setIsCallsheetDeclineModal(true))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(callsheetChangeStatusRequestError(data as SampleError))
  }
}

export function* TentativeCallsheetRequestWorker() {
  const callsheetId: string = yield select(selectCallsheetId)

  try {
    yield call(tentativeCallsheetApi, callsheetId)
    yield put(callsheetChangeStatusRequestSuccess())
    yield put(setCallsheetActionModal(true, CallsheetViewActions.TENTATIVE))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(callsheetChangeStatusRequestError(data as SampleError))
  }
}

export function* PendingCallsheetRequestWorker() {
  const callsheetId: string = yield select(selectCallsheetId)

  try {
    yield call(pendingCallsheetApi, callsheetId)
    yield put(callsheetChangeStatusRequestSuccess())
    yield put(setIsCallsheetDeclineModal(false))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(callsheetChangeStatusRequestError(data as SampleError))
  }
}

export function* TentativeConfirmationRequestWorker() {
  const callsheetId: string = yield select(selectCallsheetId)

  try {
    yield call(tentativeCallsheetApi, callsheetId)
    yield put(callsheetChangeStatusRequestSuccess())
    yield put(setConfirmationStep(STEPS.done))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(callsheetChangeStatusRequestError(data as SampleError))
  }
}

export function* AcceptCallsheetRequestWorker() {
  const callsheetId: string = yield select(selectCallsheetId)

  try {
    yield call(acceptCallsheetApi, callsheetId)
    yield put(callsheetChangeStatusRequestSuccess())
    yield put(setCallsheetActionModal(true, CallsheetViewActions.ACCEPT))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(callsheetChangeStatusRequestError(data as SampleError))
  }
}

export function* AcceptConfirmationRequestWorker() {
  const callsheetId: string = yield select(selectCallsheetId)

  try {
    yield call(acceptCallsheetApi, callsheetId)
    yield put(callsheetChangeStatusRequestSuccess())
    yield put(setConfirmationStep(STEPS.done))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(callsheetChangeStatusRequestError(data as SampleError))
  }
}

export function* PublishCallsheetRequestWorker({
  history,
}: PublishCallsheetRequestType) {
  const callsheetId: string = yield select(selectCallsheetId)

  try {
    yield call(publishCallsheetApi, callsheetId)
    yield put(publishCallsheetRequestSuccess())
    yield call(history.push, PATHS.PRIVATE.SUCCESS)
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(publishCallsheetRequestError(data as SampleError))
  }
}

export function* DuplicateCallsheetRequestWorker({
  payload: { id },
}: DuplicateCallsheetRequestType) {
  try {
    yield call(duplicateCallsheetApi, id)
    yield put(callsheetChangeStatusRequestSuccess(id))
    yield put(setCallsheetActionModal(true, CallsheetGalleryActions.DUPLICATE))
    yield call(GetCallsheetsRequestWorker)
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(callsheetChangeStatusRequestError(data as SampleError))
  }
}

export function* RestoreCallsheetRequestWorker({
  payload: { id },
}: RestoreCallsheetRequestType) {
  try {
    yield call(restoreCallsheetApi, id)
    yield put(callsheetChangeStatusRequestSuccess(id))
    yield put(setCallsheetActionModal(true, CallsheetGalleryActions.RESTORE))
    yield call(GetCallsheetsRequestWorker)
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(callsheetChangeStatusRequestError(data as SampleError))
  }
}

export function* requestCallsheetStatusWatcher(): Generator {
  yield takeLatest(DECLINE_CALLSHEET_REQUEST, DeclineCallsheetRequestWorker)
  yield takeLatest(PENDING_CALLSHEET_REQUEST, PendingCallsheetRequestWorker)
  yield takeLatest(
    DECLINE_CONFIRMATION_REQUEST,
    DeclineConfirmationRequestWorker
  )
  yield takeLatest(ACCEPT_CALLSHEET_REQUEST, AcceptCallsheetRequestWorker)
  yield takeLatest(ACCEPT_CONFIRMATION_REQUEST, AcceptConfirmationRequestWorker)
  yield takeLatest(TENTATIVE_CALLSHEET_REQUEST, TentativeCallsheetRequestWorker)
  yield takeLatest(
    TENTATIVE_CONFIRMATION_REQUEST,
    TentativeConfirmationRequestWorker
  )
  yield takeLatest(PUBLISH_CALLSHEET_REQUEST, PublishCallsheetRequestWorker)
  yield takeLatest(RESTORE_CALLSHEET_REQUEST, RestoreCallsheetRequestWorker)
  yield takeLatest(DUPLICATE_CALLSHEET_REQUEST, DuplicateCallsheetRequestWorker)
}
