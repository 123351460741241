import { noop } from 'lodash'
import React, { FC, PropsWithChildren, useState } from 'react'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import style from './style'

type MenuItemProps = {
  title: string
  onClick?: typeof noop
  disabled?: boolean
  hidden?: boolean
  red?: boolean
}

type OptionsButtonTypes = {
  menuItems: MenuItemProps[]
}

const OptionsButton: FC<OptionsButtonTypes & PropsWithChildren> = ({
  children,
  menuItems,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <IconButton sx={style.iconBtn} onClick={handleClick}>
        {children}
      </IconButton>
      <Menu
        id='dropdown-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={style.menu}
      >
        {menuItems.map(({ title, onClick, disabled, hidden, red }) => (
          <Box key={title}>
            {hidden ? null : (
              <MenuItem
                sx={style.menuItem(red)}
                onClick={() => {
                  onClick && onClick()
                  handleClose()
                }}
                disabled={disabled}
              >
                {title}
              </MenuItem>
            )}
          </Box>
        ))}
      </Menu>
    </Box>
  )
}

export default OptionsButton
