const style = {
  builderBtnLarge: {
    marginRight: '20px',
    display: 'flex',
    alignItems: 'center',
  },

  builderBtn: {
    color: '#FFFFFF',
  },
  builderBtnText: {
    fontWeight: 500,
  },

  menuItem: { minWidth: '206px', fontSize: '14px', fontHeight: '20px' },
}

export default style
