import { union } from 'lodash'
import { useDispatch } from 'react-redux'
import React, { FC, useCallback, useEffect, useState } from 'react'

import {
  Box,
  Button,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  ListSubheader,
} from '@mui/material'

import { SelectChangeEvent } from '@mui/material/Select'

import {
  DEFAULT_GROUP_STATE,
  DEFAULT_TAG,
  filterGroups,
  mapFilterStatuses,
  MenuProps,
  tagColor,
} from './constants'

import SelectFilterInput from '../../SelectFilterInput'
import { CaretBottomIcon, CrossCircleIcon } from '../../../assets/icons'
import CustomCheckbox from '../../CustomCheckbox/CustomCheckbox'
import {
  setCallsheetsFilter,
  setCallsheetsSortTag,
} from '../../../store/Gallery/actions'

import style from './style'

type FilterProps = {
  label: string
  defaultTag?: string
}

const CallsheetFilter: FC<FilterProps> = ({
  label,
  defaultTag = DEFAULT_TAG,
}) => {
  const dispatch = useDispatch()
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [selectedGroups, setSelectedGroups] = useState<{
    [key: string]: boolean
  }>(DEFAULT_GROUP_STATE)

  const handleSelectChange = (
    event: SelectChangeEvent<typeof selectedTags>
  ) => {
    const {
      target: { value },
    } = event

    setSelectedTags(typeof value === 'string' ? value.split(',') : value)
  }

  const handleShowAll = useCallback(() => {
    setSelectedGroups(DEFAULT_GROUP_STATE)
    setSelectedTags([])
    dispatch(setCallsheetsFilter([]))
  }, [])

  const renderValue = (selected: string[]) => {
    if (selectedTags.length === 1) {
      return <b style={style.text(tagColor[selected[0]])}>{selected[0]}</b>
    }

    if (selectedTags.length > 2) {
      return `${selected[0]} and ${selected.length - 1}+`
    }

    return selectedTags.length === 0 ? defaultTag : selected.join(', ')
  }

  const handleGroupSelect = (group: string) => () => {
    const nextValue = !selectedGroups[group]
    if (!nextValue) {
      setSelectedTags(
        selectedTags.filter(
          item => !filterGroups[group].filters.some(filter => filter === item)
        )
      )
      setSelectedGroups(prev => ({ ...prev, [group]: false }))
    } else {
      setSelectedGroups(prev => ({ ...prev, [group]: true }))
    }
  }

  const isGroupSelected = (group: string): boolean =>
    filterGroups[group].filters.every(entry =>
      selectedTags.some(item => item === entry)
    )

  useEffect(() => {
    const { invitations, myCallsheets } = selectedGroups
    const newTags: string[] = [
      ...(invitations ? filterGroups.invitations.filters : []),
      ...(myCallsheets ? filterGroups.myCallsheets.filters : []),
    ]

    setSelectedTags(prev => union(prev, newTags))
  }, [selectedGroups])

  const handleApply = () => {
    dispatch(setCallsheetsFilter(mapFilterStatuses(selectedTags)))
    setOpen(false)
    dispatch(setCallsheetsSortTag())
  }

  useEffect(() => {}, [selectedTags])

  const isDefault = selectedTags.length === 0

  return (
    <div>
      <FormControl>
        <Select
          id='filter-multiple-checkbox'
          multiple
          displayEmpty
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          value={selectedTags}
          onChange={handleSelectChange}
          MenuProps={MenuProps}
          input={
            <SelectFilterInput
              outlined
              label={isDefault ? label : `${label}ed by`}
              inputStyleProps={{
                width: '250px',
                borderColor: isDefault ? 'text.disabled' : 'text.primary',
              }}
              color={isDefault ? 'text.primary' : 'text.disabled'}
            />
          }
          renderValue={renderValue}
          IconComponent={props =>
            selectedTags.length !== 1 ? (
              <Box {...props} sx={style.icon}>
                <CaretBottomIcon />
              </Box>
            ) : (
              <Box position='absolute' right='10.5px' top='4.5px'>
                <CrossCircleIcon cursor='pointer' onClick={handleShowAll} />
              </Box>
            )
          }
        >
          {[
            ...Object.keys(filterGroups).map(group => [
              <ListSubheader
                sx={{ display: 'flex', alignItems: 'center' }}
                onClick={handleGroupSelect(group)}
              >
                <CustomCheckbox
                  checked={isGroupSelected(group) && selectedGroups[group]}
                />
                <ListItemText
                  primary={
                    <span>
                      {label} by{' '}
                      <b style={style.text()}>{filterGroups[group].groupTag}</b>
                    </span>
                  }
                />
              </ListSubheader>,
              ...filterGroups[group].filters.map(tag => (
                <MenuItem key={tag} value={tag} sx={style.item}>
                  <CustomCheckbox checked={selectedTags.indexOf(tag) > -1} />
                  <ListItemText
                    primary={
                      <span>
                        {label} by{' '}
                        <b style={style.text(tagColor[tag])}>
                          {tag === 'Live' ? '•Live' : tag}
                        </b>
                      </span>
                    }
                  />
                </MenuItem>
              )),
            ]),
            selectedTags.length > 1 && (
              <Button
                key='clear-all'
                sx={style.button()}
                onClick={handleShowAll}
              >
                Clear All Selections
              </Button>
            ),
            <Button
              key='apply'
              sx={style.button('apply')}
              onClick={handleApply}
            >
              Apply Filter Changes
            </Button>,
          ]}
        </Select>
      </FormControl>
    </div>
  )
}

export default CallsheetFilter
