const style = {
  wrapper: {
    minWidth: '565px',
    padding: 3,
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    boxShadow: '0px 0px 16px 0px #85858526',
    textAlign: 'left',
  },
  editBtn: {
    padding: '2px 12px',
    fontSize: '14px',
    fontWeight: 900,
    lineHeight: '20px',
    textAlign: 'right',
    borderRadius: '4px',
    border: '1px solid #265A88',
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '28px',
    color: 'text.primary',
  },
  pill: {
    backgroundColor: '#7C9CB7',
    height: '16px',
    width: '37px',
    color: 'white',
    padding: '2px 12px',
    fontWeight: 900,
    fontSize: '10px',
    lineHeight: '20px',
    borderRadius: '26px',
    span: {
      padding: '0px',
    },
  },
}

export default style
