import React, { FC, useState } from 'react'
import { noop } from 'lodash'
import { Box, ButtonBase, Typography } from '@mui/material'
import ImageUploading, { ImageListType } from 'react-images-uploading'

import { ImageIcon, UploadSimpleIcon } from '../../../../../assets/icons'
import { MAX_SITEMAP_SIZE } from '../../../../../constants'
import {
  getBase64FileSize,
  prepareImageValue,
  stripBase64Data,
} from '../../../../../utils/image'

import style from './style'

type ImageUploadFieldTypes = {
  onChange: typeof noop
  defaultValue?: string
}

const SitemapUpload: FC<ImageUploadFieldTypes> = ({
  onChange,
  defaultValue,
}) => {
  const [image, setImage] = useState(prepareImageValue(defaultValue))

  const handleChange = (imageList: ImageListType) => {
    setImage(imageList as never[])
    onChange(imageList[0] && stripBase64Data(imageList[0].dataURL))
  }

  return (
    <ImageUploading
      value={image}
      onChange={handleChange}
      maxFileSize={MAX_SITEMAP_SIZE}
    >
      {({ imageList, onImageUpload, dragProps }) => (
        <Box>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='flex-start'
          />
          <Box
            className='upload__image-wrapper'
            sx={style.wrapper}
            {...dragProps}
          >
            {image.length ? (
              <Box>
                {imageList.map(currentImage => (
                  <Box key={currentImage.dataURL} display='flex'>
                    <Box sx={{ mr: 0 }}>
                      <ImageIcon />
                    </Box>
                    <Box display='flex' flexDirection='column'>
                      <Typography
                        variant='subtitle2'
                        color='primary.main'
                        sx={style.buttonTitle}
                      >
                        <ButtonBase onClick={onImageUpload} sx={style.button}>
                          sitemap
                        </ButtonBase>
                      </Typography>
                      {currentImage.dataURL ? (
                        <Typography variant='body1' color='text.disabled'>
                          {`${getBase64FileSize(
                            currentImage.dataURL.length
                          )}KB`}
                        </Typography>
                      ) : null}
                    </Box>
                  </Box>
                ))}
              </Box>
            ) : (
              <Box
                display='flex'
                textAlign='center'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
              >
                <UploadSimpleIcon />
                <ButtonBase onClick={onImageUpload} sx={{ mt: '10px' }}>
                  <Typography variant='h5' color='primary.main'>
                    Upload SiteMap
                  </Typography>
                </ButtonBase>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </ImageUploading>
  )
}

export default SitemapUpload
