import { NotificationStateType, NotificationActions } from './types'
import {
  SET_NOTIFICATION,
  SET_NOTIFICATION_STATE_TO_INITIAL,
} from './actionTypes'

const initialState: NotificationStateType = {
  lastRequestError: null,
}

const notificationReducer = (
  state = initialState,
  action: NotificationActions
): NotificationStateType => {
  switch (action.type) {
    case SET_NOTIFICATION:
      return {
        ...state,
        lastRequestError: action.error,
      }
    case SET_NOTIFICATION_STATE_TO_INITIAL:
      return { ...initialState }
    default:
      return state
  }
}

export default notificationReducer
