import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { setCallsheetStatisticId } from 'src/store/Gallery/actions'
import { setIsConfirmDeleteCallsheetDialog } from '../../store/Builder/actions'
import {
  setCallsheetToDelete,
  setDeleteCallsheetModal,
  setIsPermanentDelete,
} from '../../store/CallsheetDeleteState/actions'
import { setCallsheetId } from '../../store/Callsheet/actions'
import {
  CallsheetStatuses,
  CallsheetStatusType,
} from '../../store/Callsheet/types'
import {
  duplicateCallsheetRequest,
  restoreCallsheetRequest,
} from '../../store/CallsheetStatus/actions'

import { selectUserId } from '../../store/User/selectors'

export const useOptions = ({
  id,
  status,
  ownerId,
  title,
}: {
  id: string
  title: string
  ownerId?: string
  status: CallsheetStatusType
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isAdmin = useSelector(selectUserId) === ownerId

  const deleteOption = {
    title: 'Delete',
    onClick: isAdmin
      ? () => {
          dispatch(setCallsheetToDelete(id, title))
          dispatch(setIsPermanentDelete(false))
          dispatch(setDeleteCallsheetModal(true))
        }
      : () => {
          dispatch(setCallsheetToDelete(id, title))
          dispatch(setIsPermanentDelete(false))
          dispatch(setIsConfirmDeleteCallsheetDialog(true))
        },
    red: true,
  }

  const adminMenuItems = [
    {
      title: 'Edit & Send',
      onClick: () => {
        dispatch(setCallsheetId(id))
        history.push('/builder')
      },
    },
    {
      title: 'Statistics',
      onClick: () => {
        dispatch(setCallsheetStatisticId(id))
      },
    },
    {
      title: 'Duplicate',
      disabled: false,
      onClick: () => dispatch(duplicateCallsheetRequest(id)),
    },
    deleteOption,
  ]

  const memberMenuItems = [
    {
      title: 'Change Status',
      onClick: () => {
        dispatch(setCallsheetId(id))
        history.push(`/callsheet/${id}`)
      },
    },
    deleteOption,
  ]

  if (status === CallsheetStatuses.DELETED) {
    return {
      optionsMenuItems: [
        {
          title: 'Restore Callsheet',
          onClick: () => dispatch(restoreCallsheetRequest(id)),
          red: true,
        },
        {
          title: 'Delete',
          onClick: () => {
            dispatch(setCallsheetToDelete(id, title))
            dispatch(setIsPermanentDelete(true))
            dispatch(setIsConfirmDeleteCallsheetDialog(true))
          },
          red: true,
        },
      ],
    }
  }

  if (status === CallsheetStatuses.DRAFT) {
    return {
      optionsMenuItems: [
        {
          title: 'Edit',
          onClick: () => {
            dispatch(setCallsheetId(id))
            history.push('/builder')
          },
        },
        deleteOption,
      ],
    }
  }

  if (status === CallsheetStatuses.PENDING) {
    return {
      optionsMenuItems: [
        {
          title: 'Accept Callsheet',
          onClick: () => {
            dispatch(setCallsheetId(id))
            history.push(`/callsheet/${id}`)
          },
        },
      ],
    }
  }

  if (isAdmin) return { optionsMenuItems: adminMenuItems }

  return { optionsMenuItems: memberMenuItems }
}
