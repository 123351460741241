const style = {
  autocomplete: (
    inputStyles: { [key: string]: number | string },
    width?: string
  ) => ({
    width,
    '& .MuiOutlinedInput-root': {
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingLeft: '0px',
      '& .MuiAutocomplete-input': {
        padding: '12px 14px',
        ...inputStyles,
      },
    },
  }),
}

export default style
