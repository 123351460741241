import produce from 'immer'
import {
  SET_EMAIL_ATTACHMENT,
  SET_EMAIL_DESCRIPTION,
  SET_EMAIL_FROM,
  SET_EMAIL_PARAMETERS,
  SET_EMAIL_REMINDER,
  SET_EMAIL_SUBJECT,
  SWITCH_EMAIL_REMINDER,
} from './actionTypes'
import { EmailParametersActions, EmailParametersState } from './types'

const emailParametersInitialState: EmailParametersState = {
  emailParameters: {
    emailSubject: '',
    emailContent: '',
    emailReminder: 12,
    emailFrom: '',
    emailAttachment: false,
    isEmailReminder: false,
  },
}

const emailParametersReducer = produce(
  (draft = emailParametersInitialState, action: EmailParametersActions) => {
    switch (action.type) {
      case SET_EMAIL_PARAMETERS:
        draft.emailParameters = {
          ...draft.emailParameters,
          ...action.emailParameters,
        }
        break
      case SET_EMAIL_FROM:
        draft.emailParameters.emailFrom = action.emailFrom
        break
      case SET_EMAIL_REMINDER:
        draft.emailParameters.emailReminder = action.emailReminder
        break
      case SWITCH_EMAIL_REMINDER:
        draft.emailParameters.isEmailReminder =
          !draft.emailParameters.isEmailReminder
        break
      case SET_EMAIL_DESCRIPTION:
        draft.emailParameters.emailContent = action.emailContent
        break
      case SET_EMAIL_SUBJECT:
        draft.emailParameters.emailSubject = action.emailSubject
        break
      case SET_EMAIL_ATTACHMENT:
        draft.emailParameters.emailAttachment =
          !draft.emailParameters.emailAttachment
        break
      default:
        return draft
    }
  }
)

export default emailParametersReducer
