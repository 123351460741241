import { STEPS } from '../../pages/BuilderPage/constants'
import {
  SET_COMPANY_TYPE,
  SET_IS_MEMBER_PROFILE_PANEL,
  SET_IS_ADD_CONTACT_PANEL,
  SET_IS_COMPANY_PANEL,
  SET_IS_GROUP_MEMBERS_PANEL,
  SET_IS_LOCATION_PANEL,
  SET_IS_MEMBERS_PANEL,
  SET_IS_PERSISTENT_PANEL,
  SET_IS_USER_GROUP_PANEL,
  SET_STEP,
  SET_IS_UPDATE_OVERVIEW,
  SET_IS_CALLSHEET_CONFIRM_MODAL,
  SET_IS_CALLSHEET_DECLINE_MODAL,
  SET_IS_CONFIRM_DELETE_ACTION_MODAL,
  SET_CALLSHEET_ACTION_MODAL,
  SET_IS_CONFIRM_DELETE_CALLSHEET_DIALOG,
  SET_IS_CONTACT_DETAILS_PANEL,
  SET_IS_CONFIRM_DELETE_CONTACT_MODAL,
  SET_IS_COMPANY_DETAILS_PANEL,
  SET_IS_CONFIRM_DELETE_COMPANY_MODAL,
} from './actionTypes'
import { BuilderActions, BuilderState, CallsheetGalleryActions } from './types'

const builderInitialState: BuilderState = {
  step: STEPS.overview,
  isContactDetailsPanel: false,
  isCompanyDetailsPanel: false,
  isPersistentPanel: false,
  isCompanyPanel: false,
  isMembersPanel: false,
  isMemberProfilePanel: false,
  isAddContactPanel: false,
  isLocationPanel: false,
  isUserGroupPanel: false,
  isGroupMembersPanel: false,
  isUpdateOverview: false,
  isConfirmDeleteContactModal: false,
  isCallsheetConfirmModal: false,
  isCallsheetDeclineModal: false,
  isConfirmDeleteCompanyModal: false,
  isConfirmDeleteActionModal: false,
  callsheetActionConfirmModal: {
    action: CallsheetGalleryActions.DUPLICATE,
    open: false,
  },
  isConfirmDeleteCallsheetDialog: false,
  companyType: 'production',
}

const builderReducer = (
  state = builderInitialState,
  action: BuilderActions
): BuilderState => {
  switch (action.type) {
    case SET_STEP:
      return { ...state, step: action.step }
    case SET_IS_CONTACT_DETAILS_PANEL:
      return { ...state, isContactDetailsPanel: action.isContactDetailsPanel }
    case SET_IS_COMPANY_DETAILS_PANEL:
      return { ...state, isCompanyDetailsPanel: action.isCompanyDetailsPanel }
    case SET_IS_PERSISTENT_PANEL:
      return { ...state, isPersistentPanel: action.isPersistentPanel }
    case SET_IS_COMPANY_PANEL:
      return { ...state, isCompanyPanel: action.isCompanyPanel }
    case SET_IS_MEMBERS_PANEL:
      return { ...state, isMembersPanel: action.isMembersPanel }
    case SET_IS_MEMBER_PROFILE_PANEL:
      return { ...state, isMemberProfilePanel: action.isMemberProfilePanel }
    case SET_IS_ADD_CONTACT_PANEL:
      return { ...state, isAddContactPanel: action.isAddContactPanel }
    case SET_IS_LOCATION_PANEL:
      return { ...state, isLocationPanel: action.isLocationPanel }
    case SET_IS_USER_GROUP_PANEL:
      return { ...state, isUserGroupPanel: action.isUserGroupPanel }
    case SET_IS_GROUP_MEMBERS_PANEL:
      return { ...state, isGroupMembersPanel: action.isGroupMembersPanel }
    case SET_COMPANY_TYPE:
      return { ...state, companyType: action.companyType }
    case SET_IS_UPDATE_OVERVIEW:
      return { ...state, isUpdateOverview: action.isUpdateOverview }
    case SET_IS_CALLSHEET_CONFIRM_MODAL:
      return {
        ...state,
        isCallsheetConfirmModal: action.isCallsheetConfirmModal,
      }
    case SET_IS_CALLSHEET_DECLINE_MODAL:
      return {
        ...state,
        isCallsheetDeclineModal: action.isCallsheetDeclineModal,
      }
    case SET_IS_CONFIRM_DELETE_ACTION_MODAL:
      return {
        ...state,
        isConfirmDeleteActionModal: action.isConfirmDeleteActionModal,
      }
    case SET_CALLSHEET_ACTION_MODAL:
      return {
        ...state,
        callsheetActionConfirmModal: action.action
          ? {
              ...state.callsheetActionConfirmModal,
              open: action.open,
              action: action.action,
            }
          : { ...state.callsheetActionConfirmModal, open: action.open },
      }
    case SET_IS_CONFIRM_DELETE_CALLSHEET_DIALOG:
      return {
        ...state,
        isConfirmDeleteCallsheetDialog: action.isConfirmDeleteCallsheetDialog,
      }
    case SET_IS_CONFIRM_DELETE_CONTACT_MODAL:
      return {
        ...state,
        isConfirmDeleteContactModal: action.isConfirmDeleteContactModal,
      }
    case SET_IS_CONFIRM_DELETE_COMPANY_MODAL:
      return {
        ...state,
        isConfirmDeleteCompanyModal: action.isConfirmDeleteCompanyModal,
      }
    default:
      return state
  }
}

export default builderReducer
