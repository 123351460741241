import { Box, ButtonBase, Typography } from '@mui/material'
import moment from 'moment/moment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setStep } from '../../../../store/Builder/actions'
import {
  selectCallsheetTitle,
  selectCrewCallTime,
  selectEventDate,
} from '../../../../store/Callsheet/selectors'
import Loader from '../../../../components/Loader'
import { STEPS } from '../../constants'
import style from './style'

const SubtitleInfo = () => {
  const callsheetTitle = useSelector(selectCallsheetTitle)
  const eventDate = useSelector(selectEventDate)
  const callTime = useSelector(selectCrewCallTime)
  const dispatch = useDispatch()

  const handleButtonClick = () => {
    dispatch(setStep(STEPS.overview))
  }

  return (
    <>
      {callsheetTitle && eventDate && callTime ? (
        <Box sx={{ mb: 4 }}>
          <Typography variant='subtitle1' sx={style.title}>
            You are confirming the CallSheet{' '}
            <span style={style.callsheetTitle}>{callsheetTitle}</span> will be
            published on
          </Typography>
          <Typography variant='h5'>
            {moment(eventDate.toString()).format('dddd, MMMM, YYYY')} at{' '}
            {callTime}.
          </Typography>
          <ButtonBase onClick={handleButtonClick} sx={style.buttonBase}>
            <Typography variant='subtitle2' sx={style.buttonText}>
              Click here to change date and time
            </Typography>
          </ButtonBase>
        </Box>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default SubtitleInfo
