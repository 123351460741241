import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'

import {
  getPublishDetailsRequest,
  savePublishDetailsRequest,
} from '../../../store/PublishDetails/actions'
import {
  selectPublishDetailsRequestStatus,
  selectPublishDetailsSaveRequestStatus,
} from '../../../store/PublishDetails/selectors'

import HeaderSection from '../ProjectOverview/CallsheetForm/HeaderSection'
import RecipientsList from './RecipientsList'
import SubtitleInfo from './SubtitleInfo'
import SmsSection from './SmsSection'
import EmailSection from './EmailSection'
import Loader from '../../../components/Loader'

import { RequestStatuses } from '../../../api/constants'

import style from './style'

const PublishAndSend = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isDisabled, setDisabled] = useState<boolean>(true)
  const requestStatus = useSelector(selectPublishDetailsRequestStatus)
  const saveRequestStatus = useSelector(selectPublishDetailsSaveRequestStatus)

  const handleSubmit = () => {
    dispatch(savePublishDetailsRequest(history))
  }

  const handleSaveAsDraft = () => {
    dispatch(savePublishDetailsRequest(history, true))
  }

  useEffect(() => {
    dispatch(getPublishDetailsRequest())
  }, [])

  return (
    <Box>
      <Box textAlign='center'>
        <HeaderSection info='SENDING' title='Publish and Send'>
          <SubtitleInfo />
        </HeaderSection>
      </Box>

      <Grid container gap={0} justifyContent='space-between'>
        <Grid item>
          <RecipientsList />
        </Grid>
        <Grid item>
          <Stack gap={4}>
            {requestStatus === RequestStatuses.PENDING ? (
              <Loader />
            ) : (
              <>
                <EmailSection
                  onSave={(disabled: boolean) => setDisabled(disabled)}
                />
                <SmsSection />
              </>
            )}

            <Box sx={style.controlBtn}>
              <Button
                sx={style.draftBtn}
                disabled={isDisabled}
                onClick={handleSaveAsDraft}
              >
                <Typography
                  variant='subtitle2'
                  sx={{ textDecoration: 'underline' }}
                >
                  Save as Draft
                </Typography>
              </Button>
              <LoadingButton
                disabled={isDisabled}
                variant='contained'
                loading={saveRequestStatus === RequestStatuses.PENDING}
                fullWidth
                onClick={handleSubmit}
              >
                Publish and Send
              </LoadingButton>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default PublishAndSend
