import { History } from 'history'
import { SampleError } from '../../api/types'

import {
  SET_CALLSHEET,
  SET_CALLSHEET_ID,
  SET_CALLSHEET_TITLE,
  SET_EVENT_DATE,
  SET_CREW_CALL_TIME,
  SET_PRODUCT_COMPANY_ID,
  SET_CLIENT_COMPANY_ID,
  GET_CALLSHEETS_REQUEST,
  GET_CALLSHEETS_REQUEST_SUCCESS,
  GET_CALLSHEETS_REQUEST_ERROR,
  GET_CALLSHEET_BY_ID_REQUEST,
  GET_CALLSHEET_BY_ID_REQUEST_SUCCESS,
  GET_CALLSHEET_BY_ID_REQUEST_ERROR,
  SAVE_CALLSHEET_REQUEST,
  SAVE_CALLSHEET_REQUEST_SUCCESS,
  SAVE_CALLSHEET_REQUEST_ERROR,
  UPDATE_CALLSHEET_REQUEST,
  UPDATE_CALLSHEET_REQUEST_SUCCESS,
  UPDATE_CALLSHEET_REQUEST_ERROR,
  GET_MEMBER_TITLES_REQUEST,
  GET_MEMBER_TITLES_REQUEST_SUCCESS,
  GET_MEMBER_TITLES_REQUEST_ERROR,
  SET_IS_COMBINED_NOTES,
  SET_PRODUCTION_NOTE_DETAILS,
  SET_PRODUCTION_NOTE_TITLE,
  SAVE_PRODUCTION_NOTES_REQUEST,
  SAVE_CALLSHEET_FINAL_TOUCHES_REQUEST,
  SAVE_CALLSHEET_FINAL_TOUCHES_REQUEST_SUCCESS,
  SAVE_CALLSHEET_FINAL_TOUCHES_REQUEST_ERROR,
  GET_CALLSHEET_FINAL_TOUCHES_REQUEST,
  SET_CALLSHEET_STATUS,
  UPDATE_CALLSHEET_OVERVIEW_REQUEST,
  GET_CALLSHEETS_STATISTIC_REQUEST,
  GET_CALLSHEETS_STATISTIC_REQUEST_SUCCESS,
} from './actionTypes'

import {
  GetCallsheetByIdRequestType,
  GetCallsheetRequestSuccessType,
  GetCallsheetRequestErrorType,
  SaveCallsheetRequestType,
  SaveCallsheetRequestSuccessType,
  SaveCallsheetRequestErrorType,
  UpdateCallsheetRequestType,
  UpdateCallsheetRequestSuccessType,
  UpdateCallsheetRequestErrorType,
  GetMemberTitlesRequestType,
  GetMemberTitlesRequestSuccessType,
  GetMemberTitlesRequestErrorType,
  Callsheet,
  SetCallsheetTitle,
  SetEventDate,
  SetCrewCallTime,
  SetProductCompanyId,
  SetClientCompanyId,
  SetIsCombinedNotes,
  GetCallsheetsRequestType,
  GetCallsheetsRequestSuccessType,
  GetCallsheetsRequestErrorType,
  SetCallsheetId,
  SetProductionNotesDetails,
  SetProductionNotesTitle,
  SaveProductionNotesRequestType,
  SaveCallsheetFinalTouchesRequestType,
  SaveCallsheetFinalTouchesRequestSuccessType,
  SaveCallsheetFinalTouchesRequestErrorType,
  GetCallsheetFinalTouchesRequestType,
  SetCallsheetStatus,
  CallsheetStatusType,
  UpdateCallsheetOverviewRequest,
  GetCallsheetsStatisticsRequestType,
  GetCallsheetsStatisticsRequestSuccessType,
  CallsheetStatistic,
} from './types'

export const getCallsheetsStatisticsRequest = (
  ids: string[]
): GetCallsheetsStatisticsRequestType => ({
  type: GET_CALLSHEETS_STATISTIC_REQUEST,
  payload: { ids },
})

export const getCallsheetsStatisticsRequestSuccess = (callsheetsStatistic: {
  [key: string]: CallsheetStatistic
}): GetCallsheetsStatisticsRequestSuccessType => ({
  type: GET_CALLSHEETS_STATISTIC_REQUEST_SUCCESS,
  callsheetsStatistic,
})

export const getCallsheetsRequest = (): GetCallsheetsRequestType => ({
  type: GET_CALLSHEETS_REQUEST,
})

export const getCallsheetsRequestSuccess = (
  callsheets: Callsheet[]
): GetCallsheetsRequestSuccessType => ({
  type: GET_CALLSHEETS_REQUEST_SUCCESS,
  callsheets,
})

export const getCallsheetsRequestError = (
  error: SampleError
): GetCallsheetsRequestErrorType => ({
  type: GET_CALLSHEETS_REQUEST_ERROR,
  error,
})

export const getCallsheetRequest = (
  id: string
): GetCallsheetByIdRequestType => ({
  type: GET_CALLSHEET_BY_ID_REQUEST,
  payload: {
    id,
  },
})

export const getCallsheetRequestSuccess = (
  callsheet: Callsheet
): GetCallsheetRequestSuccessType => ({
  type: GET_CALLSHEET_BY_ID_REQUEST_SUCCESS,
  callsheet,
})

export const getCallsheetRequestError = (
  error: SampleError
): GetCallsheetRequestErrorType => ({
  type: GET_CALLSHEET_BY_ID_REQUEST_ERROR,
  error,
})

export const saveCallsheetRequest = (
  callsheet: Callsheet
): SaveCallsheetRequestType => ({
  type: SAVE_CALLSHEET_REQUEST,
  payload: callsheet,
})

export const saveCallsheetRequestSuccess = (
  id: string
): SaveCallsheetRequestSuccessType => ({
  type: SAVE_CALLSHEET_REQUEST_SUCCESS,
  id,
})

export const saveCallsheetRequestError = (
  error: SampleError
): SaveCallsheetRequestErrorType => ({
  type: SAVE_CALLSHEET_REQUEST_ERROR,
  error,
})

export const updateCallsheetRequest = (
  callsheet: Callsheet
): UpdateCallsheetRequestType => ({
  type: UPDATE_CALLSHEET_REQUEST,
  payload: callsheet,
})

export const updateCallsheetRequestSuccess =
  (): UpdateCallsheetRequestSuccessType => ({
    type: UPDATE_CALLSHEET_REQUEST_SUCCESS,
  })

export const updateCallsheetRequestError = (
  error: SampleError
): UpdateCallsheetRequestErrorType => ({
  type: UPDATE_CALLSHEET_REQUEST_ERROR,
  error,
})

export const getMemberTitlesRequest = (): GetMemberTitlesRequestType => ({
  type: GET_MEMBER_TITLES_REQUEST,
})

export const getMemberTitlesRequestSuccess = (
  titles: string[]
): GetMemberTitlesRequestSuccessType => ({
  type: GET_MEMBER_TITLES_REQUEST_SUCCESS,
  titles,
})

export const getMemberTitlesRequestError = (
  error: SampleError
): GetMemberTitlesRequestErrorType => ({
  type: GET_MEMBER_TITLES_REQUEST_ERROR,
  error,
})

export const setCallsheetId = (id: string): SetCallsheetId => ({
  type: SET_CALLSHEET_ID,
  id,
})

export const setCallsheetTitle = (title: string): SetCallsheetTitle => ({
  type: SET_CALLSHEET_TITLE,
  title,
})

export const setCallsheetStatus = (
  callsheetId: string,
  status: CallsheetStatusType
): SetCallsheetStatus => ({
  type: SET_CALLSHEET_STATUS,
  callsheetId,
  status,
})

export const setEventDate = (eventDate: DateConstructor): SetEventDate => ({
  type: SET_EVENT_DATE,
  eventDate,
})

export const setCrewCallTime = (crewCallTime: string): SetCrewCallTime => ({
  type: SET_CREW_CALL_TIME,
  crewCallTime,
})

export const setProductCompanyId = (
  productCompanyId: string
): SetProductCompanyId => ({
  type: SET_PRODUCT_COMPANY_ID,
  productCompanyId,
})

export const setClientCompanyId = (
  clientCompanyId: string
): SetClientCompanyId => ({
  type: SET_CLIENT_COMPANY_ID,
  clientCompanyId,
})

export const setIsCombinedNotes = (
  combinedNotes: boolean
): SetIsCombinedNotes => ({
  type: SET_IS_COMBINED_NOTES,
  combinedNotes,
})

export const setProductionNotesDetails = (
  productionNoteDetails: string
): SetProductionNotesDetails => ({
  type: SET_PRODUCTION_NOTE_DETAILS,
  productionNoteDetails,
})

export const setProductionNotesTitle = (
  productionNoteTitle: string
): SetProductionNotesTitle => ({
  type: SET_PRODUCTION_NOTE_TITLE,
  productionNoteTitle,
})

export const saveProductionNotesRequest =
  (): SaveProductionNotesRequestType => ({
    type: SAVE_PRODUCTION_NOTES_REQUEST,
  })

export const setCallsheet = (callsheet: Callsheet) => ({
  type: SET_CALLSHEET,
  callsheet,
})

export const saveCallsheetFinalTouchesRequest =
  (): SaveCallsheetFinalTouchesRequestType => ({
    type: SAVE_CALLSHEET_FINAL_TOUCHES_REQUEST,
  })

export const saveCallsheetFinalTouchesRequestSuccess =
  (): SaveCallsheetFinalTouchesRequestSuccessType => ({
    type: SAVE_CALLSHEET_FINAL_TOUCHES_REQUEST_SUCCESS,
  })

export const saveCallsheetFinalTouchesRequestError =
  (): SaveCallsheetFinalTouchesRequestErrorType => ({
    type: SAVE_CALLSHEET_FINAL_TOUCHES_REQUEST_ERROR,
  })

export const getCallsheetFinalTouchesRequest =
  (): GetCallsheetFinalTouchesRequestType => ({
    type: GET_CALLSHEET_FINAL_TOUCHES_REQUEST,
  })

export const updateCallsheetOverviewRequest = (
  callsheetId: string,
  eventDate: DateConstructor | null,
  crewCallTime: string,
  history: History
): UpdateCallsheetOverviewRequest => ({
  type: UPDATE_CALLSHEET_OVERVIEW_REQUEST,
  payload: {
    callsheetId,
    eventDate,
    crewCallTime,
    history,
  },
})
