import { CallsheetStatuses } from 'src/store/Callsheet/types'

const style = {
  statusBtn: (status: string) => ({
    px: '60px',
    fontWeight: 900,
    lineHeight: '20px',
    color: 'white',

    ...(status === CallsheetStatuses.DECLINED && {
      fontWeight: 500,
      backgroundColor: 'white',
      color: '#CC2E26',
      border: '1px solid #CC2E26',
      boxShadow: 'none',
      ':hover': {
        backgroundColor: 'white',
      },
      svg: {
        alignSelf: 'center',
        path: {
          stroke: '#CC2E26',
        },
      },
    }),

    ...(status === CallsheetStatuses.TENTATIVE && {
      fontWeight: 500,
      backgroundColor: 'white',
      color: '#FA6400',
      border: '1px solid #FA6400',
      boxShadow: 'none',
      ':hover': {
        backgroundColor: 'white',
      },
    }),

    ...(status === CallsheetStatuses.ACCEPTED && {
      backgroundColor: '#2C9A5A',

      ':hover': {
        backgroundColor: '#2C9A5A',
      },

      svg: {
        ml: 0,
        path: {
          stroke: 'white',
        },
      },
    }),
  }),

  menu: {
    '& .MuiPaper-root': {
      padding: 0,
      '* + *': {
        mt: 0,
      },
    },
  },

  statusItem: (status: string) => ({
    px: '60px',
    fontWeight: 500,
    fontSize: '14px',
    borderRadius: '6px',
    svg: {
      ml: 0,
    },

    ...(status === CallsheetStatuses.DECLINED && {
      color: '#CC2E26',
      border: '1px solid #CC2E26',

      svg: {
        alignSelf: 'center',
        path: {
          stroke: '#CC2E26',
        },
      },
    }),

    ...(status === CallsheetStatuses.TENTATIVE && {
      color: '#FA6400',
      border: '1px solid #FA6400',
    }),

    ...(status === CallsheetStatuses.ACCEPTED && {
      color: '#2C9A5A',
      border: '1px solid #2C9A5A',
    }),
  }),
}

export default style
