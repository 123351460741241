const style = {
  wrapper: {
    border: '1px solid #E2E2E2',
    borderRadius: '16px',
    boxShadow: '0px 5px 8px 0px #8585851A',
    p: 1,
  },
  actionBox: {
    display: 'flex',

    gap: '27px',

    svg: {
      mt: 0,
    },
  },
}

export default style
