import { RequestStatuses } from '../api/constants'
import { RequestStatus, SampleError } from '../api/types'

export const isNotificationShown = (
  lastRequestError: SampleError | null | undefined
): boolean =>
  !!lastRequestError && typeof lastRequestError.statusCode === 'number'

export const isPrivateRouterAvailable = (
  authRequestStatus: RequestStatus
): boolean => authRequestStatus === RequestStatuses.SUCCEEDED

export const isAppReady = (authRequestStatus: RequestStatus): boolean =>
  authRequestStatus === RequestStatuses.FAILED ||
  authRequestStatus === RequestStatuses.SUCCEEDED
