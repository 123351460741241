import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Paper, Typography } from '@mui/material'

import { setIsUserGroupPanel } from '../../../../store/Builder/actions'
import {
  selectGroups,
  selectGroupsRequestStatus,
} from '../../../../store/Groups/selectors'

import { RequestStatuses } from '../../../../api/constants'

import HeaderSection from '../HeaderSection'
import AddButton from '../../../../components/AddButton'
import UserGroupCard from '../UserGroupCard'
import TeamInvitesBox from '../TeamInvitesBox'
import Loader from '../../../../components/Loader'

import style from './style'

const TeamInvitesForm: FC = () => {
  const groups = useSelector(selectGroups)
  const requestStatus = useSelector(selectGroupsRequestStatus)

  const dispatch = useDispatch()

  const handleUserGroupPanel = () => {
    dispatch(setIsUserGroupPanel(true))
  }

  const selectedGroups = groups.filter(group => group.isSelected)

  const isTeamInvites = selectedGroups.length !== 0

  return (
    <>
      <HeaderSection
        title='Team/Invites'
        subtitle='Please, select all needed user groups who will take a part in a call sheet'
      />
      {requestStatus === RequestStatuses.PENDING ? (
        <Loader />
      ) : (
        <Box mt={1}>
          {isTeamInvites ? (
            <TeamInvitesBox groups={selectedGroups} />
          ) : (
            <Paper sx={style.paper}>
              <Box sx={style.content}>
                <UserGroupCard />
                <Typography variant='body1'>
                  Clear here to start adding groups to the CallSheet.
                </Typography>
                <AddButton
                  required
                  title='Add group'
                  onClick={handleUserGroupPanel}
                />
              </Box>
            </Paper>
          )}
        </Box>
      )}
    </>
  )
}

export default TeamInvitesForm
