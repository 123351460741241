const style = {
  selectedAvatar: {
    mr: 3,
    width: '96px',
    height: '96px',
    borderRadius: '16px',
  },
  avatarImg: (src?: string) => ({
    width: '96px',
    height: '96px',
    borderRadius: '16px',
    background: `url(${src}), #F6F6F6`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }),
  avatarPlaceholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    borderRadius: '16px',
    backgroundColor: '#D3DEE7',
  },
  selectedTitle: (titleProps?: { [key: string]: string }) => ({
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    color: 'text.disabled',
    marginTop: '2px',
    mb: 0,
    ...(titleProps && titleProps),
  }),
  socialProfiles: {
    display: 'flex',
    height: 36,
  },
  bio: {
    mt: '12px',
    textAlign: 'start',
  },
  bioHeader: {
    mb: 0,
    fontWeight: 900,
    fontSize: '16px',
    lineHeight: '24px',
  },
  bioContent: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    height: 78,
    overflow: 'auto',
    color: 'text.secondary',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  noBioText: {
    display: 'flex',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    margin: 'auto',
    color: 'text.disabled',
  },
  initials: (initialsProps?: { [key: string]: string }) => ({
    fontSize: '18px',
    ...(initialsProps && initialsProps),
  }),
}

export default style
