import { capitalize } from 'lodash'
import React, { FC, useMemo } from 'react'
import { Box, Divider, Grid, Stack, Typography } from '@mui/material'
import {
  CallsheetStatuses,
  CallsheetStatusType,
} from '../../store/Callsheet/types'
import { Company } from '../../store/Companies/types'
import useCallsheetCard from '../../hooks/callsheet/useCallsheetCard'
import { useOptions } from '../../hooks/callsheet/useOptions'
import useCallsheetStatistic from '../../hooks/callsheet/useCallsheetStatistic'
import CallsheetCompletedFooter from '../CallsheetCard/CallsheetCardFooter/CallsheetCompletedFooter'
import CallsheetCardHeader from '../CallsheetCard/CallsheetCardHeader'
import StatusChip from '../CallsheetCard/StatusChip'
import OptionsButton from '../OptionsButton'
import { OptionsVerticalIcon } from '../../assets/icons'

import { CallsheetCardTypes } from '../CallsheetCard/CallsheetCard'
import CircleProgressBar from '../CircleProgressBar'
import { addBase64Data } from '../../utils/image'

import style from './style'

const LogoCard: FC<{ title: string; label: string; imgSrc?: string }> = ({
  label,
  title,
  imgSrc,
}) => (
  <Stack px='20px' py='28px' spacing='4px'>
    <Typography variant='body2'>{label}</Typography>
    <Box display='flex' gap={0}>
      <Box sx={style.imageBox}>
        {imgSrc && <img src={imgSrc} alt='company logo' style={style.image} />}
      </Box>
      <Typography sx={style.title}>{title}</Typography>
    </Box>
  </Stack>
)

const CallsheetCardExpanded: FC<
  CallsheetCardTypes & { clientCompany?: Company; productCompany?: Company }
> = ({
  id,
  status = CallsheetStatuses.DRAFT,
  eventDate,
  crewCallTime,
  title,
  ownerId,
  clientCompany,
  productCompany,
}) => {
  const { optionsMenuItems } = useOptions({ id, status, ownerId, title })
  const { handleCardClick, isClickable } = useCallsheetCard(status, id)
  const { statistics, percent, total } = useCallsheetStatistic(id) ?? {}

  const Statistics = useMemo(() => {
    if (statistics)
      return (
        <>
          <CircleProgressBar
            percent={percent ?? 0}
            data={statistics}
            total={total}
          />
          <Box display='flex' gap='12px' px='20px' py='28px'>
            {Object.keys(statistics).map(key => (
              <Stack key={key}>
                <Typography variant='body2' color='#545454'>
                  {`${capitalize(key)}:`}
                </Typography>
                <Typography sx={style.statusNumber(key as CallsheetStatusType)}>
                  {statistics[key]}
                </Typography>
              </Stack>
            ))}
          </Box>
        </>
      )
    return null
  }, [statistics])

  return (
    <Grid container sx={style.wrapper(isClickable)} position='relative'>
      <Grid item onClick={handleCardClick}>
        <Box sx={style.titleBox}>
          <CallsheetCardHeader
            title={title}
            crewCallTime={crewCallTime}
            eventDate={eventDate}
          />
          <StatusChip status={status} />
        </Box>
      </Grid>
      <Divider orientation='vertical' variant='middle' flexItem />
      <Grid item xs={2}>
        <LogoCard
          label='Production Company:'
          title={productCompany?.name ?? ''}
          imgSrc={addBase64Data(productCompany?.logo)}
        />
      </Grid>
      <Divider orientation='vertical' variant='middle' flexItem />
      <Grid item xs={2}>
        <LogoCard
          label='Client:'
          title={clientCompany?.name ?? ''}
          imgSrc={addBase64Data(clientCompany?.logo)}
        />
      </Grid>
      <Divider orientation='vertical' variant='middle' flexItem />
      <Grid item xs={5}>
        <Box sx={style.statisticsBox}>
          {status === CallsheetStatuses.COMPLETED ? (
            <Box p='28px 20px'>
              <CallsheetCompletedFooter id={id} />
            </Box>
          ) : (
            Statistics
          )}
          <Box position='absolute' right='16px' top='12px'>
            <OptionsButton menuItems={optionsMenuItems}>
              <OptionsVerticalIcon />
            </OptionsButton>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default CallsheetCardExpanded
