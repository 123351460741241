import { RequestStatus } from '../../api/types'
import { RequestStatuses } from '../../api/constants'
import { User } from '../../api/user/types'
import { AppState } from '../types'

export const selectUserId = (state: AppState): string => state.userState.user.id

export const selectUserRequestStatus = (state: AppState): RequestStatus =>
  state.userState.requestStatus
export const selectFirstName = (state: AppState): string =>
  state.userState.user.firstName
export const selectLastName = (state: AppState): string =>
  state.userState.user.lastName
export const selectEmail = (state: AppState): string =>
  state.userState.user.email
export const selectShootCount = (state: AppState): number =>
  Number(state.userState.user.shootCount)
export const selectUserPosition = (state: AppState): string =>
  state.userState.user.position
export const selectUserBio = (state: AppState): string =>
  state.userState.user.bio
export const selectUserSpecialty = (state: AppState): string =>
  state.userState.user.specialty
export const selectUserPersonalLink = (state: AppState): string =>
  state.userState.user.personalLink
export const selectUserUpsplashLink = (state: AppState): string =>
  state.userState.user.upsplashLink
export const selectUserInstagramLink = (state: AppState): string =>
  state.userState.user.instagramLink
export const selectUserJoinBeta = (state: AppState): boolean =>
  state.userState.user.joinBeta
export const selectUserHowDidYouHearAboutUs = (state: AppState): string =>
  state.userState.user.howDidYouHearAboutUs
export const selectUserFinishedOnboarding = (state: AppState): boolean =>
  state.userState.user.finishedOnboarding
export const selectUserError = (state: AppState): boolean =>
  state.userState.requestStatus === RequestStatuses.FAILED
export const selectUserPictureURL = (state: AppState): string =>
  state.userState.user.profilePictureURL
export const selectUserPictureHeaderURL = (state: AppState): string =>
  state.userState.user.profilePictureHeaderURL
export const selectUser = (state: AppState): User => state.userState.user
