import { Box, Typography } from '@mui/material'
import React, { FC, ReactNode } from 'react'
import { CallsheetStatuses } from 'src/store/Callsheet/types'
import {
  CheckCircleGreenIcon,
  CrossIcon,
  HelpCircleOutlineIcon,
} from '../../assets/icons'

import style from './style'

type InvitationStatusProps = {
  status: keyof typeof CallsheetStatuses
  withIcon?: boolean
  displayNumber?: number
  round?: boolean
  onClick?: () => void
}

const statuses: {
  [key: string]: {
    title: string
    color: string
    icon: ReactNode
  }
} = {
  [CallsheetStatuses.DECLINED]: {
    title: 'Decline',
    icon: <CrossIcon />,
    color: '#CC2E26',
  },
  [CallsheetStatuses.TENTATIVE]: {
    title: 'Tentative',
    icon: <HelpCircleOutlineIcon />,
    color: '#FA6400',
  },
  [CallsheetStatuses.ACCEPTED]: {
    title: 'Accept',
    icon: <CheckCircleGreenIcon />,
    color: '#2C9A5A',
  },
}

const InvitationStatusBox: FC<InvitationStatusProps> = ({
  status,
  withIcon = false,
  displayNumber = '',
  round = false,
  onClick,
}) => {
  return (
    <Box
      sx={style.statusWrapper(statuses[status].color, round, !!onClick)}
      onClick={onClick}
    >
      <Typography sx={style.status(statuses[status].color)}>
        {displayNumber}
      </Typography>
      <Typography sx={style.status(statuses[status].color)}>
        {statuses[status].title}
      </Typography>
      {withIcon && statuses[status].icon}
    </Box>
  )
}

export default InvitationStatusBox
