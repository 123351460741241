const style = {
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'space-between',
    height: '100%',
  },
  timeWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    width: '100%',
  },
  footer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'end',
    width: '100%',
    '> div': {
      width: '100%',
    },
  },
  item: {
    flexBasis: '50%',
    minHeight: 258,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  selectedAvatar: {
    mr: 3,
    width: '96px',
    height: '96px',
    borderRadius: '16px',
  },
  avatarImg: {
    width: '100%',
    height: '100%',
  },
  avatarPlaceholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    borderRadius: '16px',
    backgroundColor: '#D3DEE7',
  },
  selectedTitle: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    color: 'text.disabled',
    marginTop: '2px',
    mb: 0,
  },
  socialProfiles: {
    display: 'flex',
    height: 36,
  },
  bio: {
    mt: '12px',
    textAlign: 'start',
  },
  bioHeader: {
    mb: 0,
    fontWeight: 900,
    fontSize: '16px',
    lineHeight: '24px',
  },
  bioContent: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    height: 78,
    overflow: 'auto',
    color: 'text.secondary',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  noBioText: {
    display: 'flex',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    margin: 'auto',
    color: 'text.disabled',
  },
  initials: {
    fontSize: '18px',
  },
  info: {
    mt: '20px',
  },
  infoRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  input: {
    height: '32px',
    fontWeight: 900,
    borderRadius: '4px',
    input: {
      fontSize: '12px',
      width: 'auto',
    },
    svg: {
      fill: 'red',
    },
  },
  phoneInput: {
    width: '120px',
    '> div': {
      borderRadius: '4px',
    },
    input: {
      padding: '7px 14px',
      width: '110px',
      fontWeight: 900,
      fontSize: '12px',
    },
  },
}

export default style
