const style = {
  buttonBox: {
    position: 'fixed',
    top: '27px',
    right: '27px',
  },
  button: {
    p: '0px',
  },
  content: {
    p: '40px',
    width: '538px',
    height: '100%',
  },
}

export default style
