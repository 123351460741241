const style = {
  phoneWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  phoneInput: {
    maxWidth: '120px',
    'input::placeholder': {
      color: 'secondary.dark',
      opacity: 1,
    },
    '> div': {
      borderRadius: '4px',
    },
    input: {
      width: '95px',
      textAlign: 'center',
      padding: '6px 8px',
      fontWeight: 900,
      fontSize: '12px',
    },
  },
}

export default style
