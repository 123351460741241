import { Box, Divider, Stack, Typography } from '@mui/material'
import { noop } from 'lodash'
import moment, { MomentInput } from 'moment'
import React, { FC, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ContactDetailsFormWrapper from '../../../components/ContactDetailsForm/ContactDetailsFormWrapper'
import AvatarHeader from '../../../components/ContactDetailsForm/AvatarHeader'
import { InfoBox } from '../ContactDetails/ContactDetails'
import AvatarBox from '../../../components/AvatarBox'
import { selectIsConfirmDeleteCompanyModal } from '../../../store/Builder/selectors'
import { selectContacts } from '../../../store/Contacts/selectors'
import { Company } from '../../../store/Companies/types'
import { Contact } from '../../../store/Contacts/types'
import { addBase64Data } from '../../../utils/image'

import style from './style'
import ConfirmDialog from '../../BuilderPage/ManageUserGroups/ConfirmDialog'
import { setIsConfirmDeleteCompanyModal } from '../../../store/Builder/actions'
import { deleteCompanyRequest } from '../../../store/Companies/actions'

const CompanyDetails: FC<{
  company: Company
  onEdit: () => void
  onClose: () => void
}> = ({ company, onEdit, onClose }) => {
  const dispatch = useDispatch()
  const contacts = useSelector(selectContacts)
  const isConfirmDeleteCompanyModal = useSelector(
    selectIsConfirmDeleteCompanyModal
  )
  const { name, logo, website, address, primaryMembers, createAt, notes, id } =
    company

  const members = useMemo(() => {
    return contacts?.filter(({ id: contactId }: Contact) =>
      (primaryMembers as unknown as string[])?.some(
        (member: string) => member === contactId
      )
    )
  }, [company, contacts])

  const handleDeleteCompany = () => {
    if (id) {
      dispatch(deleteCompanyRequest(id))
    }
  }

  const handleDetailsClose = () => {
    onClose()
  }

  return (
    <>
      <ContactDetailsFormWrapper
        variant='company'
        onBackClick={handleDetailsClose}
        onConfirm={noop}
        backBtnTitle='Back to Companies'
        editButtonProps={{
          onEdit,
          onDelete: () => dispatch(setIsConfirmDeleteCompanyModal(true)),
        }}
      >
        <AvatarHeader bio={notes} firstName={name} logo={addBase64Data(logo)} />
        <Divider />
        {(website || address) && (
          <Box sx={{ py: 3 }}>
            <Typography variant='h5' color='text.primary'>
              Information
            </Typography>
            <Stack spacing={1} mt={0}>
              {address && <InfoBox label='Address:' value={address} />}
              {website && <InfoBox label='Company Website:' value={website} />}
            </Stack>
          </Box>
        )}
        {primaryMembers && primaryMembers.length > 0 && (
          <Stack gap={0}>
            <Typography variant='subtitle2' color='text.primary'>
              Primary Contacts
            </Typography>
            {members.map(
              ({
                firstName,
                id: memberId,
                logo: memeberLogo,
                lastName,
                title: memberTitle,
              }: Contact) => (
                <Box sx={style.memberWrapper} key={memberId}>
                  <AvatarBox img={addBase64Data(memeberLogo)} />
                  <Box>
                    <Typography fontWeight={900}>
                      {firstName} {lastName}
                    </Typography>
                    <Typography color='text.disabled'>{memberTitle}</Typography>
                  </Box>
                </Box>
              )
            )}
          </Stack>
        )}
        <Typography textAlign='center' mt={3} color='text.disabled'>
          {`Created at ${moment
            .utc(createAt as MomentInput)
            .format('MMMM D, YYYY')}`}
        </Typography>
      </ContactDetailsFormWrapper>
      <ConfirmDialog
        isOpen={isConfirmDeleteCompanyModal}
        handleCancel={() => dispatch(setIsConfirmDeleteCompanyModal(false))}
        handleConfirm={handleDeleteCompany}
        title='Delete Company'
        confirmTitle='Delete'
        content='Are you sure you want to delete this company?'
      />
    </>
  )
}

export default CompanyDetails
