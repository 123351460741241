import { noop } from 'lodash'
import React, { FC, PropsWithChildren } from 'react'
import { Box, Button, Grid } from '@mui/material'
import BackBtn from '../../BackBtn'
import HeadText from '../../HeadText'
import ControlButtons from '../../ControlButtons'
import { TrashcanIcon } from '../../../assets/icons'

import style from './style'

const modalText = {
  company: {
    title: 'Company details',
    subtitle:
      'Here you can find all the information about particular company from your list',
    editBtn: 'Edit Company',
  },
  contact: {
    title: 'Contact details',
    subtitle:
      'Here you can find all the information about particular contact from your list',
    editBtn: 'Edit Contact',
  },
}

type FormWrapperProps = {
  onBackClick: typeof noop
  onConfirm: typeof noop
  isBackBtn?: boolean
  controlButtonsProps?: {
    confirmTitle: string
    cancelTitle?: string
    isDisabled?: boolean
  }
  backBtnTitle?: string
  editButtonProps?: {
    onEdit: () => void
    onDelete: () => void
  }
  variant?: 'contact' | 'company'
}

const ContactDetailsFormWrapper: FC<FormWrapperProps & PropsWithChildren> = ({
  onBackClick,
  onConfirm,
  isBackBtn = true,
  controlButtonsProps,
  editButtonProps,
  children,
  backBtnTitle = 'Back to all members',
  variant = 'contact',
}) => {
  const {
    confirmTitle,
    cancelTitle = 'Back to all',
    isDisabled = false,
  } = controlButtonsProps ?? {}
  const { onEdit, onDelete } = editButtonProps ?? {}
  const editFooter = !!editButtonProps
  return (
    <Box sx={style.wrapper}>
      <Box sx={style.content(editFooter)}>
        <Grid item sx={style.item}>
          {isBackBtn && <BackBtn text={backBtnTitle} onClick={onBackClick} />}
          <HeadText
            title={modalText[variant].title}
            subtitle={modalText[variant].subtitle}
          />
          {children}
        </Grid>
      </Box>
      <Box sx={style.footer}>
        {controlButtonsProps && confirmTitle && (
          <ControlButtons
            disabled={isDisabled}
            confirmTitle={confirmTitle}
            cancelTitle={cancelTitle}
            handleCancelBtn={onBackClick}
            handleConfirmBtn={onConfirm}
          />
        )}
        {editButtonProps && (
          <Grid
            container
            position='fixed'
            bottom='0px'
            right='0px'
            maxWidth='538px'
            justifyContent='space-between'
            sx={{
              backgroundColor: 'white',
              borderTop: '1px solid #E2E2E2',
              pb: '40px',
              pt: '20px',
              px: '40px',
            }}
          >
            <Grid item xs={1}>
              <Box sx={style.deleteBtn} onClick={onDelete}>
                <TrashcanIcon />
              </Box>
            </Grid>
            <Grid item xs={10}>
              <Button
                onClick={onEdit}
                fullWidth
                variant='outlined'
                sx={{ color: 'text.primary', border: '1px solid #1F1F1F' }}
              >
                {modalText[variant].editBtn}
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  )
}

export default ContactDetailsFormWrapper
