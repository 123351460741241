import { AxiosPromise } from 'axios'
import { Company } from '../../store/Companies/types'
import { apiClient } from '../clients'
import { METHODS, URLS } from '../constants'

export const addMemberApi = (
  companyId: string,
  contactId: string
): AxiosPromise =>
  apiClient.request({
    method: METHODS.POST,
    url: URLS.COMPANY_MEMBER,
    data: {
      companyId,
      contactId,
    },
  })

export const deleteMemberApi = (
  companyId: string,
  memberId: string
): AxiosPromise =>
  apiClient.request({
    method: METHODS.DELETE,
    url: `${URLS.COMPANY}/${companyId}/member/${memberId}`,
  })

export const saveCompanyApi = (company: Company): AxiosPromise =>
  apiClient.request({
    method: METHODS.POST,
    url: URLS.COMPANY,
    data: {
      ...company,
    },
  })

export const updateCompanyApi = (
  companyId: string,
  company: Company
): AxiosPromise =>
  apiClient.request({
    method: METHODS.PATCH,
    url: `${URLS.COMPANY}/${companyId}`,
    data: {
      ...company,
    },
  })

export const getCompanyByNameApi = (
  name: string,
  catalogOnly = false
): AxiosPromise =>
  apiClient.request({
    method: METHODS.GET,
    url: URLS.COMPANY,
    params: {
      name,
      catalogOnly,
    },
  })

export const getCompanyByIdApi = (id: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.GET,
    url: URLS.COMPANY,
    params: {
      id,
    },
  })

export const getCompaniesByIdApi = (ids: string[]): AxiosPromise =>
  apiClient.request({
    method: METHODS.GET,
    url: URLS.COMPANY,
    params: {
      ids,
    },
    paramsSerializer: { indexes: null },
  })

export const getAllCompaniesApi = (): AxiosPromise =>
  apiClient.request({
    method: METHODS.GET,
    url: URLS.COMPANY,
  })

export const deleteCompanyApi = (companyId: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.DELETE,
    url: `${URLS.COMPANY}/${companyId}`,
  })
