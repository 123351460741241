const PREFIX = 'GROUP_MEMBERS/'

export const UPDATE_GROUP_MEMBERS = `${PREFIX}UPDATE_GROUP_MEMBERS` as const

export const SET_GROUP_MEMBER_VISIBILITY =
  `${PREFIX}SET_GROUP_MEMBER_VISIBILITY` as const
export const SET_GROUP_MEMBER_CALLTIME =
  `${PREFIX}SET_GROUP_MEMBER_CALLTIME` as const
export const SET_GROUP_MEMBER_WRAPTIME =
  `${PREFIX}SET_GROUP_MEMBER_WRAPTIME` as const
export const UPDATE_MEMBERS_GROUP_TITLE =
  `${PREFIX} UPDATE_MEMBERS_GROUP_TITLE` as const
export const ADD_PRESELECTED_GROUP_MEMBER =
  `${PREFIX} ADD_PRESELECTED_GROUP_MEMBER` as const
export const DELETE_GROUP_MEMBER = `${PREFIX}DELETE_MEMBER_ITEM` as const

export const DELETE_GROUP_MEMBER_BY_GROUP_TITLE =
  `${PREFIX}DELETE_GROUP_MEMBER_BY_GROUP_TITLE` as const
export const MEMBER_REQUEST = `${PREFIX}MEMBER_REQUEST` as const
export const MEMBER_REQUEST_SUCCESS = `${PREFIX}MEMBER_REQUEST` as const
export const MEMBER_REQUEST_ERROR = `${PREFIX}MEMBER_REQUEST` as const
export const ADD_MEMBER_REQUEST = `${PREFIX}ADD_MEMBER_REQUEST` as const
export const DELETE_MEMBER_REQUEST = `${PREFIX}DELETE_MEMBER_REQUEST` as const
export const GET_MEMBERS_REQUEST = `${PREFIX}GET_MEMBERS_REQUEST` as const
export const GET_MEMBERS_REQUEST_SUCCESS =
  `${PREFIX}GET_MEMBERS_REQUEST_SUCCESS` as const
export const UPDATE_MEMBER_REQUEST = `${PREFIX}UPDATE_MEMBER_REQUEST` as const
