import { COLOR_TEXT_DISABLED } from '../../../../../themes/constants'

const style = {
  wrapper: {
    width: '100%',
  },
  option: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 20px 12px 8px',
    width: '100%',
  },
  content: {
    display: 'flex',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40px',
    height: '40px',
    marginRight: '16px',
    img: {
      width: '100%',
    },
  },
  circle: {
    width: '22px',
    height: '22px',
    border: `3px solid ${COLOR_TEXT_DISABLED}`,
    borderRadius: '50%',
  },
}

export default style
