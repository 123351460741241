import React from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'

import { selectStep } from '../../store/Builder/selectors'
import NavigationButtons from '../NavigationButtons'
import CustomStepper from '../CustomStepper'

import style from './style'

const steps = [
  'Project overview',
  '',
  'Team/Invites',
  'Final Touches',
  'Publish & Sending',
]

export default function ProgressBar() {
  const step = useSelector(selectStep)

  return (
    <Box sx={style.container}>
      <NavigationButtons>
        <CustomStepper steps={steps} activeStep={step} builderStepper />
      </NavigationButtons>
    </Box>
  )
}
