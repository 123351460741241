import axios, { AxiosPromise } from 'axios'
import { LocationItem } from '../../store/Logistics/types'
import { apiClient } from '../clients'
import { METHODS, URLS } from '../constants'

export const getCallsheetLocationsApi = (id: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.GET,
    url: `${URLS.CALLSHEET}/${id}/location`,
  })

export const addCallsheetLocationApi = (
  callsheetId: string,
  location: LocationItem
): AxiosPromise =>
  apiClient.request({
    method: METHODS.POST,
    url: `${URLS.CALLSHEET}/location`,
    data: {
      callsheetId,
      ...location,
    },
  })

export const deleteCallsheetLocationApi = (
  callsheetId: string,
  locationId: string
): AxiosPromise =>
  apiClient.request({
    method: METHODS.DELETE,
    url: `${URLS.CALLSHEET}/${callsheetId}/location/${locationId}`,
  })

export const updateCallsheetLocationApi = (
  callsheetId: string,
  locationId: string,
  location: LocationItem
): AxiosPromise =>
  apiClient.request({
    method: METHODS.PATCH,
    url: `${URLS.CALLSHEET}/${callsheetId}/location/${locationId}`,
    data: location,
  })

const getNearestHospital = (latitude: number, longitude: number) =>
  axios
    .get(
      `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${latitude},${longitude}&radius=5000&type=hospital&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
    )
    .then(response => {
      const { data } = response

      // Извлекаем информацию о ближайшей больнице
      const nearestHospital: string = data.results[0].name
      return nearestHospital
    })
    .catch(error => {
      console.error(error)
    })

export default getNearestHospital
