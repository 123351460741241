import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'

import style from './style'

const CallsheetCommonFooter: FC<{ id: string }> = ({ id }) => {
  return (
    <Box sx={style.content}>
      <Box sx={style.statisticsItem}>
        <Typography sx={style.title}>Days Away</Typography>
        <Box sx={style.numBox}>
          <Box sx={style.days}>4 Days</Box>
        </Box>
      </Box>
      <Box sx={style.statisticsItem}>
        <Typography sx={style.title}>My Call Time</Typography>
        <Box sx={style.numBox}>
          <Box sx={style.callTime}>9:00AM</Box>
        </Box>
      </Box>
    </Box>
  )
}

export default CallsheetCommonFooter
