const style = {
  wrapper: {
    backgroundColor: '#F2F9FF',
    borderRadius: '8px',
    width: '100%',
    alignItems: 'center',
    padding: 3,
  },
  titlePlaceholder: {
    width: '100%',
    height: '30px',
    backgroundColor: '#D3DEE7',
    borderRadius: '8px',
  },
  textPlaceholder: {
    width: '100%',
    height: '8px',
    backgroundColor: '#D3DEE7',
    borderRadius: '8px',
  },
  subject: {
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '28px',
    color: 'text.primary',
  },
  description: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: 'text.primary',
  },
}

export default style
