import { AxiosResponse } from 'axios'
import { networkApiClient } from '../clients'
import { METHODS, URLS } from '../constants'
import { SignInApiResultType } from './types'

export const postSignInData = (
  fields = {}
): Promise<AxiosResponse<SignInApiResultType>> => {
  return networkApiClient.request<SignInApiResultType>({
    method: METHODS.POST,
    url: URLS.SIGN_IN,
    data: fields,
  })
}
