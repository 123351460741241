const style = {
  iconBtn: {
    p: '0px',
  },
  menu: {
    '& .MuiPaper-root': {
      width: '200px',
    },
  },
  menuItem: (red?: boolean) => ({
    fontSize: '14px',
    fontWeight: 400,
    ...(red && { color: '#FF3B30' }),
  }),
}

export default style
