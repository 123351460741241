import { SortTags } from '../../../store/Gallery/types'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
export const TAGS = [
  'Date Ascending',
  'Date Decending',
  'Status',
  'Name (A-Z)',
  'Name (Z-A)',
]
export const DEFAULT_TAG = SortTags.DATE_ASCENDING
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
  muiinputbase: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
}
