import { AppState } from '../types'
import { GroupType } from './types'

export const selectGroups = (state: AppState) => state.groupsState.groups
export const selectGroupsRequestStatus = (state: AppState) =>
  state.groupsState.requestStatus
export const selectGroupsRequestUpdateStatus = (state: AppState) =>
  state.groupsState.requestUpdateStatus
export const selectIsFavorite = (title: string) => (state: AppState) =>
  state.groupsState.groups.find(group => group.title === title)?.isPrimary ??
  false
export const selectIsStackDisplay = (state: AppState) =>
  state.groupsState.isStackGroupsDisplay

export const selectAddedGroups = (state: AppState): GroupType[] =>
  state.groupsState.groups.filter(
    group =>
      group.isSelected &&
      !state.groupsState.initialGroups.some(item => item.title === group.title)
  )

export const selectGroupsToDelete = (state: AppState) =>
  state.groupsState.groupsToDelete

export const selectGroupsToUpdate = (state: AppState) =>
  state.groupsState.groups.filter(group =>
    state.groupsState.initialGroups.some(
      item =>
        item.id === group.id &&
        (item.title !== group.title ||
          item.callTime !== group.callTime ||
          item.isPrimary !== group.isPrimary)
    )
  )
