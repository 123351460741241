const style = {
  accordion: {
    borderRadius: '8px',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.06)',
  },
  header: {
    borderRadius: '10px',
    p: '8px 16px',
    minHeight: 'auto !important',
    height: '52px !important',
    backgroundColor: '#FFFFFF !important',
  },
  details: {
    p: '2px 16px 16px 16px',
  },
  textarea: {
    textarea: {
      textTransform: 'uppercase',
    },
    '> div': {
      padding: '10px 14px',
      fontSize: '14px',
    },
  },
  footer: {
    display: 'flex',
    alignItems: 'end',
    flexDirection: 'column',
    mt: '10px',
  },
}

export default style
