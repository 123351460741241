import { call, put, takeLatest, select } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { GetCallsheetLocationsResultType } from '../../api/locations/types'
import { STEPS } from '../../pages/BuilderPage/constants'
import { setStep } from '../Builder/actions'
import {
  GET_LOCATIONS_REQUEST,
  ADD_LOCATIONS_REQUEST,
  DELETE_LOCATION_REQUEST,
} from './actionTypes'
import {
  getCallsheetLocationsApi,
  addCallsheetLocationApi,
  deleteCallsheetLocationApi,
  updateCallsheetLocationApi,
} from '../../api/locations'
import { SampleError } from '../../api/types'
import {
  getLocationsRequestSuccess,
  getLocationsRequestError,
  addLocationsRequestSuccess,
  addLocationsRequestError,
  deleteLocationRequestSuccess,
  deleteLocationRequestError,
  getLocationsRequest,
} from './actions'
import { selectCallsheetId } from '../Callsheet/selectors'
import { setNotificationErrorWorker } from '../Notification/sagas'
import { AddLocationsRequestType, DeleteLocationRequestType } from './types'

export function* GetLocationsRequestWorker() {
  try {
    const callsheetId: string = yield select(selectCallsheetId)
    const {
      data: { data },
    }: AxiosResponse<GetCallsheetLocationsResultType> = yield call(
      getCallsheetLocationsApi,
      callsheetId
    )
    yield put(getLocationsRequestSuccess(data))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(getLocationsRequestError(data as SampleError))
  }
}

export function* AddLocationsRequestWorker({
  payload: { locations },
}: AddLocationsRequestType) {
  try {
    const callsheetId: string = yield select(selectCallsheetId)
    if (locations.length) {
      for (let i = 0; i < locations.length; i += 1) {
        const { tmpId, id, ...location } = locations[i]

        if (id) {
          yield call(updateCallsheetLocationApi, callsheetId, id, location)
        } else {
          yield call(addCallsheetLocationApi, callsheetId, location)
        }
      }
    }
    yield put(setStep(STEPS.userGroups))
    yield put(addLocationsRequestSuccess())
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(addLocationsRequestError(data as SampleError))
  }
}

export function* DeleteLocationRequestWorker({
  payload: { locationId },
}: DeleteLocationRequestType) {
  try {
    const callsheetId: string = yield select(selectCallsheetId)

    yield call(deleteCallsheetLocationApi, callsheetId, locationId)
    yield put(getLocationsRequest())
    yield put(deleteLocationRequestSuccess())
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(deleteLocationRequestError(data as SampleError))
  }
}

export function* requestLocationsWatcher(): Generator {
  yield takeLatest(GET_LOCATIONS_REQUEST, GetLocationsRequestWorker)
  yield takeLatest(ADD_LOCATIONS_REQUEST, AddLocationsRequestWorker)
  yield takeLatest(DELETE_LOCATION_REQUEST, DeleteLocationRequestWorker)
}
