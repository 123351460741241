import { AppState } from '../types'

export const selectRequestStatus = (state: AppState) =>
  state.callsheetState.requestStatus
export const selectFinalTouchesRequestStatus = (state: AppState) =>
  state.callsheetState.finalTouchesRequestStatus
export const selectCallsheetsRequestStatus = (state: AppState) =>
  state.callsheetState.callsheetsRequestStatus
export const selectMembersTitlesRequestStatus = (state: AppState) =>
  state.callsheetState.memberTitlesRequestStatus
export const selectCallsheets = (state: AppState) =>
  state.callsheetState.callsheets
export const selectMembersTitles = (state: AppState) =>
  state.callsheetState.memberTitles
export const selectCallsheetTitle = (state: AppState) =>
  state.callsheetState.callsheet.title
export const selectCallsheetStatus = (state: AppState) =>
  state.callsheetState.callsheet.status
export const selectCallsheetMemberStatus = (state: AppState) =>
  state.callsheetState.callsheet.memberStatus
export const selectEventDate = (state: AppState) =>
  state.callsheetState.callsheet.eventDate
export const selectCrewCallTime = (state: AppState) =>
  state.callsheetState.callsheet.crewCallTime
export const selectProductCompanyId = (state: AppState) =>
  state.callsheetState.callsheet.productCompanyId
export const selectClientCompanyId = (state: AppState) =>
  state.callsheetState.callsheet.clientCompanyId
export const selectCallsheetId = (state: AppState) =>
  state.callsheetState.callsheet.id
export const selectIsCombinedNotes = (state: AppState) =>
  state.callsheetState.callsheet.combinedNotes
export const selectCallsheetData = (state: AppState) =>
  state.callsheetState.callsheet

export const selectCallsheetProductionNotes = (state: AppState) =>
  state.callsheetState.callsheet.productionNoteDetails
export const selectCallsheetProductionNotesTitle = (state: AppState) =>
  state.callsheetState.callsheet.productionNoteTitle

export const selectIsAdmin = (state: AppState) =>
  state.callsheetState.callsheet.ownerId === state.userState.user.id

export const selectCallsheetStatisticById = (id: string) => (state: AppState) =>
  state.callsheetState.callsheetsStatistic?.[id]
