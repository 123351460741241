const style = {
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  statisticsItem: {
    mr: '60px',
  },
  title: {
    fontSize: '12px',
    fontWeight: 900,
    color: '#545454',
    lineHeight: '20px',
    mb: '4px',
  },
  numBox: {
    fontWeight: 900,
    fontSize: '20px',
    lineHeight: '28px',
  },
  attended: {
    color: '#FB8333',
  },
  views: {
    color: '#1E486C',
  },
}

export default style
