const style = {
  light: {
    borderRadius: '10px',
    color: '#000000',
    backgroundColor: 'white',
    fontSize: '14px',
    lineHeight: '20px',
    padding: '8px 41px',
    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.12)',
  },
  colored: {
    borderRadius: '4px',
    color: '#424242',
    backgroundColor: '#C5CAE9',
    fontSize: '14px',
    lineHeight: '22px',
    padding: '7px 16px',
  },
}

export default style
