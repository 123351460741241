import axios, { AxiosPromise } from 'axios'
import { Attachment } from 'src/store/Attachments/types'
import { apiClient } from '../clients'
import { METHODS, URLS } from '../constants'

export const getAttachmentApi = (id: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.GET,
    url: `${URLS.CALLSHEET}/${id}/attachment`,
  })

export const deleteAttachmentApi = (id: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.DELETE,
    url: `${URLS.CALLSHEET}/attachment/${id}`,
  })

export const downloadAttachmentApi = (id: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.GET,
    url: `${URLS.CALLSHEET}/attachment/${id}/download`,
    responseType: 'arraybuffer',
  })

export const addAttachmentApi = (
  id: string,
  name: string,
  format: string,
  file: Blob
): AxiosPromise =>
  apiClient.request({
    method: METHODS.POST,
    url: `${URLS.CALLSHEET}/${id}/attachment`,
    data: file,
    headers: {
      'File-name': name,
      'Content-type': format,
    },
  })
