import { STORAGE_FIELDS } from '../constants'

type GetTokensFromStorageType = () => {
  token: string | null
}

export interface TokenData {
  accessToken: string
}

export const isTokenData = (data: TokenData) =>
  !!data && data instanceof Object && 'accessToken' in data

export const setTokensInStorage = (token: string): void => {
  localStorage.setItem(STORAGE_FIELDS.LOCAL.TOKEN, token)
}

export const getTokensFromStorage: GetTokensFromStorageType = () => {
  const token = localStorage.getItem(STORAGE_FIELDS.LOCAL.TOKEN)
  return { token }
}

export const deleteTokens = (): void => {
  localStorage.removeItem(STORAGE_FIELDS.LOCAL.TOKEN)
}
