import React, {
  FC,
  FunctionComponent,
  PropsWithChildren,
  SVGProps,
} from 'react'
import { Box, Card } from '@mui/material'

import style from './style'

type DateTimeFieldTypes = {
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>
  title: string
}

const DateTimeField: FC<DateTimeFieldTypes & PropsWithChildren> = ({
  Icon,
  title,
  children,
}) => {
  return (
    <Card variant='outlined' sx={style.container}>
      <Box display='flex' sx={{ alignItems: 'center' }}>
        <Icon />
        <Box
          fontSize='14px'
          fontFamily='Avenir'
          fontWeight='500'
          sx={{ ml: 0 }}
        >
          {title}
        </Box>
      </Box>
      {children}
    </Card>
  )
}

export default DateTimeField
