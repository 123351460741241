import { ImageListType, ImageType } from 'react-images-uploading'
import { Attachment } from '../store/Attachments/types'
import { createBlob } from './image'

export const prepareFiles = (attachments: Attachment[]): ImageListType => {
  return attachments.map(item => ({
    dataURL: '',
    file: new File([''], item.name ?? '', {
      type: item.format,
    }),
    ...item,
  }))
}

export const updateFiles = async (
  myObjects: ImageListType
): Promise<Attachment[]> => {
  const blobList = await Promise.all(
    myObjects.map(({ dataURL, file, size, id }) => {
      const fileSize = file?.size ?? size ?? 0
      return {
        blob: createBlob(dataURL ?? ''),
        name: file?.name ?? '',
        size: fileSize,
        format: file?.type,
        id,
      }
    })
  )
  return blobList
}

export const getDeletedAttachments = (
  initial: Attachment[],
  current: Attachment[]
): Attachment[] =>
  initial.filter(file => !current.some(item => item.name === file.name))

export const attachmentName = (attachment: ImageType) =>
  attachment.name || attachment.file?.name
