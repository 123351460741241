const style = {
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 3,
  },
  viewToggle: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '264px',
    borderRadius: '8px',
    border: '1px solid #E2E2E2',
  },
  toggleLabel: (isGalleryView: boolean) => ({
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    padding: '6px 22.5px',
    borderRadius: '8px',
    cursor: 'pointer',
    ...(isGalleryView && {
      backgroundColor: '#F6F6F6',
      border: '1px solid #E2E2E2',
      fontWeight: 900,
    }),
  }),

  builderBtnLarge: {
    marginRight: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  builderBtnSmall: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  builderBtn: {
    color: '#FFFFFF',
  },
  builderBtnText: {
    fontWeight: 500,
  },
}

export default style
