const style = {
  chipsBox: {
    display: 'flex',
    maxWidth: '240px',
    overflowX: 'scroll',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  chip: {
    fontSize: '10px',
    fontWeight: '900',
    color: '#FFFFFF',
    backgroundColor: '#FFB0AC',
    height: '16px',
    marginRight: '4px',
    textTransform: 'capitalize',
  },
  chipBg: {
    contact: {
      backgroundColor: '#FCDD60',
    },
    friend: {
      backgroundColor: '#FDC199',
    },
  },
}

export default style
