import React, { ChangeEventHandler, FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toLower } from 'lodash'
import { Box, Divider, Paper, Stack, Typography } from '@mui/material'
import { RequestStatuses } from '../../../../api/constants'

import {
  setIsGroupMembersPanel,
  setIsPersistentPanel,
} from '../../../../store/Builder/actions'
import { setFavorite, setGroupCallTime } from '../../../../store/Groups/actions'
import {
  selectGroupMembersByTitle,
  selectGroupMembersRequestStatus,
} from '../../../../store/GroupMembers/selectors'
import { selectIsFavorite } from '../../../../store/Groups/selectors'
import Loader from '../../../../components/Loader'

import Member from '../Member'
import AddButton from '../../../../components/AddButton'
import MemberHeader from '../Member/MemberHeader'
import TimePickerDropdown from '../../../../components/TimePickerDropdown'
import DateTimeField from '../../ProjectOverview/CallsheetForm/DateTimeField/DateTimeField'

import { DEFAULT_CALLTIME } from '../../../../utils/groupMemberHelpers'

import {
  ClockIcon,
  StarOutline,
  StarContained,
  CaretBottomIcon,
  CaretUpIcon,
} from '../../../../assets/icons'
import { CALLTIME_PLACEHOLDER } from '../../../../constants'

import style from './style'

type TeamProps = {
  isPreview?: boolean
  title: string
  callTime: string
}

const Team: FC<TeamProps> = ({ isPreview, title, callTime }) => {
  const isFavorite = useSelector(selectIsFavorite(title))
  const groupMembers = useSelector(selectGroupMembersByTitle(title)) || []
  const requestStatus = useSelector(selectGroupMembersRequestStatus)
  const [isMembersOpen, setIsMembersOpen] = useState(true)

  const [time, setTime] = useState<string>(
    callTime === DEFAULT_CALLTIME ? '' : toLower(callTime)
  )

  const dispatch = useDispatch()

  const handleTimeChange: ChangeEventHandler<HTMLTextAreaElement> = ({
    target: { value },
  }) => {
    setTime(value)
    dispatch(setGroupCallTime(title, value))
  }

  const handleFavoriteToggle = () => {
    if (isPreview) {
      return
    }

    dispatch(setFavorite(title))
  }

  const handleAddMember = () => {
    dispatch(setIsPersistentPanel(true))
    dispatch(setIsGroupMembersPanel(true))
  }

  const toggleMembers = () => {
    setIsMembersOpen(!isMembersOpen)
  }

  return (
    <Paper variant='outlined' sx={style.wrapper(isPreview)}>
      <Box sx={style.header}>
        <Box sx={style.header_title}>
          <Typography>{title}</Typography>
          <Typography>{`(${groupMembers.length})`}</Typography>
        </Box>
        <Box sx={style.right_items}>
          <DateTimeField title='Call Time' Icon={ClockIcon}>
            <TimePickerDropdown
              onChange={handleTimeChange}
              value={time}
              placeholder={CALLTIME_PLACEHOLDER}
              disabled={isPreview}
            />
          </DateTimeField>
          {isFavorite ? <StarContained onClick={handleFavoriteToggle} /> : null}
          {!isFavorite ? <StarOutline onClick={handleFavoriteToggle} /> : null}
          {isMembersOpen ? <CaretUpIcon onClick={toggleMembers} /> : null}
          {!isMembersOpen ? <CaretBottomIcon onClick={toggleMembers} /> : null}
        </Box>
      </Box>
      {isPreview ? null : <Divider sx={{ mt: 1, mb: 1 }} />}
      <>
        {requestStatus === RequestStatuses.PENDING ? <Loader /> : null}
        {isMembersOpen && groupMembers.length > 0 && (
          <Stack gap={0} pb='12px'>
            <MemberHeader isPreview={isPreview} />
            {groupMembers.map(member => (
              <Member {...member} key={member.title} isPreview={isPreview} />
            ))}
          </Stack>
        )}
      </>
      {isPreview ? null : (
        <AddButton title='Add member' onClick={handleAddMember} />
      )}
    </Paper>
  )
}

export default Team
