import React, { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { PATHS } from '../constants'

import SignInPage from '../pages/SignInPage'
import NotFoundPage from '../pages/NotFoundPage'

const PublicRouter: FC = () => (
  <Switch>
    <Route exact path={PATHS.PUBLIC.WELCOME}>
      <Redirect to={PATHS.PUBLIC.LOGIN} />
    </Route>
    <Route path={PATHS.PUBLIC.LOGIN}>
      <SignInPage />
    </Route>
    <Route path='*'>
      <NotFoundPage />
    </Route>
  </Switch>
)

export default PublicRouter
