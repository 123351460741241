import { noop } from 'lodash'
import { Box, Typography } from '@mui/material'
import React, { FC, HTMLAttributes } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsContactDetailsPanel } from '../../../../store/Builder/actions'
import { setSelectedContact } from '../../../../store/Contacts/actions'
import { selectUserId } from '../../../../store/User/selectors'
import { Contact } from '../../../../store/Contacts/types'
import AvatarBox from '../../../AvatarBox'

import { extractInitials } from '../../../../utils/extractInitials'
import { logoSrc } from '../../../../constants'
import style from '../style'
import { EXTENDED_WIDTH } from '../constants'

const ContactOption: FC<{
  option: Contact
  props: HTMLAttributes<HTMLLIElement>
}> = ({ option, props }) => {
  const dipatch = useDispatch()
  const userId = useSelector(selectUserId)
  const handleOptionClick = () => {
    dipatch(setIsContactDetailsPanel(true))
    dipatch(setSelectedContact(option.id))
  }
  return (
    <Box {...props} component='li' sx={style.item} onClick={handleOptionClick}>
      <AvatarBox
        width='40px'
        img={logoSrc(userId, option.id)}
        placeHolder={extractInitials(option.firstName, 1)}
      />
      <Box textAlign='left'>
        <Typography variant='subtitle1' lineHeight='21px'>
          {option.firstName} {option.lastName}
        </Typography>
        <Typography color='text.disabled' lineHeight='20px'>
          {option.email}
        </Typography>
      </Box>
    </Box>
  )
}

export default ContactOption
