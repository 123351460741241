import React, { FC } from 'react'
import { Divider, Grid, Typography } from '@mui/material'
import useCallsheetCard from '../../../hooks/callsheet/useCallsheetCard'
import { useOptions } from '../../../hooks/callsheet/useOptions'
import useCallsheetStatistic from '../../../hooks/callsheet/useCallsheetStatistic'
import StatusChip from '../../CallsheetCard/StatusChip'
import InvitationStatusBox from '../../InvitationStatusBox'
import OptionsButton from '../../OptionsButton'
import {
  CallsheetStatuses,
  CallsheetStatusType,
} from '../../../store/Callsheet/types'
import { getUTCMomentDate } from '../../../utils/getUTCDate'
import { OptionsVerticalIcon } from '../../../assets/icons'
import { grids } from '../constants'
import style from './style'

type CallsheetListItemProps = {
  id: string
  title: string
  eventDate: DateConstructor | null
  companyName: string
  ownerId?: string
  status?: CallsheetStatuses
  divider?: boolean
}

const CallsheetListItem: FC<CallsheetListItemProps> = ({
  title,
  companyName,
  eventDate,
  status = CallsheetStatuses.DRAFT,
  divider = true,
  ownerId,
  id,
}) => {
  const { optionsMenuItems } = useOptions({ id, status, ownerId, title })
  const { handleCardClick, isClickable } = useCallsheetCard(status, id)
  const { statistics } = useCallsheetStatistic(id) ?? {}

  return (
    <>
      <Grid container sx={style.itemWrapper}>
        <Grid item xs={grids.title} onClick={handleCardClick}>
          <Typography sx={style.title(isClickable)}>{title}</Typography>
        </Grid>
        <Grid item xs={grids.status}>
          <StatusChip status={status} />
        </Grid>
        <Grid item xs={grids.date}>
          <Typography sx={style.infoTxt}>
            {getUTCMomentDate(eventDate).format('dddd, MMMM Do')}
          </Typography>
        </Grid>
        <Grid item xs={grids.company}>
          <Typography sx={style.infoTxt}>{companyName}</Typography>
        </Grid>
        <Grid item xs={grids.invitations} sx={{ display: 'flex', gap: 1 }}>
          {statistics ? (
            <>
              {Object.keys(statistics).map(key => (
                <InvitationStatusBox
                  key={key}
                  status={key as CallsheetStatusType}
                  displayNumber={statistics[key]}
                  round
                />
              ))}
            </>
          ) : null}
        </Grid>
        <Grid item xs={0.2}>
          <OptionsButton menuItems={optionsMenuItems}>
            <OptionsVerticalIcon />
          </OptionsButton>
        </Grid>
      </Grid>
      {divider && <Divider variant='middle' />}
    </>
  )
}

export default CallsheetListItem
