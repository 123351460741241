import { capitalize } from 'lodash'
import { tagColor } from '../../CallsheetsFilterSection/CallsheetFilter/constants'

const style = {
  container: {
    borderRadius: '8px',
    backgroundColor: '#ECF6FF',
    p: 1,
  },
  progressBarContainer: {
    display: 'flex',
    borderRight: '1px solid #E2E2E2',
    pr: 1,
    minWidth: '270px',
  },
  legend: {
    ml: '14px',
  },
  titleItem: {
    color: tagColor.Pending,
    mb: '4px',
  },
  listItem: (itemColor: string) => ({
    display: 'flex',
    alignItems: 'baseline',
    color: tagColor[itemColor],
    fontWeight: 900,
    mt: itemColor === 'Accepted' ? '0px' : 0,
    span: {
      display: 'block',
      mr: '4px',
      width: '8px',
      height: '8px',
      borderRadius: '8px',
      backgroundColor: tagColor[capitalize(itemColor)],
    },
  }),
  viewsContainer: {
    pl: 1,
  },
  viewsTitle: {
    color: tagColor.Pending,
    mb: '4px',
  },
  viewsCount: {
    color: tagColor.Published,
    fontSize: '20px',
    fontWeight: 900,
  },
}

export default style
