import produce from 'immer'
import { RequestStatuses } from 'src/api/constants'
import {
  ACCEPT_ANNOUNCEMENT,
  ACCEPT_CALLSHEET_REQUEST,
  ACCEPT_CONFIRMATION_REQUEST,
  CALLSHEET_CHANGE_STATUS_REQUEST_ERROR,
  CALLSHEET_CHANGE_STATUS_REQUEST_SUCCESS,
  DECLINE_CALLSHEET_REQUEST,
  DECLINE_CONFIRMATION_REQUEST,
  PENDING_CALLSHEET_REQUEST,
  PUBLISH_CALLSHEET_REQUEST,
  PUBLISH_CALLSHEET_REQUEST_ERROR,
  PUBLISH_CALLSHEET_REQUEST_SUCCESS,
  SET_ANNOUNVEMENTS_TO_ACCEPT,
  SET_CONFIRMATION_STEP,
  TENTATIVE_CALLSHEET_REQUEST,
  TENTATIVE_CONFIRMATION_REQUEST,
} from './actionTypes'

import {
  AnnouncementToAccept,
  CallsheetStatusActions,
  CallsheetStatusState,
} from './types'

const CallsheetInitialState: CallsheetStatusState = {
  requestStatus: RequestStatuses.UNCALLED,
  confirmationStep: 0,
  announcementsToAccept: [],
}

const callsheetStatusReducer = produce(
  (draft = CallsheetInitialState, action: CallsheetStatusActions) => {
    switch (action.type) {
      case DECLINE_CALLSHEET_REQUEST:
      case PUBLISH_CALLSHEET_REQUEST:
      case TENTATIVE_CALLSHEET_REQUEST:
      case ACCEPT_CALLSHEET_REQUEST:
      case TENTATIVE_CONFIRMATION_REQUEST:
      case DECLINE_CONFIRMATION_REQUEST:
      case ACCEPT_CONFIRMATION_REQUEST:
      case PENDING_CALLSHEET_REQUEST:
        draft.requestStatus = RequestStatuses.PENDING
        break

      case PUBLISH_CALLSHEET_REQUEST_SUCCESS:
        draft.requestStatus = RequestStatuses.SUCCEEDED
        break
      case CALLSHEET_CHANGE_STATUS_REQUEST_SUCCESS:
        draft.requestStatus = RequestStatuses.SUCCEEDED
        draft.callsheetToChangeId = action.id
        break

      case PUBLISH_CALLSHEET_REQUEST_ERROR:
      case CALLSHEET_CHANGE_STATUS_REQUEST_ERROR:
        draft.requestStatus = RequestStatuses.FAILED
        break
      case SET_CONFIRMATION_STEP:
        draft.confirmationStep = action.step

        break
      case SET_ANNOUNVEMENTS_TO_ACCEPT:
        draft.announcementsToAccept = action.announcementsToAccept?.map(
          item => ({
            id: item,
            accepted: false,
          })
        )
        break
      case ACCEPT_ANNOUNCEMENT:
        draft.announcementsToAccept = draft.announcementsToAccept.map(
          (item: AnnouncementToAccept) =>
            item.id === action.id ? { ...item, accepted: !item.accepted } : item
        )
        break
      default:
        return draft
    }
  }
)

export default callsheetStatusReducer
