import { noop } from 'lodash'
import React, { FC, FunctionComponent, ReactElement, SVGProps } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

import { CheckCircleGreenIcon } from '../../../assets/icons'
import style from './style'

type DeleteConfirmDialogProps = {
  open: boolean
  onClose: () => void
  title?: string
  message: string
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>
}

const ActionConfirmDialog: FC<DeleteConfirmDialogProps> = ({
  open,
  onClose,
  title,
  message,
  Icon = CheckCircleGreenIcon,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      sx={style.wrapper}
    >
      {title && (
        <DialogTitle id='alert-dialog-title' sx={style.title}>
          {title}
        </DialogTitle>
      )}
      <DialogContent sx={{ py: '0px', px: 4 }}>
        <Icon width='32px' height='32px' />
        <DialogContentText id='alert-dialog-description' sx={style.contentText}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={style.actionBox}>
        <Button variant='outlined' onClick={onClose} fullWidth>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ActionConfirmDialog
