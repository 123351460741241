import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Divider, Stack, Typography } from '@mui/material'

import { useToggle } from '../../../../hooks/useToggle'
import { selectGroupMembers } from '../../../../store/GroupMembers/selectors'
import { GroupMember } from '../../../../store/GroupMembers/types'
import {
  groupValidator,
  toggleSelectByTitle,
  createNewGroup,
  updateGroupTitle,
  deleteGroupByTitle,
} from '../../../../utils/groupHelpers'

import { setIsUserGroupPanel } from '../../../../store/Builder/actions'
import { setGroups } from '../../../../store/Groups/actions'
import {
  deleteGroupMembersByGroupTitle,
  updateMembersGroupTitle,
} from '../../../../store/GroupMembers/actions'
import { selectGroups } from '../../../../store/Groups/selectors'
import { GroupType } from '../../../../store/Groups/types'
import { getUpdatedMembersGroupTitle } from '../../../../utils/groupMemberHelpers'

import ControlButtons from '../../../../components/ControlButtons'
import CustomTextButton from '../../../../components/CustomTextButton'
import EditableTextField from '../../../../components/EditableTextField'
import ConfirmDialog from '../ConfirmDialog'
import HeadText from '../../../../components/HeadText'

import { PlusCircle } from '../../../../assets/icons'

import style from './style'

const AddUserGroupForm: FC = () => {
  const initialGroups = useSelector(selectGroups)
  const initialMembers = useSelector(selectGroupMembers)
  const [groups, setGroupsList] = useState<GroupType[]>(initialGroups)
  const [members, setMembers] = useState<GroupMember[]>(initialMembers)
  const [isAddingMode, setAddingMode] = useToggle()
  const [isConfirmDialogOpen, toggleConfirmDialog] = useToggle(false)
  const [groupToDelete, setToDelete] = useState<GroupType>()

  const dispatch = useDispatch()

  const handleCancelBtn = () => {
    dispatch(setIsUserGroupPanel(false))
  }

  const handleConfirmBtn = () => {
    dispatch(updateMembersGroupTitle(members))
    dispatch(setGroups(groups))

    dispatch(setIsUserGroupPanel(false))
  }

  const handleEdit = (oldTitle: string) => (newTitle: string) => {
    setMembers(getUpdatedMembersGroupTitle(oldTitle, newTitle, members))
    setGroupsList(updateGroupTitle(oldTitle, newTitle, groups))
  }

  const handleSelect = (title: string) => () => {
    const newGroups = toggleSelectByTitle(title, groups)
    setGroupsList(newGroups)
  }

  const handleDelete = (group: GroupType) => () => {
    setToDelete(group)
    toggleConfirmDialog()
  }

  const handleConfirmDelete = () => {
    if (groupToDelete) {
      const newGroups = deleteGroupByTitle(groupToDelete.title, groups)
      setGroupsList(newGroups)

      dispatch(deleteGroupMembersByGroupTitle(groupToDelete.title))

      dispatch(setGroups(newGroups, groupToDelete.id))
    }

    toggleConfirmDialog()
  }

  const handleAddAdditional = (title: string) => {
    setGroupsList(prev => [...prev, createNewGroup(title)])
    setAddingMode()
  }

  useEffect(() => {
    setMembers(initialMembers)
  }, [initialMembers])

  return (
    <Stack sx={style.wrapper}>
      <Box>
        <HeadText
          title='User Group'
          subtitle='Please, select all needed user groups who will take a part in a call
            sheet or create a new one'
        />
        <Stack sx={style.groupsWrapper}>
          <Typography variant='subtitle1'>Pre Saved Groups</Typography>
          <Stack sx={style.groups}>
            {groups.map((group, idx) => (
              <EditableTextField
                placeholder='Group name'
                id={group.title}
                defaultValue={group.title}
                key={`${group.title}${idx}`}
                onSelect={handleSelect(group.title)}
                onDelete={handleDelete(group)}
                onEdit={handleEdit(group.title)}
                validator={groupValidator(groups)}
                isChecked={group.isSelected}
                helperText='Already had a group with that name'
              />
            ))}
          </Stack>
          <Divider />
          <Typography variant='subtitle1'>Additional</Typography>
          {isAddingMode && (
            <EditableTextField
              placeholder='Group name'
              defaultValue=''
              editMode
              onEdit={handleAddAdditional}
              validator={groupValidator(groups)}
              helperText='Already had a group with that name'
            />
          )}
          <Box sx={style.addBtn}>
            <CustomTextButton
              title='Add additional user group'
              Icon={PlusCircle}
              onClick={setAddingMode}
            />
          </Box>
        </Stack>
      </Box>
      <ControlButtons
        confirmTitle='Add user group'
        handleCancelBtn={handleCancelBtn}
        handleConfirmBtn={handleConfirmBtn}
      />
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        handleCancel={toggleConfirmDialog}
        handleConfirm={handleConfirmDelete}
        confirmTitle='Remove'
        title='Remove User Group'
        content={`Are you sure you want to remove the ${groupToDelete?.title} from the list?`}
      />
    </Stack>
  )
}

export default AddUserGroupForm
