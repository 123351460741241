import { noop } from 'lodash'
import { useSelector } from 'react-redux'
import React, { ChangeEventHandler, FC, useRef, useState } from 'react'
import {
  Divider,
  Box,
  Typography,
  Stack,
  FormControl,
  OutlinedInput,
  IconButton,
} from '@mui/material'
import validator from 'validator'
import { CrossGrayIcon } from '../../../../../assets/icons'
import { Contact } from '../../../../../store/Contacts/types'
import { stripPhoneNumber } from '../../../../../utils/contactHelpers'
import PhoneInput from '../../../../../components/PhoneInput/PhoneInput'
import TimePickerDropdown from '../../../../../components/TimePickerDropdown'
import CustomSelect from '../../../../../components/CustomSelect'
import AvatarHeader from '../../../../../components/ContactDetailsForm/AvatarHeader'
import ContactDetailsFormWrapper from '../../../../../components/ContactDetailsForm/ContactDetailsFormWrapper'
import { selectUserId } from '../../../../../store/User/selectors'
import { CALLTIME_PLACEHOLDER, logoSrc } from '../../../../../constants'
import style from './style'

type ViewTeamDialogProps = {
  onBackClick: typeof noop
  onConfirmClick?: typeof noop
  member: Contact
  groupMember?: {
    callTime?: string
    wrapTime?: string
    onCallTimeChange: typeof noop
    onWrapTimeChange: typeof noop
  }
  selectProps?: {
    label: string
    values: string[]
    onSelect: typeof noop
    defaultValue?: string
  }
}

const MemberProfile: FC<ViewTeamDialogProps> = ({
  onBackClick,
  onConfirmClick,
  member,
  groupMember,
  selectProps,
}) => {
  const {
    id,
    firstName,
    lastName,
    bio,
    title,
    instagramLink,
    unsplashLink,
    website,
    phone,
    email,
  } = member
  const { label, values, onSelect, defaultValue } = selectProps || {}
  const userId = useSelector(selectUserId)
  const [memberTitle, setMemberTitle] = useState(title)
  const [memberPhone, setMemberPhone] = useState(phone)
  const [memberEmail, setMemberEmail] = useState(email)
  const [errors, setErrors] = useState<Record<string, boolean>>({})
  const titleInputRef = useRef(null)

  const validateInputs = () => {
    const err: Record<string, boolean> = {}
    const { isEmail, isMobilePhone } = validator

    err.title = !memberTitle
    err.email = !memberEmail || !isEmail(memberEmail)
    err.phone = !!(memberPhone && !isMobilePhone(memberPhone))

    setErrors(err)
    return Object.values(err).some(val => val)
  }

  const handleTitleClear = () => {
    setMemberTitle('')
    // @ts-ignore
    titleInputRef.current.focus()
  }

  const handleTitleChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    setErrors(prev => ({
      ...prev,
      title: false,
    }))
    setMemberTitle(value)
  }
  const handlePhoneChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    setErrors(prev => ({
      ...prev,
      phone: false,
    }))
    setMemberPhone(stripPhoneNumber(value))
  }
  const handleEmailChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    setErrors(prev => ({
      ...prev,
      email: false,
    }))
    setMemberEmail(value)
  }
  const onConfirm = () => {
    const hasErrors = validateInputs()
    if (hasErrors || !onConfirmClick) {
      return
    }

    onConfirmClick({
      ...member,
      title: memberTitle,
      phone: memberPhone,
      email: memberEmail,
    })
  }

  return (
    <ContactDetailsFormWrapper
      onBackClick={onBackClick}
      onConfirm={onConfirm}
      controlButtonsProps={{ confirmTitle: 'Save information' }}
    >
      <>
        <AvatarHeader
          bio={bio}
          logo={logoSrc(userId, id)}
          firstName={firstName}
          lastName={lastName}
          title={title}
          instagramLink={instagramLink}
          unsplashLink={unsplashLink}
          email={email}
          website={website}
        />
        <Divider orientation='horizontal' />
        {groupMember && (
          <>
            <Stack gap='20px' sx={{ py: 1 }}>
              <Box sx={style.infoRow}>
                <Typography variant='subtitle2' color='text.primary'>
                  Call Time:
                </Typography>
                <TimePickerDropdown
                  placeholder={CALLTIME_PLACEHOLDER}
                  value={groupMember.callTime}
                  endLimit={
                    groupMember.wrapTime === ''
                      ? '11:45pm'
                      : groupMember.wrapTime
                  }
                  onChange={groupMember.onCallTimeChange}
                />
              </Box>
              <Box sx={style.infoRow}>
                <Typography variant='subtitle2' color='text.primary'>
                  Wrap Time:
                </Typography>
                <TimePickerDropdown
                  placeholder={CALLTIME_PLACEHOLDER}
                  value={groupMember.wrapTime}
                  beginLimit={
                    groupMember.callTime === ''
                      ? '12:00am'
                      : groupMember.callTime
                  }
                  onChange={groupMember.onWrapTimeChange}
                />
              </Box>
            </Stack>
            <Divider orientation='horizontal' />
            <Box pt={1} sx={style.infoRow}>
              <Typography variant='subtitle2' color='text.secondary'>
                User group
              </Typography>
              {label && values && onSelect && defaultValue && (
                <CustomSelect
                  placeHolder={label}
                  values={values}
                  onSelect={onSelect}
                  defaultValue={defaultValue}
                  outlined
                />
              )}
            </Box>
          </>
        )}
        <Stack spacing={2} sx={style.info}>
          <Box sx={style.infoRow}>
            <Typography variant='subtitle2' color='text.secondary'>
              Primary role
            </Typography>
            <FormControl>
              <OutlinedInput
                inputRef={titleInputRef}
                endAdornment={
                  <IconButton size='small' onClick={handleTitleClear}>
                    <CrossGrayIcon />
                  </IconButton>
                }
                value={memberTitle}
                onChange={handleTitleChange}
                error={errors.title}
                sx={{ ...style.input, color: 'text.primary' }}
              />
            </FormControl>
          </Box>
          <Box sx={style.infoRow}>
            <Typography variant='subtitle2' color='text.secondary'>
              Email
            </Typography>
            <OutlinedInput
              sx={style.input}
              value={memberEmail}
              onChange={handleEmailChange}
              error={errors.email}
            />
          </Box>
          <Box sx={style.infoRow}>
            <Typography variant='subtitle2' color='text.secondary'>
              Phone
            </Typography>
            <PhoneInput
              error={errors.phone}
              value={memberPhone}
              helperText='Incorrect phone number. Please try again.'
              onChange={handlePhoneChange}
              sx={style.phoneInput}
            />
          </Box>
        </Stack>
      </>
    </ContactDetailsFormWrapper>
  )
}

export default MemberProfile
