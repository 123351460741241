import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logoSrc } from 'src/constants'
import { setIsContactDetailsPanel } from 'src/store/Builder/actions'
import {
  selectContacts,
  selectSelectedContact,
} from '../../store/Contacts/selectors'
import { Contact } from '../../store/Contacts/types'
import { selectUserId } from '../../store/User/selectors'

const useContactDetails = () => {
  const dispatch = useDispatch()
  const userId = useSelector(selectUserId)
  const contacts = useSelector(selectContacts)
  const selectedContactId = useSelector(selectSelectedContact)
  const onClose = () => {
    dispatch(setIsContactDetailsPanel(false))
  }

  const selectedContact = useMemo(() => {
    return contacts.find((contact: Contact) => contact.id === selectedContactId)
  }, [contacts, selectedContactId])

  return {
    onClose,
    selectedContact,
    contactLogo: logoSrc(userId, selectedContactId),
  }
}

export default useContactDetails
