import React, { FC, PropsWithChildren } from 'react'
import { Box, IconButton } from '@mui/material'
import { noop } from 'lodash'

import Drawer from '@mui/material/Drawer'
import { CrossCircleIcon } from '../../assets/icons'
import style from './style'

type SidePanelProps = {
  isOpen: boolean
  variant?: 'permanent' | 'persistent' | 'temporary'
  onClose: typeof noop
}

const SidePanel: FC<SidePanelProps & PropsWithChildren> = ({
  children,
  isOpen,
  onClose,
  variant = 'temporary',
}) => {
  return (
    <Drawer anchor='right' open={isOpen} onClose={onClose} variant={variant}>
      <Box sx={style.buttonBox}>
        <IconButton onClick={onClose} sx={style.button}>
          <CrossCircleIcon />
        </IconButton>
      </Box>
      <Box sx={style.content}>{children}</Box>
    </Drawer>
  )
}

export default SidePanel
