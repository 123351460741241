export enum DeleteCallsheetFlow {
  CONFIRM = 'CONFIRM',
  DELETE_CALLSHEET = 'DELETE_CALLSHEET',
  CONTINUE_TO_BUILDER = 'CONTINUE_TO_BUILDER',
}

export type DeleteCallsheetFlowType = keyof typeof DeleteCallsheetFlow

export const btnTitles = {
  [DeleteCallsheetFlow.CONFIRM]: 'Confirm',
  [DeleteCallsheetFlow.CONTINUE_TO_BUILDER]: 'Continue to Builder',
  [DeleteCallsheetFlow.DELETE_CALLSHEET]: 'Delete Callsheet',
}

export const DEFAULT_FIELDS_STATE = { date: null, name: '', time: '' }
export type FieldsStateType = {
  date: DateConstructor | null
  name: string
  time: string
}
