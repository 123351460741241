import {
  SET_EMAIL_ATTACHMENT,
  SET_EMAIL_DESCRIPTION,
  SET_EMAIL_FROM,
  SET_EMAIL_REMINDER,
  SWITCH_EMAIL_REMINDER,
  SET_EMAIL_SUBJECT,
  SET_EMAIL_PARAMETERS,
} from './actionTypes'
import {
  EmailParameters,
  SetEmailAttachment,
  SetEmailDescription,
  SetEmailFrom,
  SetEmailParameters,
  SetEmailReminder,
  SetEmailSubject,
  SwitchEmailReminder,
} from './types'

export const setEmailFrom = (emailFrom: string): SetEmailFrom => ({
  type: SET_EMAIL_FROM,
  emailFrom,
})

export const setEmailReminder = (emailReminder: number): SetEmailReminder => ({
  type: SET_EMAIL_REMINDER,
  emailReminder,
})

export const setEmailDescription = (
  emailContent: string
): SetEmailDescription => ({
  type: SET_EMAIL_DESCRIPTION,
  emailContent,
})

export const setEmailSubject = (emailSubject: string): SetEmailSubject => ({
  type: SET_EMAIL_SUBJECT,
  emailSubject,
})

export const setEmailAttachment = (): SetEmailAttachment => ({
  type: SET_EMAIL_ATTACHMENT,
})

export const switchEmailReminder = (): SwitchEmailReminder => ({
  type: SWITCH_EMAIL_REMINDER,
})

export const setEmailParameters = (
  emailParameters: EmailParameters
): SetEmailParameters => ({
  type: SET_EMAIL_PARAMETERS,
  emailParameters,
})
