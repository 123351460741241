import React, { FC } from 'react'
import { Box, Divider, Grid, Typography } from '@mui/material'
import CallTimeCard from '../../../components/CallTimeCard'
import ProductionSchedule from '../../BuilderPage/FinalTouches/ProductionSchedule'
import CompaniesResultSection from '../../BuilderPage/ProjectOverview/OverviewResult/CompaniesResultSection'
import LocationsSection from '../../BuilderPage/ProjectOverview/OverviewResult/LocationsSection'
import AnnouncementsView from '../AnnouncementsView'
import AttachmentsView from '../AttachmentsView'
import ChannelsView from '../ChannelsView'
import GroupsPreview from '../GroupsPreview'
import NotesView from '../NotesView'
import style from '../style'

export const CALLSHEET_VIEW_ID = 'callsheetView'

type CallsheetViewType = {
  callTime: string
}

const CallsheetView: FC<CallsheetViewType> = ({ callTime }) => {
  return (
    <Box id={CALLSHEET_VIEW_ID}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <CompaniesResultSection isPreview />
        </Grid>
        <Grid item xs={4}>
          <CallTimeCard callTime={callTime} />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 0, mb: 4 }} />
      <Typography sx={style.title}>Locations</Typography>
      <LocationsSection isPreview />
      <Divider sx={{ my: 4 }} />
      <Typography sx={style.title}>Team</Typography>
      <GroupsPreview />
      <Divider sx={{ my: 4 }} />
      <Box display='flex' gap={5}>
        <ProductionSchedule isPreview />
        <Box sx={style.finalTouchesBox}>
          <NotesView />
          <AnnouncementsView />
          <Box display='flex' gap={5}>
            <ChannelsView />
            <AttachmentsView />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default CallsheetView
