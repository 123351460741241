import React, { FC } from 'react'
import clsx from 'clsx'

import { PATHS } from 'src/constants'
import { Link } from 'react-router-dom'
import { WelcomePageIllustration } from '../../assets/icons'
import useStyles from './styles'

const NotFoundPage: FC = () => {
  const classes = useStyles()
  return (
    <>
      <main className={classes.main}>
        <div className={classes.container}>
          <div className={classes.content}>
            <h1 className={classes.heading}>404</h1>
            <p className={classes.title}>Oops, that’s an error.</p>
            <p className={classes.text}>
              The requested URL doesn’t exist. If you think something is broken,
              report a problem
            </p>
            <div className={classes.buttons}>
              <Link
                className={clsx(classes.button, classes.returnLink)}
                type='button'
                to={PATHS.PRIVATE.HOME}
              >
                <span className={classes.linkText}>return home</span>
              </Link>
            </div>
          </div>
          <div className={classes.image}>
            <WelcomePageIllustration />
          </div>
        </div>
      </main>
    </>
  )
}

export default NotFoundPage
