import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Paper, Typography } from '@mui/material'
import { selectIsCombinedNotes } from 'src/store/Callsheet/selectors'
import { selectAnnouncements } from '../../../store/Announcements/selectors'
import style from '../style'

const AnnouncementsView = () => {
  const announcements = useSelector(selectAnnouncements)
  const isCombined = useSelector(selectIsCombinedNotes)

  return (
    <>
      {announcements.length ? (
        <Box sx={{ mb: 5 }}>
          <Typography sx={style.title}>Announcements</Typography>
          {announcements.map(({ id, content, important }) => (
            <Paper key={id} variant='outlined' sx={style.annoItem(important)}>
              <Typography variant='subtitle2'>{content}</Typography>
            </Paper>
          ))}
        </Box>
      ) : null}
    </>
  )
}

export default AnnouncementsView
