import React from 'react'
import { Box, IconButton, InputBase } from '@mui/material'
import { SearchIcon } from '../../assets/icons'
import style from './style'

const CallsheetSearchField = () => {
  return (
    <Box sx={style.searchBox}>
      <Box sx={style.input}>
        <IconButton sx={style.inputIcon}>
          <SearchIcon />
        </IconButton>
        <InputBase
          placeholder='Search by name, company or email...'
          inputProps={{ 'aria-label': 'search' }}
        />
      </Box>
    </Box>
  )
}

export default CallsheetSearchField
