import { AxiosPromise } from 'axios'
import { Callsheet, CallsheetStatuses } from '../../store/Callsheet/types'
import { apiClient } from '../clients'
import { METHODS, URLS } from '../constants'

export const getCallsheets = (): AxiosPromise =>
  apiClient.request({
    method: METHODS.GET,
    url: URLS.CALLSHEET,
    // params: {
    //   statuses: [CallsheetStatuses.DELETED, CallsheetStatuses.DRAFT],
    // },
    // paramsSerializer: { indexes: null },
  })

export const getCallsheetByIdApi = (id: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.GET,
    url: `${URLS.CALLSHEET}/${id}`,
  })

export const saveCallsheetApi = (callsheet: Callsheet): AxiosPromise =>
  apiClient.request({
    method: METHODS.POST,
    url: URLS.CALLSHEET,
    data: callsheet,
  })

export const updateCallsheetApi = (
  callsheetId: string,
  callsheet: Partial<Callsheet>
): AxiosPromise =>
  apiClient.request({
    method: METHODS.PATCH,
    url: `${URLS.CALLSHEET}/${callsheetId}`,
    data: callsheet,
  })

export const getMemberTitlesApi = (): AxiosPromise =>
  apiClient.request({
    method: METHODS.GET,
    url: URLS.MEMBER_TITLES,
  })

export const publishCallsheetApi = (callsheetId: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.POST,
    url: `${URLS.CALLSHEET}/${callsheetId}/publish`,
  })

export const declineCallsheetApi = (callsheetId: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.PATCH,
    url: `${URLS.CALLSHEET}/${callsheetId}/decline`,
  })

export const tentativeCallsheetApi = (callsheetId: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.PATCH,
    url: `${URLS.CALLSHEET}/${callsheetId}/tentative`,
  })

export const acceptCallsheetApi = (callsheetId: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.PATCH,
    url: `${URLS.CALLSHEET}/${callsheetId}/accept`,
  })

export const deleteCallsheetApi = (callsheetId: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.DELETE,
    url: `${URLS.CALLSHEET}/${callsheetId}`,
  })

export const duplicateCallsheetApi = (callsheetId: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.POST,
    url: `${URLS.CALLSHEET}/${callsheetId}/duplicate`,
  })

export const restoreCallsheetApi = (callsheetId: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.POST,
    url: `${URLS.CALLSHEET}/${callsheetId}/restore`,
  })

export const pendingCallsheetApi = (callsheetId: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.PATCH,
    url: `${URLS.CALLSHEET}/${callsheetId}/pending`,
  })

export const getCallsheetsStatisticApi = (
  callsheetIds: string[]
): AxiosPromise =>
  apiClient.request({
    method: METHODS.GET,
    url: `${URLS.CALLSHEET}/statistic`,
    params: {
      callsheetIds,
    },
    paramsSerializer: { indexes: null },
  })
