import React, { FC } from 'react'
import { noop } from 'lodash'
import { Box, ButtonBase, Typography } from '@mui/material'
import { CaretLeftIcon } from '../../assets/icons'

type BackBtnTypes = {
  text: string
  onClick: typeof noop
}

const BackBtn: FC<BackBtnTypes> = ({ text, onClick }) => {
  return (
    <Box sx={{ mb: '40px' }}>
      <ButtonBase onClick={onClick}>
        <CaretLeftIcon />
        <Typography variant='body2' color='primary.main' sx={{ ml: 0 }}>
          {text}
        </Typography>
      </ButtonBase>
    </Box>
  )
}

export default BackBtn
