import { Box, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { DocumentOutlineIcon } from '../../../assets/icons'
import { selectAttachments } from '../../../store/Attachments/selectors'
import { convertBytesToKb } from '../../../utils/getFileSize'
import style from '../style'

const AttachmentsView = () => {
  const attachments = useSelector(selectAttachments)

  return (
    <>
      {attachments.length ? (
        <Box sx={{ width: '100%' }}>
          <Typography sx={style.title}>Attachments</Typography>
          <Box gap='12px'>
            {attachments.map(({ id, name, size }) => (
              <Paper key={id} variant='outlined' sx={style.attachmentItem}>
                <Box sx={{ mr: 0 }}>
                  <DocumentOutlineIcon />
                </Box>
                <Stack alignItems='flex-start'>
                  <Typography
                    variant='subtitle2'
                    color='primary.main'
                    lineHeight='20px'
                  >
                    {`File name #${name}`}
                  </Typography>
                  <Typography variant='body1' color='text.disabled'>
                    {`${convertBytesToKb(size)}KB`}
                  </Typography>
                </Stack>
              </Paper>
            ))}
          </Box>
        </Box>
      ) : null}
    </>
  )
}

export default AttachmentsView
