import { AxiosPromise } from 'axios'
import { apiClient } from '../clients'
import { METHODS, URLS } from '../constants'

export const getUserGroupsApi = (callsheetId: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.GET,
    url: `${URLS.CALLSHEET}/${callsheetId}/user-group`,
  })

export const addUserGroupApi = (
  callsheetId: string,
  title: string,
  callTime: string,
  isPrimary: boolean
): AxiosPromise =>
  apiClient.request({
    method: METHODS.POST,
    url: URLS.USER_GROUP,
    data: { callsheetId, title, callTime, isPrimary },
  })

export const deleteUserGroupApi = (id: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.DELETE,
    url: `${URLS.USER_GROUP}/${id}`,
  })

export const updateUserGroupApi = (
  id: string,
  title: string,
  callTime: string,
  isPrimary: boolean
): AxiosPromise =>
  apiClient.request({
    method: METHODS.PATCH,
    url: `${URLS.USER_GROUP}/${id}`,
    data: { title, callTime, isPrimary },
  })
