import produce from 'immer'
import { RequestStatuses } from '../../api/constants'
import {
  ADD_LOCATIONS_REQUEST,
  ADD_LOCATIONS_REQUEST_ERROR,
  ADD_LOCATIONS_REQUEST_SUCCESS,
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_REQUEST_ERROR,
  DELETE_LOCATION_REQUEST_SUCCESS,
  GET_LOCATIONS_REQUEST,
  GET_LOCATIONS_REQUEST_ERROR,
  GET_LOCATIONS_REQUEST_SUCCESS,
  UPDATE_LOCATION_REQUEST,
  UPDATE_LOCATION_REQUEST_ERROR,
  UPDATE_LOCATION_REQUEST_SUCCESS,
  SET_HOSPITAL,
  SET_IS_HOSPITAL,
  SET_IS_WEATHER,
  SET_LOCATIONS,
} from './actionTypes'
import { LogisticsState, LogisticsActions } from './types'

const logisticsInitialState: LogisticsState = {
  hospital: null,
  isHospital: false,
  isWeather: false,
  locations: [],
  requestStatus: RequestStatuses.UNCALLED,
  deleteRequestStatus: RequestStatuses.UNCALLED,
}

const logisticsReducer = produce(
  (draft = logisticsInitialState, action: LogisticsActions) => {
    switch (action.type) {
      case ADD_LOCATIONS_REQUEST:
      case GET_LOCATIONS_REQUEST:
      case UPDATE_LOCATION_REQUEST:
        draft.requestStatus = RequestStatuses.PENDING
        break
      case DELETE_LOCATION_REQUEST:
        draft.deleteRequestStatus = RequestStatuses.PENDING
        break
      case ADD_LOCATIONS_REQUEST_SUCCESS:
      case UPDATE_LOCATION_REQUEST_SUCCESS:
        draft.requestStatus = RequestStatuses.SUCCEEDED
        break
      case GET_LOCATIONS_REQUEST_SUCCESS:
        draft.locations = action.locations
        draft.requestStatus = RequestStatuses.SUCCEEDED
        break
      case DELETE_LOCATION_REQUEST_SUCCESS:
        draft.deleteRequestStatus = RequestStatuses.SUCCEEDED
        break
      case ADD_LOCATIONS_REQUEST_ERROR:
      case GET_LOCATIONS_REQUEST_ERROR:
      case UPDATE_LOCATION_REQUEST_ERROR:
        draft.requestStatus = RequestStatuses.FAILED
        break
      case DELETE_LOCATION_REQUEST_ERROR:
        draft.deleteRequestStatus = RequestStatuses.FAILED
        break
      case SET_HOSPITAL:
        draft.hospital = action.hospital
        break
      case SET_IS_WEATHER:
        draft.isWeather = action.isWeather
        break
      case SET_IS_HOSPITAL:
        draft.isHospital = action.isHospital
        break
      case SET_LOCATIONS:
        draft.locations = action.locations
        break
      default:
        return draft
    }
  }
)

export default logisticsReducer
