import produce from 'immer'
import { RequestStatuses } from '../../api/constants'
import {
  GET_COMPANIES_BY_NAME_REQUEST,
  GET_COMPANY_BY_ID_REQUEST,
  GET_COMPANIES_REQUEST_ERROR,
  GET_COMPANIES_REQUEST_SUCCESS,
  GET_COMPANY_REQUEST_SUCCESS,
  SAVE_COMPANY_REQUEST,
  SAVE_COMPANY_REQUEST_ERROR,
  SAVE_COMPANY_REQUEST_SUCCESS,
  SET_COMPANY,
  UPDATE_COMPANY_MEMBERS_REQUEST,
  UPDATE_COMPANY_MEMBERS_REQUEST_SUCCESS,
  UPDATE_COMPANY_MEMBERS_REQUEST_ERROR,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_REQUEST_SUCCESS,
  UPDATE_COMPANY_REQUEST_ERROR,
  GET_CALLSHEETS_COMPANIES_BY_ID_REQUEST,
  GET_CALLSHEETS_COMPANIES_BY_ID_REQUEST_SUCCESS,
  GET_COMPANIES_REQUEST,
  ADD_COMPANY_REQUEST,
  ADD_COMPANY_REQUEST_SUCCESS,
} from './actionTypes'
import { CompaniesActions, CompaniesState } from './types'

const CompaniesInitialState: CompaniesState = {
  companies: [],
  callsheetsCompanies: [],
  client: {},
  production: {},
  membersRequestStatus: RequestStatuses.UNCALLED,
  saveCompanyRequestStatus: RequestStatuses.UNCALLED,
  getCompaniesRequestStatus: RequestStatuses.UNCALLED,
}

const companiesReducer = produce(
  (draft = CompaniesInitialState, action: CompaniesActions) => {
    switch (action.type) {
      case SAVE_COMPANY_REQUEST:
      case UPDATE_COMPANY_REQUEST:
      case ADD_COMPANY_REQUEST:
        draft.saveCompanyRequestStatus = RequestStatuses.PENDING
        break
      case GET_COMPANIES_BY_NAME_REQUEST:
      case GET_COMPANY_BY_ID_REQUEST:
      case GET_CALLSHEETS_COMPANIES_BY_ID_REQUEST:
      case GET_COMPANIES_REQUEST:
        draft.getCompaniesRequestStatus = RequestStatuses.PENDING
        break
      case SAVE_COMPANY_REQUEST_SUCCESS:
      case UPDATE_COMPANY_REQUEST_SUCCESS:
        draft[action.companyType].id = action.id
        draft.saveCompanyRequestStatus = RequestStatuses.SUCCEEDED
        break
      case ADD_COMPANY_REQUEST_SUCCESS:
        draft.saveCompanyRequestStatus = RequestStatuses.SUCCEEDED
        break
      case GET_COMPANIES_REQUEST_SUCCESS:
        draft.companies = action.companies
        draft.getCompaniesRequestStatus = RequestStatuses.SUCCEEDED
        break
      case GET_CALLSHEETS_COMPANIES_BY_ID_REQUEST_SUCCESS:
        draft.callsheetsCompanies = action.companies
        draft.getCompaniesRequestStatus = RequestStatuses.SUCCEEDED
        break
      case GET_COMPANY_REQUEST_SUCCESS:
        draft[action.companyType] = action.company
        draft.getCompaniesRequestStatus = RequestStatuses.SUCCEEDED
        break

      case SAVE_COMPANY_REQUEST_ERROR:
      case UPDATE_COMPANY_REQUEST_ERROR:
        draft.saveCompanyRequestStatus = RequestStatuses.FAILED
        break
      case GET_COMPANIES_REQUEST_ERROR:
        draft.getCompaniesRequestStatus = RequestStatuses.FAILED
        break
      case UPDATE_COMPANY_MEMBERS_REQUEST:
        draft.membersRequestStatus = RequestStatuses.PENDING
        break
      case UPDATE_COMPANY_MEMBERS_REQUEST_SUCCESS:
        draft.membersRequestStatus = RequestStatuses.SUCCEEDED
        break
      case UPDATE_COMPANY_MEMBERS_REQUEST_ERROR:
        draft.membersRequestStatus = RequestStatuses.FAILED
        break
      case SET_COMPANY:
        draft[action.companyType] = action.company
        break
      default:
        return draft
    }
  }
)

export default companiesReducer
