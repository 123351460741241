import React, { FC } from 'react'
import { noop } from 'lodash'
import { Box, Button, InputLabel } from '@mui/material'
import { PlusCircle } from '../../assets/icons'

type AddButtonTypes = {
  size?: 'small' | 'medium' | 'large'
  label?: string
  title: string
  onClick: typeof noop
  required?: boolean
}

const AddButton: FC<AddButtonTypes> = ({
  size = 'medium',
  label,
  title,
  onClick,
  required = false,
}) => (
  <Box>
    {label && <InputLabel required={required}>{label}</InputLabel>}
    <Button
      variant='outlined'
      fullWidth
      size={size}
      onClick={onClick}
      sx={{ borderRadius: '10px' }}
    >
      <Box sx={{ mr: 0, fontSize: 12, textTransform: 'initial' }}>{title}</Box>{' '}
      <PlusCircle />
    </Button>
  </Box>
)

export default AddButton
