import React, { FC, FocusEventHandler, useEffect, useState } from 'react'
import { noop, omit } from 'lodash'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  IconButton,
  TextField,
} from '@mui/material'
import { onSaveProps } from 'react-edit-text'
import { useSelector } from 'react-redux'
import { RequestStatuses } from '../../../../../api/constants'
import { LocationType } from '../../../../../api/types'
import { CaretBottomIcon, TrashcanIcon } from '../../../../../assets/icons'
import { selectDeleteRequestStatus } from '../../../../../store/Logistics/selectors'
import { LocationItem } from '../../../../../store/Logistics/types'
import CustomTextButton from '../../../../../components/CustomTextButton'
import EditButton from '../../../../../components/EditButton/EditButton'
import LocationAutocomplete from '../../../../../components/LocationAutocomplete'
import SitemapUpload from '../SitemapUpload'
import { ErrorsFieldsTypes } from '../types'
import style from './style'

type LocationInputCardType = {
  locationItem: LocationItem
  index: number
  handleRemove: (i: number) => void
  updateLocation: typeof noop
  isRemoveButton: boolean
  errorFields: ErrorsFieldsTypes
  clearErrorField: typeof noop
}

const LocationInputCard: FC<LocationInputCardType> = ({
  locationItem,
  index,
  handleRemove,
  updateLocation,
  isRemoveButton,
  errorFields = {},
  clearErrorField,
}) => {
  const requestStatus = useSelector(selectDeleteRequestStatus)
  const { title, locationNotes, image, ...location } = locationItem
  const [expand, setExpand] = useState(true)
  const [address, setAddress] = useState<LocationType | null>(location)

  const toggleAccordion = () => {
    setExpand(!expand)
  }

  const handleTitleChange = (props: onSaveProps) => {
    clearErrorField('title', index)
    updateLocation({ ...locationItem, title: props.value })
  }

  const handleAddressClear = () => {
    updateLocation({
      ...omit(locationItem, [
        'name',
        'address',
        'city',
        'state',
        'zip',
        'country',
        'longitude',
        'latitude',
      ]),
    })
  }

  const handleAddressChange = (value: LocationType | null) => {
    clearErrorField('address', index)
    updateLocation({ ...locationItem, ...value })
  }

  const handleDescriptionChange: FocusEventHandler<HTMLTextAreaElement> = ({
    target: { value },
  }) => {
    updateLocation({ ...locationItem, locationNotes: value })
  }

  const handleSetSitemap = (value: string) => {
    updateLocation({ ...locationItem, image: value })
  }

  useEffect(() => {
    handleAddressChange(address)
  }, [address])

  return (
    <Accordion variant='outlined' expanded={expand} sx={style.accordion}>
      <AccordionSummary
        expandIcon={
          <IconButton size='large' onClick={toggleAccordion}>
            <CaretBottomIcon />
          </IconButton>
        }
        onClick={() => false}
        sx={style.header}
      >
        <EditButton
          onSave={handleTitleChange}
          defaultValue={title}
          error={errorFields.title || false}
        />
      </AccordionSummary>
      <AccordionDetails sx={style.details}>
        <Box sx={{ mb: '10px' }}>
          <LocationAutocomplete
            id={`locationItem-${index}`}
            location={location.address ? location : null}
            onClear={handleAddressClear}
            onInputChange={setAddress}
            error={errorFields.address || false}
          />
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              defaultValue={locationNotes}
              onBlur={handleDescriptionChange}
              placeholder='Add description (optional)'
              multiline
              rows={3}
              sx={style.textarea}
              inputProps={{
                maxLength: 55,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <SitemapUpload defaultValue={image} onChange={handleSetSitemap} />
          </Grid>
        </Grid>
        {isRemoveButton ? (
          <Box sx={style.footer}>
            <Divider sx={{ width: '100%', mb: '12px' }} />
            <CustomTextButton
              title='Delete location'
              Icon={TrashcanIcon}
              color='error.main'
              onClick={handleRemove}
              loading={requestStatus === RequestStatuses.PENDING}
            />
          </Box>
        ) : null}
      </AccordionDetails>
    </Accordion>
  )
}

export default LocationInputCard
