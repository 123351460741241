import { AppState } from '../types'
import { RequestStatus, SampleError } from '../../api/types'

export const selectSignInRequestStatus = (state: AppState): RequestStatus =>
  state.signInState.requestStatus
export const selectSignInEmail = (state: AppState): string =>
  state.signInState.signInEmail
export const selectSignInName = (state: AppState): string =>
  state.signInState.signInName
export const selectSignInLastName = (state: AppState): string =>
  state.signInState.signInLastName
export const selectSignInPassword = (state: AppState): string =>
  state.signInState.signInPassword
export const selectSignInFetchingError = (
  state: AppState
): SampleError | null => state.signInState.fetchingError
