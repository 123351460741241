import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'

type SidePanelHeadTypes = {
  title: string
  subtitle: string
}

const HeadText: FC<SidePanelHeadTypes> = ({ title, subtitle }) => {
  return (
    <Box sx={{ mb: 4, maxWidth: '420px' }}>
      <Typography
        variant='h3'
        component='h3'
        color='text.main'
        sx={{ mb: 0, textTransform: 'capitalize' }}
      >
        {title}
      </Typography>
      <Typography variant='subtitle1' color='text.secondary'>
        {subtitle}
      </Typography>
    </Box>
  )
}

export default HeadText
