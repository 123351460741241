import { TextField, Theme } from '@mui/material'
import { SxProps } from '@mui/system'
import React, { ChangeEvent, FC } from 'react'
import InputMask from 'react-input-mask'
import style from '../CustomTextField/style'

interface PhoneInputProps {
  id?: string
  name?: string
  mask?: string
  helperText?: string
  placeholder?: string
  error: boolean
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  sx?: SxProps<Theme>
}

const PhoneInput: FC<PhoneInputProps> = ({
  id = 'phone',
  name = 'phone',
  mask = '(999) 999-9999',
  helperText,
  placeholder = '(000) 000-0000',
  value,
  onChange,
  error,
  sx,
}) => {
  return (
    <InputMask mask={mask} value={value} onChange={onChange}>
      <TextField
        fullWidth
        id={id}
        name={name}
        placeholder={placeholder}
        error={error}
        helperText={error ? helperText : ''}
        FormHelperTextProps={{ sx: style.helperText }}
        sx={sx}
      />
    </InputMask>
  )
}

export default PhoneInput
