import React, { FC, ChangeEventHandler } from 'react'
import { noop } from 'lodash'
import { Box, InputAdornment, TextField, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'

import { PlusCircle, SearchIcon } from '../../../../../assets/icons'
import { setIsAddContactPanel } from '../../../../../store/Builder/actions'
import CustomTextButton from '../../../../../components/CustomTextButton'

type SearchBoxProps = {
  onChange: typeof noop
  label?: string
}

const SearchBox: FC<SearchBoxProps> = ({ onChange, label }) => {
  const dispatch = useDispatch()
  const searchContact: ChangeEventHandler<HTMLTextAreaElement> = ({
    target: { value },
  }) => {
    onChange(value)
  }

  const openAddContactForm = () => {
    dispatch(setIsAddContactPanel(true))
  }

  return (
    <Box sx={{ mb: 3 }}>
      {label && (
        <Typography variant='h5' component='h5' sx={{ mb: '12px' }}>
          {label}
        </Typography>
      )}

      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <TextField
          size='small'
          placeholder='Search by name or email...'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant='outlined'
          sx={{ width: '325px' }}
          onChange={searchContact}
        />
        <CustomTextButton
          title='New Contact'
          Icon={PlusCircle}
          onClick={openAddContactForm}
        />
      </Box>
    </Box>
  )
}

export default SearchBox
