const style = {
  container: { width: '894px', margin: '0 auto' },
  timeContainer: {
    backgroundColor: '#ECF6FF',
    boxShadow: '0px 3px 6px 0px #0000000F',
    borderRadius: '8px',
    p: 2,
  },
  callTime: {
    color: '#1E486C',
    fontWeight: 500,
    fontSize: '44px',
    lineHeight: '56px',
  },
  date: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '28px',
    color: 'text.primary',
  },
}

export default style
