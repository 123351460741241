import React, { FC, PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'

import { Box } from '@mui/material'

import { selectIsPersistentPanel } from '../../store/Builder/selectors'

import ProgressBar from '../ProgressBar'

import style from './style'

const BuilderLayout: FC<PropsWithChildren> = ({ children }) => {
  const isPersistentPanel = useSelector(selectIsPersistentPanel)
  return (
    <Box sx={style.layout}>
      <ProgressBar />
      <Box sx={style.wrapper}>
        <Box sx={style.container(isPersistentPanel)}>
          <Box sx={style.content}>{children}</Box>
        </Box>
      </Box>
    </Box>
  )
}

export default BuilderLayout
