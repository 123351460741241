import { noop } from 'lodash'
import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Box,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  ListItem,
} from '@mui/material'

import { SelectChangeEvent } from '@mui/material/Select'
import SelectFilterInput from '../../SelectFilterInput'
import { setCallsheetsSortTag } from '../../../store/Gallery/actions'
import { SortTags } from '../../../store/Gallery/types'
import { DEFAULT_TAG, MenuProps, TAGS } from './constants'
import { selectCallsheetsFilterTags } from '../../../store/Gallery/selectors'

import { CaretBottomIcon } from '../../../assets/icons'

import style from './style'

type FilterProps = {
  label?: string
  selectedTags: string[]
  setSelectedTags: typeof noop
  defaultTag?: string
}

const CallsheetSort: FC<FilterProps> = ({
  label = 'Sort',
  selectedTags,
  setSelectedTags,
  defaultTag = DEFAULT_TAG,
}) => {
  const dispatch = useDispatch()
  const filterTags = useSelector(selectCallsheetsFilterTags)
  const handleChange = (event: SelectChangeEvent<typeof selectedTags>) => {
    const {
      target: { value },
    } = event
    const nextValue = typeof value === 'string' ? value.split(',') : value
    setSelectedTags(nextValue)
    dispatch(setCallsheetsSortTag(nextValue[0] as SortTags))
  }

  const renderValue = (selected: string[]) => {
    return selectedTags.length === 0 ? defaultTag : selected.join(', ')
  }

  const isSelected = (tag: string) => selectedTags.indexOf(tag) > -1
  const isSortByStatus = (tag: string): boolean =>
    tag === SortTags.STATUS && filterTags.length > 0

  return (
    <div>
      <FormControl>
        <Select
          id='view-select'
          displayEmpty
          value={selectedTags}
          onChange={handleChange}
          MenuProps={MenuProps}
          renderValue={renderValue}
          input={<SelectFilterInput outlined label={label} />}
          IconComponent={props => (
            <Box {...props} sx={style.icon}>
              <CaretBottomIcon />
            </Box>
          )}
        >
          {[
            ...TAGS.map(tag => (
              <MenuItem
                key={tag}
                value={tag}
                sx={style.item(isSelected(tag))}
                disabled={isSortByStatus(tag)}
              >
                <ListItem key={tag}>
                  <ListItemText primary={`${label} by ${tag}`} />
                </ListItem>
              </MenuItem>
            )),
          ]}
        </Select>
      </FormControl>
    </div>
  )
}

export default CallsheetSort
