import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { CompanyIcon } from '../../../../../assets/icons'
import { Contact } from '../../../../../store/Contacts/types'
import { addBase64Data } from '../../../../../utils/image'

import style from './style'

type CompanyCardTypes = {
  logo?: string
  name: string
  members?: Contact[]
  address?: string
  website?: string
  websiteVisible?: boolean
  addressVisible?: boolean
  companyType: string
  isPreview?: boolean
}

const CompanyResultCard: FC<CompanyCardTypes> = ({
  logo,
  name,
  members = [],
  address,
  website,
  websiteVisible,
  addressVisible,
  companyType,
  isPreview,
}) => (
  <Box width='100%'>
    {logo ? (
      <Box sx={style.imageBox}>
        <img src={addBase64Data(logo)} alt='company logo' style={style.image} />
      </Box>
    ) : (
      <Box sx={style.placeholderBox}>
        <CompanyIcon />
      </Box>
    )}
    <Typography sx={style.label}>{companyType} company:</Typography>
    {name ? (
      <span style={style.companyTitle}>{name}</span>
    ) : (
      <Box sx={style.namePlaceholder} />
    )}
    <Box display={isPreview ? 'flex' : 'block'}>
      <Box width={isPreview ? '50%' : '100%'}>
        {companyType === 'Production' ? (
          <>
            <Typography sx={style.label}>Primary Contact:</Typography>
            {members[0] ? (
              <span style={style.title}>
                {members[0].firstName} {members[0].lastName} -{' '}
                {members[0].title}
              </span>
            ) : (
              <Box sx={style.primaryPlaceholder} />
            )}
            <Typography sx={style.label}>Secondary Contact:</Typography>
            {members[1] ? (
              <span style={style.title}>
                {members[1].firstName} {members[1].lastName} -{' '}
                {members[1].title}
              </span>
            ) : (
              <Box sx={style.secondaryPlaceholder} />
            )}
          </>
        ) : null}
      </Box>
      <Box width={isPreview ? '50%' : '100%'}>
        {companyType === 'Production' && address && addressVisible ? (
          <>
            <Typography sx={style.label}>{companyType} Address:</Typography>
            <span style={style.title}>{address}</span>
          </>
        ) : null}
        {companyType === 'Client' ? (
          <>
            {address && addressVisible ? (
              <>
                <Typography sx={style.label}>{companyType} Address:</Typography>
                <span style={style.title}>{address}</span>
              </>
            ) : null}
            {!address ? (
              <>
                <Typography sx={style.label}>{companyType} Address:</Typography>
                <Box sx={{ ...style.primaryPlaceholder, mb: '4px' }} />
                <Box sx={style.primaryPlaceholder} />
              </>
            ) : null}
          </>
        ) : null}
        {website && websiteVisible ? (
          <>
            <Typography sx={style.label}>Website:</Typography>
            <span style={style.title}>{website}</span>
          </>
        ) : null}
      </Box>
    </Box>
  </Box>
)

export default CompanyResultCard
