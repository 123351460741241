import { RequestStatuses } from '../../api/constants'
import {
  SAVE_USER,
  USER_REQUEST,
  USER_REQUEST_SUCCESS,
  USER_REQUEST_ERROR,
} from './actionTypes'
import { UserActions, UserStateType } from './types'

export const INITIAL_USER = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  profilePictureURL: '',
  businessName: '',
  industry: '',
  specialty: '',
  gear: '',
  yearsExp: '',
  bio: '',
  facebookLink: '',
  instagramLink: '',
  upsplashLink: '',
  pinterestLink: '',
  personalLink: '',
  city: '',
  shootCount: '',
  position: '',
  howDidYouHearAboutUs: '',
  isBusiness: false,
  joinBeta: false,
  finishedOnboarding: false,
  acceptedInvitation: false,
  appleId: null,
  invitedBy: null,
  password: null,
  createdAt: '',
  updatedAt: '',
  profilePictureHeaderURL: '',
  displayEmail: false,
  deletedAt: null,
  profilePictureFileId: null,
  profileHeaderPictureFileId: null,
  latestFeedbackDate: null,
  feedbackRequestDate: null,
  finishedWalkthrough: false,
  stripeId: null,
  enableCalendarEvents: false,
  phoneNumber: null,
  showPhoneNumber: false,
}

export const userInitialState: UserStateType = {
  user: INITIAL_USER,
  requestStatus: RequestStatuses.UNCALLED,
}

const userReducer = (
  state = userInitialState,
  action: UserActions
): UserStateType => {
  switch (action.type) {
    case SAVE_USER:
      return { ...state, user: action.payload }
    case USER_REQUEST:
      return { ...state, requestStatus: RequestStatuses.PENDING }
    case USER_REQUEST_SUCCESS:
      return { ...state, requestStatus: RequestStatuses.SUCCEEDED }
    case USER_REQUEST_ERROR:
      return { ...state, requestStatus: RequestStatuses.FAILED }
    default:
      return state
  }
}

export default userReducer
