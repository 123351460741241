import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'

import ActionConfirmDialog from '../../components/DeleteCallsheetForm/ActionConfirmDialog'
import CallsheetsFilterSection from '../../components/CallsheetsFilterSection'
import ConfirmDialog from '../BuilderPage/ManageUserGroups/ConfirmDialog'
import CallsheetSearchField from '../../components/CallsheetSearchField'
import DeleteCallsheetForm from '../../components/DeleteCallsheetForm'
import CallsheetStatistic from '../../components/CallsheetStatistic'
import DashboardHeader from '../../components/DashboardHeader'

import Callsheets from './Callsheets/Callsheets'
import SidePanel from '../../components/SidePanel'

import {
  getCallsheetsRequest,
  getCallsheetsStatisticsRequest,
} from '../../store/Callsheet/actions'
import {
  deleteCallsheetRequest,
  setDeleteCallsheetModal,
} from '../../store/CallsheetDeleteState/actions'
import {
  selectCallsheetToDelete,
  selectIsDeleteCallsheetModal,
  selectIsPermanentDelete,
} from '../../store/CallsheetDeleteState/selectors'
import {
  selectCallsheetStatisticId,
  selectIsCallsheetStatisticModal,
} from '../../store/Gallery/selectors'
import { selectCallsheets } from '../../store/Callsheet/selectors'
import { selectCallsheetToAction } from '../../store/CallsheetStatus/selectors'
import {
  selectCallsheetActionConfirmModal,
  selectIsConfirmDeleteCallsheetDialog,
} from '../../store/Builder/selectors'
import { Callsheet, CallsheetStatuses } from '../../store/Callsheet/types'

import {
  setCallsheetActionModal,
  setIsConfirmDeleteCallsheetDialog,
} from '../../store/Builder/actions'
import { setCallsheetStatisticModal } from '../../store/Gallery/actions'
import { CallsheetGalleryActions } from '../../store/Builder/types'

import { selectUserId } from '../../store/User/selectors'
import { getCallsheetsCompaniesByIdRequest } from '../../store/Companies/actions'
import { MODAL_ACTIONS } from '../../constants'
import { GearSixIcon } from '../../assets/icons'

const CallsheetsPage = () => {
  const dispatch = useDispatch()
  const callsheets: Callsheet[] = useSelector(selectCallsheets)
  const userId = useSelector(selectUserId)

  const isDeleteModal = useSelector(selectIsDeleteCallsheetModal)
  const isConfirmDeleteCallsheetDialog = useSelector(
    selectIsConfirmDeleteCallsheetDialog
  )
  const IsStatisticModal = useSelector(selectIsCallsheetStatisticModal)
  const { open, action } = useSelector(selectCallsheetActionConfirmModal)

  const callsheetToDelete = useSelector(selectCallsheetToDelete)
  const isPermanentDelete = useSelector(selectIsPermanentDelete)
  const callsheetStatisticId = useSelector(selectCallsheetStatisticId)
  const callsheetToActionId = useSelector(selectCallsheetToAction)

  useEffect(() => {
    dispatch(getCallsheetsRequest())
  }, [])

  useEffect(() => {
    if (callsheets) {
      dispatch(
        getCallsheetsCompaniesByIdRequest([
          ...callsheets.map(item => item.clientCompanyId),
          ...callsheets.map(item => item.productCompanyId),
        ] as string[])
      )
      dispatch(
        getCallsheetsStatisticsRequest(
          callsheets
            .filter(
              item =>
                item.ownerId === userId &&
                item.status !== CallsheetStatuses.DRAFT
            )
            .map(item => item.id) as string[]
        )
      )
    }
  }, [callsheets])

  const handleCallsheetDelete = () => {
    dispatch(deleteCallsheetRequest(callsheetToDelete?.id))
    dispatch(setIsConfirmDeleteCallsheetDialog(false))
  }

  const modalMessage = useMemo(() => {
    if (
      action === CallsheetGalleryActions.DELETE ||
      action === CallsheetGalleryActions.PERMANETLY_DELETE
    ) {
      return MODAL_ACTIONS[action](callsheetToDelete?.title)
    }
    return MODAL_ACTIONS[action](
      callsheets.find(item => item.id === callsheetToActionId)?.title
    )
  }, [callsheets, action, callsheetToActionId, open])

  return (
    <Box>
      <DashboardHeader
        title='Callsheets'
        Icon={GearSixIcon}
        SearchField={CallsheetSearchField}
      />
      <CallsheetsFilterSection />
      <Callsheets callsheets={callsheets} />
      <DeleteCallsheetForm
        open={isDeleteModal}
        onClose={() => dispatch(setDeleteCallsheetModal(false))}
        callsheet={callsheets.find(item => item.id === callsheetToDelete?.id)}
      />
      {action === CallsheetGalleryActions.DELETE ? (
        <ActionConfirmDialog
          open={open}
          onClose={() => dispatch(setCallsheetActionModal(false))}
          title={modalMessage}
          message='This will remain in archive for 90 days where it can be recovered. After this period, the callsheet will be permanantly deleted.'
        />
      ) : (
        <ActionConfirmDialog
          open={open}
          onClose={() => dispatch(setCallsheetActionModal(false))}
          message={modalMessage}
        />
      )}
      <ConfirmDialog
        confirmTitle='Delete'
        isOpen={isConfirmDeleteCallsheetDialog}
        handleCancel={() => dispatch(setIsConfirmDeleteCallsheetDialog(false))}
        handleConfirm={handleCallsheetDelete}
        title={
          isPermanentDelete
            ? 'Permanently Delete Callsheet'
            : 'Delete Callsheet'
        }
        content={
          isPermanentDelete
            ? 'Are you sure you want to permanently delete this callsheet? This action cannot be undone.'
            : 'Are you sure you want to delete this callsheet?'
        }
      />
      <SidePanel
        isOpen={IsStatisticModal}
        onClose={() => dispatch(setCallsheetStatisticModal())}
      >
        <CallsheetStatistic
          callsheet={callsheets.find(item => item.id === callsheetStatisticId)}
        />
      </SidePanel>
    </Box>
  )
}

export default CallsheetsPage
