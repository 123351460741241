import { noop } from 'lodash'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Stack, Typography } from '@mui/material'
import AnnouncementCard from './AnnouncementCard'
import {
  acceptAnnouncement,
  setAnnouncementsToAccept,
} from '../../../store/CallsheetStatus/actions'
import { selectAnnouncements } from '../../../store/Announcements/selectors'
import { selectIsCombinedNotes } from '../../../store/Callsheet/selectors'

import style from './style'

const AnnouncementsStep: FC = () => {
  const dispatch = useDispatch()
  const announcements = useSelector(selectAnnouncements)
  const isCombined = useSelector(selectIsCombinedNotes)

  useEffect(() => {
    dispatch(
      setAnnouncementsToAccept(
        isCombined
          ? [announcements[0].id]
          : announcements.filter(item => item.important).map(item => item.id)
      )
    )
  }, [])

  const handleSelect = (id: string) => {
    dispatch(acceptAnnouncement(id))
  }

  return (
    <Box sx={style.wrapper}>
      <Typography sx={style.title}>Announcements</Typography>
      <Typography sx={style.info}>
        Please, read all announcements carefully and confirm them by clicking on
        a card
      </Typography>
      <Stack gap='12px' mt={3}>
        {announcements.map(item => (
          <AnnouncementCard
            id={item.id}
            content={item.content}
            important={item.important}
            key={item.id}
            combined={isCombined}
            onSelect={handleSelect}
          />
        ))}
      </Stack>
    </Box>
  )
}

export default AnnouncementsStep
