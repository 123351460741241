import MuiDrawer from '@mui/material/Drawer'
import { CSSObject, styled, Theme } from '@mui/material/styles'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: '88px',
})

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export const style = {
  container: {
    display: 'flex',
    borderRight: '1px solid rgba(31, 31, 31, 0.08)',
  },
  content: {
    backgroundColor: '#FAFAFA',
  },
  logo: {
    marginTop: '20px',
    marginLeft: '20px',
  },
  builderBtnBox: {
    paddingTop: '20px',
    paddingBottom: '4px',
  },
  builderBtnLarge: {
    marginRight: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  builderBtnSmall: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  builderBtn: {
    color: '#FFFFFF',
  },
  builderBtnText: {
    fontWeight: 500,
  },
  listBox: {
    padding: '12px 0px',
  },
  listItemButton: (open: boolean) => ({
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
  }),
  listItemIcon: (open: boolean) => ({
    minWidth: 0,
    mr: open ? 3 : '0px',
    justifyContent: 'center',
  }),
}
