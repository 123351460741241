import { makeStyles } from '@mui/styles'
import {
  COLOR_PRIMARY_LIGHT,
  COLOR_TEXT_PRIMARY,
  THEME_FONT_FAMILY,
} from '../../themes/constants'

const useStyles = makeStyles({
  main: {
    fontFamily: THEME_FONT_FAMILY,
    display: 'flex',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    position: 'relative',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    maxWidth: '1190px',
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 640,
  },
  heading: {
    color: COLOR_PRIMARY_LIGHT,
    fontWeight: 900,
    fontSize: '9rem',
    lineHeight: 1.18,
    margin: '0 0 16px',
    textAlign: 'left',
  },
  title: {
    fontWeight: 800,
    fontSize: '1.5rem',
    lineHeight: 1.335,
    color: 'text.primary',
    margin: '0 0 16px',
    textAlign: 'left',
  },
  text: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.5,
    color: 'text.secondary',
    margin: '0 0 64px',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 -4px',
  },
  button: {
    display: 'flex',
    width: '100%',
    maxWidth: 300,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    textTransform: 'capitalize',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  returnLink: {
    textDecoration: 'none',
    backgroundColor: COLOR_TEXT_PRIMARY,
    margin: '0 4px',
  },
  linkText: {
    padding: '8px',
    color: '#FFFFFF',
  },
  image: {
    display: 'flex',
    width: '100%',
    maxWidth: 422,
  },
})

export default useStyles
