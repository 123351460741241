import { COLOR_PRIMARY_MAIN } from '../../../../themes/constants'

const style = {
  title: {
    color: 'text.secondary',
  },
  callsheetTitle: {
    color: '#2A7BC0',
    fontWeight: 600,
  },
  buttonBase: {
    mt: 0,
    color: 'primary.main',
  },
  buttonText: {
    borderBottom: `1px solid ${COLOR_PRIMARY_MAIN}`,
    lineHeight: 1,
  },
}

export default style
