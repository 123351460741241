import { all } from 'redux-saga/effects'
import { watchSignIn } from './SignIn/sagas'
import { requestUserWatcher } from './User/sagas'
import { requestCallsheetWatcher } from './Callsheet/sagas'
import { requestCompaniesWatcher } from './Companies/sagas'
import { requestContactsWatcher } from './Contacts/sagas'
import { requestLocationsWatcher } from './Logistics/sagas'
import { requestUserGroupsWatcher } from './Groups/sagas'
import { requestMembersWatcher } from './GroupMembers/sagas'
import { requestWalkieChannelsWatcher } from './WalkieChannels/sagas'
import { requestAnnouncementsWatcher } from './Announcements/sagas'
import { requestScheduleEventsWatcher } from './Events/sagas'
import { requestPublishDetailsWatcher } from './PublishDetails/sagas'
import { requestAttachmentsWatcher } from './Attachments/sagas'
import { requestCallsheetStatusWatcher } from './CallsheetStatus/sagas'
import { callsheetDeleteStateWatcher } from './CallsheetDeleteState/sagas'

export default function* rootSaga(): Generator {
  yield all([
    watchSignIn(),
    requestUserWatcher(),
    requestCallsheetWatcher(),
    requestCallsheetStatusWatcher(),
    requestCompaniesWatcher(),
    requestContactsWatcher(),
    requestLocationsWatcher(),
    requestUserGroupsWatcher(),
    requestMembersWatcher(),
    requestWalkieChannelsWatcher(),
    requestAnnouncementsWatcher(),
    requestScheduleEventsWatcher(),
    requestPublishDetailsWatcher(),
    requestAttachmentsWatcher(),
    callsheetDeleteStateWatcher(),
  ])
}
