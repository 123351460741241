import React, { FC, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box } from '@mui/material'

import TeamInvitesForm from './TeamInvitesForm'
import SidePanel from '../../../components/SidePanel'
import AddUserGroupForm from './AddUserGroupForm'
import AddGroupMemberForm from './AddGroupMemberForm'

import {
  setIsGroupMembersPanel,
  setIsPersistentPanel,
  setIsUserGroupPanel,
} from '../../../store/Builder/actions'
import {
  selectIsGroupMembersPanel,
  selectIsUserGroupPanel,
} from '../../../store/Builder/selectors'
import { getGroupsRequest } from '../../../store/Groups/actions'

const ManageUserGroups: FC = () => {
  const isUserGroupPanel = useSelector(selectIsUserGroupPanel)
  const isGroupMembersPanel = useSelector(selectIsGroupMembersPanel)

  const dispatch = useDispatch()

  const handleUserGroupPanel = () => {
    dispatch(setIsUserGroupPanel(false))
  }

  const handleGroupMembersPanel = () => {
    dispatch(setIsGroupMembersPanel(false))
    dispatch(setIsPersistentPanel(false))
  }

  useEffect(() => {
    dispatch(getGroupsRequest())
  }, [])

  return (
    <Box>
      <TeamInvitesForm />
      <SidePanel isOpen={isUserGroupPanel} onClose={handleUserGroupPanel}>
        <AddUserGroupForm />
      </SidePanel>
      <SidePanel
        isOpen={isGroupMembersPanel}
        onClose={handleGroupMembersPanel}
        variant='persistent'
      >
        <AddGroupMemberForm />
      </SidePanel>
    </Box>
  )
}

export default ManageUserGroups
