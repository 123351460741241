import { CallsheetStatusType, CallsheetViews } from '../Callsheet/types'
import {
  SET_CALLSHEETS_FILTER,
  SET_CALLSHEETS_SORT_TAG,
  SET_CALLSHEETS_VIEW,
  SET_CALLSHEET_STATISTIC_ID,
  SET_CALLSHEET_STATISTIC_MODAL,
} from './actionTypes'
import {
  SetCallsheetsFilter,
  SetCallsheetsSortTag,
  SetCallsheetsView,
  SetCallsheetStatisticId,
  SetCallsheetStatisticModal,
  SortTags,
} from './types'

export const setCallsheetsView = (view: CallsheetViews): SetCallsheetsView => ({
  type: SET_CALLSHEETS_VIEW,
  view,
})

export const setCallsheetStatisticId = (
  callsheetStatisticId: string
): SetCallsheetStatisticId => ({
  type: SET_CALLSHEET_STATISTIC_ID,
  callsheetStatisticId,
})

export const setCallsheetStatisticModal = (): SetCallsheetStatisticModal => ({
  type: SET_CALLSHEET_STATISTIC_MODAL,
})

export const setCallsheetsFilter = (
  filterTags: CallsheetStatusType[]
): SetCallsheetsFilter => ({
  type: SET_CALLSHEETS_FILTER,
  filterTags,
})

export const setCallsheetsSortTag = (
  sortTag?: SortTags
): SetCallsheetsSortTag => ({
  type: SET_CALLSHEETS_SORT_TAG,
  sortTag,
})
