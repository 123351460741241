import React, { FC } from 'react'

import { Grid, InputLabel } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { capitalize } from 'lodash'
import {
  setCompanyType,
  setIsCompanyPanel,
} from '../../../../../store/Builder/actions'
import { Company } from '../../../../../store/Companies/types'

import CompanyCard from '../CompanyCard'
import AddButton from '../../../../../components/AddButton'
import { selectCompaniesState } from '../../../../../store/Companies/selectors'

const COMPANY_TYPES = ['production', 'client']

type CallsheetCompanies = {
  [index: string]: Company
}

const CompanyAddField: FC = () => {
  const { production, client } = useSelector(selectCompaniesState)
  const companies: CallsheetCompanies = { production, client }

  const dispatch = useDispatch()
  const handleOpenPanel = (type: string) => () => {
    dispatch(setIsCompanyPanel(true))
    dispatch(setCompanyType(type))
  }

  return (
    <Grid container spacing={2}>
      {COMPANY_TYPES.map(type => (
        <Grid item xs={6} key={type}>
          {companies[type].name ? (
            <>
              <InputLabel required>{type} Company</InputLabel>
              <CompanyCard
                name={companies[type].name}
                onClick={handleOpenPanel(type)}
              />
            </>
          ) : (
            <AddButton
              size='large'
              required
              label={`${type} Company`}
              title={`Add ${capitalize(type)}`}
              onClick={handleOpenPanel(type)}
            />
          )}
        </Grid>
      ))}
    </Grid>
  )
}

export default CompanyAddField
