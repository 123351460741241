import { timelineItemClasses } from '@mui/lab'

const style = {
  root: {
    [`& .${timelineItemClasses.root}:before`]: {
      flex: 0,
      padding: '0px',
    },
    padding: '28px 0px',
    margin: '0px',
  },
  dot: {
    bgcolor: 'secondary.light',
    margin: '8px auto',
  },
  connector: {
    border: '1px dashed #A8BDCF',
    backgroundColor: 'white',
    width: '1px',
    height: '74px',
  },
  content: {
    textAlign: 'right',
    padding: '8px 16px',
    paddingLeft: '0px',
    color: 'primary.main',
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 900,
  },
}

export default style
