const style = {
  modalText: {
    fontWeight: 900,
    fontSize: '18px',
    lineHeight: '28px',
    textAlign: 'center',
  },
  modalSubtitle: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: 'text.disabled',
    textAlign: 'center',
    mt: 0,
  },

  contentWrapper: {
    maxWidth: '621px',
    margin: 'auto',
    px: '55px',
    img: {
      display: 'block',
      margin: 'auto',
      marginBottom: 3,
    },
  },
}

export default style
