import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    maxHeight: '100vh',
    overflow: 'hidden',
  },
  container: {
    paddingRight: '0.83%',
  },
  mainLogo: {
    height: '40px',
    width: '58px',
  },
  logoBox: {
    position: 'absolute',
    top: '32px',
    right: '32px',
  },
  landingGridRight: {
    textAlign: 'right',
    height: '100vh',
  },
  landingGridLeft: {
    overflow: 'hidden',
  },
  landingBlurb: {
    color: '#757575',
    fontSize: '20px',
    fontFamily: 'Avenir',
    '& .heart-icon': {
      color: '#EC605A',
      fontSize: '15px',
    },
  },
  landingImageSide: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  forgotPassword: {
    color: '#545454',
    fontFamily: 'Avenir',
    padding: '0',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  noAccountText: {
    fontFamily: 'Avenir',
  },
  signUpLink: {
    textDecoration: 'none',
    fontWeight: 800,
    fontFamily: 'Avenir',
    color: 'black',
  },
  headerContainer: {
    fontFamily: 'Avenir',
    paddingTop: '168px',
  },
  fieldsGrid: {
    width: '400px',
    margin: '0 auto',
  },
  forgotPasswordBox: {
    textAlign: 'left',
  },
  signInButton: {
    borderRadius: 8,
    padding: 6,
    color: 'white',
    background: '#AFAFAF',
    textTransform: 'none',
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: 800,
    '&:hover': {
      opacity: '1',
      background: '#AFAFAF',
    },
  },
  signUpButton: {
    background: '#1F1F1F',
    color: 'white',
    textTransform: 'none',
    fontSize: '16px',
    fontFamily: 'Avenir',
    fontWeight: 800,
    borderRadius: '8px',
    height: '40px',
    '&:hover': {
      backgroundColor: '#1F1F1F',
      color: 'white',
    },
    '&:disabled': {
      background: '#AFAFAF',
      color: 'white',
    },
  },
  iconContainer: {
    width: '24px',
    height: '24px',
  },
  errorText: {
    color: 'red',
    textAlign: 'left',
    padding: '0 0 0 14px',
    margin: '5px 0 0',
    fontSize: '12px',
  },
}))

export default useStyles
