import React, { FC } from 'react'
import { Box } from '@mui/material'
import useImageLoad from '../../hooks/useImageLoad'

import { CameraIcon } from '../../assets/icons'

import style from './style'

const AvatarBox: FC<{ img?: string; placeHolder?: string; width?: string }> = ({
  img,
  placeHolder,
  width = '32px',
}) => {
  const { isImageLoaded } = useImageLoad(img as unknown as URL)
  return (
    <Box sx={style.avatarBox(width)}>
      {isImageLoaded && img ? (
        <Box sx={style.avatar(img)} />
      ) : (
        <Box sx={style.placeholder}>{placeHolder ?? <CameraIcon />}</Box>
      )}
    </Box>
  )
}

export default AvatarBox
