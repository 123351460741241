const style = {
  memberWrapper: {
    border: '1px solid #1F1F1F14',
    borderRadius: '8px',

    display: 'flex',
    alignItems: 'center',
    gap: 0,
    padding: '12px',
  },
}

export default style
