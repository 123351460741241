import { RequestStatuses } from 'src/api/constants'
import { AppState } from '../types'
import { Attachment } from './types'

export const selectAttachments = (state: AppState): Attachment[] =>
  state.attachmentsState.attachments

export const selectInitialAttachments = (state: AppState): Attachment[] =>
  state.attachmentsState.initialAttachments

export const selectAttachmentsRequestStatus = (
  state: AppState
): RequestStatuses => state.attachmentsState.requestStatus
