const PREFIX = 'EVENTS/'

export const ADD_EVENT = `${PREFIX}ADD_EVENT` as const
export const UPDATE_EVENT = `${PREFIX}UPDATE_EVENT` as const
export const DELETE_EVENT = `${PREFIX}DELETE_EVENT` as const

export const GET_EVENTS_REQUEST = `${PREFIX}GET_EVENT_REQUES` as const
export const GET_EVENTS_REQUEST_SUCCESS =
  `${PREFIX}GET_EVENT_REQUEST_SUCCESS` as const
export const GET_EVENTS_REQUEST_ERROR =
  `${PREFIX}GET_EVENT_REQUEST_ERROR` as const

export const ADD_EVENT_REQUEST = `${PREFIX}ADD_EVENT_REQUEST` as const
export const DELETE_EVENT_REQUEST = `${PREFIX}DELETE_EVENT_REQUEST` as const
export const UPDATE_EVENT_REQUEST = `${PREFIX}UPDATE_EVENT_REQUEST` as const

export const SAVE_EVENTS_REQUEST = `${PREFIX}SAVE_EVENT_REQUEST` as const
export const SAVE_EVENTS_REQUEST_SUCCESS =
  `${PREFIX}SAVE_EVENT_REQUEST_SUCCESS` as const
export const SAVE_EVENTS_REQUEST_ERROR =
  `${PREFIX}SAVE_EVENT_REQUEST_ERROR` as const
