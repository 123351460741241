import { LocationType, SampleError } from '../../api/types'
import {
  GET_LOCATIONS_REQUEST,
  GET_LOCATIONS_REQUEST_SUCCESS,
  GET_LOCATIONS_REQUEST_ERROR,
  ADD_LOCATIONS_REQUEST,
  ADD_LOCATIONS_REQUEST_SUCCESS,
  ADD_LOCATIONS_REQUEST_ERROR,
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_REQUEST_SUCCESS,
  DELETE_LOCATION_REQUEST_ERROR,
  SET_HOSPITAL,
  SET_IS_HOSPITAL,
  SET_IS_WEATHER,
  SET_LOCATIONS,
} from './actionTypes'
import {
  LocationItem,
  SetHospital,
  SetIsHospital,
  SetIsWeather,
  SetLocations,
} from './types'

export const getLocationsRequest = () => ({
  type: GET_LOCATIONS_REQUEST,
})

export const getLocationsRequestSuccess = (locations: LocationItem[]) => ({
  type: GET_LOCATIONS_REQUEST_SUCCESS,
  locations,
})

export const getLocationsRequestError = (error: SampleError) => ({
  type: GET_LOCATIONS_REQUEST_ERROR,
  error,
})

export const addLocationsRequest = (locations: LocationItem[]) => ({
  type: ADD_LOCATIONS_REQUEST,
  payload: {
    locations,
  },
})

export const addLocationsRequestSuccess = () => ({
  type: ADD_LOCATIONS_REQUEST_SUCCESS,
})

export const addLocationsRequestError = (error: SampleError) => ({
  type: ADD_LOCATIONS_REQUEST_ERROR,
  error,
})

export const deleteLocationRequest = (locationId: string) => ({
  type: DELETE_LOCATION_REQUEST,
  payload: {
    locationId,
  },
})

export const deleteLocationRequestSuccess = () => ({
  type: DELETE_LOCATION_REQUEST_SUCCESS,
})

export const deleteLocationRequestError = (error: SampleError) => ({
  type: DELETE_LOCATION_REQUEST_ERROR,
  error,
})

export const setHospital = (hospital: LocationType | null): SetHospital => ({
  type: SET_HOSPITAL,
  hospital,
})

export const setIsHospital = (isHospital: boolean): SetIsHospital => ({
  type: SET_IS_HOSPITAL,
  isHospital,
})

export const setIsWeather = (isWeather: boolean): SetIsWeather => ({
  type: SET_IS_WEATHER,
  isWeather,
})

export const setLocations = (locations: LocationItem[]): SetLocations => ({
  type: SET_LOCATIONS,
  locations,
})
