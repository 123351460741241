import moment from 'moment'

import { Callsheet, CallsheetStatuses } from '../store/Callsheet/types'
import { SortTags } from '../store/Gallery/types'
import { SORT_ORDER } from '../constants'

export const applySort: { [key: string]: (items: Callsheet[]) => Callsheet[] } =
  {
    [SortTags.DATE_ASCENDING]: (items: Callsheet[]) =>
      items.sort((a, b) =>
        moment(a.eventDate?.toString()).diff(b.eventDate?.toString())
      ),
    [SortTags.DATE_DESCENDING]: (items: Callsheet[]) =>
      items.sort((a, b) =>
        moment(b.eventDate?.toString()).diff(a.eventDate?.toString())
      ),
    [SortTags.NAME_A_Z]: (items: Callsheet[]) =>
      items.sort((a, b) => a.title.localeCompare(b.title)),
    [SortTags.NAME_Z_A]: (items: Callsheet[]) =>
      items.sort((a, b) => b.title.localeCompare(a.title)),
    [SortTags.STATUS]: (items: Callsheet[]) =>
      items.sort((a, b) => {
        return (
          SORT_ORDER.indexOf(a.status ?? CallsheetStatuses.DRAFT) -
          SORT_ORDER.indexOf(b.status ?? CallsheetStatuses.DRAFT)
        )
      }),
  }
