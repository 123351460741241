import produce from 'immer'
import { RequestStatuses } from '../../api/constants'
import {
  ADD_CHANNEL,
  DELETE_CHANNEL,
  GET_CHANNEL_REQUEST,
  GET_CHANNEL_REQUEST_ERROR,
  GET_CHANNEL_REQUEST_SUCCESS,
  SAVE_CHANNEL_REQUEST,
  SAVE_CHANNEL_REQUEST_ERROR,
  SAVE_CHANNEL_REQUEST_SUCCESS,
  UPDATE_CHANNEL,
} from './actionTypes'
import {
  WalkieChannel,
  WalkieChannelsActions,
  WalkieChannelsState,
} from './types'

const walkieChannelsState: WalkieChannelsState = {
  initialWalkieChannels: [],
  walkieChannels: [],
  requestStatus: RequestStatuses.UNCALLED,
  saveRequestStatus: RequestStatuses.UNCALLED,
}

const walkieChannelsReducer = produce(
  (draft = walkieChannelsState, action: WalkieChannelsActions) => {
    switch (action.type) {
      case GET_CHANNEL_REQUEST:
        draft.requestStatus = RequestStatuses.PENDING
        break
      case GET_CHANNEL_REQUEST_SUCCESS:
        draft.requestStatus = RequestStatuses.SUCCEEDED
        draft.initialWalkieChannels = action.walkieChannels
        draft.walkieChannels = action.walkieChannels
        break
      case GET_CHANNEL_REQUEST_ERROR:
        draft.requestStatus = RequestStatuses.FAILED
        break
      case SAVE_CHANNEL_REQUEST:
        draft.saveRequestStatus = RequestStatuses.PENDING
        break
      case SAVE_CHANNEL_REQUEST_SUCCESS:
        draft.saveRequestStatus = RequestStatuses.SUCCEEDED
        break
      case SAVE_CHANNEL_REQUEST_ERROR:
        draft.saveRequestStatus = RequestStatuses.FAILED
        break
      case ADD_CHANNEL:
        draft.walkieChannels.push(action.walkieChannel)
        break
      case DELETE_CHANNEL:
        draft.walkieChannels = draft.walkieChannels.filter(
          (channel: WalkieChannel) =>
            channel.tmpId !== action.id && channel.id !== action.id
        )
        break
      case UPDATE_CHANNEL:
        draft.walkieChannels = draft.walkieChannels.map(
          (channel: WalkieChannel) =>
            channel.tmpId === action.id || channel.id === action.id
              ? { ...channel, [action.valueType]: action.value }
              : channel
        )
        break
      default:
        return draft
    }
  }
)

export default walkieChannelsReducer
