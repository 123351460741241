import { noop } from 'lodash'
import React, { FC } from 'react'
import { Box, ButtonBase, Typography } from '@mui/material'

import { UploadIcon } from '../../assets/icons'

import style from './style'

const btnSizes = {
  h5: {
    small: '12px',
    medium: '16px',
    large: '16px',
  },
  tip: {
    small: '8px',
    medium: '12px',
    large: '14px',
  },
  browseBtn: {
    small: '11px',
    medium: '15px',
    large: '15px',
  },
}

type UploadButtonTypes = {
  onClick: typeof noop
  tip: string
  size?: 'small' | 'medium' | 'large'
}

const UploadFileButton: FC<UploadButtonTypes> = ({
  onClick,
  tip,
  size = 'medium',
}) => {
  return (
    <Box textAlign='center'>
      <UploadIcon />
      <Typography variant='h5' fontSize={btnSizes.h5[size]}>
        Drag & Drop or{' '}
        <ButtonBase
          onClick={onClick}
          sx={style.browseBtn(btnSizes.browseBtn[size])}
        >
          browse
        </ButtonBase>
      </Typography>
      <Typography sx={style.tip(btnSizes.tip[size])}>{tip}</Typography>
    </Box>
  )
}

export default UploadFileButton
