import { AxiosResponse } from 'axios'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  addAttachmentApi,
  deleteAttachmentApi,
  downloadAttachmentApi,
  getAttachmentApi,
} from '../../api/attachments'
import { GetAttachmentsResultType } from '../../api/attachments/types'
import { SampleError } from '../../api/types'
import { selectCallsheetId } from '../Callsheet/selectors'
import { setNotificationErrorWorker } from '../Notification/sagas'
import {
  getAttachmentsRequestError,
  getAttachmentsRequestSuccess,
  saveAttachmentsRequestSuccess,
} from './actions'
import {
  ADD_ATTACHMENTS_REQUEST,
  DELETE_ATTACHMENTS_REQUEST,
  DOWNLOAD_ATTACHMENTS_REQUEST,
  GET_ATTACHMENTS_REQUEST,
  SAVE_ATTACHMENTS_REQUEST,
} from './actionTypes'
import { selectAttachments, selectInitialAttachments } from './selectors'
import {
  AddAttachmentsRequestType,
  Attachment,
  DeleteAttachmentsRequestType,
  DownloadAttachmentsRequestType,
} from './types'
import { getDeletedAttachments } from '../../utils/attachmentHelpers'
import { downloadFile } from '../../utils/downloadFile'

export function* GetAttachmentsWorker() {
  const id: string = yield select(selectCallsheetId)

  try {
    const {
      data: { data },
    }: AxiosResponse<GetAttachmentsResultType> = yield call(
      getAttachmentApi,
      id
    )
    yield put(getAttachmentsRequestSuccess(data))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(getAttachmentsRequestError(data as SampleError))
  }
}

export function* AddAttachmentsWorker({
  payload: { id, name, format, file },
}: AddAttachmentsRequestType) {
  try {
    yield call(addAttachmentApi, id, name, format, file)
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(getAttachmentsRequestError(data as SampleError))
  }
}

export function* DeleteAttachmentsWorker({
  payload: { id },
}: DeleteAttachmentsRequestType) {
  try {
    yield call(deleteAttachmentApi, id)
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(getAttachmentsRequestError(data as SampleError))
  }
}

export function* DownloadAttachmentsWorker({
  payload: { id, format, name },
}: DownloadAttachmentsRequestType) {
  try {
    const response: AxiosResponse<ArrayBuffer> = yield call(
      downloadAttachmentApi,
      id
    )
    downloadFile(response.data, name, format)
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(getAttachmentsRequestError(data as SampleError))
  }
}

export function* SaveAttachmentsWorker() {
  const callsheetId: string = yield select(selectCallsheetId)
  const initial: Attachment[] = yield select(selectInitialAttachments)

  try {
    const current: Attachment[] = yield select(selectAttachments)
    const deleted: Attachment[] = getDeletedAttachments(initial, current)

    for (let i = 0; i < current.length; i += 1) {
      const { name, blob, format, id } = current[i]
      if (blob && format && !id) {
        const file: Blob = yield blob
        yield call(addAttachmentApi, callsheetId, name, format, file)
      }
    }

    for (let i = 0; i < deleted.length; i += 1) {
      const { id } = deleted[i]
      if (id) {
        yield call(deleteAttachmentApi, id)
      }
    }
    yield put(saveAttachmentsRequestSuccess())
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(getAttachmentsRequestError(data as SampleError))
  }
}

export function* requestAttachmentsWatcher(): Generator {
  yield takeLatest(GET_ATTACHMENTS_REQUEST, GetAttachmentsWorker)
  yield takeLatest(SAVE_ATTACHMENTS_REQUEST, SaveAttachmentsWorker)
  yield takeLatest(ADD_ATTACHMENTS_REQUEST, AddAttachmentsWorker)
  yield takeLatest(DELETE_ATTACHMENTS_REQUEST, DeleteAttachmentsWorker)
  yield takeLatest(DOWNLOAD_ATTACHMENTS_REQUEST, DownloadAttachmentsWorker)
}
