const style = {
  paper: {
    py: '122px',
    boxShadow: '0px 0px 16px 0px #85858533',
    textAlign: 'center',
  },
  content: {
    maxWidth: '300px',
    m: 'auto',
    '> p': {
      mt: 4,
      mb: 1,
      px: 2,
      fontSize: '14px',
    },
  },
}

export default style
