import { AxiosPromise } from 'axios'
import { METHODS, URLS } from '../constants'
import { apiClient } from '../clients'

export const getScheduleEventsApi = (callsheetId: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.GET,
    url: `${URLS.CALLSHEET}/${callsheetId}/schedule-event`,
  })

export const addScheduleEventApi = (
  callsheetId: string,
  name: string,
  startDate: string,
  endDate: string
): AxiosPromise =>
  apiClient.request({
    method: METHODS.POST,
    url: `${URLS.CALLSHEET}/schedule-event`,
    data: {
      callsheetId,
      name,
      startDate,
      endDate,
    },
  })

export const deleteScheduleEventApi = (id: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.DELETE,
    url: `${URLS.CALLSHEET}/schedule-event/${id}`,
  })

export const updateScheduleEventApi = (
  id: string,
  name: string,
  startDate: string,
  endDate: string
): AxiosPromise =>
  apiClient.request({
    method: METHODS.PATCH,
    url: `${URLS.CALLSHEET}/schedule-event/${id}`,
    data: {
      name,
      startDate,
      endDate,
    },
  })
