import { capitalize } from 'lodash'
import { tagColor } from '../CallsheetsFilterSection/CallsheetFilter/constants'

const style = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '4px',
    minHeight: '48px',
    p: '4px',
    mb: 0,
  },
  statistic: (status: string) => ({
    color: status === 'NO RESPONSE' ? '#AFAFAF' : tagColor[capitalize(status)],
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
    fontWeight: 900,
    span: {
      display: 'block',
      ml: '4px',
      mr: 1,
      width: '8px',
      height: '8px',
      borderRadius: '8px',
      backgroundColor:
        status === 'NO RESPONSE' ? '#AFAFAF' : tagColor[capitalize(status)],
    },
  }),
}

export default style
