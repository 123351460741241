import produce from 'immer'
import { RequestStatuses } from 'src/api/constants'
import {
  GET_PUBLISH_DETAILS_REQUEST,
  GET_PUBLISH_DETAILS_REQUEST_ERROR,
  GET_PUBLISH_DETAILS_REQUEST_SUCCESS,
  SAVE_PUBLISH_DETAILS_REQUEST,
  SAVE_PUBLISH_DETAILS_REQUEST_ERROR,
  SAVE_PUBLISH_DETAILS_REQUEST_SUCCESS,
} from './actionTypes'

import { PublishDetailsStatesActions, PublishDetailsState } from './types'

const publishDetailsInitialState: PublishDetailsState = {
  requestStatus: RequestStatuses.UNCALLED,
  saveRequestStatus: RequestStatuses.UNCALLED,
}

const publishDetailsReducer = produce(
  (draft = publishDetailsInitialState, action: PublishDetailsStatesActions) => {
    switch (action.type) {
      case SAVE_PUBLISH_DETAILS_REQUEST:
        draft.saveRequestStatus = RequestStatuses.PENDING
        break
      case GET_PUBLISH_DETAILS_REQUEST:
        draft.requestStatus = RequestStatuses.PENDING
        break
      case SAVE_PUBLISH_DETAILS_REQUEST_SUCCESS:
        draft.saveRequestStatus = RequestStatuses.SUCCEEDED
        break
      case GET_PUBLISH_DETAILS_REQUEST_SUCCESS:
        draft.requestStatus = RequestStatuses.SUCCEEDED
        break
      case SAVE_PUBLISH_DETAILS_REQUEST_ERROR:
        draft.saveRequestStatus = RequestStatuses.FAILED
        break
      case GET_PUBLISH_DETAILS_REQUEST_ERROR:
        draft.requestStatus = RequestStatuses.FAILED
        break
      default:
        return draft
    }
  }
)

export default publishDetailsReducer
