import { RequestStatuses } from '../../api/constants'
import {
  addNewGroupMember,
  deleteGroupMemberById,
  setGroupMemberCallTimeById,
  setGroupMemberVisibilityById,
  filterGroupMembersByGroupTitle,
  updateMembers,
} from '../../utils/groupMemberHelpers'

import {
  DELETE_GROUP_MEMBER,
  SET_GROUP_MEMBER_VISIBILITY,
  SET_GROUP_MEMBER_CALLTIME,
  SET_GROUP_MEMBER_WRAPTIME,
  ADD_PRESELECTED_GROUP_MEMBER,
  UPDATE_GROUP_MEMBERS,
  DELETE_GROUP_MEMBER_BY_GROUP_TITLE,
  MEMBER_REQUEST,
  MEMBER_REQUEST_SUCCESS,
  MEMBER_REQUEST_ERROR,
  GET_MEMBERS_REQUEST_SUCCESS,
  UPDATE_MEMBERS_GROUP_TITLE,
} from './actionTypes'
import { GroupMembersState, BuilderActions, CallTime } from './types'

const groupsInitialState: GroupMembersState = {
  members: [],
  preSelectedMembers: [],
  requestStatus: RequestStatuses.UNCALLED,
}

const groupMembersReducer = (
  state = groupsInitialState,
  action: BuilderActions
): GroupMembersState => {
  switch (action.type) {
    case UPDATE_MEMBERS_GROUP_TITLE:
      return {
        ...state,
        members: action.members,
        preSelectedMembers: action.members,
      }
    case ADD_PRESELECTED_GROUP_MEMBER:
      return {
        ...state,
        preSelectedMembers: addNewGroupMember(
          state.preSelectedMembers,
          action.member
        ),
      }
    case DELETE_GROUP_MEMBER:
      return {
        ...state,
        members: deleteGroupMemberById(state.members, action.memberId),
        preSelectedMembers: deleteGroupMemberById(
          state.preSelectedMembers,
          action.memberId
        ),
      }
    case DELETE_GROUP_MEMBER_BY_GROUP_TITLE:
      return {
        ...state,
        members: filterGroupMembersByGroupTitle(
          state.members,
          action.groupTitle
        ),
        preSelectedMembers: filterGroupMembersByGroupTitle(
          state.members,
          action.groupTitle
        ),
      }
    case SET_GROUP_MEMBER_VISIBILITY:
      return {
        ...state,
        members: setGroupMemberVisibilityById(state.members, action.memberId),
      }
    case SET_GROUP_MEMBER_CALLTIME:
      return {
        ...state,
        members: setGroupMemberCallTimeById(CallTime.START_TIME)(
          state.members,
          action.memberId,
          action.callTime
        ),
        preSelectedMembers: setGroupMemberCallTimeById(CallTime.START_TIME)(
          state.preSelectedMembers,
          action.memberId,
          action.callTime
        ),
      }
    case SET_GROUP_MEMBER_WRAPTIME:
      return {
        ...state,
        members: setGroupMemberCallTimeById(CallTime.WRAP_TIME)(
          state.members,
          action.memberId,
          action.wrapTime
        ),
        preSelectedMembers: setGroupMemberCallTimeById(CallTime.WRAP_TIME)(
          state.preSelectedMembers,
          action.memberId,
          action.wrapTime
        ),
      }
    case UPDATE_GROUP_MEMBERS:
      return {
        ...state,
        ...updateMembers(
          state.members,
          action.isReset ? [] : state.preSelectedMembers
        ),
      }
    case MEMBER_REQUEST:
      return { ...state, requestStatus: RequestStatuses.PENDING }
    case MEMBER_REQUEST_SUCCESS:
      return { ...state, requestStatus: RequestStatuses.SUCCEEDED }
    case MEMBER_REQUEST_ERROR:
      return { ...state, requestStatus: RequestStatuses.FAILED }
    case GET_MEMBERS_REQUEST_SUCCESS:
      return {
        ...state,
        members: action.members,
        preSelectedMembers: action.members,
        requestStatus: RequestStatuses.SUCCEEDED,
      }
    default:
      return state
  }
}

export default groupMembersReducer
