import callTimeBack from '../../assets/images/callTimeback.png'

const style = {
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '190px',
    borderRadius: '8px',
    mb: 3,
    background: `no-repeat url(${callTimeBack}) #FDF1BF`,
  },
  title: {
    fontSize: '60px',
    fontWeight: 500,
    color: '#645826',
    lineHeight: 'initial',
  },
  subtitle: {
    color: '#C9B04C',
    fontWeight: 800,
  },
}

export default style
