import { styled, Tab, Tabs } from '@mui/material'
import React from 'react'

interface StyledTabsProps {
  children?: React.ReactNode
  value: number
  onChange: (event: React.SyntheticEvent, newValue: number) => void
}

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 80,
    height: '2px',
    width: '100%',
    backgroundColor: '#0971E5',
  },
})

interface StyledTabProps {
  label: string
}

export const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '20px',
  lineHeight: '20px',
  color: theme.palette.text.secondary,
  '&.Mui-selected': {
    color: theme.palette.text.primary,
    fontWeight: 900,
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
  '&.MuiButtonBase-root': {
    padding: '0px',
    paddingInline: '24px',
  },
}))

const style = {
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    pr: 3,
    pt: 3,
  },
}

export default style
