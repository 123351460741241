import { call, put, select, takeLatest } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { SampleError } from '../../api/types'
import { selectCallsheetId } from '../Callsheet/selectors'
import { GetEventsResultType } from '../../api/scheduleEvents/types'
import {
  addScheduleEventApi,
  deleteScheduleEventApi,
  getScheduleEventsApi,
  updateScheduleEventApi,
} from '../../api/scheduleEvents'
import {
  getEventsErrorRequest,
  getEventsSuccessRequest,
  saveEventsRequestError,
  saveEventsRequestSuccess,
} from './actions'
import { setNotificationErrorWorker } from '../Notification/sagas'
import {
  ADD_EVENT_REQUEST,
  DELETE_EVENT_REQUEST,
  GET_EVENTS_REQUEST,
  SAVE_EVENTS_REQUEST,
  UPDATE_EVENT_REQUEST,
} from './actionTypes'
import {
  AddEventRequestType,
  DeleteEventRequestType,
  ScheduleEvent,
  UpdateEventRequestType,
} from './types'
import { selectEvents, selectInitialEvents } from './selectors'
import {
  getAddedEvents,
  getDeletedEvents,
  getUpdatedEvents,
} from '../../utils/eventsHelpers'

export function* GetScheduleEventsWorker() {
  try {
    const callsheetId: string = yield select(selectCallsheetId)

    const {
      data: { data },
    }: AxiosResponse<GetEventsResultType> = yield call(
      getScheduleEventsApi,
      callsheetId
    )
    yield put(getEventsSuccessRequest(data))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(getEventsErrorRequest(data as SampleError))
  }
}

export function* AddScheduleEventWorker({
  payload: { name, startDate, endDate, callsheetId },
}: AddEventRequestType) {
  try {
    yield call(addScheduleEventApi, callsheetId, name, startDate, endDate)
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
  }
}

export function* DeleteScheduleEventWorker({
  payload: { id },
}: DeleteEventRequestType) {
  try {
    yield call(deleteScheduleEventApi, id)
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
  }
}

export function* UpdateScheduleEventWorker({
  payload: { id, name, startDate, endDate },
}: UpdateEventRequestType) {
  try {
    yield call(updateScheduleEventApi, id, name, startDate, endDate)
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
  }
}

export function* SaveScheduleEventsRequestWorker() {
  const callsheetId: string = yield select(selectCallsheetId)
  const initial: ScheduleEvent[] = yield select(selectInitialEvents)
  const current: ScheduleEvent[] = yield select(selectEvents)

  try {
    const addedEvents = getAddedEvents(current)
    const deletedEvents = getDeletedEvents(initial, current)
    const updatedEvents = getUpdatedEvents(initial, current)

    for (let i = 0; i < deletedEvents.length; i += 1) {
      const { id } = deletedEvents[i]
      if (id) {
        yield call(deleteScheduleEventApi, id)
      }
    }

    for (let i = 0; i < updatedEvents.length; i += 1) {
      const { id, name, startDate, endDate } = updatedEvents[i]
      if (id) {
        yield call(updateScheduleEventApi, id, name, startDate, endDate)
      }
    }
    for (let i = 0; i < addedEvents.length; i += 1) {
      const { name, startDate, endDate } = addedEvents[i]
      yield call(addScheduleEventApi, callsheetId, name, startDate, endDate)
    }

    yield put(saveEventsRequestSuccess())
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(saveEventsRequestError(data as SampleError))
  }
}

export function* requestScheduleEventsWatcher(): Generator {
  yield takeLatest(GET_EVENTS_REQUEST, GetScheduleEventsWorker)
  yield takeLatest(ADD_EVENT_REQUEST, AddScheduleEventWorker)
  yield takeLatest(DELETE_EVENT_REQUEST, DeleteScheduleEventWorker)
  yield takeLatest(UPDATE_EVENT_REQUEST, UpdateScheduleEventWorker)
  yield takeLatest(SAVE_EVENTS_REQUEST, SaveScheduleEventsRequestWorker)
}
