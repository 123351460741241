import { noop } from 'lodash'
import JsPDF from 'jspdf'
import html2canvas from 'html2canvas'

export const download = (blob: Blob, fileName: string) => {
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = fileName
  link.click()
  URL.revokeObjectURL(link.href)
}

export const downloadFile = (
  fileData: ArrayBuffer,
  fileName: string,
  fileFormat: string
) => {
  const blob = new Blob([fileData], { type: fileFormat })
  download(blob, fileName)
}

export const generatePdf = (
  elementId: string,
  fileTitle: string,
  setLoader = noop
) => {
  setLoader(true)
  const element = document.getElementById(elementId)

  if (!element) {
    return
  }

  const pdfDoc = new JsPDF()

  html2canvas(element)
    .then(canvas => {
      const imgData = canvas.toDataURL('image/png')

      pdfDoc.addImage(imgData, 'PNG', 10, 10, 180, 0)
      pdfDoc.save(`${fileTitle}.pdf`)
    })
    .finally(() => setLoader(false))

  return true
}
