export const addBase64Data = (str?: string) => {
  if (!str) {
    return
  }

  return `data:image/jpeg;base64,${str}`
}

export const stripBase64Data = (str?: string) => {
  if (!str) {
    return
  }

  return str.replace(/^data:image\/[a-z]+;base64,/, '')
}

export const prepareImageValue = (value?: string) => {
  return value ? [{ dataURL: addBase64Data(value), file: {} as File }] : []
}

export const getBase64FileSize = (length: number) =>
  ((length * (3 / 4) - 2) / 1000).toFixed(1)

export const createBlob = async (dataUrl: string) => {
  const res = await fetch(dataUrl)
  const blob = await res.blob()
  return blob
}
