import { AppState } from '../types'
import { ActionDialogType } from './types'

export const selectCompanyType = (state: AppState) =>
  state.builderState.companyType
export const selectStep = (state: AppState) => state.builderState.step
export const selectIsPersistentPanel = (state: AppState) =>
  state.builderState.isPersistentPanel
export const selectIsCompanyPanel = (state: AppState) =>
  state.builderState.isCompanyPanel
export const selectIsMembersPanel = (state: AppState) =>
  state.builderState.isMembersPanel
export const selectIsMemberProfilePanel = (state: AppState) =>
  state.builderState.isMemberProfilePanel
export const selectIsAddContactPanel = (state: AppState) =>
  state.builderState.isAddContactPanel
export const selectIsLocationPanel = (state: AppState) =>
  state.builderState.isLocationPanel
export const selectIsUserGroupPanel = (state: AppState) =>
  state.builderState.isUserGroupPanel
export const selectIsGroupMembersPanel = (state: AppState) =>
  state.builderState.isGroupMembersPanel
export const selectIsContactDetailsPanel = (state: AppState) =>
  state.builderState.isContactDetailsPanel
export const selectIsCompanyDetailsPanel = (state: AppState) =>
  state.builderState.isCompanyDetailsPanel

export const selectIsCallsheetConfirmModal = (state: AppState) =>
  state.builderState.isCallsheetConfirmModal
export const selectIsCallsheetDeclineModal = (state: AppState) =>
  state.builderState.isCallsheetDeclineModal
export const selectIsConfirmDeleteActionModal = (state: AppState) =>
  state.builderState.isConfirmDeleteActionModal
export const selectIsConfirmDeleteCompanyModal = (state: AppState) =>
  state.builderState.isConfirmDeleteCompanyModal

export const selectIsUpdateOverview = (state: AppState) =>
  state.builderState.isUpdateOverview

export const selectCallsheetActionConfirmModal = (
  state: AppState
): ActionDialogType => state.builderState.callsheetActionConfirmModal

export const selectIsConfirmDeleteCallsheetDialog = (state: AppState) =>
  state.builderState.isConfirmDeleteCallsheetDialog

export const selectIsConfirmDeleteContactModal = (state: AppState) =>
  state.builderState.isConfirmDeleteContactModal
