import { Box } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Contact } from '../../store/Contacts/types'
import useContactDetails from './useContactDetails'

import {
  selectIsAddContactPanel,
  selectIsCompanyPanel,
  selectIsContactDetailsPanel,
} from '../../store/Builder/selectors'
import {
  selectContacts,
  selectEditContact,
} from '../../store/Contacts/selectors'
import {
  addCompanyRequest,
  getAllCompaniesRequest,
  setCompany,
} from '../../store/Companies/actions'
import {
  setCompanyType,
  setIsAddContactPanel,
  setIsCompanyPanel,
} from '../../store/Builder/actions'
import {
  getContactsRequest,
  setEditContact,
} from '../../store/Contacts/actions'
import { Company } from '../../store/Companies/types'
import ContactSearchField from '../../components/DashboardHeader/ContactSearchField'
import AddContactForm from '../../components/AddContactForm/AddContactForm'
import ConfirmDialog from '../BuilderPage/ManageUserGroups/ConfirmDialog'
import CompanyForm from '../BuilderPage/ProjectOverview/CompanyForm'
import DashboardHeader from '../../components/DashboardHeader'
import SidePanel from '../../components/SidePanel'
import AddNewDropdown from './AddNewDropdown'
import ContactDetails from './ContactDetails'
import CompaniesList from './CompaniesList'
import ContactsList from './ContactsList'

import { Entities, TabPanels } from './constants'
import style, { StyledTab, StyledTabs } from './style'

const NetworkPage: FC = () => {
  const dispatch = useDispatch()
  const editContact = useSelector(selectEditContact)
  const [isValidate, setIsValidate] = useState<boolean>(false)
  const isAddContactPanel = useSelector(selectIsAddContactPanel)
  const isContactDetailsPanel = useSelector(selectIsContactDetailsPanel)
  const isCompanyPanel = useSelector(selectIsCompanyPanel)
  const contacts = useSelector(selectContacts)
  const [isEditCompany, setIsEditCompany] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>()

  const [value, setValue] = useState(TabPanels.PEOPLE)
  const [isConfirmApplyDialog, setIsConfirmApplyDialog] =
    useState<boolean>(false)

  const { onClose, selectedContact, contactLogo } = useContactDetails()

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleAddContactClose = () => {
    setIsConfirmApplyDialog(true)
  }

  const handleAddCompanyClose = () => {
    dispatch(setIsCompanyPanel(false))
  }

  const handleEditCompanyPanel = () => {
    if (selectedCompany) {
      setIsEditCompany(true)
      dispatch(setIsCompanyPanel(true))
      dispatch(setCompanyType('production'))
      dispatch(
        setCompany('production', {
          ...selectedCompany,
          primaryMembers: contacts.filter((contact: Contact) =>
            // @ts-ignore
            selectedCompany.primaryMembers?.includes(contact.id)
          ),
        })
      )
    }
  }

  const handleAddCompany = (company: Company) => {
    dispatch(addCompanyRequest(company))
  }

  const handleAddNewEntity = (entity: Entities) => {
    dispatch(setEditContact())
    if (entity === Entities.CONTACT) {
      dispatch(setIsAddContactPanel(true))
    }

    if (entity === Entities.COMPANY) {
      setIsEditCompany(false)
      dispatch(setIsCompanyPanel(true))
    }
  }

  const handleCloseContactForm = () => {
    setIsConfirmApplyDialog(false)
    dispatch(setIsAddContactPanel(false))
  }

  const handleConfirmContactForm = () => {
    if (editContact) {
      setIsValidate(true)
    }
    setIsConfirmApplyDialog(false)
  }

  useEffect(() => {
    dispatch(getContactsRequest())
    dispatch(getAllCompaniesRequest())
  }, [])

  return (
    <>
      <Box>
        <DashboardHeader
          title='Contacts'
          outlined={false}
          SearchField={ContactSearchField}
        />
        <Box sx={style.header}>
          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label='disabled tabs example'
            sx={{
              minHeight: 'auto',
              height: '38px',
            }}
          >
            <StyledTab label='People' />
            <StyledTab label='Companies' />
          </StyledTabs>
          <AddNewDropdown onAddNewEntity={handleAddNewEntity} />
        </Box>
        {value === TabPanels.PEOPLE ? (
          <ContactsList />
        ) : (
          <CompaniesList
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
            handleEditCompanyPanel={handleEditCompanyPanel}
          />
        )}
      </Box>
      <SidePanel isOpen={isAddContactPanel} onClose={handleAddContactClose}>
        <AddContactForm
          editContact={editContact}
          onCancel={handleAddContactClose}
          validate={isValidate}
          onValidate={() => setIsValidate(false)}
        />
      </SidePanel>
      <ConfirmDialog
        isOpen={isConfirmApplyDialog}
        variant='apply'
        handleCancel={handleCloseContactForm}
        handleConfirm={handleConfirmContactForm}
        cancelTitle='Exit Without Saving'
        confirmTitle={
          editContact ? 'Apply Changes to Contact' : 'Keep Editing Contact'
        }
        title='Apply Changes to Contact'
        content='Changes have been made to the contact. Would you like to apply these changes? If not, you will lose all changes made.'
      />
      <SidePanel isOpen={isCompanyPanel} onClose={handleAddCompanyClose}>
        <CompanyForm
          companySearch={false}
          builderForm={false}
          saveToCatalogField={false}
          onSave={handleAddCompany}
          onUpdate={setSelectedCompany}
          isEditCompany={isEditCompany}
          extras
        />
      </SidePanel>
      <SidePanel isOpen={isContactDetailsPanel} onClose={onClose}>
        {selectedContact ? (
          <ContactDetails
            logoSrc={contactLogo}
            onClose={onClose}
            selectedContact={selectedContact}
          />
        ) : null}
      </SidePanel>
    </>
  )
}

export default NetworkPage
