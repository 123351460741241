import { styled, TableCell, tableCellClasses } from '@mui/material'

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 900,
    padding: '0px',
    paddingInline: '24px',
    svg: { cursor: 'pointer', marginLeft: theme.spacing(0) },
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 400,
    paddingInline: '24px',
    borderBottom: '1px solid #E2E2E2',
  },
}))
