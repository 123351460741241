import { LoadingButton } from '@mui/lab'
import React, {
  useState,
  useEffect,
  ChangeEventHandler,
  MouseEventHandler,
  FC,
} from 'react'
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import validator from 'validator'
import { RequestStatuses } from '../../api/constants'
import { CallsheetsLogo, EyeHiddenIcon, EyeIcon } from '../../assets/icons'
import signInSideImage from '../../assets/images/signinSideImage.jpg'
import useStyles from './style'

import {
  changeSignInEmail,
  changeSignInName,
  changeSignInLastName,
  changeSignInPassword,
  makeSignInRequest,
  returnToInitialSignInRequestState,
} from '../../store/SignIn/actions'
import { TEXTS, PLACEHOLDERS, RESPONSE_ERROR_CODES } from '../../constants'
import {
  selectSignInEmail,
  selectSignInPassword,
  selectSignInFetchingError,
  selectSignInName,
  selectSignInLastName,
  selectSignInRequestStatus,
} from '../../store/SignIn/selectors'

const SignInPage: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const [showPassword, setShowPassword] = useState(false)
  const [isActiveButton, changeIsActiveButton] = useState(false)
  const [errors, setErrors] = useState<Record<string, boolean>>({})

  const signInEmail = useSelector(selectSignInEmail)
  const signInName = useSelector(selectSignInName)
  const signInLastName = useSelector(selectSignInLastName)
  const signInPassword = useSelector(selectSignInPassword)
  const signInFetchingError = useSelector(selectSignInFetchingError)
  const requestStatus = useSelector(selectSignInRequestStatus)

  useEffect(() => {
    if (signInEmail && signInName && signInLastName) {
      changeIsActiveButton(true)
    } else {
      changeIsActiveButton(false)
    }
  }, [signInEmail, signInName, signInLastName])

  const validateInputs = () => {
    const err: Record<string, boolean> = {}
    const { isEmail } = validator

    err.firstName = !signInName
    err.lastName = !signInLastName
    err.email = !signInEmail || !isEmail(signInEmail)

    setErrors(err)
    return Object.values(err).some(val => val)
  }

  const signInEmailChangeHandler: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = event => {
    setErrors(prev => ({
      ...prev,
      [event.target.name]: false,
    }))
    dispatch(changeSignInEmail(event.target.value))
  }

  const signInNameChangeHandler: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = event => {
    setErrors(prev => ({
      ...prev,
      [event.target.name]: false,
    }))
    dispatch(changeSignInName(event.target.value))
  }

  const signInLastNameChangeHandler: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = event => {
    setErrors(prev => ({
      ...prev,
      [event.target.name]: false,
    }))
    dispatch(changeSignInLastName(event.target.value))
  }

  const signInPasswordChangeHandler: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = ({ target: { value } }) => {
    dispatch(changeSignInPassword(value))
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword: MouseEventHandler<
    HTMLButtonElement
  > = event => {
    event.preventDefault()
  }

  const handleSignInFormSubmit = () => {
    const hasErrors = validateInputs()

    if (!hasErrors) {
      dispatch(makeSignInRequest(history))
    }
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.container}>
        <Box className={classes.logoBox}>
          <Box className={classes.mainLogo}>
            <CallsheetsLogo />
          </Box>
        </Box>
        <Grid item sm={6} className={classes.landingGridRight}>
          <img
            className={classes.landingImageSide}
            src={signInSideImage}
            alt=''
          />
        </Grid>
        <Grid item sm={6} className={classes.landingGridLeft}>
          <Grid container>
            <Grid item sm={11}>
              <Box>
                <Grid className={classes.fieldsGrid}>
                  <Box className={classes.headerContainer}>
                    <Typography variant='h3'>Sign In</Typography>
                  </Box>
                  <Box mt={4}>
                    <TextField
                      fullWidth
                      name='email'
                      variant='outlined'
                      placeholder={PLACEHOLDERS.SIGN_IN_EMAIL}
                      value={signInEmail}
                      error={errors.email}
                      helperText={
                        errors.email
                          ? TEXTS.ERROR_TEXTS.EMAIL_FORMAT_ERROR_TEXT
                          : ''
                      }
                      onChange={signInEmailChangeHandler}
                    />
                  </Box>
                  <Box mt={1}>
                    <TextField
                      fullWidth
                      name='firstName'
                      variant='outlined'
                      placeholder={PLACEHOLDERS.SIGN_IN_NAME}
                      value={signInName}
                      error={errors.firstName}
                      onChange={signInNameChangeHandler}
                    />
                  </Box>
                  <Box mt={1}>
                    <TextField
                      fullWidth
                      name='lastName'
                      variant='outlined'
                      placeholder={PLACEHOLDERS.SIGN_IN_LAST_NAME}
                      value={signInLastName}
                      error={errors.lastName}
                      onChange={signInLastNameChangeHandler}
                    />
                  </Box>
                  {/* <Box mt={1}>
                      <TextField
                        fullWidth
                        variant='outlined'
                        placeholder={PLACEHOLDERS.SIGN_IN_PASSWORD}
                        type={showPassword ? 'text' : 'password'}
                        value={signInPassword}
                        onChange={signInPasswordChangeHandler}
                        error={!!signInPasswordError}
                        helperText={signInPasswordError}
                        InputProps={{
                          style: { height: '44px', overflow: 'hidden' },
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? <EyeHiddenIcon /> : <EyeIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {signInFetchingError?.statusCode ===
                        RESPONSE_ERROR_CODES.UNAUTHORIZED && (
                        <p className={classes.errorText}>
                          {signInFetchingError?.message}
                        </p>
                      )}
                    </Box> */}
                  {/* <Box mt={1} className={classes.forgotPasswordBox}> */}
                  {/*  <Button className={classes.forgotPassword} onClick={}> */}
                  {/*    Forgot password? */}
                  {/*  </Button> */}
                  {/* </Box> */}
                  <Box mt={2}>
                    <LoadingButton
                      fullWidth
                      variant='contained'
                      disabled={!isActiveButton}
                      className={classes.signUpButton}
                      loading={requestStatus === RequestStatuses.PENDING}
                      onClick={handleSignInFormSubmit}
                    >
                      Sign In
                    </LoadingButton>
                  </Box>
                  {/* <Box
                    display='flex'
                    width='100%'
                    justifyContent='center'
                  >
                    <Typography className={classes.noAccountText}>
                      Dont have an account?{' '}
                      <Link className={classes.signUpLink} to='/sign-up'>
                        Sign Up
                      </Link>
                    </Typography>
                  </Box> */}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default SignInPage
