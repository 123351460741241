import { COLOR_PRIMARY_MAIN } from '../../themes/constants'

const style = {
  labelIconBox: {
    display: 'flex',
    alignItems: 'center',
    width: '20px',
    mr: '8px',
    textAlign: 'center',
  },
  labelIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  labelCheckbox: {
    fontSize: '12px',
    color: COLOR_PRIMARY_MAIN,
    cursor: 'pointer',
  },
  checkbox: {
    color: 'primary.main',
    padding: '0px',
    ml: '4px',
  },
}

export default style
