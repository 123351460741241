import * as actionTypes from './actionTypes'
import {
  NotificationType,
  SetNotification,
  SetNotificationStateToInitial,
} from './types'

export const setNotificationStateToInitial =
  (): SetNotificationStateToInitial => ({
    type: actionTypes.SET_NOTIFICATION_STATE_TO_INITIAL,
  })

export const setNotification = (error: NotificationType): SetNotification => ({
  type: actionTypes.SET_NOTIFICATION,
  error,
})
