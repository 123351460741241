const style = {
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  value: {
    border: '1px solid #E2E2E2',
    borderRadius: '4px',
    padding: '4px 8px',

    color: 'text.disabled',
  },
}

export default style
