import React, { FC } from 'react'

import { Box } from '@mui/material'
import { isString, noop } from 'lodash'

import Autocomplete, {
  ReactGoogleAutocompleteProps,
} from 'react-google-autocomplete'
import { LocationType } from '../../api/types'
import { COLOR_ERROR_MAIN } from '../../themes/constants'
import {
  getLocationName,
  parseAddressComponents,
  returnSubstringBeforeComa,
} from '../../utils/location'
import AddressView from '../../pages/BuilderPage/ProjectOverview/CompanyForm/AddressView'
import LabelWithCheckbox from '../LabelWithCheckbox'
import { LabelWithCheckboxTypes } from '../LabelWithCheckbox/types'
import useStyles from './style'
import './styles.scss'

type LocationInputTypes = {
  id: string
  label?: string
  location?: LocationType | string | null
  onClear?: typeof noop
  onInputChange: typeof noop
  onCheckboxChange?: typeof noop
  placeholder?: string
  error?: boolean
}

const LocationAutocomplete: FC<
  LocationInputTypes & Omit<LabelWithCheckboxTypes, 'label'>
> = ({
  id,
  label,
  required,
  placeholder = 'Search Address...',
  location,
  LabelIcon,
  checkbox,
  onInputChange = noop,
  onCheckboxChange = noop,
  onClear = noop,
  checked,
  error,
}) => {
  const classes = useStyles()

  const handlePlaceSelected: ReactGoogleAutocompleteProps['onPlaceSelected'] =
    async place => {
      const locationObj: LocationType = {
        name: returnSubstringBeforeComa(place.formatted_address as string),
        address: place.formatted_address!,
        ...parseAddressComponents(place.address_components),
        longitude: String(place.geometry?.location?.lng()),
        latitude: String(place.geometry?.location?.lat()),
      }

      onInputChange(locationObj)
    }

  return (
    <Box>
      {label ? (
        <LabelWithCheckbox
          id={id}
          label={label}
          required={required}
          LabelIcon={LabelIcon}
          checkbox={checkbox}
          onChange={onCheckboxChange}
          checked={checked}
        />
      ) : null}
      {location ? (
        <AddressView
          title={isString(location) ? 'Home Office' : location.name}
          address={
            isString(location)
              ? location
              : getLocationName(location.city, location.state, location.zip)
          }
          onClose={onClear}
        />
      ) : (
        <Autocomplete
          style={{ borderColor: error ? COLOR_ERROR_MAIN : '#E2E2E2' }}
          className={classes.autocomplete}
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          onPlaceSelected={handlePlaceSelected}
          placeholder={placeholder}
          language='en'
          options={{
            types: ['establishment', 'geocode'],
          }}
        />
      )}
    </Box>
  )
}

export default LocationAutocomplete
