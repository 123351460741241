import { StepConnector, stepConnectorClasses, styled } from '@mui/material'

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 15,
    left: 'calc(-50% + 8px)',
    right: 'calc(50% + 8px)',
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: 1,
    borderRadius: 1,
    borderColor: theme.palette.secondary.main,
  },
}))

export default Connector
