const style = {
  label: {
    color: 'text.primary',
    lineHeight: '20px',
    textAlign: 'left',
    mb: 0,
  },
  btnWrapper: {
    height: '76px',
    display: 'flex',
    justifyContent: 'center',

    border: '1px solid #E2E2E2',
    borderRadius: '8px',
    maxWidth: '217px',
  },
}

export default style
