import React, { FC, PropsWithChildren, useState } from 'react'
import { Box, Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CaretLeftIcon } from '../../assets/icons'
import { PATHS } from '../../constants'
import { setStep } from '../../store/Builder/actions'
import { selectStep } from '../../store/Builder/selectors'
import { STEPS } from '../../pages/BuilderPage/constants'
import ConfirmDialog from '../../pages/BuilderPage/ManageUserGroups/ConfirmDialog'
import { clearStore } from '../../store/rootActions'
import { getUserById } from '../../store/User/actions'
import { getTokensFromStorage } from '../../utils/tokens'
import style from './style'

const NavigationButtons: FC<PropsWithChildren> = ({ children }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const step = useSelector(selectStep)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const toggleConfirmDialog = (status: boolean) => () => {
    setIsConfirmDialogOpen(status)
  }

  const handleConfirmExit = () => {
    dispatch(clearStore())
    const { token } = getTokensFromStorage()
    if (token) {
      dispatch(getUserById(token))
    }
    history.push(PATHS.PRIVATE.CALLSHEETS)
  }

  const handlePrevBtnClick = () => {
    if (step === STEPS.overview) {
      return
    }

    dispatch(setStep(step - 1))
  }

  return (
    <Box>
      <Box sx={style.buttonsBox}>
        <Button
          variant='outlined'
          sx={{ ...style.button, ...style.exitBtn }}
          onClick={toggleConfirmDialog(true)}
        >
          Exit
        </Button>
        {step !== STEPS.overview ? (
          <Button
            variant='outlined'
            sx={{ ...style.button, ...style.prevBtn }}
            onClick={handlePrevBtnClick}
          >
            <Box sx={style.iconBtn}>
              <Box sx={style.iconBox}>
                <CaretLeftIcon />
              </Box>{' '}
              prev
            </Box>
          </Button>
        ) : null}
      </Box>
      {children}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        handleCancel={toggleConfirmDialog(false)}
        handleConfirm={handleConfirmExit}
        title='Are you sure you want to exit?'
        content='All changes in this step will not be saved.'
      />
    </Box>
  )
}

export default NavigationButtons
