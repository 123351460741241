const style = {
  header: {
    padding: '12px',
  },
  title: {
    mb: '4px',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 900,
    width: '100%',
    whiteSpace: 'nowrap',
    maxWidth: '25ch',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: '30px',
  },
  dateBox: {
    display: 'flex',
    alignItems: 'center',
  },
  dateText: {
    fontWeight: 900,
    fontSize: '12px',
    color: 'info.main',
    ml: 0,
  },
}

export default style
