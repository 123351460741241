export const PREFIX = 'CONTACTS/'

export const GET_CONTACTS_REQUEST = `${PREFIX}GET_CONTACTS_REQUEST` as const
export const GET_CONTACTS_REQUEST_SUCCESS =
  `${PREFIX}GET_CONTACTS_REQUEST_SUCCESS` as const
export const GET_CONTACTS_REQUEST_ERROR =
  `${PREFIX}GET_CONTACTS_REQUEST_ERROR` as const
export const ADD_CONTACT_REQUEST = `${PREFIX}ADD_CONTACT_REQUEST` as const
export const ADD_CONTACT_REQUEST_SUCCESS =
  `${PREFIX}ADD_CONTACT_REQUEST_SUCCESS` as const
export const ADD_CONTACT_REQUEST_ERROR =
  `${PREFIX}ADD_CONTACT_REQUEST_ERROR` as const
export const UPDATE_CONTACT_REQUEST = `${PREFIX}UPDATE_CONTACT_REQUEST` as const
export const UPDATE_CONTACT_REQUEST_SUCCESS =
  `${PREFIX}UPDATE_CONTACT_REQUEST_SUCCESS` as const
export const UPDATE_CONTACT_REQUEST_ERROR =
  `${PREFIX}UPDATE_CONTACT_REQUEST_ERROR` as const
export const GET_CONTACTS_TAGS_REQUEST =
  `${PREFIX}GET_CONTACTS_TAGS_REQUEST` as const
export const GET_CONTACTS_TAGS_REQUEST_SUCCESS =
  `${PREFIX}GET_CONTACTS_TAGS_REQUEST_SUCCESS` as const
export const GET_CONTACTS_TAGS_REQUEST_ERROR =
  `${PREFIX}GET_CONTACTS_TAGS_REQUEST_ERROR` as const
export const SET_CONTACTS = `${PREFIX}SET_CONTACTS` as const

export const UPDATE_CONTACT_IMAGE_REQUEST =
  `${PREFIX}UPDATE_CONTACT_IMAGE_REQUEST` as const
export const GET_CONTACT_IMAGE_REQUEST =
  `${PREFIX}GET_CONTACT_IMAGE_REQUEST` as const

export const DELETE_CONTACT_REQUEST = `${PREFIX}DELETE_CONTACT_REQUEST` as const
export const DELETE_CONTACT_REQUEST_SUCCESS =
  `${PREFIX}DELETE_CONTACT_REQUEST_SUCCESS` as const
export const DELETE_CONTACT_REQUEST_ERROR =
  `${PREFIX}DELETE_CONTACT_REQUEST_ERROR` as const

export const SET_CONTACT_IMAGE = `${PREFIX}SET_CONTACT_IMAGE` as const
export const SET_EDIT_CONTACT = `${PREFIX} SET_EDIT_CONTACT` as const
export const SET_SELECTED_CONTACT = `${PREFIX}SET_SELECTED_CONTACT ` as const

export const GET_CONTACTS_BY_NAME_EMAIL_REQUEST =
  `${PREFIX}GET_CONTACTS_BY_NAME_EMAIL_REQUEST` as const
export const GET_CONTACTS_BY_NAME_EMAIL_REQUEST_SUCCESS =
  `${PREFIX}GET_CONTACTS_BY_NAME_EMAIL_REQUEST_SUCCESS` as const
export const GET_CONTACTS_BY_NAME_EMAIL_REQUEST_ERROR =
  `${PREFIX}GET_CONTACTS_BY_NAME_EMAIL_REQUEST_ERROR` as const
