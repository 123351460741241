const style = {
  inputBox: {
    p: 1,
    boxShadow: '0px 5px 8px 0px #8585851A',
    borderRadius: '16px',
    lineHeight: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexBasis: 1,
    flexGrow: 1,
    gap: 1,
    'svg:not(:root)': {
      minWidth: '18px',
    },
    'svg.disabled_icon': {
      cursor: 'auto',
    },
  },
  wrapper: (isReversed: boolean) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: isReversed ? 'row-reverse' : 'row',
    gap: 1,
    'svg:not(:root)': {
      cursor: 'pointer',
    },
  }),
  icons: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
  },
}

export default style
