import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Stack } from '@mui/material'

import { selectFinalTouchesRequestStatus } from '../../../../store/Callsheet/selectors'
import { saveCallsheetFinalTouchesRequest } from '../../../../store/Callsheet/actions'
import { setStep } from '../../../../store/Builder/actions'

import HeaderSection from '../../ProjectOverview/CallsheetForm/HeaderSection'
import Announcements from '../Announcements'
import Attachments from '../Attachments'
import ProductionNotes from '../ProductionNotes'
import ProductionSchedule from '../ProductionSchedule'
import WalkieChannels from '../WalkieChannels'
import { RequestStatuses } from '../../../../api/constants'

import { STEPS } from '../../constants'

const FinalTouchesForm = () => {
  const dispatch = useDispatch()
  const requestStatus = useSelector(selectFinalTouchesRequestStatus)
  const [isError, setError] = useState<boolean>(false)

  const handleSubmit = () => {
    dispatch(saveCallsheetFinalTouchesRequest())
  }

  return (
    <Stack sx={{ textAlign: 'center' }}>
      <HeaderSection
        info='FINAL'
        title='Final Touches And Publish'
        subtitle='Please, fill in all the required fields and publish the CallSheet'
      />
      <Grid container justifyContent='space-between' gap={1}>
        <Grid item>
          <Stack gap={3}>
            <ProductionNotes />
            <Announcements />
            <Box
              display='flex'
              flexWrap='wrap'
              maxWidth='458px'
              gap={3}
              sx={{ '> div': { flexGrow: 1 } }}
            >
              <WalkieChannels onError={setError} />
              <Attachments />
            </Box>
          </Stack>
        </Grid>
        <Grid item>
          <ProductionSchedule />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                variant='outlined'
                fullWidth
                onClick={() => dispatch(setStep(STEPS.userGroups))}
                sx={{ color: 'text.primary', borderColor: 'text.primary' }}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={6}>
              <LoadingButton
                disabled={isError}
                variant='contained'
                fullWidth
                onClick={handleSubmit}
                loading={requestStatus === RequestStatuses.PENDING}
              >
                Continue
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default FinalTouchesForm
