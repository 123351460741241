import React, { FC } from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'

import style from './style'

type CustomTimeLineProps = {
  events: string[]
}

const CustomtTimeline: FC<CustomTimeLineProps> = ({ events }) => {
  return (
    <Timeline position='left' sx={style.root}>
      {events.map((event, idx) => (
        <TimelineItem key={idx}>
          <TimelineSeparator>
            <TimelineDot sx={style.dot} />
            {idx !== events.length - 1 && (
              <TimelineConnector sx={style.connector} />
            )}
          </TimelineSeparator>
          <TimelineContent sx={style.content}>{event}</TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  )
}

export default CustomtTimeline
