import React, { FC, PropsWithChildren } from 'react'
import { Box, Typography } from '@mui/material'

type HeaderSectionTypes = {
  info: string
  title: string
  subtitle?: string
}

const HeaderSection: FC<HeaderSectionTypes & PropsWithChildren> = ({
  children,
  info,
  title,
  subtitle,
}) => (
  <Box sx={{ mb: 3 }}>
    <Typography variant='subtitle2' color='text.disabled' sx={{ mb: 0 }}>
      {info}
    </Typography>
    <Typography variant='h2' component='h2' color='text.main' sx={{ mb: 0 }}>
      {title}
    </Typography>
    {subtitle ? (
      <Typography variant='subtitle1' color='text.secondary'>
        {subtitle}
      </Typography>
    ) : null}
    {children}
  </Box>
)

export default HeaderSection
