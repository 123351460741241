const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
export const DEFAULT_TAG = 'Show All'
export const MISSING_TAG = 'Missing details'
export const MenuProps = {
  disableScrollLock: true,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 240,
    },
  },
  muiinputbase: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
}
