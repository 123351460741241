import { makeStyles } from '@mui/styles'
import { COLOR_PRIMARY_MAIN } from '../../themes/constants'

const useStyles = makeStyles({
  autocomplete: {
    width: '100%',
    height: '44px',
    fontFamily: 'Avenir',
    fontSize: '14px',
    borderRadius: '10px',
    border: '1px solid #E2E2E2',
    boxSizing: 'border-box',
    padding: '12px 14px',
    '&:hover': {
      outline: `1px solid ${COLOR_PRIMARY_MAIN}`,
    },
    '&:focus': {
      outline: `2px solid ${COLOR_PRIMARY_MAIN}`,
    },
  },
})

export default useStyles
