export const EMPTY_LOCATION_ITEM = {
  title: '',
  name: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  longitude: '',
  latitude: '',
  locationNotes: '',
  isHospital: false,
  image: '',
}

export const MAX_LOCATIONS = 4
