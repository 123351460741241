import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import { selectIsGroupMembersPanel } from '../../../../../store/Builder/selectors'
import { selectIsStackDisplay } from '../../../../../store/Groups/selectors'
import { gridsLong, gridsShort } from './constants'

type MemberHeaderType = {
  isPreview?: boolean
}

const MemberHeader: FC<MemberHeaderType> = ({ isPreview }) => {
  const isStackDisplay = useSelector(selectIsStackDisplay)
  const isGroupMembersPanel = useSelector(selectIsGroupMembersPanel)

  return (
    <Grid container sx={{ mt: isPreview ? 1 : '0px' }}>
      <Grid item xs={11}>
        <Grid container>
          {(isGroupMembersPanel || !isStackDisplay
            ? gridsShort
            : gridsLong
          ).map(({ previewXs, xs, label }) => (
            <Grid item xs={isPreview ? previewXs : xs} key={label}>
              <Typography variant='body2'>{label}</Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MemberHeader
