import React, { FC } from 'react'
import { Box, InputLabel, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { EditIcon } from '../../../../../assets/icons'
import { setIsMembersPanel } from '../../../../../store/Builder/actions'
import { Contact } from '../../../../../store/Contacts/types'
import AddButton from '../../../../../components/AddButton'
import ContactPanel from '../../../../../components/ContactPanel'
import CustomTextButton from '../../../../../components/CustomTextButton'

type MemberFieldTypes = {
  members?: Contact[]
}

const MemberField: FC<MemberFieldTypes> = ({ members = [] }) => {
  const dispatch = useDispatch()

  const handleMembersCLick = () => {
    dispatch(setIsMembersPanel(true))
  }

  const handleEditList = () => {
    dispatch(setIsMembersPanel(true))
  }

  return (
    <Box sx={{ mt: 1 }}>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='flex-start'
      >
        <InputLabel>Primary Contact (2 max)</InputLabel>
        {members && members.length ? (
          <CustomTextButton
            title='Edit list'
            Icon={EditIcon}
            onClick={handleEditList}
          />
        ) : null}
      </Box>
      {members && members.length ? (
        members.map((member: Contact) => {
          const { email, logo, firstName, lastName, title } = member

          return (
            <ContactPanel
              key={email}
              logo={logo}
              name={`${firstName} ${lastName}`}
            >
              <Typography variant='body1' color='text.disabled'>
                {title}
              </Typography>
            </ContactPanel>
          )
        })
      ) : (
        <AddButton title='Add 2 members' onClick={handleMembersCLick} />
      )}
    </Box>
  )
}

export default MemberField
