import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import useCallsheetStatistic from '../../../../hooks/callsheet/useCallsheetStatistic'
import { CallsheetStatuses } from '../../../../store/Callsheet/types'

import style from './style'

export type Statistics = {
  [key: string]: {
    total: number
    accept: number
    decline: number
    tentative: number
  }
}

const CallsheetPublishedFooter: FC<{ id: string }> = ({ id }) => {
  const { statistics } = useCallsheetStatistic(id) ?? {}

  return (
    <Box sx={style.content}>
      <Box sx={style.statisticsItem}>
        <Typography sx={style.title}>Accepted:</Typography>
        <Box sx={style.numBox}>
          <Box sx={style.accept}>
            {statistics?.[CallsheetStatuses.ACCEPTED] || 0}
          </Box>
        </Box>
      </Box>
      <Box sx={style.statisticsItem}>
        <Typography sx={style.title}>Decline:</Typography>
        <Box sx={style.numBox}>
          <Box sx={style.decline}>
            {statistics?.[CallsheetStatuses.DECLINED] || 0}
          </Box>
        </Box>
      </Box>
      <Box sx={style.statisticsItem}>
        <Typography sx={style.title}>Tentative:</Typography>
        <Box sx={style.numBox}>
          <Box sx={style.tentative}>
            {statistics?.[CallsheetStatuses.TENTATIVE] || 0}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default CallsheetPublishedFooter
