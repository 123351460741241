import { Company } from 'src/store/Companies/types'

export const grids: { [key: string]: string } = {
  'Company Name': '20%',
  Website: '10%',
  Address: '50%',
}

export const sortByNameAsc = (a: Company, b: Company) =>
  a.name.localeCompare(b.name)

export const sortByNameDesc = (a: Company, b: Company) =>
  b.name.localeCompare(a.name)

export const NAME_FIELD = 'Company Name'
