const style = {
  initials: {
    fontSize: '32px',
    fontWeight: '900',
    lineHeight: '44px',
  },
  title: { fontSize: '18px', fontWeight: '400', lineHeight: '28px' },
}

export default style
