import { noop } from 'lodash'
import React, {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useReducer,
  useState,
  FC,
} from 'react'
import validator from 'validator'

import {
  Box,
  Divider,
  InputLabel,
  Paper,
  Popper,
  TextField,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { LocationType } from '../../../../api/types'

import {
  setIsCompanyPanel,
  setIsMembersPanel,
} from '../../../../store/Builder/actions'
import {
  getCompaniesByNameRequest,
  getCompaniesRequestSuccess,
  saveCompanyRequest,
  updateCompanyRequest,
} from '../../../../store/Companies/actions'

import {
  selectCompanyType,
  selectIsMembersPanel,
} from '../../../../store/Builder/selectors'
import {
  selectCompanies,
  selectCurrentCompany,
  selectGetCompaniesRequestStatus,
  selectSaveCompanyRequestStatus,
} from '../../../../store/Companies/selectors'
import { Company } from '../../../../store/Companies/types'
import { removeEmptyFields } from '../../../../utils/filters'
import CustomTextField from '../../../../components/CustomTextField'
import CompanyOption from './CompanyOption'
import {
  setAddressVisible,
  setCompanyAddress,
  setCompanyLogo,
  setCompanyName,
  setCompanyNotes,
  setCompanyWebsite,
  setSaveInCatalog,
  setWebsiteVisible,
} from './localStore/actions'
import { companyFormReducer, CompanyInitialState } from './localStore/reducer'
import SearchField from '../../../../components/SearchField'
import SidePanel from '../../../../components/SidePanel'
import ControlButtons from '../../../../components/ControlButtons'
import MembersForm from '../MembersForm'
import ImageUploadField from './ImageUploadField'
import TextHead from '../../../../components/HeadText'
import LocationAutocomplete from '../../../../components/LocationAutocomplete'

import { LinkIcon, MapPinIcon } from '../../../../assets/icons'
import MemberField from './MemberField'
import SaveToCatalogField from './SaveToCatalogField'
import { ErrorsFieldsTypes } from './types'
import { RequestStatuses } from '../../../../api/constants'
import { DEFAULT_ERRORS_STATE } from './constants'
import style from './style'

type CompanyFormProps = {
  extras?: boolean
  saveToCatalogField?: boolean
  builderForm?: boolean
  companySearch?: boolean
  onSave?: (company: Company) => void
  onUpdate?: typeof noop
  isEditCompany?: boolean
  requestStatus?: RequestStatuses
}

const CompanyForm: FC<CompanyFormProps> = ({
  saveToCatalogField = true,
  builderForm = true,
  extras = false,
  companySearch = true,
  onSave,
  onUpdate,
  isEditCompany = false,
  requestStatus,
}) => {
  const companyType = builderForm ? useSelector(selectCompanyType) : ''
  const companies = useSelector(selectCompanies)
  const company =
    builderForm || isEditCompany
      ? useSelector(selectCurrentCompany)
      : CompanyInitialState
  const isMembersPanel = useSelector(selectIsMembersPanel)
  const saveRequestStatus =
    requestStatus ?? useSelector(selectSaveCompanyRequestStatus)
  const getRequestStatus = useSelector(selectGetCompaniesRequestStatus)

  const checkbox = builderForm ? 'Show on CallSheet' : undefined

  const [
    {
      id,
      name,
      logo,
      address,
      website,
      addressVisible,
      websiteVisible,
      saveInCatalog,
      primaryMembers,
      notes,
    },
    companyDispatch,
  ] = useReducer(companyFormReducer, {
    id: company.id || undefined,
    name: company.name || '',
    logo: company.logo || '',
    website: company.website || '',
    address: company.address || '',
    websiteVisible: company.websiteVisible || false,
    addressVisible: company.addressVisible || false,
    saveInCatalog: company.saveInCatalog || true,
    primaryMembers: company.primaryMembers || [],
  })

  const [errors, setErrors] = useState(DEFAULT_ERRORS_STATE)

  const dispatch = useDispatch()

  const clearErrorField = (field: keyof ErrorsFieldsTypes) => {
    if (errors[field]) {
      setErrors({ ...errors, [field]: false })
    }
  }

  const handleSetName = (value: string) => {
    if (errors.name) {
      setErrors({ ...errors, name: false })
    }

    clearErrorField('name')
    companyDispatch(setCompanyName(value))
  }

  const handleCompanySearch: ChangeEventHandler<HTMLTextAreaElement> = async ({
    target: { value },
  }) => {
    handleSetName(value)
    if (companySearch && value.length > 2) {
      dispatch(getCompaniesByNameRequest(value))
    }
  }

  const handleMembersPanel = () => {
    dispatch(setIsMembersPanel(false))
  }

  const handleSetLogo = (value: string) => {
    companyDispatch(setCompanyLogo(value || ''))
  }

  const handleSetWebsite: ChangeEventHandler<HTMLTextAreaElement> = ({
    target: { value },
  }) => {
    clearErrorField('website')
    companyDispatch(setCompanyWebsite(value))
  }

  const handleSetAddress = (value: LocationType) => {
    companyDispatch(setCompanyAddress(value.address))
  }

  const handleSetNotes = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    companyDispatch(setCompanyNotes(event.target.value))
  }

  const handleWebsiteVisible = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    companyDispatch(setWebsiteVisible(checked))
  }

  const handleAddressVisible = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    companyDispatch(setAddressVisible(checked))
  }

  const handleSaveInCatalog = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => companyDispatch(setSaveInCatalog(checked))

  const checkCompanyValidation = () => {
    const isWebsiteValid = validator.isURL(website)
    const isNameValid = name.length > 3

    if (!isNameValid || !isWebsiteValid) {
      setErrors({
        name: !isNameValid,
        website: !isWebsiteValid,
      })
    }

    return isWebsiteValid && isNameValid
  }

  const handleCancelBtn = () => {
    dispatch(setIsCompanyPanel(false))
  }

  const handleConfirmBtn = () => {
    if (!checkCompanyValidation()) {
      return
    }

    const companyData = removeEmptyFields({
      id,
      logo,
      name,
      website,
      address,
      websiteVisible,
      addressVisible,
      saveInCatalog,
      notes,
      ...(companyType === 'production' || !builderForm
        ? { primaryMembers }
        : {}),
    })

    if (id) {
      dispatch(updateCompanyRequest(id, companyData as Company, isEditCompany))
      onUpdate &&
        onUpdate({
          ...companyData,
          primaryMembers: primaryMembers?.map(member => member.id),
        })

      return
    }

    if (onSave) {
      onSave(companyData as Company)
    } else {
      dispatch(saveCompanyRequest(companyData as Company))
    }
  }

  useEffect(() => {
    if (builderForm && !isEditCompany) {
      dispatch(getCompaniesRequestSuccess([]))
    }
  }, [])

  return (
    <Box sx={style.wrapper}>
      <Box sx={style.content}>
        <TextHead
          title={`${companyType} company details`}
          subtitle={`Please, add all the required information to add a ${companyType} company.`}
        />
        {companySearch ? (
          <SearchField
            required
            id='production-company'
            label={`${companyType} company`}
            placeholder={`Type ${companyType} company name...`}
            onInputChange={handleCompanySearch}
            value={{ name }}
            error={errors.name}
            options={companies}
            requestStatus={getRequestStatus}
            getOptionLabel={(option: Company) => option.name}
            PopperComponent={props => <Popper {...props} sx={style.popper} />}
            PaperComponent={props => <Paper {...props} sx={style.paper} />}
            renderOption={(
              props: React.HTMLAttributes<HTMLLIElement>,
              option: Company
            ) => (
              <CompanyOption
                props={props}
                option={option}
                dispatch={companyDispatch}
                key={option.name}
              />
            )}
          />
        ) : (
          <CustomTextField
            required
            id='production-company'
            label='Company'
            placeholder={`Type ${companyType} company name...`}
            onInputChange={handleCompanySearch}
            value={name}
            error={errors.name}
          />
        )}

        {!builderForm && <Divider sx={style.divider} />}
        <ImageUploadField
          label={companyType}
          onChange={handleSetLogo}
          defaultValue={logo}
        />
        <CustomTextField
          required
          id='website-input'
          label='Website'
          LabelIcon={LinkIcon}
          placeholder={`Type ${companyType} website...`}
          checkbox={checkbox}
          checked={websiteVisible}
          onInputChange={handleSetWebsite}
          onCheckboxChange={handleWebsiteVisible}
          value={website}
          error={errors.website}
        />
        <Box sx={{ mt: 1 }}>
          <LocationAutocomplete
            id='address-input'
            label='Address'
            LabelIcon={MapPinIcon}
            placeholder='Search Address...'
            checkbox={checkbox}
            checked={addressVisible}
            onInputChange={handleSetAddress}
            onCheckboxChange={handleAddressVisible}
            onClear={() => companyDispatch(setCompanyAddress(''))}
            location={address}
          />
        </Box>
        {companyType === 'production' || !builderForm ? (
          <MemberField members={primaryMembers} />
        ) : null}
        {extras && (
          <>
            <Divider sx={style.divider} />
            <InputLabel>Extras</InputLabel>
            <InputLabel sx={{ ...style.labelStyles, mt: 1 }}>Notes</InputLabel>
            <TextField
              placeholder='Type here...'
              name='notes'
              value={notes}
              onChange={handleSetNotes}
              fullWidth
              multiline
              rows={4}
              sx={{ mb: 4 }}
            />
          </>
        )}
      </Box>
      <Box sx={style.footer}>
        {saveToCatalogField && (
          <SaveToCatalogField
            companyType={companyType}
            onChange={handleSaveInCatalog}
            checked={saveInCatalog}
          />
        )}
        <ControlButtons
          confirmTitle={`${
            isEditCompany ? 'Update' : 'Add'
          } ${companyType} Company`}
          handleCancelBtn={handleCancelBtn}
          handleConfirmBtn={handleConfirmBtn}
          requestStatus={saveRequestStatus}
        />
      </Box>
      <SidePanel isOpen={isMembersPanel} onClose={handleMembersPanel}>
        <MembersForm
          companyDispatch={companyDispatch}
          companyId={id}
          members={primaryMembers}
        />
      </SidePanel>
    </Box>
  )
}

export default CompanyForm
