const style = {
  wrapper: {
    width: '406px',
    padding: '16px 42px',
    textAlign: 'center',
    alignItems: 'center',
    gap: 1,
    backgroundColor: 'white',
    borderRadius: '14px',
  },
  callTime: {
    fontWeight: 900,
    fontSize: '48px',
    lineHeight: '65.5px',
    color: 'text.primary',
  },
  statusWrapper: (color: string) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    borderRadius: '6px',
    border: `1px solid ${color}`,
    padding: '4px 8px',

    svg: {
      path: {
        stroke: color,
      },
    },
  }),
  status: (color: string) => ({
    color,
    fontWeight: 500,
    fontSize: '14px',
  }),
}

export default style
