import { StepIconProps, styled } from '@mui/material'
import React from 'react'

const StepRootIcon = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean }
  logisticStep?: boolean
}>(({ theme, ownerState, logisticStep }) => ({
  zIndex: 1,
  fontSize: '12px',
  lineHeight: '20px',
  fontWeight: 900,
  fontFamily: 'Avenir',
  color: theme.palette.secondary.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: logisticStep ? 'none' : '2px solid',
  borderColor: theme.palette.secondary.main,
  backgroundColor: 'white',
  borderRadius: '50%',
  height: '32px',
  width: '32px',

  ...(ownerState.active && {
    backgroundColor: theme.palette.secondary.dark,
    border: 'none',
    color: 'white',
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.success.main,
    border: 'none',
    color: 'white',
  }),
  ...(logisticStep && {
    backgroundColor: 'transparent',
    border: 'none',
  }),
  '& .CustomStepIcon-circle': {
    width: '10px',
    marginRight: '10px',
    height: '10px',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
    ...(ownerState.active && { backgroundColor: theme.palette.secondary.dark }),
    ...(ownerState.completed && {
      backgroundColor: theme.palette.success.main,
    }),
  },
}))

export const BuilderStepIcon = (props: StepIconProps) => {
  const { active, completed, className, icon } = props

  const stepLabels: { [index: string]: number } = {
    1: 1,
    2: 2,
    3: 2,
    4: 3,
    5: 4,
  }

  return (
    <StepRootIcon
      ownerState={{ completed, active }}
      logisticStep={icon === 2}
      className={className}
    >
      {icon === 2 ? (
        <div className='CustomStepIcon-circle' />
      ) : (
        stepLabels[String(icon)]
      )}
    </StepRootIcon>
  )
}

const CustomStepIcon = (props: StepIconProps) => {
  const { active, completed, className, icon } = props

  return (
    <StepRootIcon ownerState={{ completed, active }} className={className}>
      {icon}
    </StepRootIcon>
  )
}

export default CustomStepIcon
