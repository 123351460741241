import React, { FC } from 'react'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { selectStep } from '../../../../store/Builder/selectors'
import {
  selectCallsheetTitle,
  selectEventDate,
} from '../../../../store/Callsheet/selectors'
import { STEPS } from '../../constants'

import TitleHeader from './TitleHeader'
import CompaniesResultSection from './CompaniesResultSection'
import LocationsSection from './LocationsSection'

import style from './style'

const OverviewResult: FC = () => {
  const step = useSelector(selectStep)
  const callsheetName = useSelector(selectCallsheetTitle)
  const eventDate = useSelector(selectEventDate)

  return (
    <Box sx={style.wrapper}>
      <TitleHeader title={callsheetName} date={eventDate} />
      <CompaniesResultSection />
      {step === STEPS.logistic ? <LocationsSection /> : null}
    </Box>
  )
}

export default OverviewResult
