const style = {
  container: {
    display: 'inline-block',
    width: '255px',
  },
  content: {
    backgroundColor: '#FFFFFF',
    padding: '16px 28px 16px 16px',
    borderRadius: '8px',
  },
  label: {
    mb: '6px',
    fontSize: '12px',
    color: '#7C9CB7',
  },
  inputPlaceholder: {
    width: '125px',
    height: '36px',
    borderRadius: '8px',
    backgroundColor: '#D3DEE7',
  },
  time: {
    padding: '1px 0',
    color: 'primary.main',
    fontFamily: 'Avenir',
    fontSize: '28px',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
}

export default style
