import { noop } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import moment, { MomentInput } from 'moment'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import React, { ChangeEventHandler, FC, useMemo, useRef, useState } from 'react'
import useDeleteFlow from './useDeleteFlow'
import { useOutsideClick } from '../../hooks/useOnClickOutside'

import { Callsheet } from '../../store/Callsheet/types'
import { setIsConfirmDeleteActionModal } from '../../store/Builder/actions'

import { selectDeleteCallsheetRequestStatus } from '../../store/CallsheetDeleteState/selectors'
import { selectIsConfirmDeleteActionModal } from '../../store/Builder/selectors'

import DeleteAction from './DeleteAction'
import CustomTextField from '../CustomTextField'
import OptionDateAndTime from './OptionDateAndTime'
import ActionConfirmDialog from './ActionConfirmDialog'

import { CrossCircleIcon } from '../../assets/icons'
import { RequestStatuses } from '../../api/constants'
import {
  DEFAULT_FIELDS_STATE,
  DeleteCallsheetFlow,
  FieldsStateType,
} from './constants'

import style from './style'

type DeleteCallsheetFormProps = {
  open: boolean
  onClose: typeof noop
  callsheet?: Callsheet
}

const DeleteCallsheetForm: FC<DeleteCallsheetFormProps> = ({
  open,
  onClose,
  callsheet,
}) => {
  const dispatch = useDispatch()
  const isConfirmDialog = useSelector(selectIsConfirmDeleteActionModal)
  const deleteRequestStatus = useSelector(selectDeleteCallsheetRequestStatus)
  const [fields, setFields] = useState<FieldsStateType>(DEFAULT_FIELDS_STATE)
  const textFieldRef = useRef<HTMLInputElement>(null)
  const [isError, setError] = useState<boolean>(false)
  const { title, id } = callsheet ?? {}
  const { date, name, time } = fields
  const { onConfirmBtn, btnTitle, onSelect, option, setOption } = useDeleteFlow(
    date,
    name,
    time,
    id,
    callsheet
  )

  const handleOutsideClick = () => {
    if (name && name !== title) {
      setError(true)
    } else {
      setError(false)
    }
  }

  const handleEventTime: ChangeEventHandler<HTMLTextAreaElement> = ({
    target: { value },
  }) => {
    setFields(prev => ({ ...prev, time: value }))
    setOption(DeleteCallsheetFlow.CONTINUE_TO_BUILDER)
  }

  const handleNameChange: ChangeEventHandler<HTMLTextAreaElement> = ({
    target: { value },
  }) => {
    if (isError) setError(false)
    setFields(prev => ({ ...prev, name: value }))
  }

  const handleModalClose = () => {
    onClose()
    dispatch(setIsConfirmDeleteActionModal(false))
    setFields(DEFAULT_FIELDS_STATE)
    setOption(undefined)
  }

  const callsheetTitle = useMemo(() => {
    return title ?? ''
  }, [option, name, isConfirmDialog])

  const handleSubmit = () => {
    onConfirmBtn()
    setFields(DEFAULT_FIELDS_STATE)
    setOption(undefined)
  }

  useOutsideClick([textFieldRef], handleOutsideClick)

  const isDisabled =
    option !== DeleteCallsheetFlow.DELETE_CALLSHEET
      ? !date && !time
      : name !== title

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
        <DialogTitle sx={style.title}>
          Delete Callsheet {title ?? ''}
          <IconButton onClick={onClose} sx={style.closeBtn}>
            <CrossCircleIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ px: '40px', minHeight: '618px' }}>
          <Box textAlign='left' mt={4}>
            <Typography sx={style.subtitle}>
              Choose one of the option. Remember, you can change date & time
              instead of deleting
            </Typography>
            <Typography sx={style.subtitle2}>Some text can be here</Typography>
          </Box>
          <Stack gap={1} mt={3}>
            <OptionDateAndTime
              checked={
                option === DeleteCallsheetFlow.CONFIRM ||
                option === DeleteCallsheetFlow.CONTINUE_TO_BUILDER
              }
              onSelect={onSelect(DeleteCallsheetFlow.CONFIRM)}
              onTimeChange={handleEventTime}
              onDateChange={(e: DateConstructor) =>
                setFields(prev => ({ ...prev, date: e }))
              }
              time={time}
              date={date}
            />
            <DeleteAction
              title='Just delete a CallSheet'
              text='Please, enter the name of the CallSheet in case if you are really sure in this action'
              checked={option === DeleteCallsheetFlow.DELETE_CALLSHEET}
              onSelect={onSelect(DeleteCallsheetFlow.DELETE_CALLSHEET)}
            >
              <CustomTextField
                error={isError}
                ref={textFieldRef}
                helperText={isError ? 'The name is not correct' : ''}
                required
                id='callsheetname-input'
                label='Callsheet Name'
                placeholder='Callsheet Name'
                onInputChange={handleNameChange}
                value={name}
              />
            </DeleteAction>
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 3 }}>
          <Box display='flex' gap='12px' minWidth='348px'>
            <Button
              variant='outlined'
              onClick={handleModalClose}
              sx={style.cancelBtn}
            >
              Cancel
            </Button>
            <LoadingButton
              disabled={isDisabled}
              variant='contained'
              fullWidth
              onClick={handleSubmit}
              loading={deleteRequestStatus === RequestStatuses.PENDING}
              sx={style.confirmBtn(option)}
            >
              {btnTitle}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
      <ActionConfirmDialog
        open={isConfirmDialog}
        onClose={handleModalClose}
        message={
          option === DeleteCallsheetFlow.DELETE_CALLSHEET
            ? 'This will remain in archive for 90 days where it can be recovered. After this period, the callsheet will be permanantly deleted.'
            : `${callsheetTitle} date has been updated to ${moment(
                date as MomentInput
              ).format('MM/DD/YYYY')}`
        }
        title={
          option === DeleteCallsheetFlow.DELETE_CALLSHEET
            ? `${name} has been deleted`
            : undefined
        }
      />
    </>
  )
}

export default DeleteCallsheetForm
