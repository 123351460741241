import React, { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Paper, Stack, Typography } from '@mui/material'

import {
  setEmailAttachment,
  setEmailReminder,
  switchEmailReminder,
} from '../../../../../store/EmailParameters/actions'
import { selectEmailParameters } from '../../../../../store/EmailParameters/selectors'

import Reminder from '../../Reminder'
import CustomSwitch from '../../../../../components/CustomSwitch'

import { PdfIcon } from '../../../../../assets/icons'

import style from './style'

const EmailSettings: FC = () => {
  const dispatch = useDispatch()
  const { emailReminder, emailAttachment, isEmailReminder } = useSelector(
    selectEmailParameters
  )
  return (
    <Stack gap={0}>
      <Paper variant='outlined' sx={style.pdfBox}>
        <Box display='flex' alignItems='center' gap='12px'>
          <PdfIcon />
          <Typography sx={style.title}>Include PDF file</Typography>
        </Box>
        <CustomSwitch
          onChange={() => dispatch(setEmailAttachment())}
          checked={emailAttachment}
        />
      </Paper>
      <Reminder
        defaultValue={emailReminder}
        checked={isEmailReminder}
        onRemindBeforeChange={(e: number) => dispatch(setEmailReminder(e))}
        onEmailReminderToggle={() => dispatch(switchEmailReminder())}
      />
    </Stack>
  )
}

export default EmailSettings
