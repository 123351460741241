import { noop } from 'lodash'
import validator from 'validator'
import { useSelector } from 'react-redux'
import { Box, Divider, Stack, Typography } from '@mui/material'
import React, { ChangeEventHandler, FC, useState } from 'react'

import { GroupMember } from '../../../../../store/GroupMembers/types'
import { selectContactById } from '../../../../../store/Contacts/selectors'
import { selectUserId } from '../../../../../store/User/selectors'

import AvatarHeader from '../../../../../components/ContactDetailsForm/AvatarHeader'
import ContactDetailsField from '../../../../../components/ContactDetailsForm/ContactDetailsField'
import ContactDetailsFormWrapper from '../../../../../components/ContactDetailsForm/ContactDetailsFormWrapper'
import PhoneInput from '../../../../../components/PhoneInput/PhoneInput'

import { stripPhoneNumber } from '../../../../../utils/contactHelpers'
import { CALLTIME_PLACEHOLDER, logoSrc } from '../../../../../constants'

import style from './style'

type RecipientProfileProps = {
  onBackClick: typeof noop
  onConfirm?: typeof noop
  memberDetails: GroupMember
}

const RecipientProfile: FC<RecipientProfileProps> = ({
  onBackClick,
  onConfirm,
  memberDetails,
}) => {
  const { wrapTime, callTime, groupTitle, contactId, phone, email, id } =
    memberDetails
  const userId = useSelector(selectUserId)
  const [errors, setErrors] = useState<Record<string, boolean>>({})
  const contactDetails = useSelector(selectContactById(contactId)) ?? {}
  const [memberPhone, setMemberPhone] = useState(phone)
  const { title } = contactDetails
  const isMissingPhone = !phone

  const validateInputs = () => {
    const err: Record<string, boolean> = {}
    const { isMobilePhone } = validator

    err.phone = !!(memberPhone && !isMobilePhone(memberPhone, 'any'))

    setErrors(err)
    return Object.values(err).some(val => val)
  }

  const handlePhoneChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    setErrors(prev => ({
      ...prev,
      phone: false,
    }))
    setMemberPhone(stripPhoneNumber(value))
  }

  const handleConfirm = () => {
    const hasErrors = validateInputs()

    if (hasErrors || !onConfirm) {
      return
    }
    onConfirm({
      ...memberDetails,
      phone: memberPhone,
      email,
    })
  }

  return (
    <ContactDetailsFormWrapper
      isBackBtn={false}
      onBackClick={onBackClick}
      onConfirm={handleConfirm}
      controlButtonsProps={{
        confirmTitle: 'Save data',
        isDisabled: !memberPhone,
      }}
    >
      <AvatarHeader {...contactDetails} logo={logoSrc(userId, id)} />
      <Divider orientation='horizontal' />
      <Stack gap='20px' sx={{ py: 1 }}>
        <ContactDetailsField
          labelColor='text.primary'
          label='Call time:'
          value={!callTime ? CALLTIME_PLACEHOLDER : callTime}
        />
        <ContactDetailsField
          labelColor='text.primary'
          label='Wrap time:'
          value={!wrapTime ? CALLTIME_PLACEHOLDER : wrapTime}
        />
      </Stack>
      <Divider orientation='horizontal' />
      <Box pt={1}>
        <ContactDetailsField label='User Group' value={groupTitle} />
      </Box>
      <Stack gap='12px' pt={1}>
        {title && <ContactDetailsField label='Title' value={title} />}
        <ContactDetailsField label='Email' value={email} />
        {isMissingPhone ? (
          <Box sx={style.phoneWrapper}>
            <Typography variant='subtitle2' color='text.secondary'>
              Phone
            </Typography>
            <PhoneInput
              error={errors.phone}
              value={memberPhone ?? ''}
              placeholder='Add phone'
              helperText='Incorrect phone number. Please try again.'
              onChange={handlePhoneChange}
              sx={style.phoneInput}
            />
          </Box>
        ) : (
          <ContactDetailsField label='Phone' value={phone} />
        )}
      </Stack>
    </ContactDetailsFormWrapper>
  )
}

export default RecipientProfile
