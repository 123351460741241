import { useDispatch } from 'react-redux'
import React, { FC, useEffect, useState } from 'react'
import { Button, Menu, MenuItem } from '@mui/material'
import useChangeStatus from '../../../hooks/callsheet/useChangeStatus'

import { setIsCallsheetConfirmModal } from '../../../store/Builder/actions'
import { CallsheetStatuses } from '../../../store/Callsheet/types'
import { ActionBtn, btnText, ButtonStatuses, menuItems } from './constants'
import { CaretBottomIcon } from '../../../assets/icons'

import style from './style'

const ChangeStatusButton: FC<{ status: CallsheetStatuses; id?: string }> = ({
  status,
  id,
}) => {
  const dispatch = useDispatch()
  const { onChangeStatus } = useChangeStatus()
  const [actionBtn, setActionBtn] = useState<ActionBtn>({
    txt: 'Accept Callsheet',
  })

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOptionChoose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const hadleStatusChoose = (value: ButtonStatuses) => () => {
    onChangeStatus(value)
    handleClose()
  }

  useEffect(() => {
    if (status) {
      setActionBtn(btnText[status])
    }
  }, [status, id])

  if (
    status === CallsheetStatuses.PENDING ||
    status === CallsheetStatuses.DECLINED
  ) {
    return (
      <Button
        color='info'
        variant='contained'
        onClick={() => dispatch(setIsCallsheetConfirmModal(true))}
        sx={style.statusBtn(status)}
        endIcon={actionBtn.icon}
      >
        {actionBtn.txt}
      </Button>
    )
  }
  return (
    <div>
      <Button
        color='info'
        variant='contained'
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOptionChoose}
        sx={style.statusBtn(status)}
        endIcon={actionBtn.icon}
      >
        {actionBtn.txt}{' '}
        {status === CallsheetStatuses.ACCEPTED && <CaretBottomIcon />}
      </Button>
      <Menu
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={style.menu}
      >
        {menuItems
          .filter(item => item.status !== status)
          .map(item => (
            <MenuItem
              sx={style.statusItem(item.status)}
              onClick={hadleStatusChoose(item.status)}
            >
              {item.txt}
              {item.icon}
            </MenuItem>
          ))}
      </Menu>
    </div>
  )
}

export default ChangeStatusButton
