import moment from 'moment'
import { cloneDeep, toUpper } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { useSelector, useDispatch } from 'react-redux'
import React, { FC, useMemo, useState } from 'react'

import { Box, Button, Stack, Typography } from '@mui/material'

import {
  selectEvents,
  selectEventsRequestStatus,
} from '../../../../store/Events/selectors'
import {
  addEvent,
  deleteEvent,
  updateEvent,
} from '../../../../store/Events/actions'
import { ScheduleEvent } from '../../../../store/Events/types'

import AddEventField from './AddEventField'
import ScheduleItem from './ScheduleItem'
import CustomTimeLine from './CustomTimeLine'
import Loader from '../../../../components/Loader'

import { getDuration } from '../../../../utils/getDuration'
import { RequestStatuses } from '../../../../api/constants'

import { PlusIcon } from '../../../../assets/icons'

import style from './style'

type ProductionScheduleType = {
  isPreview?: boolean
}
const ProductionSchedule: FC<ProductionScheduleType> = ({ isPreview }) => {
  const dispatch = useDispatch()
  const events = useSelector(selectEvents)
  const requestStatus = useSelector(selectEventsRequestStatus)
  const [mode, setMode] = useState<{ isAdding: boolean; isEditing: boolean }>({
    isAdding: false,
    isEditing: false,
  })
  const [eventToEdit, setEventToEdit] = useState<ScheduleEvent | null>(null)

  const handleEventUpdate = (
    name: string,
    startDate: string,
    endDate: string
  ) => {
    const newEvent = {
      ...eventToEdit,
      startDate,
      name,
      endDate,
    }

    if (eventToEdit) {
      const id = eventToEdit.id || eventToEdit.tmpId || ''
      dispatch(updateEvent(id, newEvent))
    } else {
      dispatch(addEvent(uuidv4(), newEvent))
    }

    eventToEdit && setEventToEdit(null)
    setMode({ isAdding: false, isEditing: false })
  }

  const handleEventDeletion = () => {
    if (eventToEdit) {
      const { tmpId, id } = eventToEdit

      dispatch(deleteEvent(id || tmpId || ''))
    }

    setEventToEdit(null)
  }

  const handleEdit = (event: ScheduleEvent) => () => {
    setEventToEdit(event)
    setMode({ isAdding: false, isEditing: true })
  }

  const toggleAddEvent = () => {
    setEventToEdit(null)
    setMode(prev => ({ isEditing: false, isAdding: !prev.isAdding }))
  }

  const sortedEvents = useMemo(() => {
    return cloneDeep(events).sort(
      (a, b) =>
        moment(`2023-03-24 ${a.startDate}`, 'YYYY-MM-DD h:mma').unix() -
        moment(`2023-03-24 ${b.startDate}`, 'YYYY-MM-DD h:mma').unix()
    )
  }, [events])

  return (
    <Box maxWidth={isPreview ? '500px' : '640px'}>
      <Box sx={style.wrapper}>
        <Typography sx={style.label(isPreview)}>Production Schedule</Typography>
        {isPreview ? null : (
          <Button
            variant='contained'
            sx={style.addBtn}
            onClick={toggleAddEvent}
          >
            Add event <PlusIcon />
          </Button>
        )}
      </Box>
      {requestStatus === RequestStatuses.PENDING ? (
        <Loader />
      ) : (
        <>
          {events.length === 0 ? (
            <Box sx={style.scheduleDefault}>
              <Typography>
                Production schedule can be created with a lot of events. Just{' '}
                <b>click</b> on a <b>“Add Event”</b> button to start working
                with schedule
              </Typography>
            </Box>
          ) : (
            <Box
              display='flex'
              justifyContent='space-between'
              gap='16px'
              mt={0}
            >
              <CustomTimeLine
                events={sortedEvents.map(e => toUpper(e.startDate))}
              />
              <Stack gap='12px'>
                {sortedEvents.map((item, idx) => {
                  const { name, startDate, endDate, ...ids } = item
                  return (
                    <ScheduleItem
                      duration={getDuration(startDate, endDate)}
                      name={name}
                      key={ids.id || ids.tmpId}
                      altStyle={idx % 2 === 0}
                      onEdit={handleEdit(item)}
                      isPreview={isPreview}
                    />
                  )
                })}
              </Stack>
            </Box>
          )}
        </>
      )}

      {eventToEdit && mode.isEditing && (
        <AddEventField
          onSave={handleEventUpdate}
          onDelete={handleEventDeletion}
          defaultEvent={eventToEdit}
          isPreview={isPreview}
        />
      )}
      {mode.isAdding && (
        <AddEventField
          onSave={handleEventUpdate}
          onDelete={toggleAddEvent}
          isPreview={isPreview}
        />
      )}
    </Box>
  )
}

export default ProductionSchedule
