const style = {
  wrapper: {
    height: '48px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px',
    borderRadius: '4px',
  },
  avatarWrapper: {
    display: 'flex',
    gap: 2,
    cursor: 'pointer',
  },
  missingPhoneChip: {
    color: '#FFFFFF',
    backgroundColor: '#FF8882',
    height: '22px',
    marginRight: '12px',
    marginLeft: '8px',
    borderRadius: '26px',
    span: {
      fontSize: '10px',
      lineHeight: '14px',
      fontWeight: 900,
      padding: '4px 16px',
    },
  },
  editContainer: {
    display: 'flex',
    alignItems: 'center',
    svg: {
      cursor: 'pointer',
      marginLeft: '15px',
    },
  },
}

export default style
