import { Divider, Grid, Stack, Typography } from '@mui/material'
import React, { FC } from 'react'

import useCallsheetsCompanies from '../../hooks/callsheet/useCallsheetsCompanies'
import { Callsheet, CallsheetStatuses } from '../../store/Callsheet/types'
import CallsheetListItem from './CallsheetListItem'
import { grids } from './constants'

import style from './style'

const CallsheetList: FC<{ callsheets: Callsheet[] }> = ({ callsheets }) => {
  const { callsheetsCompanies } = useCallsheetsCompanies(callsheets)

  return (
    <Stack sx={style.wrapper}>
      <Grid container sx={style.header}>
        <Grid item xs={grids.title}>
          <Typography sx={style.label}>Title</Typography>
        </Grid>
        <Grid item xs={grids.status}>
          <Typography sx={style.label}>Status</Typography>
        </Grid>
        <Grid item xs={grids.date}>
          <Typography sx={style.label}> Date</Typography>
        </Grid>
        <Grid item xs={grids.company}>
          <Typography sx={style.label}> Production Company</Typography>
        </Grid>
        <Grid item xs={grids.invitations}>
          <Typography sx={style.label}> Invitations</Typography>
        </Grid>
      </Grid>
      <Divider />
      <Stack>
        {callsheets.map(
          (
            { title, eventDate, status, id, ownerId, productCompanyId },
            idx: number
          ) => (
            <CallsheetListItem
              key={idx}
              ownerId={ownerId}
              id={id!}
              title={title}
              eventDate={eventDate}
              companyName={callsheetsCompanies[productCompanyId]?.name}
              status={status as CallsheetStatuses}
              divider={!(idx === callsheets.length - 1)}
            />
          )
        )}
      </Stack>
    </Stack>
  )
}

export default CallsheetList
