import React, { FC, PropsWithChildren, useRef } from 'react'
import { Box } from '@mui/material'
import useStyles from './style'

type CustomScrollType = {
  height: number
  onScroll?: (scrollPercentage: number) => void
}

const CustomScroll: FC<CustomScrollType & PropsWithChildren> = ({
  children,
  height,
  onScroll,
}) => {
  const classes = useStyles({ height })
  const scrollContainerRef = useRef(null)

  const handleScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target
    const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100

    if (onScroll) {
      onScroll(scrollPercentage)
    }
  }

  return (
    <Box
      className={classes.container}
      ref={scrollContainerRef}
      onScroll={handleScroll}
    >
      {children}
    </Box>
  )
}

export default CustomScroll
