const PREFIX = 'GALLERY/'

export const SET_CALLSHEETS_VIEW = `${PREFIX}SET_CALLSHEETS_VIEW` as const
export const SET_CALLSHEET_STATISTIC_ID =
  `${PREFIX}SET_CALLSHEET_STATISTIC_ID` as const
export const SET_CALLSHEETS_FILTER = `${PREFIX}SET_CALLSHEETS_FILTER` as const
export const SET_CALLSHEETS_SORT_TAG =
  `${PREFIX}SET_CALLSHEETS_SORT_TAG` as const
export const SET_CALLSHEET_STATISTIC_MODAL =
  `${PREFIX}SET_CALLSHEET_STATISTIC_MODAL` as const
