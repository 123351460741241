import { DRAWER_WIDTH } from 'src/themes/constants'

const style = {
  layout: {
    width: '100%',
  },
  wrapper: {
    paddingLeft: '140px',
    paddingRight: '80px',
    zIndex: 0,
  },
  container: (isPersistentPanel: boolean) => ({
    maxWidth: isPersistentPanel ? `calc(100% - ${DRAWER_WIDTH})` : '1220px',
    margin: '0 auto',
    marginRight: isPersistentPanel ? DRAWER_WIDTH : 'auto',
    transition: 'margin-right 0.5s ease-out 0.5s',
  }),
  content: {
    height: 'calc(100vh  - 156px)',
  },
}

export default style
