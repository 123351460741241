import React, { FC, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Stack, TextField, Typography } from '@mui/material'

import {
  selectAnnouncementRequestStatus,
  selectAnnouncements,
  selectCombinedAnnouncement,
  selectIsAnnouncementCombined,
} from '../../../../store/Announcements/selectors'
import {
  addAnnouncement,
  deleteAnnouncement,
  getAnnouncementRequest,
  selectAnnouncement,
  setAnnouncement,
  setCombinedAnnouncement,
  switchCombinedAnnouncement,
} from '../../../../store/Announcements/actions'

import CustomSwitch from '../../../../components/CustomSwitch'
import CustomTextButton from '../../../../components/CustomTextButton'
import EditableTextField from '../../../../components/EditableTextField'
import InfoPopover from './InfoPopover/InfoPopover'
import Loader from '../../../../components/Loader'

import {
  InfoIcon,
  PlusCircle,
  WarningOctagonIcon,
  WarningOctagonCheckedIcon,
  WarningOctagonDisabledIcon,
  PlusCircleDisabled,
} from '../../../../assets/icons'
import { RequestStatuses } from '../../../../api/constants'
import { MAX_ANNOUNCEMENTS_NUMBER } from '../../../../constants'

import style from './style'

type IDs = {
  id?: string
  tmpId?: string
}

const Announcements: FC = () => {
  const dispatch = useDispatch()

  const announcements = useSelector(selectAnnouncements)
  const isCombined = useSelector(selectIsAnnouncementCombined)
  const combinedAnnouncement = useSelector(selectCombinedAnnouncement)
  const requestStatus = useSelector(selectAnnouncementRequestStatus)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleAddition = () => {
    const newAnnouncement = {
      tmpId: uuidv4(),
      content: '',
      important: false,
    }
    dispatch(addAnnouncement(newAnnouncement))
  }

  const handleCombinedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setCombinedAnnouncement(e.target.value))
  }

  const handleValueChange =
    ({ id, tmpId }: IDs) =>
    (value: string) => {
      if (id) {
        dispatch(setAnnouncement(id, value))
      }
      if (tmpId) {
        dispatch(setAnnouncement(tmpId, value))
      }
    }

  const handleSelect =
    ({ id, tmpId }: IDs) =>
    () => {
      if (id) {
        dispatch(selectAnnouncement(id))
      }
      if (tmpId) {
        dispatch(selectAnnouncement(tmpId))
      }
    }

  const handleDelete =
    ({ id, tmpId }: IDs) =>
    () => {
      if (id) {
        dispatch(deleteAnnouncement(id))
      }
      if (tmpId) {
        dispatch(deleteAnnouncement(tmpId))
      }
    }

  const isDisabled = announcements.length === MAX_ANNOUNCEMENTS_NUMBER
  const isPopover = Boolean(anchorEl)

  return (
    <Box maxWidth='458px'>
      <Stack gap={0}>
        <Box sx={style.header}>
          <Box sx={style.labelWrapper}>
            <Typography variant='h5'>Announcements</Typography>
            <Box
              aria-owns={isPopover ? 'mouse-over-popover' : undefined}
              aria-haspopup='true'
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <InfoIcon />
            </Box>
          </Box>
          <Box sx={style.switchWrapper}>
            <Typography>Combined Announcement</Typography>
            <CustomSwitch
              checked={isCombined}
              onChange={() => dispatch(switchCombinedAnnouncement())}
            />
          </Box>
        </Box>
        {requestStatus === RequestStatuses.PENDING ? (
          <Loader />
        ) : (
          <>
            {isCombined ? (
              <TextField
                placeholder='Type all announcements'
                value={combinedAnnouncement}
                onChange={handleCombinedChange}
                multiline
              />
            ) : (
              <>
                {announcements.map((item, idx) => {
                  const { important, content, ...ids } = item
                  return (
                    <EditableTextField
                      key={ids.id || ids.tmpId}
                      placeholder='Announcement'
                      isReversed
                      editMode
                      onChange={handleValueChange(ids)}
                      onSelect={handleSelect(ids)}
                      onDelete={handleDelete(ids)}
                      isChecked={important}
                      defaultValue={content}
                      helperText='Already had a announcement with that content'
                      selectionColor='#FF3B30'
                      toggleEditMode={false}
                      iconsProps={{
                        Icon: WarningOctagonIcon,
                        IconChecked: WarningOctagonCheckedIcon,
                        IconDisabled: WarningOctagonDisabledIcon,
                      }}
                    />
                  )
                })}
                <Box alignSelf='flex-start'>
                  <CustomTextButton
                    title='Add additional announcement'
                    Icon={isDisabled ? PlusCircleDisabled : PlusCircle}
                    onClick={handleAddition}
                    disabled={isDisabled}
                  />
                </Box>
              </>
            )}
          </>
        )}
      </Stack>
      <InfoPopover
        open={isPopover}
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
      >
        <Box sx={style.popOver}>
          <Typography variant='h5'>Required Announcements</Typography>
          <Typography variant='subtitle1'>
            Announcements that will be confirmed by the user during the
            confirmation process can be set as either <b>separate</b>{' '}
            announcements or a <b>combined</b> announcement. To mark an
            individual announcement as important, just select the (!) icon
            beside the individual announcement.
          </Typography>
        </Box>
      </InfoPopover>
    </Box>
  )
}

export default Announcements
