import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectWalkieChannels } from '../../../store/WalkieChannels/selector'
import style from '../style'

const ChannelsView = () => {
  const channels = useSelector(selectWalkieChannels)

  return (
    <>
      {channels.length ? (
        <Box sx={{ width: '100%' }}>
          <Typography sx={style.title}>Walkie Channels</Typography>
          {channels.map(({ id, name, channel }) => (
            <Paper key={id} variant='outlined' sx={style.channelItem}>
              <Typography variant='subtitle2'>{`[${name}]`}</Typography>
              <Typography variant='subtitle2'>#{channel}</Typography>
            </Paper>
          ))}
        </Box>
      ) : null}
    </>
  )
}

export default ChannelsView
