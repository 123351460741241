import { Contact } from '../store/Contacts/types'
import { createFilter } from './filters'

const KEYS_BY_NAME = ['firstName', 'lastName', 'email']

export const typeFilter = (type: string) => (contacts: Contact[]) => {
  switch (type) {
    case 'friends':
      return contacts.filter(contact => contact.isFriend)
    case 'contacts':
      return contacts.filter(contact => !contact.isFriend)
    default:
      return contacts
  }
}

export const nameFilter = (searchName: string) => (contacts: Contact[]) => {
  return contacts.filter(createFilter(searchName, KEYS_BY_NAME))
}

export const groupsFilter =
  (selectedTags: string[]) => (contacts: Contact[]) => {
    if (!selectedTags.length) {
      return contacts
    }

    return contacts.filter(contact =>
      contact.tags?.some(tag => selectedTags.includes(tag.name))
    )
  }

export const getContactsById = (ids: string[], contacts: Contact[]) =>
  contacts.filter(contact => ids.includes(contact.id))

export const formatPhoneNumber = (phoneNumberString?: string) => {
  if (!phoneNumberString) {
    return ''
  }

  const cleaned = phoneNumberString.replace(/\D/g, '')
  const maxLength = 10
  const formattedNumber = cleaned.slice(0, maxLength)
  const match = formattedNumber.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }

  return ''
}

export const stripPhoneNumber = (pattern = '') => pattern.replace(/\D/g, '')
